import { CardHeader } from '@bindystreet/bindystreet.kit.react';
import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import IPostComment from 'Colugo/interfaces/social/IPostComment';
import { skinType } from 'component/utility/ConfirmDeletePopup';
import { useState } from 'react';
import ReportButtons from './ReportButtons';
import ReportPostComments from './ReportPostComments';

type Props = {
  closeModal: () => void;
  selectedUserReport?: IUserReport;
  onClickDeleteIcon: (postId?: string) => void;
  onClickSuspendIcon: (userId?: string) => void;
  onClickBanIcon: (userId?: string) => void;
  setUserReportId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isLoading: boolean;
  isUserBanned: boolean;
  isUserSuspended: boolean;
};

export default function CommentReportModal(props: Props) {
  const {
    closeModal,
    selectedUserReport,
    onClickDeleteIcon,
    onClickSuspendIcon,
    onClickBanIcon,
    setUserReportId,
    isLoading,
    isUserBanned,
    isUserSuspended
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<skinType>('suspendUser');

  const selectedUserReportEntity = selectedUserReport?.entity as IPostComment;
  const isDeleted = !!selectedUserReportEntity.deletedAt;

  function handleClickConfirmDeletePopupSubmit() {
    modalState === 'suspendUser'
      ? onClickSuspendIcon(selectedUserReport?.reportedUser?.id)
      : onClickBanIcon(selectedUserReport?.reportedUser?.id);
    setIsModalOpen(false);
  }

  return (
    <div style={{ height: '510px' }}>
      <CardHeader
        titleAlign="text-center"
        title={'Comment'}
        fontWeight="font-semibold"
      />
      <div className="text-left mx-4 mt-4 flex flex-row">
        <img
          src={selectedUserReportEntity.post?.metadata?.attachments?.urls![0]}
          className="my-auto"
          width={'400px'}
          alt="Post img"
        />
        <div className="w-full ml-4 flex-col flex">
          <span className="font-semibold">
            Username: {selectedUserReport?.reportedUser?.tag}
          </span>
          <span className="font-semibold">
            Email: {selectedUserReport?.reportedUser?.email}
          </span>
          <ReportPostComments
            comments={selectedUserReportEntity?.post?.comments}
            selectedUserReport={selectedUserReport}
          />
          <ReportButtons
            isDeleted={isDeleted}
            entityType={'Comment'}
            closeModal={closeModal}
            handleClickConfirmDeletePopupSubmit={
              handleClickConfirmDeletePopupSubmit
            }
            selectedUserReport={selectedUserReport}
            onClickDeleteIcon={onClickDeleteIcon}
            onClickSuspendIcon={onClickSuspendIcon}
            onClickBanIcon={onClickBanIcon}
            setUserReportId={setUserReportId}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalState={modalState}
            setModalState={setModalState}
            isLoading={isLoading}
            isUserBanned={isUserBanned}
            isUserSuspended={isUserSuspended}
          />
        </div>
      </div>
    </div>
  );
}
