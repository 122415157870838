import ky, { Options } from 'ky';
import useSWR, { cache, SWRConfiguration } from 'swr';
import {
  BUDGIE_API_URL,
  newTokenHeaderKeyValueFail,
  newTokenHeaderKeyValue
} from '../utility/constants/constants';
import localStore from 'utility/general/localStore';
import { toast } from 'react-toastify';

const budgieHttpClientFactory = () => {
  const token = localStore.getItem('token');
  const defaultOptions: Options = {
    prefixUrl: BUDGIE_API_URL,
    timeout: false,
    headers: {
      Authorization: `bearer ${token}`,
      TransformableImage: 'False'
    }
  };

  return ky.create(defaultOptions);
};

const authOptions: Options = {
  prefixUrl: BUDGIE_API_URL
};

export type requestResponse<T = any> = Promise<
  | {
      error: Response;
      data: undefined;
    }
  | {
      data: T;
      error: undefined;
    }
>;
export type swrRequestResponse<T = any> = {
  data?: T;
  isError: boolean | undefined;
  mutate: any;
  isLoading: boolean;
};

export const authHttpClient = ky.create(authOptions);
const emptyHttpClient = ky.create({});

export const useGetHttpClient = (
  url: string | null,
  options: SWRConfiguration<any, any, any> = {},
  toCache: boolean = true,
  fullyTyped: boolean = false
) => {
  const fetcher = (url: string, options?: Options) => {
    if (toCache && cache.has(url)) {
      return cache.get(url);
    }

    if (fullyTyped) {
      options = {
        ...options,
        headers: {
          ...options?.headers,
          JsonSettings: 'FullyTyped',
          TransformableImage: 'False'
        }
      };
    }

    //null string does not trigger fetcher
    return budgieHttpClientFactory()
      .get(url, options)
      .then((response) => {
        checkAndUpdateToken(response?.headers?.get(newTokenHeaderKeyValue));
        return response.json();
      })
      .catch((err) => {
        console.error(`useGet http request failed:${err}`);
        checkAndUpdateToken(err.response?.headers?.get(newTokenHeaderKeyValue));
        throw err;
      });
  };

  const { data, error, mutate } = useSWR(url, fetcher, options);

  if (error) {
    console.error(error);
  }

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
};

const verbHttpClient = async (
  verb: 'post' | 'put' | 'get' | 'delete',
  url: string,
  body: any = undefined,
  options: Options = {},
  fullyTyped: boolean = false,
  client?: any //TODO: properly write out or find the type
) => {
  if (fullyTyped) {
    options = {
      ...options,
      headers: {
        ...options?.headers,
        JsonSettings: 'FullyTyped',
        TransformableImage: 'False'
      }
    };
  }

  const httpClient = client || budgieHttpClientFactory();
  options.body = JSON.stringify(body);
  if (!client) {
    options.headers = {
      ...options.headers,
      'content-type': 'application/json',
      TransformableImage: 'False'
    };
  }

  let response: Response | undefined = undefined;
  let data: any;
  try {
    switch (verb) {
      case 'post':
        response = await httpClient.post(url, options);
        break;
      case 'get':
        response = await httpClient.get(url, options);
        break;
      case 'delete':
        response = await httpClient.delete(url, options);
        break;
      case 'put':
        response = await httpClient.put(url, options);
        break;
    }
    checkAndUpdateToken(response?.headers?.get(newTokenHeaderKeyValue));
    data = await parseJson(response!);
  } catch (error) {
    if (response && response.ok) {
      console.error(error);
      return { error: undefined, data: {} };
    }
    checkAndUpdateToken(response?.headers?.get(newTokenHeaderKeyValue));
    return handleErrors(error as any);
  }
  return { data: data || {}, error: undefined };
};

const parseJson = async (response: Response) => {
  const text = await response.text();
  try {
    if (text === '') {
      return;
    }
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    throw new Error('Did not receive JSON, instead received: ' + text);
  }
};

export const postHttpClient = async (
  url: string,
  body: any = undefined,
  options: Options = {},
  fullyTyped: boolean = false
) => {
  return verbHttpClient('post', url, body, options, fullyTyped);
};

export const getHttpClient = (
  url: string,
  options: Options = {},
  fullyTyped: boolean = false,
  noPrefixUrl: boolean = false
): requestResponse => {
  return verbHttpClient(
    'get',
    url,
    undefined,
    options,
    fullyTyped,
    noPrefixUrl && emptyHttpClient
  ) as requestResponse;
};

export const putHttpClient = async (
  url: string,
  body: any = undefined,
  options: Options = {},
  fullyTyped: boolean = false
) => {
  return verbHttpClient(
    'put',
    url,
    body,
    options,
    fullyTyped
  ) as requestResponse;
};

export const deleteHttpClient = async (
  url: string,
  body: any = undefined,
  options: Options = {}
) => {
  return verbHttpClient('delete', url, body, options);
};

export const handleError = async (error: Response) => {
  const body = await error?.json();
  const msg = body.message || 'Error, please try again';
  toast.error(msg);
};

// lame
const handleErrors = async (err: {
  Response: Response;
  response: Response;
}) => {
  let response: Response = err.Response;
  if (!response) {
    response = err.response;
  }
  return { error: response, data: undefined };
};

const checkAndUpdateToken = (newToken?: string | null) => {
  if (!newToken) {
    return;
  }
  console.trace();

  if (newToken?.toLowerCase() === newTokenHeaderKeyValueFail.toLowerCase()) {
    console.error(
      'The failure case was hit correctly. Please copy this message and stack trace into KW-726'
    );
    window.location.reload();
    return;
  }

  localStore.setItem('token', newToken);
};

// Used solely for progress event during a request.
export const requestXHR = (
  method: 'POST' | 'GET' | 'PUT',
  url: string,
  file: File,
  onProgressUpdate?: (progress: number) => void
): Promise<{ error?: string; data: any }> => {
  const token = localStore.getItem('token');
  return new Promise(function (resolve, reject) {
    const formData = new FormData();
    formData.append('file', file);
    let xhr = new XMLHttpRequest();

    xhr.open(method, BUDGIE_API_URL + url);
    xhr.setRequestHeader('Authorization', `bearer ${token}`);
    xhr.setRequestHeader('TransformableImage', `False`);
    xhr.withCredentials = false;

    if (onProgressUpdate) {
      xhr.upload.onprogress = (event) => {
        const percentage = (event.loaded / event.total) * 100;
        onProgressUpdate(percentage);
      };
    }
    xhr.onload = function () {
      if (this.readyState === this.HEADERS_RECEIVED) {
        xhr.getResponseHeader(newTokenHeaderKeyValue) &&
          checkAndUpdateToken(xhr.getResponseHeader(newTokenHeaderKeyValue));
      }
      if (this.status >= 200 && this.status < 300) {
        resolve({
          data: JSON.parse(xhr.response),
          error: undefined
        });
      } else {
        resolve({
          data: undefined,
          error: xhr.status.toString()
        });
      }
    };
    xhr.onerror = function () {
      resolve({
        data: undefined,
        error: xhr.status.toString()
      });
    };
    xhr.send(formData);
  });
};
