import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import SignIn from './SignIn';
import { useHistory } from 'react-router-dom';

const LoginContainer = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/`);
  }, [history]);

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white"
      style={{ backgroundColor: '#263238' }}
    >
      <div className="container mb-64 text-left px-44">
        <div className="md:w-full ">
          <h2 className="text-4xl">
            Beware those who enter the lair of the <b>Kraken.</b>
          </h2>
          <span className="text-xl text-gray-300"></span>
        </div>
        <div className=" md:w-full xl:w-2/3">
          <Switch>
            <Route path="/" component={SignIn} />
          </Switch>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default LoginContainer;
