import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ISpiritCustomization } from 'Colugo/interfaces/spirits';
import {
  SpiritCustomizationOperations,
  useReqListSpiritCustomizations
} from 'Colugo/operations/spiritCustomizations';
import { handleError } from 'provider/httpClient';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import SpiritCustomizationEditor from './SpiritCustomizationEditor';

const spiritCustomizationOperations = container.resolve(
  SpiritCustomizationOperations
);

function SpiritCustomizationContainer() {
  const {
    data: spiritCustomizations,
    isLoading,
    isError,
    mutate
  } = useReqListSpiritCustomizations();

  const handleDeleteSpiritCustomization = async (
    spiritCustomizationToDelete: ISpiritCustomization
  ) => {
    if (!spiritCustomizationToDelete?.id) {
      toast.error('Invalid spiritCustomization Id, please try again');
      return false;
    }

    const { error } = await spiritCustomizationOperations.deleteAsync(
      spiritCustomizationToDelete.id
    );

    if (error) {
      toast.error('Failed to delete spiritCustomization, please try again');
      return false;
    }

    const newSpiritCustomizations = spiritCustomizations?.filter(
      (spiritCustomization) =>
        spiritCustomization.id !== spiritCustomizationToDelete.id
    );

    mutate(newSpiritCustomizations, true);
    return true;
  };

  const handleCreateSpiritCustomization = async (
    spiritCustomizationToAdd: ISpiritCustomization
  ) => {
    if (
      spiritCustomizationToAdd.name.length < 5 ||
      spiritCustomizationToAdd.name.length > 25
    ) {
      toast.error('Name must be between 5 and 25 characters');
      return false;
    }
    if (
      spiritCustomizationToAdd.description.length < 5 ||
      spiritCustomizationToAdd.description.length > 90
    ) {
      toast.error('Description must be between 5 and 90 characters');
      return false;
    }

    const { data, error } = await spiritCustomizationOperations.createAsync(
      spiritCustomizationToAdd
    );

    if (!data || error) {
      error && handleError(error);
      return false;
    }

    const newSpiritCustomizations = spiritCustomizations
      ? [...spiritCustomizations, data]
      : [data];
    mutate(newSpiritCustomizations, true);
    return true;
  };

  const handleUpdateSpiritCustomization = async (
    spiritCustomizationToAdd: ISpiritCustomization
  ) => {
    if (
      spiritCustomizationToAdd.name.length < 5 ||
      spiritCustomizationToAdd.name.length > 25
    ) {
      toast.error('Name must be between 5 and 25 characters');
      return false;
    }
    if (
      spiritCustomizationToAdd.description.length < 5 ||
      spiritCustomizationToAdd.description.length > 90
    ) {
      toast.error('Description must be between 5 and 90 characters');
      return false;
    }

    const { error } = await spiritCustomizationOperations.updateAsync(
      spiritCustomizationToAdd
    );

    if (error) {
      error && handleError(error);
      return false;
    }

    const newSpiritCustomizations = spiritCustomizations
      ? [...spiritCustomizations, spiritCustomizationToAdd]
      : [spiritCustomizationToAdd];
    mutate(newSpiritCustomizations, true);
    return true;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load spiritCustomizations from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full overflow-hidden">
      <SpiritCustomizationEditor
        spiritCustomizations={spiritCustomizations || []}
        deleteSpiritCustomization={handleDeleteSpiritCustomization}
        createSpiritCustomization={handleCreateSpiritCustomization}
        updateSpiritCustomization={handleUpdateSpiritCustomization}
      />
    </div>
  );
}

export default SpiritCustomizationContainer;
