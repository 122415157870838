export const {
  NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_MOCKED: IS_APP_MOCKED,
  REACT_APP_API_URL: BUDGIE_API_URL = '',
  REACT_APP_MAPBOX_ACCESS_TOKEN: MAPBOX_ACCESS_TOKEN = '',
  REACT_APP_IPSTACK_KEY: IPSTACK_KEY = '',
  REACT_APP_ENV: APP_ENV = 'LOCAL',
  REACT_APP_ENV_PATH = ''
} = process.env;

export const localStorage_Me = 'me';
export const localStorage_Token = 'token';
export const localStorage_isPreviewerOn = 'isPreviewerOn';
export const modalRoot = 'modal-root';
export const newTokenHeaderKeyValueFail = 'FAIL';
export const newTokenHeaderKeyValue = 'NewToken';
export const gameplayGridPreviewRoot = 'gameplay-grid-preview-root';
export const MAXIMUM_FILE_SIZE = 5e7;
