import { Cell } from 'react-table';

export const reportedCommentsTableColumns = [
  {
    Header: 'Image',
    accessor: 'entity.post.metadata.attachments.urls[0]',
    width: 1,
    Cell: (cellInfo: { cell: Cell }) => {
      return (
        <div className="m-auto w-16 h-16 p-0 flex flex-col">
          <div className="flex-grow"></div>
          {cellInfo.cell.value && (
            <img
              width={'80%'}
              height={'80%'}
              className="rounded-md"
              src={cellInfo.cell.value}
              alt="userReport preview"
            />
          )}
          <div className="flex-grow"></div>
        </div>
      );
    }
  },
  {
    Header: 'Text',
    accessor: 'entity.text'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Reported User Email',
    accessor: 'reportedUser.email'
  },
  {
    Header: 'Reported Reason',
    accessor: 'reportReason'
  },
  {
    Header: '',
    accessor: 'entity.deletedAt'
  },
  {
    Header: '',
    accessor: 'id',
    width: 1
  }
];
