import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class CollectionOperations extends AbstractOperations {
  protected basePath: string = 'collection';

  public async reindexAllAsync(secretValue: string): requestResponse {
    return await this.executePost(`reindexAll?code=${secretValue}`);
  }
}

export default CollectionOperations;
