import React, { useEffect, useState } from 'react';
import { Input } from '@bindystreet/bindystreet.kit.react';
import {
  ISpiritCustomization,
  ISpiritCustomizationForTable
} from 'Colugo/interfaces/spirits';
import { BiSearchAlt } from 'react-icons/bi';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<ISpiritCustomizationForTable>[];
  spiritCustomizations: ISpiritCustomizationForTable[];
  onClickDeleteIcon: (spiritCustomizationId: string) => void;
  onClickRow: (spiritCustomizationId: string) => void;
  rowSelectedId?: string;
  customizationType: string;
};

const SpiritCustomizationTable: React.FC<Props> = (props) => {
  const {
    spiritCustomizations,
    tableColumns,
    onClickDeleteIcon,
    children,
    onClickRow,
    rowSelectedId,
    customizationType
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [isDeleteHover, setIsDeleteHover] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: spiritCustomizations
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const table = (
    <div>
      <div {...getTableProps()} className="w-full pb-32">
        <div>{spiritCustomizationTableHeaderConfig(headerGroups)}</div>
        {spiritCustomizationTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          setIsDeleteHover,
          isDeleteHover,
          onClickDeleteIcon,
          spiritCustomizations,
          onClickRow,
          rowSelectedId
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          customizationType={customizationType}
        />
        {children}
      </div>
      {table}
    </div>
  );
};

export default SpiritCustomizationTable;

//Component specifically used for this table
const GlobalFilter: React.FC<any> = ({
  globalFilter,
  setGlobalFilter,
  customizationType
}) => {
  const [filterValue, setFilterValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 500);

  //GlobalFilter resets along with everything else on a mutation that
  // changes items in the table. This is messy but it works for an admin tool
  useEffect(() => {
    if (filterValue !== globalFilter) {
      setGlobalFilter(filterValue);
    }
  }, [globalFilter, setGlobalFilter, filterValue]);

  return (
    <div className="w-4/5 flex">
      <div className="relative flex flex-row w-full">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder={`Search ${customizationType} Customizations`}
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
      </div>
    </div>
  );
};

export const spiritCustomizationTableColumns: Column<ISpiritCustomizationForTable>[] =
  [
    {
      Header: 'Key',
      accessor: 'key'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Price',
      accessor: 'priceString'
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Colour1',
      accessor: 'colour1'
    },
    {
      Header: 'Colour2',
      accessor: 'colour2'
    },
    {
      Header: '',
      accessor: 'id', //note: id must be the last row
      width: 50
    }
  ];

const getCellWidth = (header: string) => {
  switch (header) {
    case 'Name':
    case 'Key':
      return '200px';
    case 'Description':
      return '500px';
    case 'Price':
      return '60px';
    default:
      return '100px';
  }
};

const spiritCustomizationTableHeaderConfig = (
  headerGroups: HeaderGroup<ISpiritCustomization>[]
) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: getCellWidth(column.Header!.toString()) }}
            className="w-full ml-4"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>{column.render('Header')}</div>
              <div>
                {column.Header!.toString() === '' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const spiritCustomizationTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<ISpiritCustomization> | undefined
  ) => TableBodyProps,
  rows: Row<ISpiritCustomization>[],
  prepareRow: (row: Row<ISpiritCustomization>) => void,
  setIsDeleteHover: (isHover: boolean) => void,
  isDeleteHover: boolean,
  onClickDeleteIcon: (spiritCustomizationId: string) => void,
  spiritCustomizations: ISpiritCustomization[],
  onClickRow: (spiritCustomizationId: string) => void,
  rowSelectedId?: string
) => (
  <div
    className="overflow-y-auto"
    style={{ maxHeight: `calc(${getMaxViewHeight()}` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      const elementId = row.cells[row.cells.length - 1].value;
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap h-16 relative rounded-md ${bgcolor} hover:shadow-lg cursor-pointer`}
          onMouseEnter={() => setRowHovered(i)}
          onClick={() => onClickRow(elementId)} //SpiritCustomization Id
        >
          {rowSelectedId === elementId && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'my-1 ml-4 text-left';
            if (cell.column.Header?.toString() === '') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: getCellWidth(cell.column.Header.toString()) }}
                  className="absolute top-5 right-0"
                >
                  <div className="flex w-full flex-row">
                    <RiDeleteBin2Line
                      className={`text-2xl cursor-pointer ml-4 mr-4 ${
                        isDeleteHover && rowHovered === i
                          ? 'text-error1'
                          : 'text-theme6'
                      }`}
                      onMouseEnter={() => setIsDeleteHover(true)}
                      onMouseLeave={() => setIsDeleteHover(false)}
                      onClick={() => onClickDeleteIcon(cell.value)}
                    />
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} my-auto`}
                style={{
                  width: getCellWidth(cell.column.Header!.toString())
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
