import { ScreenType } from 'Colugo/interfaces/advertisement/IAdvertLocation';
import AdvertLocationEditor from './AdvertLocationEditor';

function CommnityEditor() {
  const containerTitle = 'Community Advert Management';

  return (
    <AdvertLocationEditor
      containerTitle={containerTitle}
      screenType={ScreenType.Community}
    />
  );
}

export default CommnityEditor;
