export const getMaxViewHeight = () => {
  if (window.innerHeight < 800) {
    return '65vh';
  }
  if (window.innerHeight < 1000) {
    return '70vh';
  }
  if (window.innerHeight < 1400) {
    return '74vh';
  }
  if (window.innerHeight < 1800) {
    return '75vh';
  }
  return '85vh';
};
