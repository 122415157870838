import { FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';

class EnumHelper {
  public static getEnumKeysForFlyout(enumValue: any) {
    const enumValues = Object.keys(enumValue).filter((item) => {
      return isNaN(Number(item));
    });
    return enumValues.map((val, index) => {
      return { label: val, value: val, key: index } as FlyoutMenuItem;
    });
  }

  public static getEnumValuesForFlyout(enumValue: any) {
    const enumValues = Object.values(enumValue).filter((item) => {
      return isNaN(Number(item));
    });
    return enumValues.map((val, index) => {
      return { label: val, value: val, key: index } as FlyoutMenuItem;
    });
  }
}

export function hasFlag(enumValue: number, flag: number): boolean {
  return (enumValue & flag) === flag;
}

export default EnumHelper;
