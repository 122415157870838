import { ScreenType } from 'Colugo/interfaces/advertisement/IAdvertLocation';
import AdvertLocationEditor from './AdvertLocationEditor';

function MapSearchCsEditor() {
  const containerTitle = 'Map, Search & Content Scroll Advert Management';

  return (
    <AdvertLocationEditor
      containerTitle={containerTitle}
      screenType={ScreenType.MapSearchAndContentScroll}
    />
  );
}

export default MapSearchCsEditor;
