import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { requestResponse } from '../../provider/HttpClient';
import { ICombo, IFilterGroup } from 'Colugo/interfaces/tags';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

@scoped(Lifecycle.ContainerScoped)
class ComboOperations extends AbstractOperations {
  protected basePath: string = 'tagging/combo';

  public async createAsync(combo: ICombo): requestResponse<ICombo> {
    return await this.executePost('', combo);
  }

  public async updateAsync(combo: ICombo): requestResponse<IFilterGroup> {
    return await this.executePut(`${combo.id}`, combo);
  }

  public async updateOrderAsync(
    comboId: string,
    position: number
  ): requestResponse<ICombo[]> {
    return await this.executePut(`${comboId}/order/${position}`);
  }

  public async addFilterGroupAsync(
    comboId: string,
    filterGroupId: string
  ): requestResponse<IFilterGroup> {
    return await this.executePost(`${comboId}/filterGroup/${filterGroupId}`);
  }

  public async removeFilterGroupAsync(
    comboId: string,
    filterGroupId: string
  ): requestResponse {
    return await this.executeDelete(`${comboId}/filterGroup/${filterGroupId}`);
  }

  public async deleteAsync(comboId: string): requestResponse {
    return await this.executeDelete(`${comboId}`);
  }
}

const useReqListCombos = (): swrRequestResponse<ICombo[]> => {
  return useGetHttpClient(
    `tagging/combo/list`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default ComboOperations;
export { useReqListCombos };
