import { useReqListInteractionEvents } from 'Colugo/operations/interest/InteractionEventOperations';
import React from 'react';
import InterestScoreCard from './InterestScoreCard';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IInteractionEvent } from 'Colugo/interfaces/interest/IInteractionEvent';

function InterestScoreEditor() {
  const {
    data: interactionEvents,
    isLoading,
    isError,
    mutate
  } = useReqListInteractionEvents();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load interaction events from server.'}</span>
      </ErrorPage>
    );
  }

  async function mutateLocalInteractionEventsAsync(
    interactionEvent: IInteractionEvent
  ) {
    const updatedInteractionEvents = interactionEvents?.map((i) => {
      if (i.id === interactionEvent.id) {
        return { ...i, value: interactionEvent.value };
      }
      return i;
    });
    mutate(updatedInteractionEvents, true);
  }

  return (
    <div className="h-full overflow-hidden container flex flex-col">
      <div className="flex h-20 items-center">
        <div className="font-inter text-sm font-bold leading-5 tracking-normal text-left w-52">
          Actions
        </div>
        <div className="border-r-2 border-gray-300 h-20 mr-12"></div>
        <div className="font-inter text-sm font-bold leading-5 tracking-normal text-left">
          Interest Score Contribution
        </div>
      </div>
      {interactionEvents &&
        interactionEvents.map((listItem) => (
          <InterestScoreCard
            key={listItem.id}
            interactionEvent={listItem}
            updateInteractionEvents={mutateLocalInteractionEventsAsync}
          />
        ))}
    </div>
  );
}

export default InterestScoreEditor;
