import 'reflect-metadata';
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import LoginContainer from 'component/login/LoginContainer';
import { AuthContext } from 'provider/auth/authProvider';
import { modalRoot } from 'utility/constants/constants';
import DashboardContainer from 'component/dashboard/DashboardContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { token } = useContext(AuthContext);
  //TODO: Routing /w 404 page
  return (
    <div className={`m-auto antialiased text-center ${modalRoot}`}>
      <ToastContainer position="bottom-right" />
      <React.StrictMode>
        <Route
          path="/"
          render={() =>
            token === null ? <LoginContainer /> : <DashboardContainer />
          }
        />
      </React.StrictMode>
    </div>
  );
}

export default App;
