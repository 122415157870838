import { Input } from '@bindystreet/bindystreet.kit.react';
import React from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { useAsyncDebounce } from 'react-table';

type Props = {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
  searchPlaceholder: string;
};

function GlobalFilter(props: Props) {
  const {
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    searchPlaceholder
  } = props;

  const [filterValue, setFilterValue] = React.useState(globalFilter);

  const count = preGlobalFilteredRows.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex w-full">
      <div className="relative flex flex-row w-full mt-1">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder={`Search ${
            count !== 0 ? count : ''
          } ${searchPlaceholder} `}
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
      </div>
    </div>
  );
}

export default GlobalFilter;
