import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from 'Colugo/provider/HttpClient';
import AbstractOperations from '../AbstractOperations';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { IDiscoverPage } from 'Colugo/interfaces/lobby/discover';
import { SubPageType } from 'Colugo/interfaces/lobby/discover/enums/SubPageType';

@scoped(Lifecycle.ContainerScoped)
class SubPageOperations extends AbstractOperations {
  protected basePath: string = 'discover/subpage';

  public async createSubPageAsync(
    discoverSubPage: IDiscoverSubPage
  ): requestResponse<IDiscoverSubPage> {
    return await this.executePost(``, discoverSubPage, undefined, true);
  }

  public async updateSubPageAsync(
    subPage: IDiscoverSubPage
  ): requestResponse<IDiscoverSubPage> {
    return await this.executePut(`${subPage.id}`, subPage);
  }

  public async updateSubPageOrderAsync(
    subPageId: string,
    position: number
  ): requestResponse<IDiscoverSubPage[]> {
    return await this.executePut(`${subPageId}/order/${position}`);
  }

  public async deleteSubPageAsync(subPageId: string): requestResponse {
    return await this.executeDelete(`${subPageId}`);
  }
}

const useReqGetSubPagesForEditing = (
  subPageType: SubPageType
): swrRequestResponse<IDiscoverPage> => {
  return useGetHttpClient(
    `discover/getForEditing?subPageType=${subPageType}`,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

export { useReqGetSubPagesForEditing };
export default SubPageOperations;
