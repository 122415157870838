import { IBudgieVersion } from 'Colugo/interfaces';
import { ICategory } from 'Colugo/interfaces/common';
import { AssetTypeEnum } from 'Colugo/interfaces/file';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { IManifest } from 'Colugo/interfaces/manifests';
import { ITag } from 'Colugo/interfaces/tags';
import {
  deleteHttpClient,
  getHttpClient,
  postHttpClient,
  putHttpClient,
  requestResponse,
  requestXHR,
  swrRequestResponse,
  useGetHttpClient
} from 'provider/httpClient';

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqDeleteTag = async (tagId: string): requestResponse =>
  await deleteHttpClient(`tag/${tagId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqCreateManifest = async (
  manifest: IManifest
): requestResponse<IManifest> => await postHttpClient(`manifest`, manifest);

/**
 * @deprecated Method should no longer be used, create respective method in Colugo as no fix for the useGetHttpCLient hook currently
 */
const useReqGetLatestManifest = (): swrRequestResponse<IManifest> => {
  return useGetHttpClient(
    `manifest/get?appVersion=0.0.1`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqDeleteBlock = async (blockId: string): requestResponse<IBlock> =>
  await deleteHttpClient(`discover/block/${blockId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateCategory = async (
  category: ICategory
): requestResponse<ICategory> =>
  await putHttpClient(`category/${category.id}`, category);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateTag = async (tag: ITag): requestResponse<ITag> =>
  await putHttpClient(`tag/${tag.id}`, tag);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqCreateOrUpdateTagRelevance = async (
  tag1Id: string,
  tag2Id: string,
  relevance: number
): requestResponse =>
  await putHttpClient(`tag/relevance/${tag1Id}/${tag2Id}?value=${relevance}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateCategoryOrder = async (
  categoryId: string,
  position: number | null
): requestResponse<ICategory[]> =>
  await putHttpClient(`category/${categoryId}/position/${position}`, undefined);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateBlockOrder = async (
  subPageId: string,
  blockId: string,
  position: number
) =>
  await putHttpClient(
    `discover/subPage/${subPageId}/block/${blockId}/order/${position}`
  );

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateDefaultCategoryForTag = async (
  tagId: string,
  defaultCategoryId: string | undefined
): requestResponse =>
  defaultCategoryId
    ? await putHttpClient(`tag/${tagId}/defaultCategory/${defaultCategoryId}`)
    : await putHttpClient(`tag/${tagId}/defaultCategory`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqAddGameToBlock = async (
  gameId: string,
  blockId: string
): requestResponse<IBlock> =>
  await postHttpClient(`discover/block/${blockId}/item/${gameId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqAddBlockToSubPage = async (
  subPageId: string,
  blockId: string
): requestResponse<IBlock> =>
  await postHttpClient(`discover/subPage/${subPageId}/block/${blockId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqAddCategoryToTag = async (
  tagId: string,
  categoryId: string
): requestResponse =>
  await postHttpClient(`tag/${tagId}/category/${categoryId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqRemoveCategoryFromTag = async (
  tagId: string,
  categoryId: string
): requestResponse =>
  await deleteHttpClient(`tag/${tagId}/category/${categoryId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqRemoveBlock = async (
  subPageId: string,
  blockId: string
): requestResponse =>
  await deleteHttpClient(`discover/subPage/${subPageId}/block/${blockId}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqUpdateBlockGamePosition = async (
  blockId: string,
  gameId: string,
  order: number
) =>
  await putHttpClient(
    `discover/block/${blockId}/item/${gameId}/order/${order}`
  );

/**
 * @deprecated Method should no longer be used, create respective method in Colugo as no fix for the useGetHttpCLient hook currently
 */
const useReqGetVersionInfo = (): swrRequestResponse<IBudgieVersion> => {
  const result = useGetHttpClient(`status/version`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  return result;
};

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqResetPlayerProfile = async (
  playerId: string,
  secretCode: string
): requestResponse =>
  await putHttpClient(`player/${playerId}/reset?code=${secretCode}`);

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqListRelevantTags = async (tagId: string): requestResponse<ITag[]> =>
  await getHttpClient(`tag/relevance/${tagId}/list`);

/**
 * @deprecated Method should no longer be used, create respective method in Colugo as no fix for the useGetHttpCLient hook currently
 */
const useReqListGameBlocks = (): swrRequestResponse<IBlock[]> => {
  return useGetHttpClient(
    `discover/block/list?blockType=GuidesGroup`,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

/**
 * @deprecated Method should no longer be used, create respective operation in Colugo
 */
const reqCreatePicture = async (
  file: File,
  handleProgressUpdate?: (number: any) => void
) => {
  return await requestXHR(
    'POST',
    `file/image/${AssetTypeEnum.Venue}`,
    file,
    handleProgressUpdate
  );
  // return await postFileHttpClient(`file/image/${AssetTypeEnum.Venue}`, file);
};

/**
 * @deprecated Method should no longer be used, create respective method in Colugo as no fix for the useGetHttpCLient hook currently
 */

export {
  reqAddBlockToSubPage,
  reqAddCategoryToTag,
  reqAddGameToBlock,
  reqCreateManifest,
  reqCreateOrUpdateTagRelevance,
  reqCreatePicture,
  reqDeleteBlock,
  reqDeleteTag,
  reqListRelevantTags,
  reqRemoveBlock,
  reqRemoveCategoryFromTag,
  reqResetPlayerProfile,
  reqUpdateBlockGamePosition,
  reqUpdateBlockOrder,
  reqUpdateCategory,
  reqUpdateCategoryOrder,
  reqUpdateDefaultCategoryForTag,
  reqUpdateTag,
  useReqGetLatestManifest,
  useReqGetVersionInfo,
  useReqListGameBlocks
};
