import React, { useMemo, useState } from 'react';
import { ICombo } from 'Colugo/interfaces/tags';
import { BiPlus } from 'react-icons/bi';
import CombosCreate from './CombosCreate';
import Table from 'component/utility/Table';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import { Modal } from '@bindystreet/bindystreet.kit.react';
import ComboUpdateForm from './ComboUpdateForm';

type Props = {
  combos: ICombo[];
  addCombo: (combo: ICombo) => Promise<boolean>;
  updateCombo: (combo: ICombo) => Promise<boolean>;
  updateComboOrder: (combo: ICombo, position: number) => void;
  deleteCombo: (combo: ICombo) => Promise<boolean>;
  isMutationLoading: boolean;
};

function CombosEditor(props: Props) {
  const {
    combos,
    addCombo,
    updateCombo,
    updateComboOrder,
    deleteCombo,
    isMutationLoading
  } = props;
  const [isCreateComboOpen, setIsCreateComboOpen] = useState(false);
  const [comboToDelete, setComboToDelete] = useState<ICombo | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [comboToUpdate, setComboToUpdate] = useState<ICombo | undefined>(
    undefined
  );

  const selectComboForEditing = (combo: ICombo) => {
    const comboToUpdate = combos.find((c) => c?.id === combo.id);
    setComboToUpdate(comboToUpdate);
    setIsModalOpen(true);
  };

  const handleClickActiveIcon = async (combo: ICombo) => {
    if (!combo) {
      toast.error('No combo selected to update');
      return;
    }
    combo.isActive = !combo.isActive;
    await updateCombo(combo);
  };

  async function handleCreateCombo(newCombo: ICombo) {
    if (!newCombo.name) {
      toast.error('Combo must have a name to be created');
      return;
    }
    const isAdded = await addCombo(newCombo);
    isAdded && setIsCreateComboOpen(false);
  }

  const handleUpdateCombo = async (combo: ICombo) => {
    if (!comboToUpdate) {
      toast.error('No combo selected to update');
      return;
    }
    await updateCombo(combo);
    setComboToUpdate(undefined);
  };

  async function handleDeleteCombo() {
    if (!comboToDelete) {
      toast.error('No combo selected to delete');
      return;
    }

    const isDeleted = await deleteCombo(comboToDelete);
    isDeleted && setComboToDelete(undefined);
  }

  const columns = useMemo(() => {
    const comboTableColumns: Column<ICombo>[] = [
      {
        Header: 'Combo Name',
        accessor: 'name'
      },
      {
        Header: 'Edit',
        accessor: 'id',
        width: 50
      }
    ];

    return comboTableColumns;
  }, []);

  const deleteComboPopup = (
    <ConfirmDeletePopup
      isModalOpen={!!comboToDelete}
      onSubmit={handleDeleteCombo}
      skin="combo"
      title={comboToDelete?.name || 'Unnamed Combo'}
      closeModal={() => setComboToDelete(undefined)}
    />
  );

  const createComboButton = (
    <div
      className="flex flex-row justify-center items-center cursor-pointer w-3/12 h-16 rounded-md border-2 hover:text-success border-gray-200 bg-theme2"
      onClick={() => setIsCreateComboOpen(!isCreateComboOpen)}
    >
      <div className="font-semibold">Add New Combo</div>
      <BiPlus className="ml-2" size={20} />
    </div>
  );

  return (
    <div className="w-full">
      <div>
        <CombosCreate
          onCreateCombo={handleCreateCombo}
          isModalOpen={isCreateComboOpen}
          setIsModalOpen={setIsCreateComboOpen}
        />
        <Modal
          isMenuOpen={comboToUpdate !== undefined && isModalOpen}
          position={'fixedCenter'}
          size="3xl"
          overlay
          className="rounded-xl pt-8 px-6 overflow-y-scroll -mt-32"
        >
          {comboToUpdate ? (
            <ComboUpdateForm
              key={comboToUpdate.id}
              combo={comboToUpdate}
              onClickUpdateSubmit={handleUpdateCombo}
              onClickCloseModal={() => setIsModalOpen(false)}
            />
          ) : (
            'Error, no combo group selected'
          )}
        </Modal>

        <span className="mx-auto block text-4xl text-black mt-4">Combos</span>
      </div>
      <div className="flex flex-row pt-4">
        {deleteComboPopup}
        <div className="px-12 h-full w-3/5">
          <DndProvider backend={HTML5Backend}>
            <Table
              entities={combos}
              children={createComboButton}
              tableColumns={columns}
              onClickDeleteIcon={setComboToDelete}
              onClickActiveIcon={handleClickActiveIcon}
              onClickManageIcon={selectComboForEditing}
              searchPlaceholderText={'combos'}
              isMutationLoading={isMutationLoading}
              onChangeOrder={updateComboOrder}
              showActiveToggle={true}
              isOrderable={true}
              isEditable={true}
            />
          </DndProvider>
        </div>
      </div>
    </div>
  );
}

export default CombosEditor;
