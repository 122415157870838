import {
  Button,
  Input,
  Spinner,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { IEssenceRewards, IManifest } from 'Colugo/interfaces/manifests';
import { reqCreateManifest } from 'provider/admin/methods';
import React, { useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';
import DiscoverContentCollectionEditor from './DiscoverContentCollectionEditor';
import EssenceRewardsEditor from './EssenceRewardsEditor';

type Props = {
  gameBlocks: IBlock[];
  latestManifest: IManifest;
};

const ManifestEditor: React.FC<Props> = (props) => {
  const { gameBlocks, latestManifest } = props;

  const [isLoading, setIsLoading] = useState(false);

  const noQuizzesCollected =
    latestManifest.metadata?.discoverContentCollection?.noQuizzesCollected;

  const [selectedNoQuizzesCollected, setSelectedNoQuizzesCollected] = useState<
    FlyoutMenuItem | undefined
  >(
    noQuizzesCollected && {
      label: noQuizzesCollected.name!,
      value: noQuizzesCollected.id
    }
  );

  const [essenceRewards, setEssenceRewards] = useState<IEssenceRewards>(
    latestManifest.metadata?.essenceRewards || {}
  );

  const [searchBlockIds, setSearchBlockIds] = useState<string>(
    latestManifest.metadata?.searchBlockIds?.toString() || ''
  );

  const handleCreateManifest = async () => {
    if (!selectedNoQuizzesCollected) {
      toast.error('NoQuizzesCollected block not chosen.');
      return;
    }
    if (
      essenceRewards.sapSpotReward === undefined ||
      essenceRewards.sapPostReward === undefined ||
      essenceRewards.simpleSpotReward === undefined ||
      essenceRewards.gapPostReward === undefined ||
      essenceRewards.napPostReward === undefined
    ) {
      toast.error('Essence rewards must have a value');
      return;
    }

    const newManifest: IManifest = {
      metadata: {
        discoverContentCollection: {
          noQuizzesCollected: {
            $type:
              'Dippy.Models.Lobby.Discover.Blocks.GamesBlock, Dippy.Models',
            id: selectedNoQuizzesCollected.value,
            name: selectedNoQuizzesCollected.label
          }
        },
        essenceRewards,
        searchBlockIds: searchBlockIds.split(',')
      }
    };

    setIsLoading(true);
    const { data, error } = await reqCreateManifest(newManifest);
    setIsLoading(false);

    if (!data && error) {
      toast.error('Failed to create manifest, please try again');
      return;
    }

    toast.success(`Your manifest has been created`);
  };

  const confirmManifestChangesButton = (
    <Button className="mt-2" size="md" onClick={() => handleCreateManifest()}>
      <div className="flex flex-row">
        {isLoading ? (
          <Spinner color={'white'} />
        ) : (
          <div className="flex flex-row">
            <BiPencil size="20" />
            <div className="ml-1">{'Confirm'}</div>
          </div>
        )}
      </div>
    </Button>
  );

  return (
    <div className="flex flex-col mx-20">
      {gameBlocks.length > 0 ? (
        <DiscoverContentCollectionEditor
          blocks={gameBlocks}
          selectedNoQuizzesCollected={selectedNoQuizzesCollected}
          setSelectedNoQuizzesCollected={setSelectedNoQuizzesCollected}
        />
      ) : (
        <div>Create guides block to modify this field on the manifest</div>
      )}
      <div className="flex flex-col mt-6 p-4 rounded-md border border-stroke1 bg-theme1">
        <Input
          label="Search Interface Block Ids"
          value={searchBlockIds}
          onChange={(e) => setSearchBlockIds(e.currentTarget.value)}
        />
      </div>
      <EssenceRewardsEditor
        essenceRewards={essenceRewards || {}}
        setEssenceRewards={setEssenceRewards}
      />
      {confirmManifestChangesButton}
    </div>
  );
};

export default ManifestEditor;
