import { CardHeader, Input } from '@bindystreet/bindystreet.kit.react';
import { IBaseWithName } from 'Colugo/interfaces/common';
import { IFilterGroup, ITag } from 'Colugo/interfaces/tags';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';
import {
  ComboOperations,
  FilterGroupOperations,
  TagOperations
} from 'Colugo/operations/tags';
import ItemCreate from 'component/utility/ItemCreate';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiPlus, BiSearchAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { ImRadioUnchecked } from 'react-icons/im';

const filterGroupOperations = container.resolve(FilterGroupOperations);
const tagOperations = container.resolve(TagOperations);
const comboOperations = container.resolve(ComboOperations);

type Props = {
  selectedItem: IBaseWithName;
  itemChildren: IBaseWithName[];
  allChildren: IBaseWithName[];
  filterGroup?: IFilterGroup;
  filterGroupTaggingType: FilterGroupTagging;
  mutate: (
    itemChildren: ITag[],
    shouldRevalidate: boolean
  ) => Promise<ITag[] | undefined>;
};

function ItemUpdateForm(props: Props) {
  const {
    selectedItem,
    itemChildren,
    allChildren,
    filterGroup,
    filterGroupTaggingType,
    mutate
  } = props;

  const getFirstArrayExcludingSecond = (
    array1: IBaseWithName[],
    array2: IBaseWithName[]
  ) => {
    const removed = array1.filter(
      ({ id: id1 }) => !array2?.some(({ id: id2 }) => id1 === id2)
    );
    return removed;
  };

  const allChildrenWithoutFeatureFlag = allChildren?.map((child) =>
    produce(child, (draftChild) => {
      draftChild.isFeatured = undefined;
      return draftChild;
    })
  );

  selectedItem.children = itemChildren;

  const isAddTagToFilterGroup =
    filterGroupTaggingType === FilterGroupTagging.AddTagToFilterGroup;

  const [localItem, setLocalItem] = useState<IBaseWithName>(selectedItem);
  const [localItemChildren, setLocalItemChildren] = useState(itemChildren);
  const [localAllChildren, setLocalAllChildren] = useState(
    allChildrenWithoutFeatureFlag
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [childrenFilteredBySearchTerm, setChildrenFilteredBySearchTerm] =
    useState<IBaseWithName[]>(() =>
      getFirstArrayExcludingSecond(localAllChildren, localItemChildren)
    );

  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState<boolean>(false);
  const itemName = isAddTagToFilterGroup ? 'tag' : 'filter group';

  useEffect(() => {
    setLocalItemChildren(itemChildren);
  }, [itemChildren]);

  const unfilteredChildren = getFirstArrayExcludingSecond(
    allChildrenWithoutFeatureFlag,
    itemChildren
  );

  const searchAllChildren = (searchValue: string) => {
    setSearchInput(searchValue);
    if (searchValue !== '') {
      const filteredData = unfilteredChildren.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setChildrenFilteredBySearchTerm(filteredData);
    } else {
      setChildrenFilteredBySearchTerm(
        getFirstArrayExcludingSecond(localAllChildren, localItemChildren)
      );
    }
  };

  const handleCreateItem = async (newItem: IBaseWithName) => {
    if (!newItem?.name) {
      toast.error(
        isAddTagToFilterGroup
          ? 'Tag must have a name to be added'
          : 'Filter Group must have a name to be added'
      );
      return;
    }

    const createItem = async (itemToCreate: IBaseWithName) => {
      let response: {
        error: Response | undefined;
        data: IFilterGroup | ITag | undefined;
      } = { error: undefined, data: undefined };

      switch (filterGroupTaggingType) {
        case FilterGroupTagging.AddTagToFilterGroup:
          response = await tagOperations.create({
            ...itemToCreate,
            isFeatured: false
          });
          break;
        case FilterGroupTagging.AddFilterGroupToTag:
        case FilterGroupTagging.AddFilterGroupToCombo:
          response = await filterGroupOperations.create(itemToCreate);
          break;
        default:
      }

      const { data, error } = response;

      if (!data || error) {
        if (error?.status === 409) {
          toast.error(
            `Failed to create ${itemName}, most likely ${itemName}, name already exists`
          );
        } else {
          toast.error(`Failed to create ${itemName}, please try again`);
        }
        return false;
      }

      const newChildren = [...allChildrenWithoutFeatureFlag, data];
      mutate(newChildren, true);
      setLocalAllChildren(newChildren);
      const allItemsExcludingChildren = getFirstArrayExcludingSecond(
        newChildren,
        isAddTagToFilterGroup ? localItemChildren : itemChildren
      );
      setChildrenFilteredBySearchTerm(allItemsExcludingChildren);
      return true;
    };

    const isAdded = await createItem(newItem);

    if (isAdded) {
      setIsAddTagModalOpen(false);
    }
  };

  const handleAddChildToSelectedItem = async (child: IBaseWithName) => {
    const filterGroupId = isAddTagToFilterGroup ? localItem.id : child.id;
    const tagId = isAddTagToFilterGroup ? child.id : localItem.id;
    const comboId = localItem.id;

    const updatedItem = produce(selectedItem, (draftItem) => {
      draftItem?.children?.push(child);
      return draftItem;
    });

    const allItemsExcludingChildren = getFirstArrayExcludingSecond(
      allChildrenWithoutFeatureFlag,
      updatedItem?.children!
    );

    setLocalItem(updatedItem!);
    setLocalItemChildren(updatedItem.children!);
    setChildrenFilteredBySearchTerm(allItemsExcludingChildren);
    let error: Response | undefined = undefined;

    switch (filterGroupTaggingType) {
      case FilterGroupTagging.AddTagToFilterGroup:
      case FilterGroupTagging.AddFilterGroupToTag:
        const { error: addTagError } = await filterGroupOperations.addTag(
          filterGroupId!,
          tagId!
        );
        error = addTagError;
        break;
      case FilterGroupTagging.AddFilterGroupToCombo:
        const { error: addFilterGroupError } =
          await comboOperations.addFilterGroupAsync(comboId!, child.id!);
        error = addFilterGroupError;
    }

    if (error) {
      setLocalItem(selectedItem!);
      setLocalItemChildren(selectedItem.children!);
      setChildrenFilteredBySearchTerm(
        getFirstArrayExcludingSecond(
          allChildrenWithoutFeatureFlag,
          selectedItem?.children!
        )
      );

      let errorText = '';
      switch (filterGroupTaggingType) {
        case FilterGroupTagging.AddTagToFilterGroup:
          errorText = 'Failed to add tag to filter group';

          break;
        case FilterGroupTagging.AddFilterGroupToTag:
          errorText = 'Failed to add filter group to tag';

          break;
        case FilterGroupTagging.AddFilterGroupToCombo:
          errorText = 'Failed to add filter group to combo';
          break;
      }

      toast.error(errorText);
      return;
    }

    await mutate(allItemsExcludingChildren, true);
  };

  const handleRemoveChildFromSelectedItem = async (index: number) => {
    const deletedTag = selectedItem.children![index];
    const filterGroupId = isAddTagToFilterGroup ? localItem.id : deletedTag.id;
    const tagId = isAddTagToFilterGroup ? deletedTag.id : localItem.id;
    const comboId = localItem.id;
    let error: Response | undefined = undefined;

    const removedItem = produce(selectedItem, (draftItem) => {
      draftItem!.children = draftItem?.children!.filter(
        (_, childIndex) => childIndex !== index
      );

      return draftItem;
    });

    const allItemsExcludingChildren = getFirstArrayExcludingSecond(
      localAllChildren,
      removedItem.children!
    );

    setLocalItem(removedItem!);
    setLocalItemChildren(removedItem.children!);
    setChildrenFilteredBySearchTerm(allItemsExcludingChildren);

    switch (filterGroupTaggingType) {
      case FilterGroupTagging.AddTagToFilterGroup:
      case FilterGroupTagging.AddFilterGroupToTag:
        const { error: removeTagError } = await filterGroupOperations.removeTag(
          filterGroupId!,
          tagId!
        );
        error = removeTagError;
        break;
      case FilterGroupTagging.AddFilterGroupToCombo:
        const { error: addFilterGroupError } =
          await comboOperations.removeFilterGroupAsync(
            comboId!,
            selectedItem.children![index].id!
          );
        error = addFilterGroupError;
    }

    if (error) {
      setLocalItem(selectedItem!);
      setLocalItemChildren(selectedItem.children!);
      setChildrenFilteredBySearchTerm(
        getFirstArrayExcludingSecond(localAllChildren, selectedItem.children!)
      );

      let errorText = '';
      switch (filterGroupTaggingType) {
        case FilterGroupTagging.AddTagToFilterGroup:
          errorText = 'Failed to remove tag from filter group';

          break;
        case FilterGroupTagging.AddFilterGroupToTag:
          errorText = 'Failed to remove filter group from tag';

          break;
        case FilterGroupTagging.AddFilterGroupToCombo:
          errorText = 'Failed to remove filter group from combo';
          break;
      }
      toast.error(errorText);
      return;
    }
    mutate(allItemsExcludingChildren, true);
  };

  const handleUpdateFilterGroupTag = async (tag: ITag, isFeatured: boolean) => {
    if (!filterGroup) {
      toast.error('No filterGroup selected to update');
      return;
    }

    if (
      localItem.children?.filter((c) => c.isFeatured).length! >= 10 &&
      isFeatured
    ) {
      return toast.warning(
        'Only 10 featured Tags are permitted per FilterGroup'
      );
    }

    const { error } = await filterGroupOperations.updateTagFeatureState(
      filterGroup,
      tag.id!,
      isFeatured
    );

    if (error) {
      toast.error(
        'Failed to update filter group, please try again. Filter Group name must be unique.'
      );
      return false;
    }

    const newTagsForFilterGroups = itemChildren!.map((tagInFilterGroup) => {
      if (tagInFilterGroup.id === tag.id) {
        const newTag = {
          name: tagInFilterGroup.name,
          isFeatured: isFeatured,
          id: tagInFilterGroup.id
        };
        return newTag;
      }
      return tagInFilterGroup;
    });
    setLocalItem(filterGroup);
    setLocalItemChildren(newTagsForFilterGroups);
    await mutate(newTagsForFilterGroups, true);
  };

  let cardHeaderTitle = '';
  let subTitle = '';
  let emptyListTitle = '';
  function updateFormTexts(filterGroupTaggingType: FilterGroupTagging) {
    switch (filterGroupTaggingType) {
      case FilterGroupTagging.AddTagToFilterGroup:
        cardHeaderTitle = 'Edit Tags in Filter Group';
        subTitle = 'Tags';
        emptyListTitle = 'No tags added to this filter group';
        break;
      case FilterGroupTagging.AddFilterGroupToTag:
        cardHeaderTitle = 'Edit Filter Groups in Tag';
        subTitle = 'Used in these filter groups';
        emptyListTitle = 'Tag not used in any Filter Group';
        break;
      case FilterGroupTagging.AddFilterGroupToCombo:
        cardHeaderTitle = 'Edit Filter Groups in this Combo';
        subTitle = 'Filter Groups used in this Combo';
        emptyListTitle = 'No Filter Group used in this Combo';

        break;
    }
    return { cardHeaderTitle, subTitle, emptyListTitle };
  }

  return (
    <div className="flex flex-col w-full items-center">
      <div className="flex w-full mt-4">
        <CardHeader
          title={updateFormTexts(filterGroupTaggingType).cardHeaderTitle}
          titleAlign="text-left"
          fontWeight="font-semibold"
          className="flex flex-row"
        />
      </div>
      <div className="w-full text-left mx-4 mt-2">
        <div className="flex flex-col mt-4">
          <div className="font-semibold text-lg">
            {updateFormTexts(filterGroupTaggingType).subTitle}
          </div>

          <div className="w-full mt-2">
            <div className="flex flex-row flex-wrap bg-theme3 rounded-md p-3 overflow-y-scroll max-h-48">
              <div className="flex flex-row w-full justify-center">
                {localItem.children!.length < 1 && (
                  <div className="flex self-center text-lg font-bold">
                    {updateFormTexts(filterGroupTaggingType).emptyListTitle}
                  </div>
                )}
              </div>
              {localItem.children!.map((tag, i) => (
                <div
                  className={`${isAddTagToFilterGroup && `cursor-pointer `}`}
                  key={i}
                >
                  <div
                    className={`flex flex-col relative items-stretch m-4 justify-start bg-white border-gray-400 border-2 rounded-md text-md font-semibold py-3 px-1 max-w-prose h-auto`}
                  >
                    {isAddTagToFilterGroup && (
                      <div
                        className={`flex w-4 ${
                          tag.isFeatured ? 'bg-primaryButton' : ''
                        } rounded-full ${
                          tag.isFeatured
                            ? 'hover:opacity-75'
                            : 'hover:opacity-100'
                        } flex-row absolute mt-3.5 rounded-full`}
                      >
                        <div>
                          <ImRadioUnchecked
                            color={`${tag.isFeatured ? '#27AF96' : '#27AF96'}`}
                            onClick={() => {
                              isAddTagToFilterGroup &&
                                handleUpdateFilterGroupTag(
                                  tag,
                                  tag.isFeatured ? false : true
                                );
                            }}
                            className={`self-start cursor-pointer  ${
                              tag.isFeatured
                                ? 'hover:text-white'
                                : 'hover:text-primaryButton'
                            }`}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={`self-end cursor-pointer absolute ${
                        tag.isFeatured
                          ? 'hover:text-white'
                          : 'hover:text-error1'
                      }  -mt-2`}
                      onClick={() => handleRemoveChildFromSelectedItem(i)}
                    >
                      <AiOutlineClose />
                    </div>
                    <div className="px-5">{tag.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full p-3 justify-center">
          <div className="flex flex-row w-full justify-between items-center px-2">
            <div className="font-semibold text-center">
              {isAddTagToFilterGroup ? 'All Tags' : 'All Filter Groups'}
            </div>
            <div className="flex flex-row w-2/3 justify-end">
              <Input
                className="w-full"
                placeholder={
                  isAddTagToFilterGroup ? 'Search Tags' : 'Search Filter Groups'
                }
                color="text-gray-400"
                size="lg"
                value={searchInput || ''}
                onChange={(e) => {
                  searchAllChildren(e.currentTarget.value);
                }}
              />
              <BiSearchAlt className="text-3xl text-hot absolute mt-5 mx-3" />
            </div>
            <div className="ml-2">
              <div
                className="flex flex-row justify-center items-center cursor-pointer w-44 h-16 p-3 rounded-md border-2 hover:text-success border-gray-200 bg-theme3"
                onClick={() => setIsAddTagModalOpen(true)}
              >
                <div className="font-semibold text-center p-2">
                  Add New {isAddTagToFilterGroup ? 'Tag' : 'Filter Group'}
                </div>
                <BiPlus size={20} />
              </div>
              <div>
                <ItemCreate
                  setIsModalOpen={setIsAddTagModalOpen}
                  isModalOpen={isAddTagModalOpen}
                  onCreateItem={handleCreateItem}
                  itemTaggingType={filterGroupTaggingType}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full h-auto max-h-96 pt-4 px-1 mt-6 pb-5 overflow-y-scroll rounded-md bg-theme3">
          {childrenFilteredBySearchTerm.length ? (
            <div className="flex flex-wrap relative w-full h-full">
              {childrenFilteredBySearchTerm.map((child, i) => (
                <div
                  key={i}
                  onClick={() => {
                    handleAddChildToSelectedItem(child);
                  }}
                  className="flex flex-row items-center mx-4 my-2 cursor-pointer  hover:text-success bg-white rounded-md text-lg font-semibold p-6 max-w-prose max-h-px"
                >
                  {child.name}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-row justify-center items-center text-3xl font-bold bg-theme3 w-full h-28">
              No {`${itemName}`} with that name exists
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemUpdateForm;
