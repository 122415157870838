import { useState } from 'react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';
import {
  useReqListTags,
  useReqListTagsByFilterGroup
} from 'Colugo/operations/tags';
import ItemUpdateForm from 'component/utility/ItemUpdateForm';
import {
  Button,
  CardHeader,
  ErrorPage,
  Input,
  Spinner,
  Switch
} from '@bindystreet/bindystreet.kit.react';
import { MdClose } from 'react-icons/md';

type Props = {
  filterGroup: IFilterGroup;
  onClickUpdateSubmit: (filterGroup: IFilterGroup) => void;
  onClickCloseModal: () => void;
};

function FilterGroupUpdateForm(props: Props) {
  const { filterGroup, onClickUpdateSubmit, onClickCloseModal } = props;
  const [localFilterGroup, setLocalFilterGroup] =
    useState<IFilterGroup>(filterGroup);

  const {
    data: tagsForFilterGroup,
    isLoading: isTagsForFilterGroupLoading,
    mutate: mutateTagsForFilterGroup,
    isError: isTagsForFilterGroupError
  } = useReqListTagsByFilterGroup(filterGroup?.id!);

  const {
    data: allTags,
    isLoading: isTagsLoading,
    isError: isTagsError
  } = useReqListTags();

  const submitEnabled = localFilterGroup.name;

  const submitForUpdating = () => {
    onClickUpdateSubmit(localFilterGroup);
  };

  if (isTagsForFilterGroupLoading || isTagsLoading) {
    return <Spinner />;
  }

  if (isTagsForFilterGroupError || isTagsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load tags from server.'}</span>
      </ErrorPage>
    );
  }
  return (
    <div className="flex flex-row w-full px-6">
      <div className="w-full">
        <CardHeader
          title={'Update Filter Group Information'}
          fontWeight="font-semibold"
        />
        <div className="w-full text-left mt-2">
          <span className="font-semibold text-lg">{'Filter Group Name:'}</span>
          <Input
            value={localFilterGroup.name}
            onChange={(e) =>
              setLocalFilterGroup({
                ...localFilterGroup,
                name: e.currentTarget.value
              })
            }
          />
          <div className="w-full flex flex-row mt-3">
            <div className="w-96 font-semibold text-lg">
              {'Display tag categories?'}
            </div>
            <div className="mt-1">
              <Switch
                checked={localFilterGroup.hasCategorisedTags}
                onChange={() =>
                  setLocalFilterGroup({
                    ...localFilterGroup,
                    hasCategorisedTags: !localFilterGroup.hasCategorisedTags
                  })
                }
              />
            </div>
            <div className="w-full flex flex-grow" />
          </div>
        </div>
        <ItemUpdateForm
          selectedItem={filterGroup}
          itemChildren={tagsForFilterGroup!}
          allChildren={allTags || []}
          filterGroupTaggingType={FilterGroupTagging.AddTagToFilterGroup}
          mutate={mutateTagsForFilterGroup}
          filterGroup={filterGroup}
        />
        <div className="flex flex-row mt-10 justify-evenly">
          <Button onClick={submitForUpdating} disabled={!submitEnabled}>
            {'Submit'}
          </Button>
          <Button onClick={onClickCloseModal} skin="secondary">
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FilterGroupUpdateForm;
