import React, { useState } from 'react';
import { Input } from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import { IEssenceRewards } from 'Colugo/interfaces/manifests';
import { toast } from 'react-toastify';

interface Props {
  essenceRewards: IEssenceRewards;
  setEssenceRewards: (value: IEssenceRewards) => void;
}

const EssenceRewardsEditor = (props: Props) => {
  const { essenceRewards, setEssenceRewards } = props;

  const [essenceRewardsLocal, setEssenceRewardsLocal] =
    useState(essenceRewards);

  const handleSetEssenceRewardLocal = (currentEssenceRewardField: number) => {
    const value =
      currentEssenceRewardField &&
      parseInt(currentEssenceRewardField.toString(), 10);
    if (!value || isNaN(value)) {
      toast.error('value not recognized as number');
      return;
    }
    setEssenceRewards(essenceRewardsLocal);
  };

  const essenceRewardsInput = (
    label: string,
    value: number | undefined,
    onChange: (e: InputFormEvent) => void,
    maxCharacterCount?: number
  ) => {
    return (
      <div className="mt-2">
        <Input
          value={value?.toString()}
          label={label}
          onChange={onChange}
          onBlurWithinCharacterLimit={() => handleSetEssenceRewardLocal(value!)}
          maxCharacterCount={maxCharacterCount || 3}
          error={!value ? 'Value required' : undefined}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col border border-stroke1 bg-theme1 rounded-md mt-4 p-4">
      {essenceRewardsInput(
        'Simple Spotting Reward',
        essenceRewardsLocal.simpleSpotReward,
        (e) =>
          setEssenceRewardsLocal({
            ...essenceRewardsLocal,
            simpleSpotReward: parseInt(e.currentTarget.value, 10) || 0
          }),
        2
      )}
      {essenceRewardsInput(
        'SAP Spotting Reward',
        essenceRewardsLocal.sapSpotReward,
        (e) =>
          setEssenceRewardsLocal({
            ...essenceRewardsLocal,
            sapSpotReward: parseInt(e.currentTarget.value, 10) || 0
          }),
        3
      )}
      {essenceRewardsInput(
        'SAP Posting Reward',
        essenceRewardsLocal.sapPostReward,
        (e) =>
          setEssenceRewardsLocal({
            ...essenceRewardsLocal,
            sapPostReward: parseInt(e.currentTarget.value, 10) || 0
          }),
        3
      )}
      {essenceRewardsInput(
        'GAP Posting Reward',
        essenceRewardsLocal.gapPostReward,
        (e) =>
          setEssenceRewardsLocal({
            ...essenceRewardsLocal,
            gapPostReward: parseInt(e.currentTarget.value, 10) || 0
          }),
        2
      )}
      {essenceRewardsInput(
        'NAP Posting Reward',
        essenceRewardsLocal.napPostReward,
        (e) =>
          setEssenceRewardsLocal({
            ...essenceRewardsLocal,
            napPostReward: parseInt(e.currentTarget.value, 10) || 0
          }),
        2
      )}
    </div>
  );
};

export default EssenceRewardsEditor;
