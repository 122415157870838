export const { REACT_APP_API_URL: BUDGIE_API_URL = '' } = process.env;

export const localUser = 'user';
export const modalRoot = 'modal-root';
export const newTokenHeaderKeyValueFail = 'FAIL';
export const newTokenHeaderKeyValue = 'NewToken';
export const budgieUserErrorCode = 'BudgieUserErrorCode';
export const localGameOverviewViewTableOptions = 'tableViewOptions';

export const budgieErrorCodes = {
  UpdatedAtMismatchErrorCode: '2001'
};

//TODO: find a better place to put this type definition
export type ColumnName =
  | 'spotsCount'
  | 'activePerDay'
  | 'dateSinceUpdate'
  | 'updatedBy';
