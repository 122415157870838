import React, { useState } from 'react';
import CombosEditor from './CombosEditor';
import { ComboOperations, useReqListCombos } from 'Colugo/operations/tags';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ICombo } from 'Colugo/interfaces/tags';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const comboOperations = container.resolve(ComboOperations);

function CombosContainer() {
  const {
    data: combos,
    isLoading: isCombosLoading,
    isError: isCombosError,
    mutate: mutateCombos
  } = useReqListCombos();

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  async function handleCreateCombo(comboToAdd: ICombo) {
    const { data, error } = await comboOperations.createAsync(comboToAdd);

    if (!data || error) {
      if (error?.status === 409) {
        toast.error(
          'Failed to create combo, most likely combo name already exists.'
        );
      } else {
        toast.error('Failed to create combo, please try again');
      }
      return false;
    }

    const newCombo = [...(combos || []), data];
    mutateCombos(newCombo, true);
    return true;
  }

  async function handleUpdateCombo(comboToUpdate: ICombo) {
    const { error } = await comboOperations.updateAsync(comboToUpdate);

    if (error) {
      toast.error(
        'Failed to update combo, please try again. Combo name must be unique.'
      );
      return false;
    }

    const newCombo =
      combos &&
      combos.map((combo) =>
        combo.id === comboToUpdate.id ? comboToUpdate : combo
      );

    toast.success('Combo successfully updated');
    mutateCombos(newCombo, true);
    return true;
  }

  async function handleUpdateComboOrder(combo: ICombo, position: number) {
    if (!combo.id || !combos) {
      toast.error('Combo not found');
      return;
    }

    const itemToReorder = combos.find((c) => c.id === combo.id);
    if (!itemToReorder) {
      toast.error('Combo not found. Cannot reorder, please refresh the page.');
      return;
    }

    setIsMutationLoading(true);

    const { data: updatedCombosOrder, error } =
      await comboOperations.updateOrderAsync(combo.id, position);

    if (!updatedCombosOrder || error) {
      toast.error('Failed to update order. Please refresh the page.');
      setIsMutationLoading(false);
      return;
    }

    setIsMutationLoading(false);
    mutateCombos(combos, true);
    return true;
  }

  async function handleDeleteCombo(comboToDelete: ICombo) {
    if (!comboToDelete?.id) {
      toast.error('Invalid combo Id, please try again');
      return false;
    }

    const { error } = await comboOperations.deleteAsync(comboToDelete.id);

    if (error) {
      toast.error('Failed to delete combo, please try again');
      return false;
    }

    const newCombos = combos?.filter(
      (filterGroup) => filterGroup.id !== comboToDelete.id
    );
    mutateCombos(newCombos, true);
    return true;
  }

  if (isCombosLoading) {
    return <Spinner />;
  }

  if (isCombosError) {
    return (
      <ErrorPage>
        <span>{'Unable to load combos from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full w-full overflow-y-auto  overflow-x-hidden">
      <CombosEditor
        combos={combos || []}
        isMutationLoading={isMutationLoading}
        addCombo={handleCreateCombo}
        updateCombo={handleUpdateCombo}
        updateComboOrder={handleUpdateComboOrder}
        deleteCombo={handleDeleteCombo}
      />
    </div>
  );
}

export default CombosContainer;
