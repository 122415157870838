export enum SpiritCustomizationType {
  Head = 1,
  Neck = 2,
  Top = 4,
  Podium = 8,
  Emote = 16,
  Eyes = 32,
  Skin = 64,
  Marking = 128,
  SkinColor = 256,
  MarkingColor = 512,
  Handheld = 1024,
  FacialHair = 2048,
  Glasses = 4096
}
