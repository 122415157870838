import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  AdvertLocationType,
  IAdvertLocation,
  ScreenType
} from 'Colugo/interfaces/advertisement/IAdvertLocation';
import { useReqListAdvertLocations } from 'Colugo/operations/advertisement/AdvertOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import Table from 'component/utility/Table';
import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column } from 'react-table';
import AdvertLocationModal from './AdvertLocationModal';

type Props = {
  containerTitle: string;
  screenType: ScreenType;
};

function AdvertLocationEditor(props: Props) {
  const { containerTitle, screenType } = props;
  const [advertLocationToUpdate, setAdvertLocationToUpdate] = useState<
    IAdvertLocation | undefined
  >(undefined);

  const {
    data: advertLocations,
    isLoading: isAdvertLocationsLoading,
    isError: isAdvertLocationsError,
    mutate: mutateAdvertLocations
  } = useReqListAdvertLocations(screenType);

  const columns = useMemo(() => {
    const advertLocationTableColumns: Column<IAdvertLocation>[] = [
      {
        Header: 'Name',
        accessor: (row) => {
          return EnumHelper.getEnumValuesForFlyout(AdvertLocationType)[row.type]
            .value;
        }
      },
      {
        Header: 'Ad Free Frequency',
        accessor: 'freeFrequency'
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return advertLocationTableColumns;
  }, []);

  if (isAdvertLocationsLoading) {
    return <Spinner />;
  }

  if (isAdvertLocationsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load advert locations from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="w-full h-auto overflow-y-auto">
      <AdvertLocationModal
        advertLocationToUpdate={advertLocationToUpdate}
        setAdvertLocationToUpdate={setAdvertLocationToUpdate}
        screenLocations={advertLocations}
        mutateScreenLocations={mutateAdvertLocations}
      />
      <div className="mx-auto block text-4xl text-black mt-4">
        {containerTitle}
      </div>
      <div className="flex flex-row">
        <div className="px-12 py-8 w-full h-full">
          <DndProvider backend={HTML5Backend}>
            <Table
              tableColumns={columns}
              searchPlaceholderText={'adverts'}
              isEditable
              entities={advertLocations || []}
              onClickManageIcon={setAdvertLocationToUpdate}
              isOrderable={false}
            />
          </DndProvider>
        </div>
      </div>
    </div>
  );
}

export default AdvertLocationEditor;
