export interface ISearchRequest {
  order: Order;
  entityFilter: Filter;
  location: ILocation;
  query: string;
  take: number;
  liveEventsOnly?: boolean;
}

export enum Order {
  Relevance = 1,
  Proximity = 2
}

export enum Filter {
  Spot = 2,
  Game = 4,
  ListingActive = 8,
  EventActive = 128,
  ActiveEntities = 142
}

export interface ILocation {
  latitude: number;
  longitude: number;
}
