import React from 'react';
import ReactSlider from 'react-slider';

interface Props {
  size?: 'md';
  onChange: (value: number) => void;
  value: number;
  max: number;
  min: number;
  step?: number;
}

const Slider: React.FunctionComponent<Props> = (props) => {
  const { size = 'md', onChange, value, max, min, step = 1 } = props;

  const sizeList: { [key: string]: string } = {
    md: 'h-3'
  };

  const thumbSizeList: { [key: string]: string } = {
    md: 'h-8 w-8'
  };

  const rootMarginList: { [key: string]: string } = {
    md: 'my-3'
  };

  const handleOnChange = (input: any) => {
    const value = input as number;
    if (value) {
      onChange(value);
    }
  };

  return (
    <div className={rootMarginList[size]}>
      <ReactSlider
        className={`w-full ${sizeList[size]}`}
        thumbClassName={`${thumbSizeList[size]} rounded-full outline-none -top-2 -mt-0.5 bottom-0 bg-theme1 border-2 border-hot`}
        trackClassName={`rounded-full h-3 bg-hot top-0 bottom-0`}
        onChange={handleOnChange}
        value={value}
        max={max}
        min={min}
        step={step}
      />
    </div>
  );
};

export default Slider;
