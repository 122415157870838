import AbstractOperations from 'Colugo/operations/AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';

class PostCommentOperations extends AbstractOperations {
  protected basePath: string = 'social/postComment';

  public async deleteAsync(postCommentId: string): requestResponse {
    return await this.executeDelete(postCommentId);
  }
}

export default PostCommentOperations;
