import React, { useState } from 'react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import FilterGroupsTable, {
  filterGroupTableColumns
} from './FilterGroupsTable';
import { Modal } from '@bindystreet/bindystreet.kit.react';
import { BiPlus } from 'react-icons/bi';
import FilterGroupUpdateForm from './FilterGroupUpdateForm';
import { toast } from 'react-toastify';
import FilterGroupCreate from './FilterGroupCreate';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';

type Props = {
  filterGroups: IFilterGroup[];
  deleteFilterGroup: (filterGroup: IFilterGroup) => Promise<boolean>;
  addFilterGroup: (filterGroup: IFilterGroup) => Promise<boolean>;
  updateFilterGroup: (filterGroup: IFilterGroup) => Promise<boolean>;
};

function FilterGroupsEditor(props: Props) {
  const { filterGroups, deleteFilterGroup, addFilterGroup, updateFilterGroup } =
    props;
  const [localFilterGroupId, setLocalFilterGroupId] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddFilterGroupModalOpen, setIsAddFilterGroupModalOpen] =
    useState(false);

  const [filterGroupToUpdate, setFilterGroupToUpdate] = useState<
    IFilterGroup | undefined
  >(undefined);

  const [filterGroupToDelete, setFilterGroupToDelete] = useState<
    IFilterGroup | undefined
  >(undefined);

  const filterGroupColumns = React.useMemo(() => filterGroupTableColumns, []);
  const containerTitle = 'Filter Groups';

  const selectFilterGroupRow = (filterGroupId: string) => {
    setLocalFilterGroupId(filterGroupId);
  };

  const selectFilterGroupForEditing = (filterGroup: IFilterGroup) => {
    const filterGroupToUpdate = filterGroups.find(
      (fg) => fg?.id === filterGroup.id
    );

    setFilterGroupToUpdate(filterGroupToUpdate);
    setIsModalOpen(true);
  };

  const handleClickFeaturedIcon = async (filterGroup: IFilterGroup) => {
    if (!filterGroup) {
      toast.error('No filterGroup selected to update');
      return;
    }
    filterGroup.isFeatured = !filterGroup.isFeatured;
    await updateFilterGroup(filterGroup);
  };

  const handleCreateFilterGroup = async (newFilterGroup: IFilterGroup) => {
    if (!newFilterGroup.name) {
      toast.error('Filter Group must have a name to be created');
      return;
    }

    const isAdded = await addFilterGroup(newFilterGroup);
    isAdded && setIsAddFilterGroupModalOpen(false);
  };

  const handleUpdateFilterGroup = async (filterGroup: IFilterGroup) => {
    if (!filterGroupToUpdate) {
      toast.error('No filterGroup selected to update');
      return;
    }
    await updateFilterGroup(filterGroup);
    setFilterGroupToUpdate(undefined);
  };

  const handleDeleteFilterGroup = async () => {
    if (!filterGroupToDelete) {
      toast.error('No filter group selected to delete');
      return;
    }

    const isDeleted = await deleteFilterGroup(filterGroupToDelete);
    isDeleted && setFilterGroupToDelete(undefined);
  };

  const deleteFilterGroupPopup = (
    <ConfirmDeletePopup
      isModalOpen={!!filterGroupToDelete}
      onSubmit={handleDeleteFilterGroup}
      skin="filter group"
      title={filterGroupToDelete?.name || 'Unnamed FilterGroup'}
      closeModal={() => setFilterGroupToDelete(undefined)}
    />
  );

  const createFilterGroupButton = (
    <div
      className="flex flex-row justify-center items-center cursor-pointer w-3/12 h-16 rounded-md border-2 hover:text-success border-gray-200 bg-theme2"
      onClick={() => setIsAddFilterGroupModalOpen(!isAddFilterGroupModalOpen)}
    >
      <div className="font-semibold">Add New Group</div>
      <BiPlus className="ml-2" size={20} />
    </div>
  );

  return (
    <div className="w-full">
      <div className="w-full">
        <FilterGroupCreate
          setIsModalOpen={setIsAddFilterGroupModalOpen}
          isModalOpen={isAddFilterGroupModalOpen}
          onCreateFilterGroup={handleCreateFilterGroup}
        />
        <Modal
          isMenuOpen={filterGroupToUpdate !== undefined && isModalOpen}
          position={'fixedCenter'}
          size="3xl"
          overlay
          className="rounded-xl pt-8 px-6 overflow-y-scroll -mt-32"
        >
          {filterGroupToUpdate ? (
            <FilterGroupUpdateForm
              key={filterGroupToUpdate.id}
              filterGroup={filterGroupToUpdate}
              onClickUpdateSubmit={handleUpdateFilterGroup}
              onClickCloseModal={() => setIsModalOpen(false)}
            />
          ) : (
            'Error, no filter group selected'
          )}
        </Modal>
      </div>
      <span className="mx-auto block text-4xl text-black mt-4">
        {containerTitle}
      </span>
      <div className="flex flex-row pt-4">
        {deleteFilterGroupPopup}
        <div className="px-12 h-full w-3/5">
          <FilterGroupsTable
            filterGroups={filterGroups}
            onClickRow={selectFilterGroupRow}
            tableColumns={filterGroupColumns}
            onClickDeleteIcon={setFilterGroupToDelete}
            onClickEditIcon={selectFilterGroupForEditing}
            onClickFeaturedIcon={handleClickFeaturedIcon}
            children={createFilterGroupButton}
            rowSelectedId={localFilterGroupId}
          />
        </div>
      </div>
    </div>
  );
}

export default FilterGroupsEditor;
