import React, { useEffect, useState } from 'react';
import { Input } from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BiSearchAlt, BiPencil } from 'react-icons/bi';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useFlexLayout,
  Column,
  HeaderGroup,
  TableBodyPropGetter,
  TableBodyProps,
  Row
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<IBlock>[];
  blocks: IBlock[];
  onClickDeleteIcon: (block: IBlock) => void;
  onClickRow: (blockId: string) => void;
  onClickEditIcon: (block: IBlock) => void;
  rowSelectedId?: string;
};

const BlockTable: React.FC<Props> = (props) => {
  const {
    blocks,
    tableColumns,
    onClickDeleteIcon,
    onClickEditIcon,
    children,
    onClickRow,
    rowSelectedId
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [isDeleteHover, setIsDeleteHover] = useState(false);
  const [isEditHover, setIsEditHover] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: blocks
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const table = (
    <div>
      <div {...getTableProps()} className="w-full pb-32">
        <div>{blockTableHeaderConfig(headerGroups)}</div>
        {blockTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          setIsDeleteHover,
          isDeleteHover,
          setIsEditHover,
          isEditHover,
          onClickDeleteIcon,
          onClickEditIcon,
          blocks,
          onClickRow,
          rowSelectedId
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {children}
      </div>
      {table}
    </div>
  );
};

export default BlockTable;

//Component specifically used for this table
const GlobalFilter: React.FC<any> = ({ globalFilter, setGlobalFilter }) => {
  const [filterValue, setFilterValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 500);

  //GlobalFilter resets along with everything else on a mutation that
  // changes items in the table. This is messy but it works for an admin tool
  useEffect(() => {
    if (filterValue !== globalFilter) {
      setGlobalFilter(filterValue);
    }
  }, [globalFilter, setGlobalFilter, filterValue]);

  return (
    <div className=" w-4/5 flex">
      <div className="relative flex flex-row w-full">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder="Search Blocks"
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
      </div>
    </div>
  );
};

export const blockTableColumns: Column<IBlock>[] = [
  {
    Header: 'Name',
    accessor: (row) => {
      return row.name;
    }
  },
  {
    Header: 'CardSize',
    accessor: 'cardSize'
  },
  {
    Header: 'Edit',
    width: 50
  },
  {
    Header: '',
    accessor: 'id',
    width: 50
  }
];

const blockTableHeaderConfig = (headerGroups: HeaderGroup<IBlock>[]) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: '200px' }}
            className="w-full ml-16"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>
                {' '}
                {column.Header!.toString() !== 'Edit'
                  ? column.render('Header')
                  : ''}
              </div>
              <div>
                {column.Header!.toString() === '' ||
                column.Header!.toString() === 'Edit' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const blockTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IBlock> | undefined
  ) => TableBodyProps,
  rows: Row<IBlock>[],
  prepareRow: (row: Row<IBlock>) => void,
  setIsDeleteHover: (isHover: boolean) => void,
  isDeleteHover: boolean,
  setIsEditHover: (isHover: boolean) => void,
  isEditHover: boolean,
  onClickDeleteIcon: (block: IBlock) => void,
  onClickEditIcon: (block: IBlock) => void,
  blocks: IBlock[],
  onClickRow: (blockId: string) => void,
  rowSelectedId?: string
) => (
  <div
    className="overflow-y-auto"
    style={{ maxHeight: `calc(${getMaxViewHeight()} - 9vh` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      const elementId = row.cells[row.cells.length - 1].value;
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap h-16 relative rounded-md ${bgcolor} hover:shadow-lg cursor-pointer`}
          onMouseEnter={() => setRowHovered(i)}
          onClick={() => onClickRow(elementId)} //Block Id
        >
          {rowSelectedId === elementId && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'my-1 text-left';
            if (index !== row.cells.length - 1) {
              classNames += ' text-center';
            }
            if (cell.column.Header?.toString() === '') {
              return (
                <div
                  {...cell.getCellProps()}
                  className="absolute top-4 right-0"
                >
                  <div className="flex w-full flex-row">
                    {onClickDeleteIcon && (
                      <RiDeleteBin2Line
                        className={`text-3xl cursor-pointer ${
                          isDeleteHover && rowHovered === i
                            ? 'text-error1'
                            : 'text-theme6'
                        }`}
                        onMouseEnter={() => setIsDeleteHover(true)}
                        onMouseLeave={() => setIsDeleteHover(false)}
                        onClick={() => {
                          onClickDeleteIcon(
                            blocks.find((block) => block.id === cell.value)!
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            } else if (cell.column.Header?.toString() === 'Edit') {
              return (
                <div
                  {...cell.getCellProps()}
                  className="absolute top-4 right-12"
                >
                  <div className="flex w-full flex-row">
                    <div>
                      {onClickEditIcon && (
                        <BiPencil
                          className={`text-3xl cursor-pointer  ${
                            isEditHover && rowHovered === i
                              ? 'text-success'
                              : 'text-theme6'
                          }`}
                          onMouseEnter={() => setIsEditHover(true)}
                          onMouseLeave={() => setIsEditHover(false)}
                          onClick={() => {
                            onClickEditIcon(
                              blocks.find(
                                (block) => block.id === cell.row.cells[3].value
                              )!
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} my-auto ml-2`}
                style={{
                  width:
                    cell.column.Header?.toString() === 'Name'
                      ? '200px'
                      : '120px'
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
