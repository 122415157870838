import dayjs from 'dayjs';

export const getFromNow = (date: dayjs.Dayjs) => {
  const sinceDateFormat = (date: dayjs.Dayjs, dateFormat: string) => {
    return Number(dayjs().format(dateFormat)) - Number(date.format(dateFormat));
  };

  const dateString = (dateDiff: number, dateType: string) => {
    return `${dateDiff} ${dateType}${dateDiff !== 1 ? 's' : ''} ago`;
  };

  const yearDiff = sinceDateFormat(date, 'YYYY');
  if (yearDiff > 0) {
    return dateString(yearDiff, 'year');
  }
  const monthDiff = sinceDateFormat(date, 'MM');
  if (monthDiff > 0) {
    return dateString(monthDiff, 'month');
  }
  const dayDiff = sinceDateFormat(date, 'DD');
  if (dayDiff >= 7) {
    return dateString(Math.floor(dayDiff / 7), 'week');
  }
  if (dayDiff > 0) {
    return dateString(dayDiff, 'day');
  }

  return dateString(sinceDateFormat(date, 'HH'), 'hour');
};

export function formatDateWithTime(dateTime: string) {
  const localDate = dayjs(dateTime);
  return localDate.format('DD MMMM YYYY HH:mm');
}

export function formatDate(dateTime: string) {
  const localDate = dayjs(dateTime);
  return localDate.format('DD MMMM, YYYY');
}
