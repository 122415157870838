import React from 'react';
import CreateModal from 'component/admin/CreateModal';
import { IBaseWithName } from 'Colugo/interfaces/common';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';

type Props = {
  onCreateItem: (item: IBaseWithName) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  itemTaggingType?: FilterGroupTagging;
};

function ItemCreate(props: Props) {
  const { onCreateItem, setIsModalOpen, isModalOpen, itemTaggingType } = props;

  const handleSubmitItem = async (name: string) => {
    const newItem: IBaseWithName = {
      name
    };

    await onCreateItem(newItem);
  };

  let title = ' ';
  switch (itemTaggingType) {
    case FilterGroupTagging.AddTagToFilterGroup:
      title = 'Create Tag';
      break;
    case FilterGroupTagging.AddFilterGroupToTag:
    case FilterGroupTagging.AddFilterGroupToCombo:
      title = 'Create Filter Group';
  }

  const isAddTagToFilterGroup =
    itemTaggingType === FilterGroupTagging.AddTagToFilterGroup;

  return (
    <CreateModal
      title={title}
      minCharacterCount={isAddTagToFilterGroup ? 1 : 4}
      submitButtonText={title}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSubmit={handleSubmitItem}
    />
  );
}

export default ItemCreate;
