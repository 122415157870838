import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from '../../provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';
import { ISpiritCustomization } from 'Colugo/interfaces/spirits';

@scoped(Lifecycle.ContainerScoped)
class SpiritCustomizationOperations extends AbstractOperations {
  protected basePath: string = 'spiritCustomization';

  public async createAsync(
    spiritCustomization: ISpiritCustomization
  ): requestResponse<ISpiritCustomization> {
    return await this.executePost(``, spiritCustomization);
  }

  public async updateAsync(
    spiritCustomization: ISpiritCustomization
  ): requestResponse<ISpiritCustomization> {
    return await this.executePut(
      `${spiritCustomization.id}`,
      spiritCustomization,
      {},
      true
    );
  }

  public async deleteAsync(spiritCustomizationId: string): requestResponse {
    return await this.executeDelete(`${spiritCustomizationId}`);
  }
}

//TODO: still used as a function until we find a fix for the swr library(https://jirabindystreet.atlassian.net/browse/KW-2152)
const useReqListSpiritCustomizations = (): swrRequestResponse<
  ISpiritCustomization[]
> => {
  return useGetHttpClient(
    `spiritCustomization/list`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default SpiritCustomizationOperations;
export { useReqListSpiritCustomizations };
