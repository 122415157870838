import { useState } from 'react';
import { Input, Spinner } from '@bindystreet/bindystreet.kit.react';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import { toast } from 'react-toastify';
import PQueue from 'p-queue/dist';
import './buttonOfDeath.css';
import { Prompt } from 'react-router';
import { container } from 'tsyringe';
import { GuideOperations } from 'Colugo/operations/guides';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import { AiOutlineWarning } from 'react-icons/ai';
import { UserOperations } from 'Colugo/operations/identity';
import CollectionOperations from 'Colugo/operations/collections/CollectionOperations';

export const republishGamesQueue = new PQueue({ concurrency: 3 });

const guideOperations = container.resolve(GuideOperations);
const listingOperations = container.resolve(ListingOperations);
const userOperations = container.resolve(UserOperations);
const collectionOperations = container.resolve(CollectionOperations);

function SecretContainer() {
  const [isPublishAllWarningModalOpen, setIsPublishAllWarningModalOpen] =
    useState(false);
  const [
    isReindexListingsWarningModalOpen,
    setIsReindexListingsWarningModalOpen
  ] = useState(false);
  const [isReindexUsersWarningModalOpen, setIsReindexUsersWarningModalOpen] =
    useState(false);
  const [
    isReindexCollectionsWarningModalOpen,
    setIsReindexCollectionsWarningModalOpen
  ] = useState(false);
  const [secretValue, setSecretValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleRepublishAllGames = async () => {
    setIsPublishAllWarningModalOpen(false);
    if (secretValue === undefined || secretValue.length === 0) {
      toast.error('request missing secret');
      return;
    }
    setIsLoading(true);
    await guideOperations.republishAll(secretValue);
    await new Promise((f) => setTimeout(f, 3000));
    setIsLoading(false);
  };

  const handleReindexAllListings = async () => {
    setIsPublishAllWarningModalOpen(false);
    if (secretValue === undefined || secretValue.length === 0) {
      toast.error('request missing secret');
      return;
    }
    setIsLoading(true);
    await listingOperations.reindexAllAsync(secretValue);
    await new Promise((f) => setTimeout(f, 3000));
    setIsLoading(false);
  };

  const handleReindexAllUsers = async () => {
    setIsPublishAllWarningModalOpen(false);
    if (secretValue === undefined || secretValue.length === 0) {
      toast.error('request missing secret');
      return;
    }
    setIsLoading(true);
    await userOperations.reindexAllAsync(secretValue);
    await new Promise((f) => setTimeout(f, 3000));
    setIsLoading(false);
  };

  const handleReindexAllCollections = async () => {
    setIsPublishAllWarningModalOpen(false);
    if (secretValue === undefined || secretValue.length === 0) {
      toast.error('request missing secret');
      return;
    }
    setIsLoading(true);
    await collectionOperations.reindexAllAsync(secretValue);
    await new Promise((f) => setTimeout(f, 3000));
    setIsLoading(false);
  };

  function buttonWithLabel(
    labelText: string,
    setIsOpen: (value: React.SetStateAction<boolean>) => void
  ) {
    return (
      <div className="mt-10">
        <span className="ml-4 absolute z-10 bg-error1 p-2 rounded-md font-bold text-white">
          {labelText}
        </span>
        <button
          className="publishAllGamesButton ml-32 mt-40"
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </div>
    );
  }
  return (
    <div className="h-full overflow-hidden">
      <div className="w-full flex flex-row mt-10">
        <div className="flex mx-auto">
          <AiOutlineWarning size={48} className="text-error1" />
          <span className="block text-4xl text-black mt-0.5 mx-2">
            THE BIG RED BUTTONS
          </span>
          <AiOutlineWarning size={48} className="text-error1" />
        </div>
      </div>
      {!isLoading && (
        <div className="w-full h-full flex flex-row ml-40">
          <div className="flex mx-auto">
            <div className="h-full flex flex-col">
              <div className="flex my-auto">
                {buttonWithLabel(
                  'Republish all Guides',
                  setIsPublishAllWarningModalOpen
                )}
                <div className="w-20" />
                {buttonWithLabel(
                  'Reindex all Users',
                  setIsReindexUsersWarningModalOpen
                )}
              </div>
              <div className="flex my-auto">
                {buttonWithLabel(
                  'Reindex all Listings and Events',
                  setIsReindexListingsWarningModalOpen
                )}
                <div className="w-20" />
                {buttonWithLabel(
                  'Reindex all Collections',
                  setIsReindexCollectionsWarningModalOpen
                )}
              </div>
              <div className="h-56 flex" />
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="animate-bounce mt-24">
          Loading...
          <Spinner size="xl" color="green" />
        </div>
      )}
      <Prompt
        when={isLoading}
        message="Data is still updating, if you leave you may cause chaos. Leave?"
      />
      <ConfirmDeletePopup
        isModalOpen={isPublishAllWarningModalOpen}
        closeModal={() => setIsPublishAllWarningModalOpen(false)}
        onSubmit={handleRepublishAllGames}
        skin={'publishAllGames'}
        title="Republish all existing guides"
      >
        <Input
          label="type in the secret code"
          onChange={(e) => setSecretValue(e.currentTarget.value)}
        />
      </ConfirmDeletePopup>
      <ConfirmDeletePopup
        isModalOpen={isReindexListingsWarningModalOpen}
        closeModal={() => setIsReindexListingsWarningModalOpen(false)}
        onSubmit={handleReindexAllListings}
        skin={'reindexAllListings'}
        title="Reindex all the Listings & Events"
      >
        <Input
          label="type in the secret code"
          onChange={(e) => setSecretValue(e.currentTarget.value)}
        />
      </ConfirmDeletePopup>
      <ConfirmDeletePopup
        isModalOpen={isReindexUsersWarningModalOpen}
        closeModal={() => setIsReindexUsersWarningModalOpen(false)}
        onSubmit={handleReindexAllUsers}
        skin={'reindexAllUsers'}
        title="Reindex all the Users"
      >
        <Input
          label="type in the secret code"
          onChange={(e) => setSecretValue(e.currentTarget.value)}
        />
      </ConfirmDeletePopup>{' '}
      <ConfirmDeletePopup
        isModalOpen={isReindexCollectionsWarningModalOpen}
        closeModal={() => setIsReindexCollectionsWarningModalOpen(false)}
        onSubmit={handleReindexAllCollections}
        skin={'reindexAllCollections'}
        title="Reindex all the Collections"
      >
        <Input
          label="type in the secret code"
          onChange={(e) => setSecretValue(e.currentTarget.value)}
        />
      </ConfirmDeletePopup>
    </div>
  );
}

export default SecretContainer;
