import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IBase } from 'Colugo/interfaces';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { ISpotVersion } from 'Colugo/interfaces/games';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { BlockOperations } from 'Colugo/operations/lobby';
import Table from 'component/utility/Table';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const blockOperations = container.resolve(BlockOperations);

export const spotRankedResultItemType =
  'Dippy.Models.Search.Ranked.SpotRankedResultItem, Dippy.Models';
export const guideRankedResultItemType =
  'Dippy.Models.Search.Ranked.GuideRankedResultItem, Dippy.Models';
export const eventRankedResultItemType =
  'Dippy.Models.Search.Ranked.EventRankedResultItem, Dippy.Models';
export const listingRankedResultItemType =
  'Dippy.Models.Search.Ranked.ListingRankedResultItem, Dippy.Models';
export const spotType = 'Dippy.Models.Games.SpotVersion, Dippy.Models';
export const guideType = 'Dippy.Models.Games.Game, Dippy.Models';
export const eventType = 'Dippy.Models.Ioi.Event, Dippy.Models';
export const listingType = 'Dippy.Models.Ioi.Listing, Dippy.Models';

export function getBlockEntityType($type?: string): BlockEntityType {
  switch ($type) {
    case listingType:
    case listingRankedResultItemType:
      return BlockEntityType.Listing;
    case eventType:
    case eventRankedResultItemType:
      return BlockEntityType.Event;
    case guideType:
    case guideRankedResultItemType:
      return BlockEntityType.Guide;
    case spotType:
    case spotRankedResultItemType:
      return BlockEntityType.Spot;
  }

  return BlockEntityType.Video;
}

export function getEntityId(entity: IBase): string {
  const blockEntityType = getBlockEntityType(entity.$type);
  return (
    (blockEntityType === BlockEntityType.Spot
      ? (entity as ISpotVersion).versionable?.id
      : entity.id) || ''
  );
}

type Props<T extends IBase = IListing | IEvent | IVideo> = {
  entityName: string;
  entityType: BlockEntityType;
  onChangeSearchValue?: (value: string) => void;
  isBlockItemsLoading?: boolean;
  isBlockItemsError?: boolean;
  isAllEntitiesSelected: boolean;
  selectedBlock: IBlock;
  updateItemsForBlock: (
    data?: T[],
    shouldRevalidate?: boolean | undefined
  ) => Promise<T[] | undefined>;
  setIsAllEntitiesSelected: (isAllEntitiesSelected: boolean) => void;
  currentBlockItemsEntities: T[];
  entities: T[];
  tableName: string;
  showGuideNameColumn?: boolean;
  columns: Column<T>[];
  tableRowHeight?: string;
  onClickManage?: (entityId: T) => void;
};

function ManualBlockEntityEditor<
  TModel extends IBase = IListing | IEvent | IVideo
>(props: Props<TModel>) {
  const {
    entityName,
    entityType,
    columns,
    currentBlockItemsEntities,
    entities,
    tableName,
    selectedBlock,
    isBlockItemsLoading,
    isBlockItemsError,
    updateItemsForBlock,
    isAllEntitiesSelected,
    setIsAllEntitiesSelected,
    onChangeSearchValue,
    tableRowHeight,
    onClickManage
  } = props;

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  async function addItemToBlockAsync(entity: TModel) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }
    const itemToAdd = entities?.find((e) => e.id === entity.id);
    if (!itemToAdd) {
      toast.error(`${entityName} not found. Could not add to Block.`);
      return;
    }
    setIsMutationLoading(true);

    const blockEntityType = getBlockEntityType(entity.$type);
    const entityId = getEntityId(entity);

    const { error } = await blockOperations.addItemToBlockAsync(
      selectedBlock.id,
      entityId!,
      blockEntityType
    );
    setIsMutationLoading(false);
    if (error) {
      if (error.status === 409) {
        toast.error(`${entityName} already exists in Block.`);
        return;
      }
      toast.error(`Error occurred: Unable to add ${entityName} to Block`);
      return;
    }
    toast.success(`${entityName} added to block`);
    updateItemsForBlock([...currentBlockItemsEntities, itemToAdd], false);
  }

  async function handleRemoveItemFromItemsBlocksAsync(entity: TModel) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }
    if (!entities) {
      toast.error('No Items exists for this block.');
      return;
    }

    const itemToRemove = entities.find((l) => l?.id === entity.id);

    if (!itemToRemove) {
      toast.error('Item not found. Cannot remove, please refresh the page.');
      return;
    }
    setIsMutationLoading(true);

    const entityId = getEntityId(entity);

    let error;
    switch (entityType) {
      case BlockEntityType.Listing:
        error = await blockOperations.removeListingsGroupItemFromBlockAsync(
          selectedBlock.id,
          entityId
        );
        break;
      case BlockEntityType.Event:
        error = await blockOperations.removeEventsGroupItemFromBlockAsync(
          selectedBlock.id,
          entityId
        );
        break;
      case BlockEntityType.Video:
        error = await blockOperations.removeVideoGroupItemFromBlockAsync(
          selectedBlock.id,
          entityId
        );
        break;
      case BlockEntityType.None:
        error = await blockOperations.removeEntitiesGroupItemFromBlockAsync(
          selectedBlock.id,
          entityId
        );
        break;
    }
    setIsMutationLoading(false);
    if (error.response) {
      toast.error(`Error occurred: Unable to remove ${entityName} from Block.`);
      return;
    }

    const remnainingEntities = entities.filter(
      (entity) => entity.id !== itemToRemove.id
    );

    updateItemsForBlock(remnainingEntities, false);
  }

  async function handleUpdateItemsOrderAsync(entity: TModel, position: number) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }
    const itemToReorder = entities.find((i) => i?.id === entity.id);
    if (!itemToReorder) {
      toast.error(
        `${entityName} not found. Cannot reorder, please refresh the page.`
      );
      return;
    }

    setIsMutationLoading(true);

    const entityId = getEntityId(entity);

    const { data: updatedItemsGroupOrder, error } =
      await blockOperations.updateItemOrderAsync(
        selectedBlock.id,
        entityId,
        position
      );

    if (!updatedItemsGroupOrder || error) {
      toast.error('Failed to update order. Please refresh the page.');
      setIsMutationLoading(false);
      return;
    }
    setIsMutationLoading(false);
    updateItemsForBlock(entities, true);
  }

  const containerTitle = `Add or Remove entities to ${selectedBlock?.name}`;

  const allItemsButton = (
    <div className="w-auto flex flex-row mb-2 justify-center">
      <div
        onClick={() => setIsAllEntitiesSelected(false)}
        className={` ${
          isAllEntitiesSelected
            ? 'text-black bg-white'
            : 'text-white bg-primaryButton'
        } font-medium px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        Manage Block
      </div>
      <div
        onClick={() => setIsAllEntitiesSelected(true)}
        className={`  ${
          isAllEntitiesSelected
            ? 'text-white bg-primaryButton'
            : 'text-black bg-white'
        } px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        Search
      </div>
    </div>
  );

  if (isBlockItemsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load items from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div
      className="w-auto relative flex flex-col items-center rounded-md border-2 border-gray-400"
      style={{ height: 'auto' }}
    >
      <div className="bg-theme3 w-full h-12 flex flex-col items-center justify-center">
        <div className="mt-1 mb-2 font-bold text-xl">{tableName}</div>
      </div>

      <div className="px-4 w-full block text-lg italic text-left mt-3">
        <div>{containerTitle}</div>
      </div>
      <div className="w-full mb-1">
        {isBlockItemsLoading ? (
          <Spinner />
        ) : (
          <div>
            <DndProvider backend={HTML5Backend}>
              <Table
                tableColumns={columns as Column<TModel>[]}
                searchPlaceholderText={entityName + 's'}
                children={allItemsButton}
                onChangeSearchValue={onChangeSearchValue}
                isBlockPageView
                isBlockItems={!isAllEntitiesSelected}
                entities={entities || []}
                onClickAddIcon={
                  isAllEntitiesSelected ? addItemToBlockAsync : undefined
                }
                onClickManageIcon={onClickManage}
                isEditable={!!onClickManage}
                onChangeOrder={
                  !isAllEntitiesSelected
                    ? handleUpdateItemsOrderAsync
                    : undefined
                }
                isMutationLoading={isMutationLoading}
                isOrderable={!isAllEntitiesSelected}
                onClickDeleteIcon={
                  !isAllEntitiesSelected
                    ? handleRemoveItemFromItemsBlocksAsync
                    : undefined
                }
                shouldShowOrderArrows={false}
                rowHeight={tableRowHeight}
              />
            </DndProvider>
          </div>
        )}
      </div>
    </div>
  );
}

export default ManualBlockEntityEditor;
