import React, { useState } from 'react';
import { Input } from '@bindystreet/bindystreet.kit.react';
import { IPlayer } from 'Colugo/interfaces/identity';
import { BiSearchAlt, BiReset } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<IPlayer>[];
  players: IPlayer[];
  onClickSelectPlayer: (e: any) => void;
  onClickResetPlayer: () => void;
  onChangeSearchValue: (value: string) => void;
  onClickRow: (playerId: string) => void;
  rowSelectedId?: string;
};

const PlayersTable: React.FC<Props> = (props) => {
  const {
    players,
    tableColumns,
    onClickSelectPlayer,
    onClickResetPlayer,
    onChangeSearchValue,
    onClickRow,
    rowSelectedId
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [isEditHover, setIsEditHover] = useState(false);
  const [isResetHover, setIsResetHover] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: players
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  // Trigger web request from result
  const onChangePlayerNameInput = (value: string) => {
    setGlobalFilter(value);
    onChangeSearchValue(value);
  };

  const table = (
    <div>
      <div {...getTableProps()} className="w-full pb-32">
        <div>{playerTableHeaderConfig(headerGroups)}</div>
        {playerTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          players,
          onClickSelectPlayer,
          onClickResetPlayer,
          isEditHover,
          setIsEditHover,
          isResetHover,
          setIsResetHover,
          onClickRow,
          rowSelectedId
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row my-4">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={onChangePlayerNameInput}
        />
      </div>
      {table}
    </div>
  );
};

export default PlayersTable;

const GlobalFilter: React.FC<any> = ({ globalFilter, setGlobalFilter }) => {
  const [filterValue, setFilterValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="w-full flex">
      <div className="relative flex flex-row w-full">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder="Search Players"
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
      </div>
    </div>
  );
};

export const playerTableColumns = [
  {
    Header: 'Email',
    accessor: 'userEmail'
  },
  {
    Header: 'Essence',
    accessor: 'essences'
  },
  {
    Header: 'Reset',
    width: 50
  },
  {
    Header: '',
    accessor: 'id',
    width: 100
  }
];

const playerTableHeaderConfig = (headerGroups: HeaderGroup<IPlayer>[]) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{
              width: column.Header?.toString() === 'Reset' ? '10px' : '250px'
            }}
            className="w-full ml-4"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>
                {' '}
                {column.Header?.toString() !== 'Reset'
                  ? column.render('Header')
                  : ''}
              </div>
              <div>
                {column.Header?.toString() === '' ||
                column.Header?.toString() === 'Reset' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const playerTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IPlayer> | undefined
  ) => TableBodyProps,
  rows: Row<IPlayer>[],
  prepareRow: (row: Row<IPlayer>) => void,
  players: IPlayer[],
  onClickSelectPlayer: (e: any) => void,
  onClickResetPlayer: () => void,
  isEditHover: boolean,
  setIsEditHover: (isHover: boolean) => void,
  isResetHover: boolean,
  setIsResetHover: (isHover: boolean) => void,
  onClickRow: (playerId: string) => void,
  rowSelectedId?: string
) => (
  <div
    style={{ maxHeight: `calc(${getMaxViewHeight()}` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const playerId = row.cells.find((c) => c.column.id === 'id')?.value;
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap relative rounded-md ${bgcolor} hover:shadow-lg`}
          onMouseEnter={() => setRowHovered(i)}
          onClick={() => onClickRow(playerId)}
        >
          {rowSelectedId === playerId && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'text-left';
            if (index !== row.cells.length - 1) {
              classNames += ' text-center';
            }
            if (cell.column.Header?.toString() === '') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: '100px', height: '50px' }}
                  className="absolute top-5 right-10"
                >
                  <MdEdit
                    className={`text-2xl cursor-pointer mx-4 -mt-2 ${
                      rowHovered === cell.row.index && isEditHover
                        ? 'text-success'
                        : 'text-theme6'
                    }`}
                    onMouseEnter={() => setIsEditHover(true)}
                    onMouseLeave={() => setIsEditHover(false)}
                    onClick={() => onClickSelectPlayer(cell.row.values)}
                  />
                </div>
              );
            }
            if (cell.column.Header?.toString() === 'Reset') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: '100px', height: '50px' }}
                  className="absolute top-5 right-24"
                >
                  <div>
                    <BiReset
                      className={`text-2xl cursor-pointer mx-4 -mt-2 ${
                        rowHovered === cell.row.index && isResetHover
                          ? 'text-error1'
                          : 'text-theme6'
                      }`}
                      onMouseEnter={() => setIsResetHover(true)}
                      onMouseLeave={() => setIsResetHover(false)}
                      onClick={onClickResetPlayer}
                    ></BiReset>
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} mt-2 h-10`}
                style={{ width: '220px' }}
              >
                {cell.value ? cell.render('Cell') : 0}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
