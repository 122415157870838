import { SubPageType } from 'Colugo/interfaces/lobby/discover/enums';
import SubPagesContainer from '../discover/subPage/SubPagesContainer';

function HomeContainer() {
  return (
    <SubPagesContainer type={SubPageType.Home} title="Home Screen Management" />
  );
}

export default HomeContainer;
