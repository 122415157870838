import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ISuggestion } from 'Colugo/interfaces/feedback/ISuggestion';
import FeedbackOperations, {
  useReqGetSuggestions
} from 'Colugo/operations/feedback/FeedbackOperations';
import FixedInput from 'component/utility/FixedInput';
import Table from 'component/utility/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { formatDateWithTime } from 'utility/general/dateUtilities';
import { formatLabel } from 'utility/general/surveyUtilities';

const feedbackOperations = container.resolve(FeedbackOperations);

interface IFormattedSuggestionResponse {
  id: string;
  username: string;
  title?: string;
  submissionDate: string;
  description: string;
}

function SuggestionEntries() {
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<string>('');
  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const [formattedResponse, setFormattedResponse] = useState<
    IFormattedSuggestionResponse | undefined
  >(undefined);

  const {
    data: suggestions,
    isLoading: isSuggestionsLoading,
    isError: isSuggestionsError,
    mutate: mutateSuggestions
  } = useReqGetSuggestions();

  const columns = useMemo(() => {
    const eventGroupItemTableColumns: Column<ISuggestion>[] = [
      {
        Header: 'Username',
        accessor: (row) => {
          return row?.user.username;
        }
      },
      {
        Header: 'Date Submitted',
        accessor: (row) => {
          return formatDateWithTime(row?.createdAt!);
        }
      },

      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return eventGroupItemTableColumns;
  }, []);

  const formattedSuggestionResponse = useCallback(() => {
    let responses: IFormattedSuggestionResponse[] = [];
    if (!suggestions) {
      return;
    }

    suggestions.forEach((suggestionResponse) => {
      const formattedResponse: IFormattedSuggestionResponse = {
        id: suggestionResponse?.id!,
        username: suggestionResponse.user.username!,
        title: suggestionResponse?.title ? suggestionResponse.title : '',
        submissionDate: formatDateWithTime(suggestionResponse.createdAt!),
        description: suggestionResponse.description
      };
      responses.push(formattedResponse);
    });
    return responses;
  }, [suggestions]);

  const handleOnClickRow = useCallback(
    (entityId: string) => {
      if (entityId) {
        setSelectedSuggestionId(entityId);
        const formattedResponse = formattedSuggestionResponse()?.find(
          (fr) => fr.id === entityId
        );
        setFormattedResponse(formattedResponse);
      }
    },
    [formattedSuggestionResponse, setFormattedResponse]
  );

  useEffect(() => {
    if (suggestions && suggestions.length > 0) {
      handleOnClickRow(suggestions[0].id!);
    }
  }, [suggestions, handleOnClickRow]);

  async function handleDeleteSuuggestionAsync(selectedSuggestionId: string) {
    if (!selectedSuggestionId) {
      toast.error(`No suggestion with that id : ${selectedSuggestionId} found`);
      return;
    }

    setIsMutationLoading(true);
    const { error } = await feedbackOperations.deleteSuggestionAsync(
      selectedSuggestionId
    );

    setIsMutationLoading(false);
    if (error) {
      toast.error('Error occurred: Unable to delete suggestion.');
      return;
    }

    const remainingSuggestions = suggestions?.filter(
      (sr) => sr.id !== selectedSuggestionId
    );

    mutateSuggestions(remainingSuggestions, true);
  }

  if (isSuggestionsLoading) {
    return <Spinner />;
  }

  if (isSuggestionsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load bug suggestions from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="w-full flex px-4 justify-between mt-2">
      <div className="flex justify-start" style={{ width: '48%' }}>
        <DndProvider backend={HTML5Backend}>
          <Table
            tableColumns={columns}
            searchPlaceholderText={'suggestions'}
            isBlockPageView
            isMutationLoading={isMutationLoading}
            entities={suggestions || []}
            onClickDeleteIcon={async (entity) =>
              await handleDeleteSuuggestionAsync(entity?.id!)
            }
            shouldShowOrderArrows={false}
            onClickRow={handleOnClickRow}
            rowSelectedId={selectedSuggestionId}
          />
        </DndProvider>
      </div>
      <div className="flex flex-col items-start" style={{ width: '48%' }}>
        <div className="mt-2 font-inter font-semibold">Suggestions View</div>
        <div
          className="flex flex-col items-start bg-theme3 rounded-lg px-10 py-2 mt-2 overflow-y-scroll pb-8 w-full"
          style={{ height: '80vh' }}
        >
          {formattedResponse &&
            [formattedResponse]?.map((fp, i) => {
              return (
                <div key={i} className="w-full">
                  {Object.entries(fp).map(([key, value]) => {
                    if (key === 'id') {
                      return null;
                    }
                    return (
                      <div key={key} className="mt-4">
                        <FixedInput
                          label={formatLabel(key)}
                          text={formatLabel(value)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SuggestionEntries;
