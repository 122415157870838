import { FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { AiOutlinePlus } from 'react-icons/ai';

type Props = {
  tag: FlyoutMenuItem;
  onClick: (item: FlyoutMenuItem) => void;
};

function TagCard(props: Props) {
  const { tag, onClick } = props;

  return (
    <div
      className="flex flex-row items-center mr-3 px-4 mb-3 rounded-lg border border-theme6 bg-theme8 cursor-pointer h-10 hover:bg-theme9"
      onClick={() => onClick(tag)}
    >
      <span className="mr-2 font-nunito font-normal leading-normal inline-block whitespace-nowrap text-base">
        {tag.label}
      </span>
      <AiOutlinePlus />
    </div>
  );
}

export default TagCard;
