import React, { useState, useMemo } from 'react';
import { ICategory } from 'Colugo/interfaces/common';
import { ITag } from 'Colugo/interfaces/tags/ITag';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import CategoryTable from './CategoryTable';
import CategoryUpdateForm from './CategoryUpdateForm';
import { Column } from 'react-table';

type Props = {
  categories: ICategory[];
  updateCategory: (category: ICategory) => Promise<boolean>;
  onChangeCategoryOrder: (
    category: ICategory,
    position: number
  ) => Promise<ICategory | undefined>;
  onAddTagToCategory: (
    tag: ITag,
    categoryId: string,
    isAdded: boolean
  ) => Promise<boolean>;
  tags: ITag[];
};

const CategoryEditor: React.FC<Props> = (props) => {
  const {
    categories,
    updateCategory,
    onChangeCategoryOrder,
    onAddTagToCategory,
    tags
  } = props;

  const [selectedCategoryId, setSelectedCategory] = useState<
    undefined | string
  >(undefined);

  const handleUpdateCategory = async (category: ICategory) => {
    if (!selectedCategoryId) {
      toast.error('No category selected to update');
      return;
    }

    if (
      isEqual(category.key, selectedCategory?.key) &&
      isEqual(category.name, selectedCategory?.name) &&
      isEqual(category.colour, selectedCategory?.colour)
    ) {
      toast.warning('Nothing to update');
      return;
    }

    await updateCategory(category);
  };

  const handleUpdateCategoryOrder = async (
    category: ICategory,
    position: number
  ) => {
    if (!selectedCategoryId) {
      toast.error('No category selected to update');
      return;
    }

    return await onChangeCategoryOrder(category, position);
  };

  const columns = useMemo(() => {
    const categoryTableColumns: Column<ICategory>[] = [
      {
        Header: 'Category Name',
        accessor: 'name'
      },
      {
        Header: 'Category Key',
        accessor: 'key'
      },
      {
        Header: 'Category Colour',
        accessor: 'colour'
      },
      {
        Header: 'Category Order',
        accessor: 'order'
      },
      {
        Header: '',
        accessor: 'id',
        width: 50
      }
    ];

    return categoryTableColumns;
  }, []);

  const containerTitle = 'Categories';

  const selectedCategory = categories.find(
    (category) => category?.id === selectedCategoryId
  );

  return (
    <div className="w-full">
      <span className="mx-auto block text-4xl text-black mt-6">
        {containerTitle}
      </span>
      <div className="flex flex-row pt-10">
        <div className="px-12 h-full w-2/5">
          <CategoryTable
            onClickRow={setSelectedCategory}
            tableColumns={columns}
            categories={categories}
            rowSelectedId={selectedCategoryId}
          />
        </div>
        <div className="w-full">
          {selectedCategory && (
            <CategoryUpdateForm
              key={selectedCategory.key}
              category={selectedCategory}
              onClickUpdateSubmit={handleUpdateCategory}
              onChangeCategoryOrder={handleUpdateCategoryOrder}
              categoryCount={categories.length}
              onAddTagToCategory={onAddTagToCategory}
              tags={tags}
            />
          )}
          {/* Need to build out game assignment/removal table */}
          {/* Must be easily searchable, so probs by name */}
          {/* Will be row: game name, category name, button to add to current category */}
        </div>
      </div>
    </div>
  );
};

export default CategoryEditor;

//TODO: Create Table /w searchbar layout.
// Categories list with category name and potentially number of games in list.
