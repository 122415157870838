export enum ScenarioType {
  None = 0,
  Sunny = 1,
  Raining = 2,
  Morning = 3,
  Afternoon = 4,
  Evening = 5,
  SunnyInactive = 6,
  RainingInactive = 7,
  MorningInactive = 8,
  AfternoonInactive = 9,
  EveningInactive = 10
}
