import { IUserCountThreshold } from 'Colugo/interfaces/identity';
import { requestResponse } from 'Colugo/provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

class UserCountThresholdOperations extends AbstractOperations {
  protected basePath: string = 'userCountThreshold';

  public async createAsync(
    userCountThreshold: IUserCountThreshold
  ): requestResponse<IUserCountThreshold> {
    return await this.executePost('', userCountThreshold);
  }

  public async getAsync(): requestResponse<IUserCountThreshold[]> {
    return await this.executeGet('');
  }

  public async deleteAsync(id: string): requestResponse<number[]> {
    return await this.executeDelete(`${id}`);
  }
}

export default UserCountThresholdOperations;
