import {
  IAdvertLocation,
  ScreenType
} from 'Colugo/interfaces/advertisement/IAdvertLocation';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class AdvertOperations extends AbstractOperations {
  protected basePath: string = 'advert';

  public async updateAdvertLocationAsync(
    advertLocation: IAdvertLocation
  ): requestResponse {
    return await this.executePut(
      `location/${advertLocation.id}`,
      advertLocation,
      {},
      true
    );
  }
}

const useReqListAdvertLocations = (
  screenType: ScreenType
): swrRequestResponse<IAdvertLocation[]> => {
  return useGetHttpClient(
    screenType !== undefined
      ? `advert/location/list?screenType=${screenType}`
      : null,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};
export default AdvertOperations;
export { useReqListAdvertLocations };
