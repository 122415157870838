type Props = {
  label: string;
  text?: string;
  textFromMultiSelect?: boolean;
};

function FixedInput(props: Props) {
  const { label, text, textFromMultiSelect } = props;

  const multiSelectText = textFromMultiSelect ? text : '';

  const multiSelectTextArray =
    multiSelectText &&
    multiSelectText.split(',').map((item) => item.trim().replace(/,/g, ''));

  return (
    <div className="flex flex-col w-full items-start">
      <div className="font-inter font-bold">{label}</div>

      <div className="bg-white font-inter border-surfaceOutline rounded-lg px-6 py-2 w-full flex justify-start mt-2">
        {textFromMultiSelect ? (
          <>
            {multiSelectTextArray &&
              multiSelectTextArray.map((mst, i) => (
                <div
                  key={i}
                  className="border-surfaceOutline rounded-lg mx-2 px-2 py-1 font-inter flex justify-center items-center"
                  style={{ backgroundColor: '#C2EBE3' }}
                >
                  {mst}
                </div>
              ))}
          </>
        ) : (
          <div>{text}</div>
        )}
      </div>
    </div>
  );
}

export default FixedInput;
