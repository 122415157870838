import { ImageInput } from '@bindystreet/bindystreet.kit.react';
import { reqCreatePicture } from 'provider/admin/methods';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  imageUrl?: string;
  onimageUpload: (url: string) => void;
  onClickDelete: () => void;
};

const SimpleImageUploader = (props: Props) => {
  const { imageUrl, onimageUpload: onImageUpload, onClickDelete } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadFile = async (acceptedFile: File) => {
    setIsLoading(true);
    const { data, error } = await reqCreatePicture(acceptedFile);
    setIsLoading(false);

    if (error) {
      toast.error('Image failed to upload');
      return;
    }

    onImageUpload(data);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const imageFile = acceptedFiles[0];
    await handleUploadFile(imageFile);
  };

  return (
    <ImageInput
      autoMargins={true}
      fit={'fill'}
      // isFullWidth={true}
      customHeightPx={'512px'}
      customWidthPx={'512px'}
      imageUrl={imageUrl}
      onDrop={onDrop}
      resolution={{
        width: 1024,
        height: 1024
      }}
      isLoading={isLoading}
      deleteImage={onClickDelete}
    />
  );
};

export default SimpleImageUploader;
