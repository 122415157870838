import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { BlockOperations, useReqListBlocks } from 'Colugo/operations/lobby';
import { reqDeleteBlock } from 'provider/admin/methods';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import BlockEditor from './BlockEditor';

const blockOperations = container.resolve(BlockOperations);

function BlockContainer() {
  const [blockType, setBlockType] = useState<BlockType>(
    BlockType.EntitiesGroup
  );
  const [localBlocks, setLocalBlocks] = useState<IBlock[]>();

  const {
    data: blocks,
    isLoading,
    isError,
    mutate
  } = useReqListBlocks(blockType);
  const { url } = useRouteMatch();

  useEffect(() => {
    const blocksWithType = (blocks || []).map((block) => {
      if (!block.type) {
        block.type = BlockType.NewGuides;
      }
      return block;
    });
    setLocalBlocks(blocksWithType);
  }, [blocks]);

  useEffect(() => {
    const segments = url.split('/');
    const lastSegment = segments[segments.length - 1];

    switch (lastSegment) {
      case 'GuideSpots':
        setBlockType(BlockType.GuideSpots);
        break;
      case 'ZoneSpots':
        setBlockType(BlockType.ZoneSpots);
        break;
      case 'SuggestedListings':
        setBlockType(BlockType.SuggestedListings);
        break;
      case 'EntitiesGroup':
        setBlockType(BlockType.EntitiesGroup);
        break;
      case 'SuggestedEvents':
        setBlockType(BlockType.SuggestedEvents);
        break;
      case 'SuggestedGuides':
        setBlockType(BlockType.SuggestedGuides);
        break;
      case 'VideosGroup':
        setBlockType(BlockType.VideosGroup);
        break;
      default:
        setBlockType(BlockType.GuideSpots);
        break;
    }
  }, [url]);

  const handleDeleteBlock = async (blockToDelete: IBlock) => {
    if (!blockToDelete?.id) {
      toast.error('Invalid game Id, please try again');
      return false;
    }

    const { data, error } = await reqDeleteBlock(blockToDelete.id);

    //Because we cant figure out how to return json on delete.
    if (!data && error?.status !== 404) {
      toast.error('Failed to delete block, please try again');
      return false;
    }

    const newBlocks = localBlocks?.filter(
      (block) => block.id !== blockToDelete.id
    );

    mutate(newBlocks, true);
    return true;
  };

  const handleCreateBlock = async (blockToAdd: IBlock) => {
    const { data, error } = await blockOperations.createAsync(blockToAdd);

    if (!data || error) {
      if (error?.status === 409) {
        toast.error(
          'Failed to create block, most likely block name already exists.'
        );
      } else {
        toast.error('Failed to create block, please try again');
      }
      return false;
    }

    const newBlocks = localBlocks ? [...localBlocks, data] : [data];
    mutate(newBlocks, true);
    return true;
  };

  const handleUpdateBlock = async (blockToUpdate: IBlock) => {
    const updateBlock = await blockOperations.updateAsync(blockToUpdate);

    if (!updateBlock.data || updateBlock.error) {
      toast.error('Failed to update block, please try again.');
      return false;
    }

    const newBlocks = (localBlocks || []).map((localBlock) =>
      localBlock === blockToUpdate ? updateBlock.data : localBlock
    );

    blockToUpdate.updatedAt = updateBlock.data.updatedAt;

    mutate(newBlocks, true);
    toast.success('Block details has been successfully updated ');
    return true;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load blocks from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full overflow-hidden">
      <BlockEditor
        blocks={localBlocks || []}
        deleteBlock={handleDeleteBlock}
        addBlock={handleCreateBlock}
        editBlockAsync={handleUpdateBlock}
        setBlockType={setBlockType}
        blockType={blockType}
      />
    </div>
  );
}

export default BlockContainer;
