import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  FavouriteFeaturesAnswer,
  HowUsingAnswer,
  ISurveyResponse,
  WhyUsingAnswer
} from 'Colugo/interfaces/feedback/ISurveyResponse';
import FeedbackOperations, {
  useReqGetSurveyResponses
} from 'Colugo/operations/feedback/FeedbackOperations';
import FixedInput from 'component/utility/FixedInput';
import Table from 'component/utility/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { formatDateWithTime } from 'utility/general/dateUtilities';
import {
  formatLabel,
  getResponseNameForQuestion,
  parseEnum,
  parseQuestionAnswer,
  parseRadioAnswer
} from 'utility/general/surveyUtilities';

const feedbackOperations = container.resolve(FeedbackOperations);

interface IFormattedSurveyResponse {
  id: string;
  username: string;
  submissionDate: string;
  howFastAnswer: string;
  haveVisitedAnswer: string;
  howUsefulAnswer: string;
  favouriteFeaturesAnswers: string;
  whyUsingAnswers: string;
  howUsingAnswers: string;
  otherApps: string;
  additionalThoughts: string;
}

function SurveySubmissions() {
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>('');
  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const [formattedResponse, setFormattedResponse] = useState<
    IFormattedSurveyResponse | undefined
  >(undefined);

  const {
    data: surveyResponses,
    isLoading: isSurveyResponsesLoading,
    isError: isSurveyResponsesError,
    mutate: mutateSurveyResponses
  } = useReqGetSurveyResponses();

  const columns = useMemo(() => {
    const eventGroupItemTableColumns: Column<ISurveyResponse>[] = [
      {
        Header: 'Username',
        accessor: (row) => {
          return row?.user.username;
        }
      },
      {
        Header: 'Date Submitted',
        accessor: (row) => {
          return formatDateWithTime(row?.createdAt!);
        }
      },

      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return eventGroupItemTableColumns;
  }, []);

  const formattedSurveyResponse = useCallback(() => {
    let responses: IFormattedSurveyResponse[] = [];
    if (!surveyResponses) {
      return;
    }

    surveyResponses.forEach((surveyResponse) => {
      const formattedResponse: IFormattedSurveyResponse = {
        id: surveyResponse?.id!,
        username: surveyResponse.user.username!,
        submissionDate: formatDateWithTime(surveyResponse.createdAt!),
        howFastAnswer: parseRadioAnswer(surveyResponse.howFastAnswer),
        haveVisitedAnswer: parseRadioAnswer(surveyResponse.haveVisitedAnswer),
        howUsefulAnswer: parseRadioAnswer(surveyResponse.howUsefulAnswer),
        favouriteFeaturesAnswers: parseEnum(
          surveyResponse.favouriteFeaturesAnswers,
          FavouriteFeaturesAnswer
        ),
        whyUsingAnswers: parseEnum(
          surveyResponse.whyUsingAnswers,
          WhyUsingAnswer
        ),
        howUsingAnswers: parseEnum(
          surveyResponse.howUsingAnswers,
          HowUsingAnswer
        ),
        otherApps: surveyResponse.otherApps ? surveyResponse.otherApps : '',
        additionalThoughts: surveyResponse.additionalThoughts
          ? surveyResponse.additionalThoughts
          : ''
      };
      responses.push(formattedResponse);
    });
    return responses;
  }, [surveyResponses]);

  const handleOnClickRow = useCallback(
    (entityId: string) => {
      if (entityId) {
        setSelectedSurveyId(entityId);
        const formattedResponse = formattedSurveyResponse()?.find(
          (fr) => fr.id === entityId
        );
        setFormattedResponse(formattedResponse);
      }
    },
    [formattedSurveyResponse, setFormattedResponse]
  );

  useEffect(() => {
    if (surveyResponses && surveyResponses.length > 0) {
      handleOnClickRow(surveyResponses[0].id!);
    }
  }, [surveyResponses, handleOnClickRow]);

  async function handleDeleteSurveyAsync(selectedSurveyId: string) {
    if (!selectedSurveyId) {
      toast.error('No survey found');
      return;
    }

    setIsMutationLoading(true);
    const { error } = await feedbackOperations.deleteSurveyAsync(
      selectedSurveyId
    );
    setIsMutationLoading(false);
    if (error) {
      toast.error('Error occurred: Unable to delete survey.');
      return;
    }

    const remainingSubmissions = surveyResponses?.filter(
      (sr) => sr.id !== selectedSurveyId
    );

    mutateSurveyResponses(remainingSubmissions, true);
  }

  if (isSurveyResponsesLoading) {
    return <Spinner />;
  }

  if (isSurveyResponsesError) {
    return (
      <ErrorPage>
        <span>{'Unable to load survey responses from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="w-full flex px-4 justify-between mt-2">
      <div className="flex justify-start" style={{ width: '48%' }}>
        <DndProvider backend={HTML5Backend}>
          <Table
            tableColumns={columns}
            searchPlaceholderText={'submissions'}
            isBlockPageView
            isMutationLoading={isMutationLoading}
            entities={surveyResponses || []}
            onClickDeleteIcon={async (entity) =>
              await handleDeleteSurveyAsync(entity?.id!)
            }
            shouldShowOrderArrows={false}
            onClickRow={handleOnClickRow}
            rowSelectedId={selectedSurveyId}
          />
        </DndProvider>
      </div>
      <div className="flex flex-col items-start" style={{ width: '48%' }}>
        <div className="mt-2 font-inter font-semibold">Submissions View</div>
        <div
          className="flex flex-col items-start bg-theme3 rounded-lg px-10 py-2 mt-2 overflow-y-scroll pb-8 w-full"
          style={{ height: '80vh' }}
        >
          {formattedResponse &&
            [formattedResponse]?.map((fp, i) => {
              return (
                <div key={i} className="w-full">
                  {Object.entries(fp).map(([key, value]) => {
                    if (key === 'id') {
                      return null;
                    }
                    const label = key.includes('Answer')
                      ? parseQuestionAnswer(key)
                      : formatLabel(key);

                    return (
                      <div key={key} className="mt-4">
                        <FixedInput
                          label={label}
                          text={
                            getResponseNameForQuestion(
                              formatLabel(value),
                              label
                            ) || 'None'
                          }
                          textFromMultiSelect={!!key.includes('Answer')}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SurveySubmissions;
