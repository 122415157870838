import { IOrderedItem } from 'Colugo/interfaces/common';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IGame, ISpotVersion } from 'Colugo/interfaces/games';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IBlock, IBlockItem } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import { ScenarioType } from 'Colugo/interfaces/lobby/discover/enums/ScenarioType';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class BlockOperations extends AbstractOperations {
  protected basePath: string = 'discover/block';

  public async createAsync(block: IBlock): requestResponse<IBlock> {
    return await this.executePost('', block, {}, true);
  }

  public async updateAsync(block: IBlock): requestResponse<IBlock> {
    return await this.executePut(`${block.id}`, block, {}, true);
  }

  public async updateItemDescriptionAsync(
    blockId: string,
    entityId: string,
    description?: string
  ): requestResponse {
    return await this.executePut(
      `${blockId}/entity/${entityId}?description=${description}`,
      {},
      {},
      true
    );
  }

  public async changeScenarioActiveStateAsync(
    id: string,
    isActivating: boolean
  ): requestResponse {
    return await this.executePut(`scenarioActiveState/${id}/${isActivating}`);
  }

  public async addItemToBlockAsync(
    blockId: string,
    itemId: string,
    blockEntityType: BlockEntityType
  ): requestResponse<IBlock> {
    return await this.executePost(
      `${blockId}/item/${itemId}/${blockEntityType}`
    );
  }

  public async removeItemsGroupOrGuideSpotItemFromBlockAsync(
    blockId: string,
    itemId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/itemsBlocks/item/${itemId}`);
  }

  public async removeEventsGroupItemFromBlockAsync(
    blockId: string,
    itemId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/eventsGroup/item/${itemId}`);
  }

  public async removeListingsGroupItemFromBlockAsync(
    blockId: string,
    itemId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/listingsGroup/item/${itemId}`);
  }

  public async removeVideoGroupItemFromBlockAsync(
    blockId: string,
    itemId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/videosGroup/item/${itemId}`);
  }

  public async removeEntitiesGroupItemFromBlockAsync(
    blockId: string,
    entityId: string
  ): requestResponse {
    return await this.executeDelete(
      `${blockId}/entitiesGroup/entity/${entityId}`
    );
  }

  public async removeAllItemsFromGuideSpotsBlockAsync(
    blockId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/guideSpots/items`);
  }

  public async removeGuidesGrouptItemFromBlockAsync(
    blockId: string,
    itemId: string
  ): requestResponse {
    return await this.executeDelete(`${blockId}/guidesGroup/item/${itemId}`);
  }

  public async updateItemOrderAsync(
    blockId: string,
    entityId: string,
    order: number
  ): requestResponse<IOrderedItem> {
    return await this.executePut(`${blockId}/item/${entityId}/order/${order}`);
  }
}

const useReqListBlocks = (
  blockType?: BlockType
): swrRequestResponse<IBlock[]> => {
  return useGetHttpClient(
    blockType !== undefined
      ? `discover/block/list?blockType=${blockType}`
      : null,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

const useReqListItemsForGuidesGroupBlock = (
  blockId?: string
): swrRequestResponse<IGame[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/listForGuidesGroup` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListByScenarioTypeAsync = (
  scenarioType: ScenarioType
): swrRequestResponse<IBlock[]> => {
  return useGetHttpClient(
    scenarioType === ScenarioType.None
      ? null
      : `discover/block/list/scenarioType/${scenarioType}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListItemsForItemsGroupOrGuideSpotsBlock = (
  blockId?: string
): swrRequestResponse<ISpotVersion[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/listForItemsGroupOrGuideSpots` : null,
    {
      revalidateOnFocus: false
    },
    false,
    true
  );
};

const useReqListItemsForEventsGroupBlock = (
  blockId?: string
): swrRequestResponse<IEvent[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/listForEventsGroup` : null,
    {
      revalidateOnFocus: false
    },
    false,
    true
  );
};

const useReqListItemsForListingsGroupBlock = (
  blockId?: string
): swrRequestResponse<IListing[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/listForListingsGroup` : null,
    {
      revalidateOnFocus: false
    },
    false,
    true
  );
};

const useReqListItemsForEntitiesGroupBlock = (
  blockId?: string
): swrRequestResponse<IBlockItem[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/item/listForEntitiesGroup` : null,
    {
      revalidateOnFocus: false
    },
    false,
    true
  );
};

const useReqListItemsForVideosGroupBlock = (
  blockId?: string
): swrRequestResponse<IVideo[]> => {
  return useGetHttpClient(
    blockId ? `discover/block/${blockId}/listForVideosGroup` : null,
    {
      revalidateOnFocus: false
    },
    false,
    true
  );
};

export {
  useReqListBlocks,
  useReqListByScenarioTypeAsync,
  useReqListItemsForEntitiesGroupBlock,
  useReqListItemsForEventsGroupBlock,
  useReqListItemsForGuidesGroupBlock,
  useReqListItemsForItemsGroupOrGuideSpotsBlock,
  useReqListItemsForListingsGroupBlock,
  useReqListItemsForVideosGroupBlock
};
export default BlockOperations;
