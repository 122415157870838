import { useState } from 'react';
import { Area, Point } from 'react-easy-crop/types';
import JPEG from 'jpeg-js';
import { MdClose } from 'react-icons/md';
import Cropper from 'react-easy-crop';
import { VscZoomIn, VscZoomOut } from 'react-icons/vsc';
import Slider from 'component/utility/Slider';
import { Button } from '@bindystreet/bindystreet.kit.react';
import { BiCrop } from 'react-icons/bi';
import { RiDeleteBin2Line } from 'react-icons/ri';
import Jimp from 'jimp';

type Props = {
  imageSrc: string;
  resolution?: { width: number; height: number };
  hint?: string;
  label?: string;
  onCrop: (image: File) => void;
  onClose?: () => void;
  onDelete?: () => void;
  setIsCroppingImage: (isCroppingImage: boolean) => void;
};

function ImageCropper(props: Props) {
  const {
    imageSrc,
    hint,
    label = 'CROP IMAGE',
    onCrop,
    onClose,
    onDelete,
    setIsCroppingImage,
    resolution = { width: 64, height: 64 }
  } = props;

  Jimp.decoders['image/jpeg'] = (data) =>
    JPEG.decode(data, {
      maxMemoryUsageInMB: 6144,
      maxResolutionInMP: 600
    });

  const aspectRatio = resolution.width / resolution.height;

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [zoom, setZoom] = useState(1);

  function handleOnCropAsync() {
    if (croppedAreaPixels === null) {
      return;
    }
    setIsCroppingImage(true);
    applyCropAsync(croppedAreaPixels);
    if (!!onClose) {
      onClose();
    }
  }

  async function applyCropAsync(cropArea: Area) {
    if (!imageSrc) {
      return;
    }
    const jimpImage = await Jimp.read(imageSrc);
    const mime = jimpImage.getMIME();
    const croppedBuffer = await jimpImage
      .crop(cropArea.x, cropArea.y, cropArea.width, cropArea.height)
      .resize(resolution.width, resolution.height, Jimp.RESIZE_BEZIER)
      .getBufferAsync(mime);
    const file = new File(
      [croppedBuffer as BlobPart],
      `croppedimage.${jimpImage.getExtension()}`,
      { type: mime }
    );
    onCrop(file);
  }

  return (
    <div className="bg-theme1 rounded-md overflow-y-auto h-full">
      <div className="border-b border-stroke1">
        <div className="mx-8">
          <div className="py-6 flex flex-row">
            <div className="text-lg text-left text-gray-800 font-extrabold">
              {label}
            </div>
            <div className="text-lg text-left text-gray-800 italic ml-2">
              {hint}
            </div>
            <div className="flex-grow"></div>
            {!!onClose && (
              <MdClose className="text-4xl cursor-pointer" onClick={onClose} />
            )}
          </div>

          <div
            style={{ height: '45vh' }}
            className="border border-hot bg-hotLight rounded-md relative"
          >
            <div className="bg-white w-full">
              <Cropper
                image={imageSrc}
                crop={crop}
                onCropChange={setCrop}
                aspect={aspectRatio}
                onCropComplete={(area, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
                zoom={zoom}
                classes={{
                  containerClassName: 'rounded-md m-3'
                }}
              />
            </div>
          </div>

          <div className="my-8 flex flex-row">
            <div className="w-1/2">
              <div className="flex flex-row">
                <div>
                  <VscZoomOut className="text-4xl" />
                </div>

                <div className="flex-grow mx-1">
                  <Slider
                    onChange={setZoom}
                    value={zoom}
                    min={1}
                    max={5}
                    step={0.05}
                  />
                </div>
                <div>
                  <VscZoomIn className="text-4xl" />
                </div>
              </div>
            </div>
            <div className="flex-grow"></div>
            <div className="text-gray-800 text-xl font-bold">
              {`Crop resolution: ${resolution.width}x${resolution.height}`}
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 rounded-md">
        <div className="mx-8">
          <div className="flex flex-row">
            <div className="flex-grow"></div>
            {!!onDelete && (
              <div className="mr-8">
                <Button skin="secondary" onClick={onDelete}>
                  <RiDeleteBin2Line className="text-2xl mr-1" />
                  <div>Delete Image</div>
                </Button>
              </div>
            )}
            <div>
              <Button onClick={handleOnCropAsync} skin="primary">
                <BiCrop className="text-2xl mr-1" />
                <div>Apply Crop</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCropper;
