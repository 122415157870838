import { Lifecycle, scoped } from 'tsyringe';
import { IEvent } from '../event/IEvent';
import { IGame, ISpotVersion } from '../games';
import { ISpot } from '../games/ISpot';
import { IListing } from '../listing/IListing';
import IRankedResultItem from '../search/IRankedResultItem';

@scoped(Lifecycle.ContainerScoped)
class RankedResultItemConverter {
  public convertToGuide(rankedResultItem: IRankedResultItem): IGame {
    return {
      $type: rankedResultItem.$type,
      id: rankedResultItem.id,
      versions: [
        { name: rankedResultItem.name, icon: rankedResultItem.images[0] }
      ]
    };
  }

  public convertToItem(rankedResultItem: IRankedResultItem): ISpotVersion {
    return {
      $type: rankedResultItem.$type,
      name: rankedResultItem.name,
      images: rankedResultItem.images,
      versionable: { id: rankedResultItem.id } as ISpot
    };
  }

  public convertToEvent(rankedResultItem: IRankedResultItem): IEvent {
    return {
      $type: rankedResultItem.$type,
      name: rankedResultItem.name,
      images: rankedResultItem.images,
      isActive: rankedResultItem.isActive,
      id: rankedResultItem.id,
      startDate: rankedResultItem.startDate,
      endDate: rankedResultItem.endDate
    };
  }

  public convertToListing(rankedResultItem: IRankedResultItem): IListing {
    return {
      $type: rankedResultItem.$type,
      name: rankedResultItem.name,
      images: rankedResultItem.images,
      isActive: rankedResultItem.isActive,
      id: rankedResultItem.id
    };
  }
}

export default RankedResultItemConverter;
