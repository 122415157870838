import React from 'react';
import CreateModal from 'component/admin/CreateModal';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { toast } from 'react-toastify';
import {
  ContentType,
  SubPageType
} from 'Colugo/interfaces/lobby/discover/enums';

type Props = {
  type: SubPageType;
  onCreateDiscoverSubPage: (
    discoverGenericContent: IDiscoverSubPage
  ) => Promise<boolean>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

function SubPageCreate(props: Props) {
  const { onCreateDiscoverSubPage, setIsModalOpen, isModalOpen, type } = props;

  const handleSubmitDiscoverPageItem = async (name: string) => {
    const discoverSubPage: IDiscoverSubPage = {
      name: name,
      contentType: ContentType.Local,
      type: type
    };
    if ((await onCreateDiscoverSubPage(discoverSubPage)) === false) {
      toast.error('Unable to create discover sub page. Please try again.');
      return;
    }

    setIsModalOpen(false);
  };

  return (
    <CreateModal
      title={'Create A Discover Sub Page'}
      submitButtonText={'Create'}
      minCharacterCount={4}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSubmit={handleSubmitDiscoverPageItem}
    />
  );
}

export default SubPageCreate;
