import React, { useEffect, useMemo, useState } from 'react';
import {
  BindyIcon,
  FlyoutMenu,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import {
  ISpiritCustomization,
  ISpiritCustomizationForTable
} from 'Colugo/interfaces/spirits';
import { BiPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';
import SpiritCustomizationModal from './SpiritCustomizationModal';
import SpiritCustomizationTable, {
  spiritCustomizationTableColumns
} from './SpiritCustomizationTable';
import { EnumHelper } from 'Colugo/utility/helpers';
import { SpiritCustomizationType } from 'Colugo/interfaces/spirits/enums';
import { isEqual } from 'lodash';

type Props = {
  spiritCustomizations: ISpiritCustomization[];
  deleteSpiritCustomization: (
    spiritCustomization: ISpiritCustomization
  ) => Promise<boolean>;
  createSpiritCustomization: (
    spiritCustomization: ISpiritCustomization
  ) => Promise<boolean>;
  updateSpiritCustomization: (
    spiritCustomization: ISpiritCustomization
  ) => Promise<boolean>;
};

const spiritCustomizationTypeFlyoutMenuItems =
  EnumHelper.getEnumValuesForFlyout(SpiritCustomizationType);

function SpiritCustomizationEditor(props: Props) {
  const {
    spiritCustomizations,
    deleteSpiritCustomization,
    createSpiritCustomization,
    updateSpiritCustomization
  } = props;

  const [selectedSpiritCustomizationId, setSelectedSpiritCustomizationId] =
    useState<undefined | string>(undefined);
  const [spiritCustomizationsForTable, setSpiritCustomizationsForTable] =
    useState<ISpiritCustomizationForTable[]>();
  const [isFlyoutMenuOpen, setIsFlyoutMenuOpen] = useState(false);
  const [selectedCustomizationTypeLabel, setSelectedCustomizationLabel] =
    useState<string>('Head');

  const [localSpiritCustomizations, setLocalSpiritCustomizations] =
    useState<ISpiritCustomization[]>(spiritCustomizations);

  const [
    isDeleteSpiritCustomizationModalOpen,
    setIsDeleteSpiritCustomizationModalOpen
  ] = useState(false);

  const [
    isUpdateSpiritCustomizationModalOpen,
    setIsUpdateSpiritCustomizationModalOpen
  ] = useState(false);

  const [
    isCreateSpiritCustomizationModalOpen,
    setIsCreateSpiritCustomizationModalOpen
  ] = useState(false);

  useEffect(() => {
    const filteredSpiritCustomization = spiritCustomizations.filter((sc) => {
      //NOTE: because of the StringEnumConverter in the SpiritCustomization Model on Dippy, this value is being returned as a string
      //@ts-ignore
      return sc.type === selectedCustomizationTypeLabel;
    });
    if (filteredSpiritCustomization) {
      setLocalSpiritCustomizations(filteredSpiritCustomization);
    }
  }, [spiritCustomizations, selectedCustomizationTypeLabel]);

  const handleDeleteSpiritCustomization = async () => {
    if (!selectedSpiritCustomization) {
      toast.error('No spiritCustomization selected to delete');
      return;
    }

    const isDeleted = await deleteSpiritCustomization(
      selectedSpiritCustomization
    );

    if (isDeleted) {
      setIsDeleteSpiritCustomizationModalOpen(false);
      setIsUpdateSpiritCustomizationModalOpen(false);
    }
  };

  async function handleUpdateSpiritCustomizationAsync(
    localSpiritCustomization: ISpiritCustomization
  ) {
    if (!localSpiritCustomization) {
      toast.error('No spiritCustomization selected to update');
      return;
    }
    await updateSpiritCustomization(localSpiritCustomization);
  }

  async function handleCreateSpiritCustomizationAsync(
    localSpiritCustomization: ISpiritCustomization
  ) {
    if (!localSpiritCustomization) {
      toast.error('No spiritCustomization to create');
      return;
    }
    await createSpiritCustomization(localSpiritCustomization);
  }

  function handleSpiritCustomizationTypeFlyoutChange(e: FlyoutMenuItem) {
    setSelectedCustomizationLabel(e.label!);
  }

  useMemo(() => {
    (localSpiritCustomizations as ISpiritCustomizationForTable[])?.forEach(
      (spiritCustomization) => {
        spiritCustomization.colour1 =
          spiritCustomization.colours && spiritCustomization.colours[0];
        spiritCustomization.colour2 =
          spiritCustomization.colours && spiritCustomization.colours[1];
        spiritCustomization.priceString = spiritCustomization.price
          ? spiritCustomization.price?.toString()
          : 'Free';
      }
    );
    setSpiritCustomizationsForTable(localSpiritCustomizations);
  }, [localSpiritCustomizations]);

  const columns = useMemo(() => spiritCustomizationTableColumns, []);

  const containerTitle = 'Spirit Customizations';
  const createButtonText = 'Create Spirit Customization';
  const createFormTitle = 'Create a New Spirit Customization';
  const updateFormTitle = 'Update Spirit Customization';
  const updateButtonText = 'Update Spirit Customization';

  const openSpiritCustomizationModalButton = (
    <BindyIcon
      className="z-50 absolute ml-auto mt-2"
      size="lg"
      onClick={() => {
        setIsCreateSpiritCustomizationModalOpen(true);
      }}
    >
      <BiPencil
        size="1.5rem"
        className="absolute top-0 bottom-0 right-0 left-0 m-auto cursor-pointer text-orange-500"
      />
    </BindyIcon>
  );

  const selectedSpiritCustomization = localSpiritCustomizations.find(
    (spiritCustomization) =>
      spiritCustomization?.id === selectedSpiritCustomizationId
  );

  const selectSpiritCustomizationForUpdate = (id: string) => {
    setSelectedSpiritCustomizationId(id);
    setIsUpdateSpiritCustomizationModalOpen(true);
  };

  const selectSpiritCustomizationForDelete = (id: string) => {
    setSelectedSpiritCustomizationId(id);
    setIsDeleteSpiritCustomizationModalOpen(true);
  };

  const deleteSpiritCustomizationPopup = (
    <ConfirmDeletePopup
      isModalOpen={isDeleteSpiritCustomizationModalOpen}
      onSubmit={handleDeleteSpiritCustomization}
      skin="spirit customization"
      title={selectedSpiritCustomization?.name || 'Unnamed SpiritCustomization'}
      closeModal={() => {
        setIsDeleteSpiritCustomizationModalOpen(false);
        setIsUpdateSpiritCustomizationModalOpen(false);
      }}
    />
  );

  return (
    <div className="w-full">
      <SpiritCustomizationModal
        title={createFormTitle}
        submitButtonText={createButtonText}
        isModalOpen={isCreateSpiritCustomizationModalOpen}
        setIsModalOpen={setIsCreateSpiritCustomizationModalOpen}
        onSubmitAsync={handleCreateSpiritCustomizationAsync}
      />
      <div className="flex flex-col">
        <span className="mx-auto block text-4xl text-black mt-4">
          {`${selectedCustomizationTypeLabel} ${containerTitle}`}
        </span>
        <div className="mt-1 self-start ml-8 px-4 w-96">
          <FlyoutMenu
            isEqual={isEqual}
            size="sm"
            activeLabel={selectedCustomizationTypeLabel}
            items={spiritCustomizationTypeFlyoutMenuItems}
            onChange={handleSpiritCustomizationTypeFlyoutChange}
            isMenuOpen={isFlyoutMenuOpen}
            setIsMenuOpen={setIsFlyoutMenuOpen}
          />
        </div>
      </div>
      <div className="flex flex-row">
        {isDeleteSpiritCustomizationModalOpen && deleteSpiritCustomizationPopup}
        <div className="pl-12 py-8 h-full w-full px-20">
          <SpiritCustomizationTable
            onClickDeleteIcon={selectSpiritCustomizationForDelete}
            onClickRow={selectSpiritCustomizationForUpdate}
            tableColumns={columns}
            spiritCustomizations={spiritCustomizationsForTable!}
            children={openSpiritCustomizationModalButton}
            rowSelectedId={selectedSpiritCustomizationId}
            customizationType={selectedCustomizationTypeLabel}
          />
        </div>
        <div className="mx-8 pt-8 w-full">
          {isUpdateSpiritCustomizationModalOpen &&
            !isDeleteSpiritCustomizationModalOpen && (
              <SpiritCustomizationModal
                title={updateFormTitle}
                submitButtonText={updateButtonText}
                isModalOpen={isUpdateSpiritCustomizationModalOpen}
                setIsModalOpen={setIsUpdateSpiritCustomizationModalOpen}
                onSubmitAsync={handleUpdateSpiritCustomizationAsync}
                spiritCustomization={selectedSpiritCustomization}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default SpiritCustomizationEditor;
