import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { QuestionSummary } from 'Colugo/interfaces/feedback/ISurveyResponseSummary';
import { useReqGetSurveyResponseSummary } from 'Colugo/operations/feedback/FeedbackOperations';
import {
  getResponseName,
  parseQuestionType
} from 'utility/general/surveyUtilities';

function SurveySummary() {
  const {
    data: surveyResponse,
    isLoading: isSurveyResponseLoading,
    isError: isSurveyResponseError
  } = useReqGetSurveyResponseSummary();

  if (isSurveyResponseLoading) {
    return <Spinner />;
  }

  if (isSurveyResponseError) {
    return (
      <ErrorPage>
        <span>{'Unable to load survey summary from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="flex flex-row w-full flex-wrap">
      {surveyResponse &&
        Object.keys(surveyResponse).map((key, i) => {
          const question = surveyResponse[key] as QuestionSummary<any>;

          return (
            <div
              key={i}
              className="bg-white border flex flex-col py-6 px-4 items-start rounded-lg m-5 border-surfaceOutline"
              style={{ width: '435px', minHeight: '453px' }}
            >
              <div className="bg-primaryGreen text-white rounded-2xl px-3 py-2">
                {question.responseCount} responses
              </div>

              <div className="text-black mt-4 text-lg font-bold text-left">
                {parseQuestionType(question.type)}
              </div>
              <div className="w-full -ml-2 mt-2">
                {Object.keys(question.answerSpread).map((key, i) => (
                  <div
                    className="bg-theme3 w-full flex px-4 py-2 m-2 justify-between rounded-lg gap-4"
                    key={i}
                  >
                    <div className="font-inter font-semibold">
                      {getResponseName(key, question.type)}
                    </div>

                    <div className="bg-primarySurfaceTeal text-white font-inter font-normal flex items-center justify-center w-8 h-8 rounded-full">
                      {question.answerSpread[key]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default SurveySummary;
