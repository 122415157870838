import { ITab } from './TabList';

type Props = {
  activeTabName: string;
  tabName: string;
  index: number;
  tab: ITab;
  onClickTab: () => void;
};

function TabComponent(props: Props) {
  const { tab, tabName, activeTabName, index, onClickTab } = props;

  return (
    <div className="flex flex-row justify-start h-auto" onClick={onClickTab}>
      <div
        className={`flex flex-row items-start py-1 cursor-pointer border-b-4
          ${tabName === activeTabName ? 'border-primaryTeal' : 'border-none'} ${
          index > 0 && 'ml-8'
        }
           `}
      >
        <div
          className={`${
            tabName === activeTabName ? 'text-black' : 'text-lightText'
          } font-inter text-xl font-semibold`}
        >
          {tab.name}
        </div>
      </div>
    </div>
  );
}

export default TabComponent;
