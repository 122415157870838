import { ITag } from 'Colugo/interfaces/tags';
import { useState } from 'react';
import ImageCropperInput from 'storybook/ImageCropperInput';
import { imageUploadOnDrop } from 'utility/general/ImageUploadOnDrop';

type Props = {
  localTag: ITag;
  addIcon(imageUrl: string): void;
  deleteIcon: () => void;
};

function TagIconUploader(props: Props) {
  const { localTag, addIcon, deleteIcon } = props;

  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  const [isCroppingIcon, setIsCroppingIcon] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  let entityIcon = localTag.icon || '';

  const onDropImageAsync = imageUploadOnDrop(
    addIcon,
    setIsLoadingIcon,
    setProgress
  );

  async function handleUploadIconAsync(image: File) {
    setIsCroppingIcon(false);
    setIsLoadingIcon(true);
    await onDropImageAsync([image]);
    setIsLoadingIcon(false);
  }

  return (
    <div className="flex items-start">
      <ImageCropperInput
        onCropComplete={(icon) => {
          handleUploadIconAsync(icon);
        }}
        imageUrl={entityIcon}
        progress={progress}
        isCroppingImage={isCroppingIcon}
        setIsCroppingImage={setIsCroppingIcon}
        isLoading={isLoadingIcon}
        deleteImage={() => deleteIcon()}
        isMandatory={true}
        resolution={{
          width: 64,
          height: 64
        }}
        customPlaceholderSize={1}
        customHeightPx={'110px'}
        customWidthPx={'110px'}
        accept=".png"
        isIcon={true}
      />
    </div>
  );
}

export default TagIconUploader;
