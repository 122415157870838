import {
  Input,
  useOutsideClick,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import { ITag } from 'Colugo/interfaces/tags';
import { useMemo, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaTags } from 'react-icons/fa';
import TagCard from './TagCard';

type Props = {
  tags: ITag[];
  selectedTags: ITag[];
  onClickTagDeleteIcon: (tagId: string) => void;
  onConfirmNewTag: (tagId: string) => void;
};

function TagSelector(props: Props) {
  const { tags, onClickTagDeleteIcon, onConfirmNewTag, selectedTags } = props;
  const [inputValue, setInputValue] = useState('');
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setIsFlyoutOpen(false);
  });

  const flyoutItems: FlyoutMenuItem[] = useMemo(
    () =>
      tags
        ?.filter(
          (tag) =>
            tag.name?.toLowerCase().startsWith(inputValue.toLowerCase()) &&
            !selectedTags.some(
              (listingTag) =>
                tag.name?.toLowerCase() === listingTag.name?.toLowerCase()
            )
        )
        .map((tag) => {
          return {
            label: tag.name?.toLowerCase(),
            value: tag.id
          } as FlyoutMenuItem;
        }),
    [tags, selectedTags, inputValue]
  );

  function handleFlyoutOnConfirmNewTag(flyoutItem: {
    label: string;
    value?: string;
  }) {
    const item: ITag = {
      name: flyoutItem.label,
      id: flyoutItem.value
    };
    setInputValue('');
    onConfirmNewTag(item?.id!);
  }

  const tagsElements = selectedTags.map((tag, i) => (
    <div
      className="rounded-lg border border-primaryTeal flex flex-row items-center bg-lightGreen h-10 mr-3 mb-4 cursor-pointer"
      key={i}
      onClick={() => onClickTagDeleteIcon(tag.id!)}
    >
      <div className="ml-4 mr-2 flex justify-center">
        <span className="text-primaryTeal font-nunito text-base ">
          {tag.name?.toLowerCase()}
        </span>
      </div>
      <AiOutlineClose className="text-primaryTeal mr-4 " size="20px" />
    </div>
  ));

  const flyout = (
    <div
      className="-mt-3 overflow-y-auto bg-theme1 mr-6"
      style={{ scrollbarWidth: 'thin' }}
    >
      {isFlyoutOpen && (
        <div className="px-4 border rounded-lg flex-col flex h-36">
          <div className="flex flex-wrap py-4">
            {flyoutItems.length === 0 && inputValue.length > 0 ? (
              <div className="w-full flex flex-col items-center justify-items-center absolute top-48 -ml-2">
                <span>
                  Sorry, we couldn't find any tags for your search. Please try
                  searching again
                </span>
              </div>
            ) : (
              flyoutItems.map((tag, i) => (
                <TagCard
                  tag={tag}
                  key={i}
                  onClick={handleFlyoutOnConfirmNewTag}
                />
              ))
            )}
          </div>
          <div className="flex-grow flex" />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row">
        <div className="relative w-full" ref={ref}>
          <div className="absolute top-4 left-6 z-50">
            <FaTags size={25} className="text-black" />
          </div>
          <Input
            className="flex-grow rounded-tl-lg rounded-bl-lg rounded-br-none rounded-tr-none mr-6 -mt-2"
            placeholder="Start typing to search tags"
            color="text-black"
            size="lg"
            value={inputValue}
            skin="tagLabel"
            onChange={(e) => {
              setIsFlyoutOpen(true);
              setInputValue(e.currentTarget.value);
            }}
            onFocus={() => {
              setIsFlyoutOpen(true);
            }}
          />
          {flyout}
          <div className={`flex flex-wrap mt-3`}>
            {tagsElements.length > 0 && tagsElements}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagSelector;
