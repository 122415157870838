import React from 'react';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { toast } from 'react-toastify';
import SubPageEditor from './SubPageEditor';
import SubPageOperations, {
  useReqGetSubPagesForEditing
} from 'Colugo/operations/lobby/SubPageOperations';
import { container } from 'tsyringe';
import { SubPageType } from 'Colugo/interfaces/lobby/discover/enums';

const subPageOperations = container.resolve(SubPageOperations);

type Props = {
  type: SubPageType;
  title: string;
};

function SubPagesContainer(props: Props) {
  const { type, title } = props;
  const {
    data: discoverPage,
    isLoading,
    isError,
    mutate
  } = useReqGetSubPagesForEditing(type);

  const handleUpdateDiscoverSubPage = async (
    subPageToUpdate: IDiscoverSubPage
  ) => {
    const { data, error } = await subPageOperations.updateSubPageAsync(
      subPageToUpdate
    );

    if (!data || error) {
      toast.error('Failed to update sub page, please try again');
      return false;
    }

    const newDiscoverPage = discoverPage!.subPages!.find(
      (subPage: IDiscoverSubPage) =>
        subPage.id === data.id ? subPageToUpdate : subPage
    );

    mutate(newDiscoverPage, true);
    return true;
  };

  const handleUpdateDiscoverSubPageOrder = async (
    subPageId: string,
    position: number
  ) => {
    const { data, error } = await subPageOperations.updateSubPageOrderAsync(
      subPageId,
      position
    );

    if (!data || error) {
      toast.error('Failed to update sub page order, please try again');
      return false;
    }

    const newDiscoverPage = discoverPage!.subPages!.find(
      (subPage: IDiscoverSubPage) =>
        data.map((d) =>
          d.id === subPage.id ? { ...subPage, order: position } : subPage
        )
    );

    mutate(newDiscoverPage, true);
    return true;
  };

  const handleDeleteDiscoverSubPage = async (discoverSubPageId: string) => {
    if (!discoverSubPageId) {
      toast.error('Invalid game Id, please try again');
      return false;
    }

    const { error } = await subPageOperations.deleteSubPageAsync(
      discoverSubPageId
    );

    if (error) {
      toast.error('Failed to delete sub page, please try again');
      return false;
    }

    const newDiscoverPage = discoverPage!.subPages!.find(
      (subPage: IDiscoverSubPage) => subPage.id !== discoverSubPageId
    );
    mutate(newDiscoverPage, true);
    return true;
  };

  const handleCreateDiscoverSubPage = async (
    discoverSubPage: IDiscoverSubPage
  ) => {
    const { data, error } = await subPageOperations.createSubPageAsync(
      discoverSubPage
    );

    if (!data || error) {
      return false;
    }

    const newDiscoverPage = { ...discoverPage, data };
    mutate(newDiscoverPage, true);
    return true;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load subPages from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full overflow-hidden container">
      <span className="mx-auto block text-4xl text-black mt-4">{title}</span>
      <div className="mx-auto flex flex-col">
        <SubPageEditor
          type={type}
          onCreateSubPage={handleCreateDiscoverSubPage}
          onConfirmDeleteSubPage={handleDeleteDiscoverSubPage}
          discoverSubPages={discoverPage!.subPages || []}
          onUpdateSubpage={handleUpdateDiscoverSubPage}
          onChangeSubPageOrder={handleUpdateDiscoverSubPageOrder}
        />
      </div>
    </div>
  );
}

export default SubPagesContainer;
