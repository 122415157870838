export namespace ImageKit {
  export function withSizing(
    url: string,
    height: number = 300,
    width: number = 300
  ): string {
    return `${url}?tr=h-${height},w-${width},c-at_least`;
  }

  export function convertVideoUrl(url: string, quality: number = 0.4): string {
    const storageAccountUrl = `https://${process.env.REACT_APP_ENV_PATH}stvideosuksouth001.blob.core.windows.net/all/`;
    const imageKitUrl = `https://ik.imagekit.io/bk9wnm0eymr/${process.env.REACT_APP_ENV_PATH}/`;
    const formattedUrl =
      url.replace(storageAccountUrl, imageKitUrl) + `?tr:q-${quality},f-mp4`;
    return formattedUrl;
  }
}

export const LargeImageDimension = 1024;
export const MediumImageDimension = 512;
export const SmallImageDimension = 256;
