import FileUploadOperations from 'Colugo/operations/file/FileUploadOperations';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const fileUploadOperations = container.resolve(FileUploadOperations);

export const imageUploadOnDrop =
  (
    callback: (imageUrl: string) => void,
    setIsLoading?: (isLoading: boolean) => void,
    setProgress?: (progress: number) => void
  ) =>
  async (acceptedFiles: File[]) => {
    const setLoading = (isLoading: boolean) =>
      setIsLoading && setIsLoading(isLoading);

    if (!callback) {
      console.error('callback is undefined in blockitem');
      return;
    }

    setLoading(true);
    const { data: imageUrl, error } =
      await fileUploadOperations.uploadImageAsync(
        acceptedFiles[0],
        setProgress
      );

    if (error) {
      console.error(error);
      toast.error('Unable to upload the image.');
      setLoading(false);
      return;
    }

    setLoading(false);
    callback(imageUrl);
  };
