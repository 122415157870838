import React from 'react';
import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IGame, IZoneVersion } from 'Colugo/interfaces/games';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';

type Props = {
  game?: IGame;
  block: IBlock | undefined;
  zoneVersion?: IZoneVersion;
  showBlockItemsAdded: boolean;
  isEditing: boolean;
  isGameLoading: boolean;
  isZoneLoading: boolean;
  removeItem: () => void;
};

function BlockItemAdded(props: Props) {
  const {
    game,
    block,
    zoneVersion,
    showBlockItemsAdded,
    isEditing,
    isGameLoading,
    isZoneLoading,
    removeItem
  } = props;

  const isGuideSpotsBlock = block?.type === BlockType.GuideSpots;
  const isZoneSpotsBlock = block?.type === BlockType.ZoneSpots;

  return (
    <div>
      {((isGuideSpotsBlock && game) || (isZoneSpotsBlock && zoneVersion)) &&
        (showBlockItemsAdded || isEditing) && (
          <div>
            <div className="flex flex-col">
              <div className="font-medium text-2xl mt-2">
                {isZoneSpotsBlock ? 'Zone in Block' : 'Game in Block'}
              </div>
              {(isGuideSpotsBlock && isGameLoading) ||
              (isZoneSpotsBlock && isZoneLoading) ? (
                <Spinner />
              ) : (
                <div>
                  <div className="flex flex-row items-center h-auto mt-2">
                    <img
                      src={
                        isZoneSpotsBlock
                          ? zoneVersion?.icon
                          : game?.versions[0].icon
                      }
                      alt={isZoneSpotsBlock ? 'zone preview' : 'game preview'}
                      width={'100px'}
                      height={'100px'}
                      className="rounded-md"
                    />
                    <div className="ml-4">
                      {isZoneSpotsBlock
                        ? zoneVersion?.name
                        : game?.versions[0].name}
                    </div>
                    <div className="ml-6 cursor-pointer" onClick={removeItem}>
                      <RiDeleteBin2Line className="text-3xl cursor-pointer ml-8" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
}

export default BlockItemAdded;
