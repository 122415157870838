export enum CardSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

export enum CtaBannerCardSize {
  Small = 'Small',
  Large = 'Large'
}
