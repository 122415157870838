import { Input, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IGame } from 'Colugo/interfaces/games';
import React, { useState } from 'react';
import { BiPlus, BiSearchAlt } from 'react-icons/bi';
import { FaArrowDown, FaArrowUp, FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<IGame>[];
  games: IGame[];
  onClickAddIcon: (gameId: string) => void;
  onClickDeleteIcon?: (gameId: string) => void;
  isGroupGames?: boolean;
  onChangeSearchValue: (value: string) => void;
  onChangeOrder?: (gameId: string, position: number) => void;
  isMutationLoading?: boolean;
  isLarge?: boolean;
};

const GameTable: React.FC<Props> = (props) => {
  const {
    games,
    tableColumns,
    onClickAddIcon,
    isGroupGames,
    onClickDeleteIcon,
    children,
    onChangeSearchValue,
    onChangeOrder,
    isMutationLoading,
    isLarge
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [isAddHover, setIsAddHover] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: games
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  // Trigger web request from result
  const onChangeGameNameInput = (value: string) => {
    setGlobalFilter(value);
    onChangeSearchValue(value);
  };

  const setClickableRowIconElements = (cell: Cell) => {
    if (isMutationLoading) {
      return <Spinner />;
    }
    return isGroupGames ? (
      <div
        onMouseEnter={() => setIsAddHover(true)}
        onMouseLeave={() => setIsAddHover(false)}
        className="flex flex-row"
      >
        {cell.row.index !== 0 && (
          <FaArrowUp
            className={`text-4xl cursor-pointer mx-4 mt-2 ${
              isAddHover ? 'text-hot' : 'text-theme6'
            }`}
            onClick={() => {
              const gameId = games.find((game) => game.id === cell.value)!.id!;
              onChangeOrder && onChangeOrder(gameId, cell.row.index - 1);
            }}
          />
        )}
        {cell.row.index !== games.length - 1 && (
          <FaArrowDown
            className={`text-4xl cursor-pointer mx-4 mt-2 ${
              isAddHover ? 'text-hot' : 'text-theme6'
            }`}
            onClick={() => {
              const gameId = games.find((game) => game.id === cell.value)!.id!;
              onChangeOrder && onChangeOrder(gameId, cell.row.index + 1);
            }}
          />
        )}
        <RiDeleteBin2Line
          className={`text-4xl cursor-pointer mx-4 mt-2 ${
            isAddHover ? 'text-error1' : 'text-theme6'
          }`}
          onClick={() => {
            onClickDeleteIcon &&
              onClickDeleteIcon(
                games.find((game) => game.id === cell.value)!.id!
              );
          }}
        />
      </div>
    ) : (
      <BiPlus
        className={`text-4xl cursor-pointer m-4 ${
          isAddHover ? 'text-success' : 'text-theme6'
        }`}
        onMouseEnter={() => setIsAddHover(true)}
        onMouseLeave={() => setIsAddHover(false)}
        onClick={() => {
          onClickAddIcon(games.find((game) => game.id === cell.value)!.id!);
        }}
      />
    );
  };

  const table = (
    <div
      className="overflow-y-auto p-2"
      style={{
        maxHeight: isLarge ? `calc(${getMaxViewHeight()} - 20vh` : '15vh'
      }}
    >
      <div {...getTableProps()} className="w-full">
        <div>{gameTableHeaderConfig(headerGroups)}</div>
        <div>
          {gameTableRowsConfig(
            rowHovered,
            setRowHovered,
            getTableBodyProps,
            rows,
            prepareRow,
            setClickableRowIconElements
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="flex flex-row my-4">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={onChangeGameNameInput}
        />
        {children}
      </div>
      {table}
    </div>
  );
};

export default GameTable;

const GlobalFilter: React.FC<any> = ({ globalFilter, setGlobalFilter }) => {
  const [filterValue, setFilterValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="w-full p-2 flex">
      <div className="relative flex flex-row w-full">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder="Search Games"
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <div>
          <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
        </div>
      </div>
    </div>
  );
};

export const gameTableColumns = [
  {
    Header: 'Image',
    accessor: 'versions[0].icon',
    width: 1,
    Cell: (cellInfo: { cell: Cell }) => {
      return (
        <div className="m-auto w-16 h-16 p-0 flex flex-col">
          <div className="flex-grow"></div>
          {cellInfo.cell.value && (
            <img
              width={'80%'}
              height={'80%'}
              className="rounded-md"
              src={cellInfo.cell.value}
              alt="game preview"
            />
          )}
          <div className="flex-grow"></div>
        </div>
      );
    }
  },
  {
    Header: 'Game Name',
    accessor: 'versions[0].name'
  },
  {
    Header: '',
    accessor: 'id',
    width: 1
  }
];

const gameTableHeaderConfig = (headerGroups: HeaderGroup<IGame>[]) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: '150px' }}
            className="w-full ml-4"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>{column.render('Header')}</div>
              <div>
                {column.Header!.toString() === '' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const gameTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IGame> | undefined
  ) => TableBodyProps,
  rows: Row<IGame>[],
  prepareRow: (row: Row<IGame>) => void,
  setEndRowElements: (cell: Cell) => JSX.Element
) => (
  <div
    style={{ height: `calc(${getMaxViewHeight()}` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap relative rounded-md ${bgcolor} hover:shadow-lg`}
          onMouseEnter={() => setRowHovered(i)}
        >
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'my-1 text-left';
            if (index !== row.cells.length - 1) {
              classNames += ' text-center';
            } else if (rowHovered !== i) {
              return <div {...cell.getCellProps()}></div>;
            }
            if (cell.column.Header?.toString() === '') {
              return (
                <div {...cell.getCellProps()} className="flex flex-row w-full">
                  <div className="flex-grow" />
                  <div className="flex flex-row mr-4">
                    {setEndRowElements(cell as any)}
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} my-auto ${
                  cell.column.Header!.toString() !== 'Image' && 'ml-6'
                }`}
                style={{
                  width: `${
                    cell.column.Header!.toString() === 'Image'
                      ? '120px'
                      : '200px'
                  }`
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
