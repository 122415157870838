import { Modal } from '@bindystreet/bindystreet.kit.react';
import ReportSource from 'Colugo/interfaces/moderation/enums/ReportSource';
import ResolutionStatus from 'Colugo/interfaces/moderation/enums/ResolutionStatus';
import { UserOperations } from 'Colugo/operations/identity';
import UserReportOperations, {
  useReqListUserReportsAsync
} from 'Colugo/operations/moderation/UserReportOperations';
import PostCommentOperations from 'Colugo/operations/social/post/comment/PostCommentOperations';
import PostOperations from 'Colugo/operations/social/post/PostOperations';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { reportedCommentsTableColumns } from './accessors/ReportedCommentsTableColumns';
import { reportedPostsTableColumns } from './accessors/ReportedPostsTableColumns';
import PostCommentReportModal from './PostCommentReportModal';
import PostReportModal from './PostReportModal';
import UserReportTable from './UserReportsTable';

const userOperations = container.resolve(UserOperations);
const userReportOperations = container.resolve(UserReportOperations);
const postOperations = container.resolve(PostOperations);
const postCommentOperations = container.resolve(PostCommentOperations);

export default function ModerationContainer() {
  const [userReportId, setUserReportId] = useState<string | undefined>();
  const [resolveUserReportId, setResolveUserReportId] = useState<
    string | undefined
  >();
  const [reportSource, setReportSource] = useState<ReportSource>(
    ReportSource.Post
  );
  const [isUserSuspended, setIsUserSuspended] = useState<boolean>(false);
  const [isUserBanned, setIsUserBanned] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: userReports, mutate: updateUserReports } =
    useReqListUserReportsAsync(reportSource);

  const selectedUserReport = userReports?.find((ur) => ur.id === userReportId);

  const containerTitle = `Moderation`;

  const columns = useMemo(
    () =>
      reportSource === ReportSource.Post
        ? reportedPostsTableColumns
        : reportedCommentsTableColumns,
    [reportSource]
  );

  const closeModal = () => {
    setUserReportId(undefined);
    setResolveUserReportId(undefined);
  };

  const handleClickMarkResolved = async (userReportId: string) => {
    if (!userReportId) {
      toast.error('No userReport Id provided.');
      return;
    }

    closeModal();

    const { error } = await userReportOperations.updateResolutionStatusAsync(
      userReportId,
      ResolutionStatus.Resolved
    );

    if (error) {
      toast.error('Failed to mark user report as resolved. Please refresh.');
      return;
    }

    updateUserReports(
      userReports?.filter((ur) => ur.id !== userReportId),
      true
    );
  };

  const updateResolutionStatus = async (
    userReportId: string,
    resolutionStatus: ResolutionStatus
  ) => {
    await userReportOperations.updateResolutionStatusAsync(
      userReportId,
      (selectedUserReport?.resolutionStatus || ResolutionStatus.None) |
        resolutionStatus
    );
  };

  const handleClickDeletePost = async (postId?: string) => {
    if (!postId || !userReportId) {
      toast.error('No Post Id provided.');
      return;
    }

    const { error } = await postOperations.deleteAsync(postId);

    if (error) {
      toast.error('Failed to delete post.');
      return;
    }

    toast.success('Post has been successfully deleted');

    await updateResolutionStatus(userReportId, ResolutionStatus.PostDeleted);
    updateUserReports(userReports, true);
  };

  const handleClickDeleteComment = async (commentId?: string) => {
    if (!commentId || !userReportId) {
      toast.error('No Comment Id provided.');
      return;
    }

    const { error } = await postCommentOperations.deleteAsync(commentId);

    if (error) {
      toast.error('Failed to delete comment.');
      return;
    }

    toast.success('Comment has been successfully deleted');

    await updateResolutionStatus(userReportId, ResolutionStatus.CommentDeleted);
    updateUserReports(userReports, true);
  };

  const handleClickSuspendAccount = async (userId?: string) => {
    if (!userId || !userReportId) {
      toast.error('No User Id provided.');
      return;
    }

    setIsLoading(true);
    setIsUserSuspended(true);

    const { error } = await userOperations.suspendAsync(userId);

    setIsLoading(false);

    if (error) {
      toast.error('Failed to suspend account.');
      setIsUserSuspended(false);
      return;
    }
    toast.success(
      "User account has been successfully suspended, we won't be hearing from this troublemaker any time soon!"
    );

    await updateResolutionStatus(userReportId, ResolutionStatus.UserSuspended);
    updateUserReports(userReports, true);
  };

  const handleClickBanAccount = async (userId?: string) => {
    if (!userId || !userReportId) {
      toast.error('No User Id provided.');
      return;
    }
    setIsLoading(true);
    setIsUserBanned(true);

    const { error } = await userOperations.banAsync(userId);

    setIsLoading(false);

    if (error) {
      toast.error('Failed to ban account.');
      setIsUserBanned(false);
      return;
    }
    toast.success('User account has been successfully banned');

    await updateResolutionStatus(userReportId, ResolutionStatus.UserBanned);
    updateUserReports(userReports, true);
  };

  const handleClickEditIcon = (userReportId: string) => {
    setUserReportId(userReportId);
    const selectedUserReport = userReports?.find(
      (ur) => ur.id === userReportId
    );
    setIsUserSuspended(
      (selectedUserReport?.resolutionStatus &&
        selectedUserReport.resolutionStatus &
          ResolutionStatus.UserSuspended) === ResolutionStatus.UserSuspended
    );
    setIsUserBanned(
      (selectedUserReport?.resolutionStatus &&
        selectedUserReport.resolutionStatus & ResolutionStatus.UserBanned) ===
        ResolutionStatus.UserBanned
    );
  };

  const entityTypeSelectorButton = (
    <div className="w-full flex flex-row">
      <div
        className="mx-auto flex rounded-full px-2 bg-yellow-400 cursor-pointer"
        onClick={() => {
          setReportSource(
            reportSource === ReportSource.Post
              ? ReportSource.Comment
              : ReportSource.Post
          );
        }}
      >
        {reportSource === ReportSource.Post ? 'Posts' : 'Comments'}
      </div>
    </div>
  );

  const moderationModal = (
    <Modal
      isMenuOpen={!!userReportId}
      className="overflow-x-visible rounded-xl"
      position={'center'}
      size="2xl"
      overlay
      zIndex={51}
    >
      {reportSource === ReportSource.Post ? (
        <PostReportModal
          selectedUserReport={selectedUserReport}
          closeModal={closeModal}
          onClickDeleteIcon={handleClickDeletePost}
          onClickSuspendIcon={handleClickSuspendAccount}
          onClickBanIcon={handleClickBanAccount}
          setUserReportId={setUserReportId}
          isLoading={isLoading}
          isUserSuspended={isUserSuspended}
          isUserBanned={isUserBanned}
        />
      ) : (
        <PostCommentReportModal
          selectedUserReport={selectedUserReport}
          closeModal={closeModal}
          onClickDeleteIcon={handleClickDeleteComment}
          onClickSuspendIcon={handleClickSuspendAccount}
          onClickBanIcon={handleClickBanAccount}
          setUserReportId={setUserReportId}
          isLoading={isLoading}
          isUserSuspended={isUserSuspended}
          isUserBanned={isUserBanned}
        />
      )}
    </Modal>
  );

  const resolutionModal = (
    <ConfirmDeletePopup
      isModalOpen={!!resolveUserReportId}
      onSubmit={() => handleClickMarkResolved(resolveUserReportId!)}
      closeModal={() => setResolveUserReportId(undefined)}
      title={'Resolve User Report'}
      skin={'resolveUserReport'}
    />
  );

  return (
    <div className="h-full overflow-y-auto container">
      <div className="mx-auto flex flex-col">
        <div className="w-full">
          <span className="block text-3xl text-black mt-1 ml-4">
            {containerTitle}
          </span>
          {moderationModal}
          {resolutionModal}
          {entityTypeSelectorButton}
          <UserReportTable
            userReports={userReports || []}
            tableColumns={columns as any}
            onClickModerateIcon={setResolveUserReportId}
            onClickEditIcon={handleClickEditIcon}
          />
        </div>
      </div>
    </div>
  );
}
