export enum BlockType {
  //Auto
  NewGuides = 0,
  TrendingGuides = 1,
  TopGuides = 2,
  SuggestedGuides = 3,
  SuggestedListings = 11,
  SuggestedEvents = 16,
  Following = 19,
  CollectedNearMe = 20,
  Popular = 21,
  Trending = 22,
  MostLiked = 23,

  //Manual
  GuideSpots = 5,
  ZoneSpots = 10,
  VideosGroup = 24,
  EntitiesGroup = 25,

  //Custom
  MyDownloadedGuides = 6,
  MyRecentGuides = 7,
  QuickAccess = 8,
  ViewByCategory = 9,
  FilterAndEventSearchWidgets = 12,
  CtaBanner = 14,
  Ads = 17
}

export const ManualBlockNames = [
  'GuideSpots',
  'ZoneSpots',
  'SuggestedGuides',
  'SuggestedListings',
  'SuggestedEvents',
  'VideosGroup',
  'EntitiesGroup'
];

export const OmittedBlockTypes = [
  'MyDownloadedGuides',
  'MyRecentGuides',
  'TrendingGuides',
  'TopGuides',
  'QuickAccess',
  'FilterAndEventSearchWidgets'
];
