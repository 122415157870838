import React, { useState } from 'react';
import { FlyoutMenu, FlyoutMenuItem } from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { isEqual } from 'lodash';

interface Props {
  blocks: IBlock[];
  selectedNoQuizzesCollected: FlyoutMenuItem | undefined;
  setSelectedNoQuizzesCollected: (item: FlyoutMenuItem | undefined) => void;
}

//TODO: This should be generated dynamically
const DiscoverContentCollectionEditor = (props: Props) => {
  const { blocks, selectedNoQuizzesCollected, setSelectedNoQuizzesCollected } =
    props;

  const [isNoQuizzesCollectedFlyoutOpen, setIsNoQuizzesCollectedFlyoutOpen] =
    useState(false);

  const flyoutMenuItems = () =>
    blocks.map((val) => {
      return { label: val.name, value: val.id };
    });

  const blockOptionElement = (
    blockName: string,
    selectedLabel: string | undefined,
    setSelectedLabel: (item: FlyoutMenuItem | undefined) => void,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
  ) => {
    return (
      <div className={`flex flex-row mt-4`}>
        <div className="mt-3 w-96 text-left">
          Guides Group block for {blockName}:
        </div>
        <div className="w-48">
          <FlyoutMenu
            activeLabel={selectedLabel}
            isEqual={isEqual}
            size="sm"
            isMenuOpen={isOpen}
            setIsMenuOpen={setIsOpen}
            items={flyoutMenuItems()}
            onChange={(e) => setSelectedLabel(e)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col mt-6 p-4 rounded-md border border-stroke1 bg-theme1">
      {blockOptionElement(
        'NoQuizzesCollected',
        selectedNoQuizzesCollected?.label,
        setSelectedNoQuizzesCollected,
        isNoQuizzesCollectedFlyoutOpen,
        setIsNoQuizzesCollectedFlyoutOpen
      )}
    </div>
  );
};

export default DiscoverContentCollectionEditor;
