import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ISpotVersion } from 'Colugo/interfaces/games';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import { BlockOperations } from 'Colugo/operations/lobby';
import Table from 'component/utility/Table';
import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Cell, Column } from 'react-table';
import { toast } from 'react-toastify';
import { MutatorCallback } from 'swr/dist/types';
import { container } from 'tsyringe';

const blockOperations = container.resolve(BlockOperations);

type Props = {
  onChangeSearchValue?: (value: string) => void;
  isGuideItemsLoading?: boolean;
  isGuideItemsError?: boolean | undefined;
  isAllItemsSelected: boolean;
  selectedBlock: IBlock;
  updateItemsForBlock: (
    data?:
      | ISpotVersion[]
      | Promise<ISpotVersion[]>
      | MutatorCallback<ISpotVersion[]>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<ISpotVersion[] | undefined>;
  setIsAllItemsSelected: (isAllItemsSelected: boolean) => void;
  itemVersions: ISpotVersion[];
  tableName: string;
};

function BlockItemsEditor(props: Props) {
  const {
    itemVersions,
    tableName,
    selectedBlock,
    isGuideItemsLoading,
    isGuideItemsError,
    updateItemsForBlock,
    isAllItemsSelected,
    setIsAllItemsSelected,
    onChangeSearchValue
  } = props;
  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const columns = useMemo(() => {
    const subPageItemTableColumns: Column<ISpotVersion>[] = [
      {
        Header: 'Image',
        accessor: (row) => {
          return row?.images![0];
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="w-16 h-16 flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <img
                  width={'80%'}
                  height={'80%'}
                  className="rounded-md"
                  src={cellInfo.cell.value}
                  alt="spot icon"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Item Name',
        accessor: 'name'
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return subPageItemTableColumns;
  }, []);

  async function handleAddItemToItemBlocks(spot: ISpotVersion) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }

    const spotToAdd = itemVersions?.find(
      (gi) => gi.versionable?.id === spot.versionable?.id
    );

    if (!spotToAdd) {
      toast.error('Game not found. Could not add to Block.');
      return;
    }

    setIsMutationLoading(true);
    const { error } = await blockOperations.addItemToBlockAsync(
      selectedBlock.id,
      spot.versionable?.id!,
      BlockEntityType.Spot
    );
    setIsMutationLoading(false);

    if (error) {
      toast.error('Error occurred: Unable to add game to Block');
      return;
    }
    if (!itemVersions) {
      return;
    }

    updateItemsForBlock([...itemVersions, spotToAdd]);
  }

  async function handleRemoveItemFromItemBlocks(spotVersion: ISpotVersion) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }
    if (!itemVersions) {
      toast.error('No Items exists for this block.');
      return;
    }
    const itemToRemove = itemVersions.find(
      (gi) => gi.versionable?.id === spotVersion.versionable?.id
    );
    if (!itemToRemove) {
      toast.error('Item not found. Cannot remove, please refresh the page.');
      return;
    }

    setIsMutationLoading(true);
    const { error } =
      await blockOperations.removeItemsGroupOrGuideSpotItemFromBlockAsync(
        selectedBlock.id,
        spotVersion.versionable?.id!
      );
    setIsMutationLoading(false);

    if (error) {
      toast.error('Error occurred: Unable to remove game from Block.');
      return;
    }

    updateItemsForBlock(itemVersions, true);
  }

  async function handleUpdateItemsOrder(
    spotVersion: ISpotVersion,
    position: number
  ) {
    if (!selectedBlock.id) {
      toast.error('Block not found');
      return;
    }

    const itemToReorder = itemVersions.find(
      (gi) => gi.versionable?.id === spotVersion.versionable?.id
    );
    if (!itemToReorder) {
      toast.error('Spot not found. Cannot reorder, please refresh the page.');
      return;
    }

    setIsMutationLoading(true);
    const { data: updatedGuideSpotsOrder, error } =
      await blockOperations.updateItemOrderAsync(
        selectedBlock.id,
        spotVersion.versionable?.id!,
        position
      );

    if (!updatedGuideSpotsOrder || error) {
      toast.error('Failed to update order. Please refresh the page.');
      setIsMutationLoading(false);
      return;
    }

    setIsMutationLoading(false);
    updateItemsForBlock(itemVersions, true);
  }

  if (isGuideItemsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load items from server.'}</span>
      </ErrorPage>
    );
  }
  const containerTitle = `Add or Remove Spots to ${selectedBlock.name}`;
  const allItemsButton = (
    <div className="w-auto flex flex-row mb-2 justify-center">
      <div
        onClick={() => setIsAllItemsSelected(false)}
        className={` ${!isAllItemsSelected ? 'bg-primaryButton' : 'bg-white'} ${
          !isAllItemsSelected ? 'text-white' : 'text-black'
        } font-medium px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        Block Items
      </div>
      <div
        onClick={() => setIsAllItemsSelected(true)}
        className={` ${isAllItemsSelected ? 'bg-primaryButton' : 'bg-white'} ${
          isAllItemsSelected ? 'text-white' : 'text-black'
        } px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        All Items
      </div>
    </div>
  );

  return (
    <div
      className="w-auto relative flex flex-col items-center rounded-md border-2 border-gray-400"
      style={{ height: '82vh' }}
    >
      <div className="bg-theme3 w-full h-12 flex flex-col items-center justify-center">
        <div className="mt-1 mb-2 font-bold text-xl">{tableName}</div>
      </div>

      <div className="px-4 w-full block text-lg italic text-left mt-3">
        <div>{containerTitle}</div>
      </div>
      <div className="w-full mb-1">
        {isGuideItemsLoading ? (
          <Spinner />
        ) : (
          <div>
            <DndProvider backend={HTML5Backend}>
              <Table
                tableColumns={columns}
                searchPlaceholderText={'items'}
                children={allItemsButton}
                onChangeSearchValue={onChangeSearchValue}
                isBlockPageView
                isOrderable={!isAllItemsSelected}
                isBlockItems={!isAllItemsSelected}
                entities={itemVersions}
                onClickAddIcon={
                  isAllItemsSelected ? handleAddItemToItemBlocks : undefined
                }
                onChangeOrder={
                  !isAllItemsSelected ? handleUpdateItemsOrder : undefined
                }
                isMutationLoading={isMutationLoading}
                onClickDeleteIcon={
                  !isAllItemsSelected
                    ? handleRemoveItemFromItemBlocks
                    : undefined
                }
                shouldShowOrderArrows={false}
                isVersionableId
              />
            </DndProvider>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlockItemsEditor;
