// add useContext
import React, { useContext } from 'react';
import { AuthContext } from '../../provider/auth/authProvider';
import { Input, Form, Button } from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import isEmail from '../../utility/general/isEmail';

const Signin = () => {
  const { handleSignIn, inputs, setInputs, errors, isLoading, setErrors } =
    useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!isEmail(inputs.email)) {
      setErrors(['Email Invalid']);
      return;
    }
    await handleSignIn();
  };
  const handleChange = (e: InputFormEvent) => {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="mt-4" style={{ maxWidth: '500px' }}>
      <Form onSubmit={handleSubmit}>
        <Input
          label="Email Address"
          isHeaderInInput={true}
          className="mb-6 md:mb-3"
          transparentHeaderColor={'#263238'}
          skin="transparent"
          color="white"
          onChange={handleChange}
          name="email"
          value={inputs.email}
        />
        <Input
          label="Password"
          isHeaderInInput={true}
          className="mb-6 md:mb-3"
          transparentHeaderColor={'#263238'}
          color="white"
          skin="transparent"
          onChange={handleChange}
          name="password"
          value={inputs.password}
          type="password"
        />
        <div className="lg:w-1/2">
          <Button
            disabled={inputs.email === '' || inputs.password === ''}
            size="lg"
            type="submit"
            isLoading={isLoading}
            className="w-full"
          >
            <div className="mx-auto">LOG IN</div>
          </Button>
        </div>
        {errors.length > 0
          ? errors.map((error: string, idx: number) => (
              <p key={idx} className="text-sm text-red-500">
                {' '}
                {error}
              </p>
            ))
          : null}
      </Form>
    </div>
  );
};

export default Signin;
