import { Options } from 'ky';
import HttpClient from './HttpClient';
import useSWR, { cache, SWRConfiguration } from 'swr';
import { MutatorCallback } from 'swr/dist/types';

const httpClient = new HttpClient();

export type swrRequestResponse<T = any> = {
  data?: T;
  isError: boolean | undefined;
  mutate: (
    data?: T | Promise<T> | MutatorCallback<T> | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<T | undefined>;
  isLoading: boolean;
};

export const useGetHttpClient = <T = any>(
  url: string | null,
  options: SWRConfiguration<any, any, any> = {},
  toCache: boolean = true,
  fullyTyped: boolean = false
) => {
  const fetcher = (url: string, options?: Options) => {
    if (toCache && cache.has(url)) {
      return cache.get(url);
    }

    if (fullyTyped) {
      options = {
        ...options,
        headers: {
          ...options?.headers,
          JsonSettings: 'FullyTyped'
        }
      };
    }

    //null string does not trigger fetcher
    return httpClient
      .budgieHttpClientFactory()
      .get(url, options)
      .then((response) => {
        httpClient.checkHeaders(httpClient.getHeaders(response));
        return response.json();
      })
      .catch((err) => {
        console.error(`useGet http request failed:${err}`);
        httpClient.checkHeaders(httpClient.getHeaders(err.response));
        throw err;
      });
  };

  const { data, error, mutate } = useSWR<T>(url, fetcher, options);

  if (error) {
    console.error(error);
  }

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
};
