import { Button, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import ConfirmDeletePopup, {
  skinType
} from 'component/utility/ConfirmDeletePopup';
import { MdClose } from 'react-icons/md';

type Props = {
  entityType: string;
  closeModal: () => void;
  selectedUserReport?: IUserReport;
  onClickDeleteIcon: (postId?: string) => void;
  onClickSuspendIcon: (userId?: string) => void;
  onClickBanIcon: (userId?: string) => void;
  handleClickConfirmDeletePopupSubmit: () => void;
  setUserReportId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalState: skinType;
  setModalState: React.Dispatch<React.SetStateAction<skinType>>;
  isDeleted: boolean;
  isLoading: boolean;
  isUserBanned: boolean;
  isUserSuspended: boolean;
};

export default function ReportButtons(props: Props) {
  const {
    entityType,
    closeModal,
    selectedUserReport,
    onClickDeleteIcon,
    handleClickConfirmDeletePopupSubmit,
    setUserReportId,
    isModalOpen,
    setIsModalOpen,
    modalState,
    setModalState,
    isDeleted,
    isLoading,
    isUserBanned,
    isUserSuspended
  } = props;

  return (
    <div className="flex flex-row mt-auto justify-evenly">
      <Button
        skin="error"
        disabled={isDeleted}
        onClick={() => {
          onClickDeleteIcon(selectedUserReport?.entity?.id);
          setUserReportId(undefined);
        }}
      >
        {`Delete ${entityType}`}
      </Button>
      <Button
        skin="error"
        disabled={isUserBanned || isUserSuspended}
        onClick={() => {
          setIsModalOpen(true);
          setModalState('suspendUser');
        }}
      >
        {'Suspend User'}
        <div className="ml-2">
          {isLoading && modalState === 'suspendUser' && (
            <Spinner size="sm" color="white" />
          )}
        </div>
      </Button>
      <Button
        skin="error"
        disabled={isUserBanned}
        onClick={() => {
          setIsModalOpen(true);
          setModalState('banUser');
        }}
      >
        {'Ban User'}
        <div className="ml-2">
          {isLoading && modalState === 'banUser' && (
            <Spinner size="sm" color="white" />
          )}
        </div>
      </Button>

      {isModalOpen && (
        <ConfirmDeletePopup
          isModalOpen={isModalOpen}
          onSubmit={handleClickConfirmDeletePopupSubmit}
          closeModal={() => setIsModalOpen(false)}
          title={modalState === 'suspendUser' ? 'Suspend User' : 'Ban User'}
          skin={modalState}
        />
      )}
      <Button onClick={() => closeModal()} skin="secondary">
        <MdClose className="mr-1 mt-1" />
        <div>Close</div>
      </Button>
    </div>
  );
}
