import { Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  useReqGetLatestManifest,
  useReqListGameBlocks
} from 'provider/admin/methods';
import React from 'react';
import ManifestEditor from './ManifestEditor';

type Props = {};

const ManifestContainer: React.FC<Props> = () => {
  const { data: gameBlocks } = useReqListGameBlocks();

  const { data: latestManifest } = useReqGetLatestManifest();

  return (
    <div className="h-full overflow-hidden container">
      <div className="mx-auto flex flex-col">
        <span className="mx-auto block text-4xl text-black mt-1">
          Manifest Management
        </span>
        {latestManifest ? (
          <ManifestEditor
            gameBlocks={gameBlocks || []}
            latestManifest={latestManifest}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default ManifestContainer;
