import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { IInteractionEvent } from 'Colugo/interfaces/interest/IInteractionEvent';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { requestResponse } from 'Colugo/provider/HttpClient';

@scoped(Lifecycle.ContainerScoped)
class InterestScoreOperations extends AbstractOperations {
  protected basePath: string = 'interactionEvent';

  public async updateInteractionEventValueAsync(
    event: IInteractionEvent
  ): requestResponse {
    return await this.executePut(`${event.id}`, event);
  }
}

const useReqListInteractionEvents = (): swrRequestResponse<
  IInteractionEvent[]
> => {
  return useGetHttpClient(
    'interactionEvent/list',
    {
      revalidateOnFocus: true
    },
    false
  );
};

export { useReqListInteractionEvents };
export default InterestScoreOperations;
