import {
  Button,
  CardHeader,
  ErrorPage,
  FlyoutMenu,
  Input,
  Spinner,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import { ICategory } from 'Colugo/interfaces/common';
import { ITag } from 'Colugo/interfaces/tags';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';
import {
  useReqListFilterGroups,
  useReqListFilterGroupsByTag
} from 'Colugo/operations/tags';
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import ItemUpdateForm from 'component/utility/ItemUpdateForm';
import TagIconUploader from './TagIconUploader';
import { toast } from 'react-toastify';

type Props = {
  categories: ICategory[];
  tag: ITag;
  onClickUpdateSubmit: (tag: ITag) => void;
  onClickCloseModal: () => void;
};

function TagUpdateForm(props: Props) {
  const { tag, categories, onClickUpdateSubmit, onClickCloseModal } = props;

  const [localTag, setLocalTag] = useState<ITag>(tag);
  const [isDefaultCategoryOpen, setIsDefaultCategoryOpen] = useState(false);

  const {
    data: filterGroupsForTag,
    isLoading: isFilterGroupsForTagLoading,
    mutate: mutateFilterGroupsForTag,
    isError: isFilterGroupsForTagError
  } = useReqListFilterGroupsByTag(localTag.id!);

  const noneSelectedOption = { value: 'none', label: 'None' };

  const { data: filterGroups } = useReqListFilterGroups();

  const selectedCategory =
    categories.find(
      (category) =>
        localTag.defaultCategoryId && category.id === localTag.defaultCategoryId
    )?.name || noneSelectedOption.label;

  const categoryOptions: FlyoutMenuItem[] = [
    noneSelectedOption,
    ...categories.map((category) => {
      return {
        value: category.id,
        label: category.name
      };
    })
  ];

  const handleCategorySelected = (value: string) => {
    let newDefaultCategoryId: string | undefined = value;
    if (value === noneSelectedOption.value) {
      newDefaultCategoryId = undefined;
    }
    setLocalTag({
      ...localTag,
      defaultCategoryId: newDefaultCategoryId
    });
  };

  const submitForUpdating = () => {
    onClickUpdateSubmit(localTag);
  };

  const submitEnabled = localTag.name;

  if (isFilterGroupsForTagLoading) {
    return <Spinner />;
  }

  if (isFilterGroupsForTagError) {
    return (
      <ErrorPage>
        <span>{'Unable to load filter group from server.'}</span>
      </ErrorPage>
    );
  }

  function addTagIcon(iconUrl: string) {
    if (!iconUrl) {
      toast.error('No icon was selected, Please try again');
      return;
    }
    if (!iconUrl.toLowerCase().endsWith('.png')) {
      toast.error('Only PNG images are allowed');
      return;
    }
    setLocalTag({
      ...localTag,
      icon: iconUrl
    });
  }

  function deleteTagIcon() {
    setLocalTag({
      ...localTag,
      icon: ''
    });
  }

  return (
    <div className="flex flex-row w-full px-6">
      <div className="w-full">
        <div className="w-full ml-3">
          <CardHeader
            title={'Update Tag Information'}
            fontWeight="font-semibold"
          />
        </div>
        <div className="w-full text-left mx-4 mt-4">
          <span className="font-semibold">{'Tag Name:'}</span>
          <Input
            value={localTag.name}
            onChange={(e) =>
              setLocalTag({
                ...localTag,
                name: e.currentTarget.value
              })
            }
          />
          <span className="font-semibold">{'Default Category:'}</span>
          <FlyoutMenu
            isEqual={(i1, i2) => i1.label === i2.label}
            size={'lg'}
            items={categoryOptions}
            activeLabel={selectedCategory}
            isMenuOpen={isDefaultCategoryOpen}
            setIsMenuOpen={setIsDefaultCategoryOpen}
            onChange={(selection) => handleCategorySelected(selection.value)}
          />
          <div className="font-semibold mt-2">{'Icon:'}</div>
          <TagIconUploader
            localTag={localTag}
            addIcon={addTagIcon}
            deleteIcon={deleteTagIcon}
          />
          <div className="mt-6">
            <ItemUpdateForm
              selectedItem={tag}
              itemChildren={filterGroupsForTag || []}
              allChildren={filterGroups || []}
              filterGroupTaggingType={FilterGroupTagging.AddFilterGroupToTag}
              mutate={mutateFilterGroupsForTag}
            />
          </div>
          <div className="flex flex-row mt-8 justify-evenly">
            <Button onClick={submitForUpdating} disabled={!submitEnabled}>
              {'Submit'}
            </Button>
            <Button onClick={onClickCloseModal} skin="secondary">
              <MdClose className="mr-1 mt-1" />
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagUpdateForm;
