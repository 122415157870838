import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaSortDown } from 'react-icons/fa';
import { IUser } from 'Colugo/interfaces/identity';
import { useClickAway } from 'ahooks';
import defaultAvatar from 'images/defaultAvatar.png';

type Props = {
  isMenuOpen: boolean;
  user: IUser;
  setMenuOpen: (x: boolean) => void;
  onClickSignout: (e: React.MouseEvent) => void;
};
const DashboardHeader = (props: Props) => {
  const { isMenuOpen, user, setMenuOpen, onClickSignout } = props;

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(() => {
    setMenuOpen(false);
  }, ref);

  const ButtonType =
    'block px-4 py-1 text-md text-gray-700 hover:text-black cursor-pointer';

  const fullName =
    user.username ??
    user?.firstName +
      ' ' +
      (user?.lastName ? user?.lastName?.split(' ').pop() : '');

  return (
    <>
      <header className="flex justify-between items-center py-4 px-6 bg-theme2 z-30 shadow-md">
        <div className="flex w-7/12" />
        <div className="flex w-1/3 xl:w-1/4">
          <div className="relative flex flex-row ml-auto w-full">
            <div className="w-2/3 md:w-1/2 lg:w-5/12 xl:w-1/2 ml-auto">
              <div className="w-11/12 ml-auto">
                <div className="w-full h-14 rounded-lg flex bg-white border border-stroke1 flex-row ">
                  <div className="xl:w-2 w-4 flex" />
                  <span
                    className="text-left mx-auto my-4 pl-4 leading-tight text-sm"
                    style={{ fontSize: '15px', lineHeight: '19px' }}
                  >
                    {fullName}
                    <br />
                    <span
                      className="font-bold "
                      style={{ fontSize: '10px', lineHeight: '12px' }}
                    ></span>
                  </span>
                  <div
                    className="ml-auto w-1/5 bg-theme1 rounded-r-lg cursor-pointer border-l border-stroke1 relative"
                    ref={ref}
                    onClick={() => setMenuOpen(!isMenuOpen)}
                  >
                    <FaSortDown
                      style={{ top: '-10px' }}
                      className="m-auto text-xl absolute bottom-0 left-0 right-0 text-theme6"
                    />
                  </div>
                </div>
              </div>
              <div
                className="absolute z-10 block h-10 w-10 rounded-full overflow-hidden shadow focus:outline-none top-0 mt-2 border-hot border-2 "
                style={{ marginLeft: '-3px' }}
              >
                <img
                  className="h-full w-full object-cover "
                  src={user.avatar ?? defaultAvatar}
                  alt="Your avatar"
                />
              </div>
            </div>

            {isMenuOpen ? (
              <div
                x-show="dropdownOpen"
                className="absolute right-0 mt-14 w-48 text-left bg-white overflow-hidden border-gray-800 border shadow-xl z-20"
              >
                <div className="px-4 pt-4 text-xs text-gray-600 uppercase">
                  {fullName}
                </div>
                <Link to="/profile" className={ButtonType}>
                  Your Profile
                </Link>

                <Link to="/account" className={ButtonType}>
                  Your Account
                </Link>

                <Link to="/settings" className={ButtonType}>
                  Settings
                </Link>

                <Link to="#" className={ButtonType} onClick={onClickSignout}>
                  Sign Out
                </Link>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </header>
    </>
  );
};
export default DashboardHeader;
