import { toast } from 'react-toastify';

export async function copyToClipboardAsync(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    toast.info('Copied to your clipboard', { autoClose: 2000 });
  } catch (err) {
    toast.error('Failed  to copy, please try again', { autoClose: 3000 });
  }
}
