import { IGame } from 'Colugo/interfaces/games';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockOperations } from 'Colugo/operations/lobby';
import Table from 'component/utility/Table';
import {
  reqAddGameToBlock,
  reqUpdateBlockGamePosition
} from 'provider/admin/methods';
import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Cell, Column } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const blockOperations = container.resolve(BlockOperations);

type Props = {
  games: IGame[];
  block: IBlock;
  isShowMyGames?: boolean;
  setIsShowMyGames: (isShowMyGames: boolean) => void;
  updateGamesForBlock: (games: IGame[]) => void;
  onChangeSearchValue: (value: string) => void;
  isLoading: boolean;
};

function BlockGamesEditor(props: Props) {
  const {
    games,
    block,
    isShowMyGames,
    setIsShowMyGames,
    updateGamesForBlock,
    onChangeSearchValue,
    isLoading
  } = props;

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const columns = useMemo(() => {
    const eventGroupItemTableColumns: Column<IGame>[] = [
      {
        Header: 'Image',
        accessor: (row) => {
          return row?.versions[0].icon;
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="w-16 h-16 flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <img
                  width={'80%'}
                  height={'80%'}
                  className="rounded-md"
                  src={cellInfo.cell.value}
                  alt="event icon"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Name',
        accessor: (row) => row.versions[0].name
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return eventGroupItemTableColumns;
  }, []);

  const handleAddGameToBlock = async (game: IGame) => {
    if (!block?.id) {
      toast.error('Block not found');
      return;
    }
    const gameToAdd = games.find((g) => g.id === game.id);
    if (!gameToAdd) {
      toast.error('Game not found. Could not add to Block.');
      return;
    }

    setIsMutationLoading(true);
    const { error } = await reqAddGameToBlock(game.id!, block.id);
    setIsMutationLoading(false);

    if (error) {
      toast.error('Error occurred: Unable to add game to Block');
      return;
    }

    updateGamesForBlock([...games, gameToAdd]);
  };

  const handleUpdateGameOrder = async (game: IGame, position: number) => {
    if (!block?.id) {
      toast.error('Block not found');
      return;
    }

    const gameToReorder = games.find((g) => g.id === game.id);
    if (!gameToReorder) {
      toast.error('Game not found. Cannot reorder, please refresh the page.');
      return;
    }

    setIsMutationLoading(true);
    const { data: updatedGuidesOrder, error } =
      await reqUpdateBlockGamePosition(block.id!, game.id!, position);

    setIsMutationLoading(false);

    if (!updatedGuidesOrder || error) {
      toast.error('Failed to update order. Please refresh the page.');
      setIsMutationLoading(false);
      return;
    }

    setIsMutationLoading(false);
    updateGamesForBlock(games);
  };

  const handleRemoveBlockFromGame = async (game: IGame) => {
    if (!block?.id) {
      toast.error('Block not found');
      return;
    }
    if (!games) {
      toast.error('No games found for this block.');
      return;
    }
    const gameToRemove = games.find((g) => g.id === game.id);
    if (!gameToRemove) {
      toast.error('Game not found. Cannot remove, please refresh the page.');
      return;
    }

    setIsMutationLoading(true);
    const { error } =
      await blockOperations.removeGuidesGrouptItemFromBlockAsync(
        block.id,
        game.id!
      );
    setIsMutationLoading(false);

    if (error) {
      toast.error('Error occurred: Unable to remove game from Block.');
      return;
    }

    updateGamesForBlock(games);
  };

  const allGuidesButton = (
    <div className="w-auto flex flex-row mb-2 justify-center">
      <div
        onClick={() => setIsShowMyGames(true)}
        className={`  ${
          isShowMyGames ? 'text-white bg-primaryButton' : 'text-black bg-white'
        } px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        My Games
      </div>
      <div
        onClick={() => setIsShowMyGames(false)}
        className={` ${
          isShowMyGames ? 'text-black bg-white' : 'text-white bg-primaryButton'
        } font-medium px-4 py-2 mx-2 rounded-md cursor-pointer`}
      >
        All Games
      </div>
    </div>
  );

  //Should adjust container title based on if viewing existing or viewing to add
  const containerTitle = `${
    isShowMyGames ? 'Reorder or remove guides in' : 'Add guides to'
  }  ${block.name}`;

  return (
    <div className="w-full">
      <span className="mx-auto block text-lg italic text-left mt-3">
        {containerTitle}
      </span>
      <div>
        <div className="h-full w-full">
          <DndProvider backend={HTML5Backend}>
            <Table
              tableColumns={columns}
              searchPlaceholderText={'guides'}
              children={allGuidesButton}
              onChangeSearchValue={onChangeSearchValue}
              isBlockPageView
              isBlockItems={isShowMyGames}
              entities={games}
              onClickAddIcon={!isShowMyGames ? handleAddGameToBlock : undefined}
              onChangeOrder={isShowMyGames ? handleUpdateGameOrder : undefined}
              isMutationLoading={isMutationLoading}
              isOrderable={isShowMyGames}
              onClickDeleteIcon={
                isShowMyGames ? handleRemoveBlockFromGame : undefined
              }
              shouldShowOrderArrows={false}
              isLoading={isLoading}
            />
          </DndProvider>
        </div>
      </div>
    </div>
  );
}

export default BlockGamesEditor;

//TODO: Create Table /w searchbar layout.
// Games list with game name and potentially number of games in list.
