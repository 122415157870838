import { Input, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IUserCountThreshold } from 'Colugo/interfaces/identity';
import { ThresholdType } from 'Colugo/interfaces/identity/IUserCountThreshold';
import { UserOperations } from 'Colugo/operations/identity';
import UserCountThresholdOperations from 'Colugo/operations/identity/UserCountThresholdOperations';
import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const userOperations = container.resolve(UserOperations);
const userCountThresholdOperations = container.resolve(
  UserCountThresholdOperations
);

function UserCountContainer() {
  const [userCount, setUserCount] = useState<number>();
  const [userCountThresholds, setUserCountThresholds] =
    useState<IUserCountThreshold[]>();
  const [newUserCountThresold, setNewUserCountThreshold] =
    useState<IUserCountThreshold>();
  const absoluteLimit = userCountThresholds?.find(
    (uct) => uct.type === ThresholdType.AbsoluteLimit
  );

  useEffect(() => {
    (async () => {
      const { data: serverUserCount, error } =
        await userOperations.getUserCountAsync();
      if (error) {
        toast.error('Failed to get user count');
        return;
      }
      setUserCount(serverUserCount);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data: serverUserCountThresholds, error } =
        await userCountThresholdOperations.getAsync();
      if (error) {
        toast.error('Failed to get user count thresholds');
        return;
      }
      setUserCountThresholds(serverUserCountThresholds);
    })();
  }, []);

  const userCountComponent = (
    <div className="flex flex-row">
      <div className="font-bold text-lg">Current user count: {userCount}</div>
    </div>
  );

  const userCountNotificationThresholdComponent = (userCountThresholds || [])
    .filter((uct) => uct.type === ThresholdType.Notification)
    .sort((a, b) => (a.threshold > b.threshold ? 1 : -1))
    .map((nt, index) => (
      <div key={index}>
        <div className="flex flex-row">
          <div
            className={`${
              nt.threshold < (userCount || 0) ? 'text-error1' : 'text-black'
            }`}
          >
            Notification threshold {index + 1}: {nt.threshold}
          </div>
          <MdDelete
            className="text-lg mt-0.5 cursor-pointer text-error1"
            onClick={() => handleDeleteCountThreshold(nt.id)}
          />
        </div>
      </div>
    ));

  const handleCreateUserCountThreshold = async (
    thresholdType: ThresholdType
  ) => {
    if (!newUserCountThresold) {
      toast.error('Invalid new user count threshold');
      return;
    }

    if (newUserCountThresold.threshold <= (userCount || 0)) {
      toast.error(
        'New user count threshold must be greater than current user count'
      );
      return;
    }

    const localNewUserCountThreshold = {
      ...newUserCountThresold,
      type: thresholdType
    };

    const { data: createdUserCountThreshold, error } =
      await userCountThresholdOperations.createAsync(
        localNewUserCountThreshold
      );

    if (error || !createdUserCountThreshold) {
      toast.error(
        'Error when creating user count threshold, please try again.'
      );
      return;
    }

    setUserCountThresholds([
      ...(userCountThresholds || []),
      createdUserCountThreshold!
    ]);
    setNewUserCountThreshold({ ...newUserCountThresold, threshold: 0 });

    toast.success('New user count threshold created');
  };

  const handleDeleteCountThreshold = async (userCountThresholdId?: string) => {
    const userCountThresholdToBeDeleted = userCountThresholds?.find(
      (uct) => uct.id === userCountThresholdId
    );

    if (!userCountThresholdId || !userCountThresholdToBeDeleted) {
      toast.error('No ID provided.');
      return;
    }

    setUserCountThresholds(
      userCountThresholds?.filter((uct) => uct.id !== userCountThresholdId)
    );

    const { error } = await userCountThresholdOperations.deleteAsync(
      userCountThresholdId
    );

    if (error) {
      toast.error(
        'Error when deleting user count threshold, please try again.'
      );
      setUserCountThresholds([
        ...(userCountThresholds || []),
        userCountThresholdToBeDeleted
      ]);
      return;
    }

    toast.success('User user count threshold deleted.');
  };

  const newUserCountThresholdInput = (
    <div className="w-full flex-row flex">
      <div className="flex">
        <div className="mt-3" style={{ width: '370px' }}>
          User count threshold{' '}
          {(userCountThresholds?.filter(
            (uct) => uct.type === ThresholdType.Notification
          ).length || 0) + 1}
          :
        </div>
        <Input
          className="w-full"
          color="text-gray-400"
          size="sm"
          value={
            newUserCountThresold?.threshold === 0
              ? ''
              : newUserCountThresold?.threshold.toString()
          }
          onChange={(e) =>
            setNewUserCountThreshold({
              ...newUserCountThresold!,
              threshold: +e.currentTarget.value
            })
          }
        />
        <div className="w-10 mt-2">
          {newUserCountThresold?.threshold ? (
            <div className="w-full cursor-pointer flex flex-row">
              <div
                className="bg-success rounded-xl p-1 mx-1"
                onClick={() =>
                  handleCreateUserCountThreshold(ThresholdType.Notification)
                }
              >
                Notification
              </div>
              {!absoluteLimit && (
                <div
                  className="bg bg-yellow-600 rounded-xl p-1"
                  onClick={() =>
                    handleCreateUserCountThreshold(ThresholdType.AbsoluteLimit)
                  }
                >
                  Absolute
                </div>
              )}
            </div>
          ) : (
            <div className="w-0" />
          )}
        </div>
        <div className="flex flex-grow" />
      </div>
    </div>
  );

  const absoluteLimitComponent = () => {
    if (!absoluteLimit) {
      return (
        <div className="w-full flex-row flex ">
          <div className="rounded-md bg-yellow-600 px-1 ">
            No absolute limit
          </div>
        </div>
      );
    }
    return (
      <div className="w-full flex-row flex">
        <div className="flex flex-row">
          <div className="rounded-md bg-yellow-600 px-1 font-semibold">
            Absolute limit: {absoluteLimit.threshold}
          </div>
          <MdDelete
            className="text-lg mt-1 cursor-pointer text-error1"
            onClick={() => handleDeleteCountThreshold(absoluteLimit.id)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="ml-2">
      {userCount ? userCountComponent : <Spinner />}
      {userCountNotificationThresholdComponent}
      {absoluteLimitComponent()}
      {newUserCountThresholdInput}
    </div>
  );
}

export default UserCountContainer;
