import IBase from '../IBase';
import { IUser } from '../identity';

export interface ISurveyResponse extends IBaseFeedback {
  howFastAnswer: RadioAnswer;
  haveVisitedAnswer: RadioAnswer;
  howUsefulAnswer: RadioAnswer;
  whyUsingAnswers: WhyUsingAnswer[];
  favouriteFeaturesAnswers: FavouriteFeaturesAnswer[];
  howUsingAnswers: HowUsingAnswer[];
  additionalThoughts?: string;
  otherApps?: string;
}

export interface IBaseFeedback extends IBase {
  user: IUser;
}

export enum RadioAnswer {
  None,
  Bad,
  Average,
  Good,
  VeryGood
}

export enum WhyUsingAnswer {
  Inspiration,
  BestThingsToDo,
  HottestEvents,
  ExclusiveDeals
}

export enum HowUsingAnswer {
  Spontaneous,
  Planning,
  OutAndAbout
}

export enum FavouriteFeaturesAnswer {
  Map,
  FilterSearch,
  Browsing,
  Collections,
  WizardSearch,
  Guides
}

export enum QuestionType {
  WhyUsing,
  HaveVisited,
  HowUseful,
  FavouriteFeatures,
  HowUsing,
  HowFast,
  AdditionalThoughts,
  OtherApps
}
