import React from 'react';
import InterestScoreEditor from './InterestScoreEditor';

function InterestScoreContainer() {
  return (
    <div className="h-full overflow-hidden container ml-32 mt-2">
      <div className="mx-auto flex flex-col">
        <InterestScoreEditor />
      </div>
    </div>
  );
}

export default InterestScoreContainer;
