import { Modal, Spinner, Switch } from '@bindystreet/bindystreet.kit.react';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { ScenarioType } from 'Colugo/interfaces/lobby/discover/enums/ScenarioType';
import BlockOperations, {
  useReqListByScenarioTypeAsync
} from 'Colugo/operations/lobby/BlockOperations';
import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import Afternoon from '../../../images/Afternoon.png';
import Evening from '../../../images/Evening.png';
import Morning from '../../../images/Morning.png';
import Raining from '../../../images/Raining.png';
import Sunny from '../../../images/Sunny.png';
import BlockManage from '../discover/blocks/BlockManage';

const blockOperations = container.resolve(BlockOperations);

export default function ScenarioContainer() {
  const [scenarioType, setScenarioType] = useState<ScenarioType>(
    ScenarioType.None
  );
  const [blockToUpdate, setBlockToUpdate] = useState<IBlock | undefined>();
  const [isBlockCreateOpen, setIsBlockCreateOpen] = useState<boolean>(false);
  function getComplement(scenarioType: ScenarioType) {
    switch (scenarioType) {
      case ScenarioType.Morning:
        return ScenarioType.MorningInactive;
      case ScenarioType.Afternoon:
        return ScenarioType.AfternoonInactive;
      case ScenarioType.Evening:
        return ScenarioType.EveningInactive;
      case ScenarioType.Sunny:
        return ScenarioType.SunnyInactive;
      case ScenarioType.Raining:
        return ScenarioType.RainingInactive;
    }
    return ScenarioType.None;
  }
  const {
    data: blocks,
    mutate: mutateBlocks,
    isLoading
  } = useReqListByScenarioTypeAsync(getComplement(scenarioType));

  const isModalOpen = scenarioType !== ScenarioType.None;

  function scenarioImage(imageUrl: string, scenarioType: ScenarioType) {
    return (
      <div className="mt-4">
        <span className="font-bold text-2xl ">
          {ScenarioType[scenarioType]}
        </span>
        <img
          src={imageUrl}
          alt={scenarioType.toString()}
          width={'300px'}
          height={'300px'}
          className="rounded-md mt-4 mx-6 cursor-pointer"
          onClick={() => setScenarioType(scenarioType)}
        />
      </div>
    );
  }

  async function onClickActivateAsync(id: string, isActivating: boolean) {
    if (!id) {
      return;
    }
    const response = await blockOperations.changeScenarioActiveStateAsync(
      id,
      isActivating
    );

    if (response.error) {
      toast.error(response.errorMessage);
      return;
    }
    mutateBlocks(blocks, true);
    toast.success('Block activated successfully');
  }

  async function handleUpdateBlockAsync(blockToUpdate: IBlock) {
    const { data: updatedBlock, error } = await blockOperations.updateAsync(
      blockToUpdate
    );

    if (!updatedBlock || error) {
      toast.error('Failed to update block, please try again.');
      return false;
    }

    const newBlocks = (blocks || []).map((localBlock) => {
      if (localBlock.id === blockToUpdate.id) {
        localBlock = updatedBlock;
      }
      return localBlock;
    });

    blockToUpdate.updatedAt = updatedBlock.updatedAt;

    mutateBlocks(newBlocks);
    toast.success('Block details has been successfully updated');
    setBlockToUpdate(undefined);
  }

  async function handleCreateBlockAsync(blockToAdd: IBlock) {
    blockToAdd.count = 1;
    blockToAdd.scenarioType = getComplement(scenarioType);
    const { data, error } = await blockOperations.createAsync(blockToAdd);

    if (!data || error) {
      toast.error('Failed to create block, please try again');
      return false;
    }

    const newBlocks = blocks ? [...blocks, data] : [data];
    mutateBlocks(newBlocks, true);
    toast.success('Block has been successfully creatd');
    return true;
  }

  function rowElement(value?: string, isActive?: boolean) {
    return (
      <span
        className={`${
          isActive ? 'font-bold' : ''
        } mx-2 w-52 text-left whitespace-nowrap overflow-hidden`}
      >
        {value}
      </span>
    );
  }

  function renderBlock(block: IBlock) {
    const isActive = block.scenarioType === scenarioType;
    return (
      <div
        className={`w-full text-xl flex-row flex my-1 rounded-md`}
        key={block.id}
      >
        <div
          className={`hover:bg-gray-300 text-xl flex-row flex my-1 rounded-md cursor-pointer w-full`}
          onClick={() => setBlockToUpdate(block)}
        >
          {rowElement(block.name, isActive)}
          {rowElement(BlockType[block.type!], isActive)}
          {rowElement(block.heading, isActive)}
          {rowElement(block.subHeading, isActive)}
        </div>
        <div>
          <Switch
            checked={isActive}
            onChange={async () =>
              await onClickActivateAsync(block.id!, !isActive)
            }
          />
        </div>
      </div>
    );
  }

  const scenarioViewModal = (
    <Modal
      isMenuOpen={isModalOpen}
      position={'fixedCenter'}
      size="3xl"
      overlay
      className="rounded-xl pt-8 px-6 overflow-y-auto"
      styles={{ maxHeight: '60vh', minHeight: '550px' }}
    >
      {isModalOpen && isLoading ? (
        <div className="mx-40 my-64">
          <Spinner size="xl" />
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="text-left font-bold text-2xl mb-4">
            {ScenarioType[scenarioType]}
          </span>

          {blocks && blocks.length > 0 && (
            <div>{blocks.map((b) => renderBlock(b))}</div>
          )}
          <div className="flex absolute bottom-6 left-72">
            <div
              className="cursor-pointer bg-primaryTeal flex-row flex w-48 pl-10 py-2 rounded-lg text-white text-xl"
              onClick={() => setIsBlockCreateOpen(true)}
            >
              <span>Add Block</span> <BiPlus size={26} className="ml-2" />
            </div>
            <div
              className="cursor-pointer bg-error1 flex-row flex w-48 pl-14 py-2 rounded-lg text-white text-xl ml-4"
              onClick={() => setScenarioType(ScenarioType.None)}
            >
              <span>Close</span> <MdClose size={26} className="ml-4" />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );

  const blockUpdateModal = (
    <Modal
      isMenuOpen={blockToUpdate !== undefined}
      overlay
      position="fixed"
      size="3xl"
      className="rounded-xl right-1/4 top-10"
      styles={{ maxHeight: '90vh', minHeight: '550px' }}
    >
      <BlockManage
        title={'Update block'}
        key={blockToUpdate?.id || ''}
        block={blockToUpdate}
        setIsModalOpen={() => {}}
        onClickUpdateSubmit={handleUpdateBlockAsync}
        isBlockPage={true}
        onClickCloseUpdateModal={() => setBlockToUpdate(undefined)}
        isScenario={true}
      />
    </Modal>
  );

  const blockCreateModal = (
    <Modal
      isMenuOpen={isBlockCreateOpen}
      overlay
      position="fixed"
      size="3xl"
      className="rounded-xl right-1/4 top-10"
      styles={{ maxHeight: '90vh', minHeight: '550px' }}
    >
      <BlockManage
        title={'Create block'}
        onCreateDiscoverBlock={handleCreateBlockAsync}
        setIsModalOpen={setIsBlockCreateOpen}
        isBlockPage={true}
        onClickCloseUpdateModal={() => setIsBlockCreateOpen(false)}
        isScenario={true}
      />
    </Modal>
  );

  return (
    <div className="flex flex-col my-auto">
      {scenarioViewModal}
      {blockUpdateModal}
      {blockCreateModal}
      <div className="flex flex-row mx-auto">
        {scenarioImage(Morning, ScenarioType.Morning)}
        {scenarioImage(Afternoon, ScenarioType.Afternoon)}
        {scenarioImage(Evening, ScenarioType.Evening)}
      </div>
      <div className="flex flex-row mx-auto">
        {scenarioImage(Sunny, ScenarioType.Sunny)}
        {scenarioImage(Raining, ScenarioType.Raining)}
      </div>
    </div>
  );
}
