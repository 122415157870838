import { IBugReport } from 'Colugo/interfaces/feedback/IBugReport';
import { ISuggestion } from 'Colugo/interfaces/feedback/ISuggestion';
import { ISurveyResponse } from 'Colugo/interfaces/feedback/ISurveyResponse';
import { ISurveyResponseSummary } from 'Colugo/interfaces/feedback/ISurveyResponseSummary';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class FeedbackOperations extends AbstractOperations {
  protected basePath: string = 'feedback';

  public async deleteSurveyAsync(surveyId: string): requestResponse {
    return await this.executeDelete(`surveyResponse/${surveyId}`);
  }

  public async deleteBugReportAsync(bugReportId: string): requestResponse {
    return await this.executeDelete(`bugReport/${bugReportId}`);
  }

  public async deleteSuggestionAsync(suggestionId: string): requestResponse {
    return await this.executeDelete(`suggestion/${suggestionId}`);
  }
}

const useReqGetSurveyResponses = (): swrRequestResponse<ISurveyResponse[]> => {
  return useGetHttpClient(
    `feedback/surveyResponse/list`,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

const useReqGetBugReports = (): swrRequestResponse<IBugReport[]> => {
  return useGetHttpClient(
    `feedback/bugReport/list`,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

const useReqGetSuggestions = (): swrRequestResponse<ISuggestion[]> => {
  return useGetHttpClient(
    `feedback/suggestion/list`,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

const useReqGetSurveyResponseSummary =
  (): swrRequestResponse<ISurveyResponseSummary> => {
    return useGetHttpClient(
      `feedback/surveyResponse/summary`,
      {
        revalidateOnFocus: true
      },
      false,
      true
    );
  };
export default FeedbackOperations;
export {
  useReqGetBugReports,
  useReqGetSuggestions,
  useReqGetSurveyResponseSummary,
  useReqGetSurveyResponses
};
