import React, { useState } from 'react';
import { Button } from '@bindystreet/bindystreet.kit.react';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { BiPencil } from 'react-icons/bi';
import BlocksContainer from '../blocks/BlocksContainer';
import SubPageCreate from './SubPageCreate';
import DiscoverSubPageTable, {
  discoverSubPageTableColumns
} from './SubPageTable';
import { ContentType } from 'Colugo/interfaces/lobby/discover/enums/ContentType';
import { SubPageType } from 'Colugo/interfaces/lobby/discover/enums';

type Props = {
  type: SubPageType;
  discoverSubPages: IDiscoverSubPage[];
  onUpdateSubpage: (item: IDiscoverSubPage) => Promise<boolean>;
  onCreateSubPage: (
    discoverGenericContent: IDiscoverSubPage
  ) => Promise<boolean>;
  onConfirmDeleteSubPage: (discoverSubPage: string) => Promise<boolean>;
  onChangeSubPageOrder: (
    subPageId: string,
    position: number
  ) => Promise<boolean>;
};

function SubPageEditor(props: Props) {
  const {
    type,
    discoverSubPages,
    onUpdateSubpage,
    onChangeSubPageOrder,
    onCreateSubPage,
    onConfirmDeleteSubPage
  } = props;

  const [discoverSubPageToManage, setDiscoverSubPageToManage] = useState<
    undefined | IDiscoverSubPage
  >(undefined);
  const [selectedDiscoverSubPageId, setSelectedDiscoverSubPageId] = useState<
    undefined | string
  >(undefined);
  const [discoverSubPageIdToDelete, setDiscoverSubPageIdToDelete] = useState<
    undefined | string
  >(undefined);

  const [isAddDiscoverPageItemModalOpen, setIsAddDiscoverPageItemModalOpen] =
    useState<boolean>(false);

  const columns = React.useMemo(() => discoverSubPageTableColumns, []);

  const selectedDiscoverSubPage =
    discoverSubPages &&
    discoverSubPages.find(
      (subPage) => subPage.id === selectedDiscoverSubPageId
    );

  async function handleOnChangeIsTourist() {
    if (!selectedDiscoverSubPage) {
      return;
    }
    const newContentType =
      selectedDiscoverSubPage.contentType === ContentType.Tourist
        ? ContentType.Local
        : ContentType.Tourist;

    setDiscoverSubPageToManage({
      ...selectedDiscoverSubPage,
      contentType: newContentType
    });

    const isSucessful = await onUpdateSubpage({
      ...selectedDiscoverSubPage,
      contentType: newContentType
    });

    if (!isSucessful) {
      setDiscoverSubPageToManage(selectedDiscoverSubPage);
    }
  }

  const openCreateSubPageModal = (
    <Button
      className="z-50 absolute ml-4 mt-2 w-22"
      size="md"
      onClick={() =>
        setIsAddDiscoverPageItemModalOpen(!isAddDiscoverPageItemModalOpen)
      }
    >
      <div className="flex flex-row">
        <BiPencil
          size="20"
          className="absolute top-0 bottom-0 left-0 m-auto cursor-pointer text-orange-500 ml-1"
        />
        <div className="ml-4">{'Create'}</div>
      </div>
    </Button>
  );

  const deleteDiscoverPageEditor = (
    <ConfirmDeletePopup
      isModalOpen={!!discoverSubPageIdToDelete}
      onSubmit={() => {
        onConfirmDeleteSubPage(selectedDiscoverSubPage?.id!);
        setDiscoverSubPageIdToDelete(undefined);
      }}
      skin="discoverSubPage"
      title={selectedDiscoverSubPage?.name || 'Unnamed'}
      closeModal={() => setDiscoverSubPageIdToDelete(undefined)}
    />
  );

  discoverSubPages.map((s) => {
    s.name = s.category ? s.category.name : s.name;
    s.label = s.category ? 'Category Sub Page' : 'Custom Sub Page';
    return s;
  });

  return (
    <div className="w-full">
      {!!discoverSubPageIdToDelete && deleteDiscoverPageEditor}
      <SubPageCreate
        type={type}
        onCreateDiscoverSubPage={onCreateSubPage}
        isModalOpen={isAddDiscoverPageItemModalOpen}
        setIsModalOpen={setIsAddDiscoverPageItemModalOpen}
      />
      <div className="flex flex-row">
        <div className="px-12 py-8 h-full w-full">
          <DiscoverSubPageTable
            onClickManageIcon={setDiscoverSubPageToManage}
            children={openCreateSubPageModal}
            onClickRow={setSelectedDiscoverSubPageId}
            tableColumns={columns}
            discoverSubPages={discoverSubPages!.sort((a, b) =>
              a.order! > b.order! ? 1 : -1
            )}
            rowSelectedId={selectedDiscoverSubPageId}
            onChangeOrder={onChangeSubPageOrder}
            onClickDeleteIcon={setDiscoverSubPageIdToDelete}
          />
        </div>
        <div className="mx-auto pt-24 px-12 max-w-xl w-full">
          {discoverSubPageToManage && (
            <BlocksContainer
              subPage={discoverSubPageToManage}
              handleOnChangeIsTourist={handleOnChangeIsTourist}
              blocks={discoverSubPageToManage?.blocks}
              setIsBlockContainerOpen={() =>
                setDiscoverSubPageToManage(undefined)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SubPageEditor;
