import React, { useState } from 'react';
import {
  Button,
  CardHeader,
  Input,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { MdClose } from 'react-icons/md';

type Props = {
  title: string;
  minCharacterCount: number;
  submitButtonText: string;
  onSubmit: (name: string) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

function CreateModal(props: Props) {
  const {
    onSubmit,
    isModalOpen,
    title,
    minCharacterCount,
    setIsModalOpen,
    submitButtonText
  } = props;

  const [newName, setNewName] = useState('');

  const submitEnabled =
    newName.length >= minCharacterCount && newName.length <= 40;

  function handleKeyPress(event) {
    if (event.key === 'Enter' && submitEnabled) {
      onSubmit(newName);
      setNewName('');
    }
  }

  return (
    <Modal
      isMenuOpen={isModalOpen}
      className="overflow-x-visible"
      position={'center'}
      size="lg"
      overlay
      zIndex={51}
    >
      <CardHeader title={title} fontWeight="font-semibold" />
      <div className="text-left mx-4 mt-4">
        <span className="font-semibold">{'Name:'}</span>
        <Input
          autoFocus={true}
          onKeyDown={handleKeyPress}
          defaultValue={newName}
          onChange={(e) => setNewName(e.currentTarget.value)}
        />
        {!submitEnabled && (
          <span className="block mx-auto text-xs italic">
            {`Name must be between ${minCharacterCount}  and 40 characters`}
          </span>
        )}
        <div className="flex flex-row mt-4 justify-evenly">
          <Button
            onClick={() => {
              onSubmit(newName);
              setNewName('');
            }}
            disabled={!submitEnabled}
          >
            {submitButtonText}
          </Button>
          <Button onClick={() => setIsModalOpen(false)} skin="secondary">
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CreateModal;
