import { ITrailVersion } from 'Colugo/interfaces/games';
import { ITrailMapSpot } from 'Colugo/interfaces/games/ITrailVersion';
import { Lifecycle, scoped } from 'tsyringe';
import { IOrderedItem } from '../../interfaces/common';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class TrailOperations extends AbstractOperations {
  protected basePath: string = 'trail';

  public async createTrailMapSpotAsync(
    trailVersionId: string,
    mapSpotId: string,
    trailMapSpot?: ITrailMapSpot
  ): requestResponse<ITrailMapSpot> {
    const trailMapSpotToCreate = {
      ...trailMapSpot,
      mapSpotId,
      trailVersionId
    };

    return await this.executePost(
      `version/${trailVersionId}/mapSpot/${mapSpotId}/trailMapSpot`,
      trailMapSpotToCreate
    );
  }

  public async deleteTrailMapSpotAsync(
    trailMapSpotId: string
  ): requestResponse {
    return await this.executeDelete(`/trailMapSpot/${trailMapSpotId}`);
  }

  public async updateOrderAsync(
    gameVersionId: string,
    trailVersionId: string,
    position: number
  ): requestResponse<IOrderedItem[]> {
    return await this.executePut(
      `/game/version/${gameVersionId}/trail/version/${trailVersionId}/position/${position}`
    );
  }

  public async createAsync(
    trail: ITrailVersion
  ): requestResponse<ITrailVersion> {
    return await this.executePost(
      `/game/version/${trail.gameVersion?.id}/trail/version`,
      trail
    );
  }

  public async deleteAsync(trailVersionId: string) {
    return await this.executeDelete(`version/${trailVersionId}`);
  }
}

export default TrailOperations;
