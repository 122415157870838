import { IOrderedItem } from 'Colugo/interfaces/common';
import {
  IOperationWithUpdatedModulesRequest,
  IOperationWithUpdatedModulesResponse,
  IZoneVersion
} from 'Colugo/interfaces/games';
import AbstractOperations from 'Colugo/operations/AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
class ZoneOperations extends AbstractOperations {
  protected basePath: string = 'zone';

  public async createAsync(
    zoneVersion: IZoneVersion,
    operationWithUpdatedModulesRequest: IOperationWithUpdatedModulesRequest
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executePost(
      `/game/version/${zoneVersion.gameVersion!.id}/zone/version`,
      operationWithUpdatedModulesRequest,
      undefined,
      true
    );
  }

  public async listByGameAsync(
    gameId: string
  ): requestResponse<IZoneVersion[]> {
    return await this.executeGet(`listByGame/${gameId}`);
  }

  public async getLiveVersionAsync(
    zoneId: string
  ): requestResponse<IZoneVersion> {
    return await this.executeGet(`${zoneId}/liveVersion`);
  }

  public async listByNameAsync(query: string): requestResponse<IZoneVersion[]> {
    return await this.executeGet(
      `listByName?query=${encodeURIComponent(query)}`
    );
  }

  public async updateOrderAsync(
    gameVersionId: string,
    zoneVersionId: string,
    position: number
  ): requestResponse<IOrderedItem[]> {
    return await this.executePut(
      `/game/version/${gameVersionId}/zone/version/${zoneVersionId}/position/${position}`
    );
  }

  public async deleteAsync(
    zoneVersionId: string,
    operationWithUpdatedModulesRequest: IOperationWithUpdatedModulesRequest
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executeDelete(
      `version/${zoneVersionId}`,
      operationWithUpdatedModulesRequest,
      undefined,
      true
    );
  }
}

export default ZoneOperations;
