import { ICategory } from 'Colugo/interfaces/common';
import IBase from 'Colugo/interfaces/IBase';
import {
  BlockType,
  CardSize,
  Layout,
  ContentType
} from 'Colugo/interfaces/lobby/discover/enums';
import { BlockEntityType } from '../enums/EntityType';
import { ActionType } from '../enums/ActionType';
import { ScenarioType } from '../enums/ScenarioType';
import { IVideo } from 'Colugo/interfaces/video/IVideo';

export interface IBlock extends IBase {
  type?: BlockType;
  name: string;
  id?: string;
  order?: number;
  layout?: Layout;
  cardSize?: CardSize;
  count?: number;
  gameId?: string;
  tagIds?: string[];
  zoneId?: string;
  freeFrequency?: number;
  isLocationBased?: boolean;
  contentType?: ContentType;
  category?: ICategory;
  linkMetadata?: ILinkMetadata;
  entityType?: BlockEntityType;
  actionType?: ActionType;
  scenarioType?: ScenarioType;
  heading?: string;
  subHeading?: string;
  video?: IVideo;
}

export interface ILinkMetadata {
  type?: CtaBannerType;
  title?: string;
  topic?: string;
  description?: string;
  linkText?: string;
  cardColour?: string;
  textColour?: string;
  tagIds?: string[];
  routeUrl?: string;
  image?: string;
  animationType?: AnimationType;
  mediaType?: CtaBannerMediaType | EditorialBlockMediaType;
}

export enum CtaBannerType {
  None = 0,
  App = 1,
  Search = 2
}

export enum AnimationType {
  None = 0,
  Bowling = 1,
  Ramen = 2,
  Burger = 3,
  Books = 4
}

export enum CtaBannerMediaType {
  None = 0,
  Image = 1,
  Animation = 2
}

export enum EditorialBlockMediaType {
  None = 0,
  Image = 1,
  Video = 2
}
