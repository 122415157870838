import { IPlayer } from 'Colugo/interfaces/identity';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';

class PlayerOperations extends AbstractOperations {
  protected basePath: string = 'player';

  public async updateEssenceAsync(
    updateEssenceBy: number,
    playerId: string
  ): requestResponse {
    return await this.executePut(`${playerId}/essence`, updateEssenceBy);
  }
}

const useListPlayersByEmail = (
  query?: string
): swrRequestResponse<IPlayer[]> => {
  return useGetHttpClient(
    query ? `player/listByEmail?startsWith=${query}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default PlayerOperations;
export { useListPlayersByEmail };
