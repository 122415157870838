import { ITag } from 'Colugo/interfaces/tags';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class TagOperations extends AbstractOperations {
  protected basePath: string = 'tag';

  public async addSpotVersion(spotId: string, tagId: string): requestResponse {
    return await this.executePost(`${tagId}/spot/${spotId}`);
  }

  public async removeSpotVersion(
    spotId: string,
    tagId: string
  ): requestResponse {
    return await this.executeDelete(`${tagId}/spot/${spotId}`);
  }

  public async create(tag: ITag): requestResponse<ITag> {
    return await this.executePost('', tag);
  }

  public async addGameVersion(
    gameVersionId: string,
    tagId: string
  ): requestResponse {
    return await this.executePost(`${tagId}/gameVersion/${gameVersionId}`);
  }

  public async removeGameVersion(
    gameVersionId: string,
    tagId: string
  ): requestResponse {
    return await this.executeDelete(`${tagId}/gameVersion/${gameVersionId}`);
  }
}

const useReqListTags = (): swrRequestResponse<ITag[]> => {
  return useGetHttpClient(
    `tag/list`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListTagsByFilterGroup = (
  filterGroupId: string
): swrRequestResponse<ITag[]> => {
  return useGetHttpClient(
    filterGroupId ? `tag/listByFilterGroup/${filterGroupId}?take=100` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export { useReqListTags, useReqListTagsByFilterGroup };
export default TagOperations;
