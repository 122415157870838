import { SubPageType } from 'Colugo/interfaces/lobby/discover/enums';
import SubPagesContainer from '../discover/subPage/SubPagesContainer';

function ExploreContainer() {
  return (
    <SubPagesContainer
      type={SubPageType.Explore}
      title="Explore Screen Management"
    />
  );
}

export default ExploreContainer;
