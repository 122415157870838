import { IOrderedItem } from 'Colugo/interfaces/common';
import { requestResponse } from 'provider/httpClient';
import { toast } from 'react-toastify';

export const handleSetOrderableItems = async <TItem extends IOrderedItem>(
  handleUpdateOrderRequest: () => requestResponse<IOrderedItem[]>,
  items: TItem[],
  setItems: (items: TItem[]) => void
) => {
  const { data: orderedItems, error } = await handleUpdateOrderRequest();
  if (error || !orderedItems) {
    toast.error(
      'Unable to update order. Please try again. If the issue persists refresh the page.'
    );
    return false;
  }

  let newItems: TItem[] = [];
  for (let item of items || []) {
    let orderedItem = orderedItems!.find(
      (otherItem) => otherItem.id === item.id
    );
    item.order = orderedItem!.order;
    item.updatedAt = orderedItem!.updatedAt;
    newItems.push(item);
  }

  setItems(newItems);
  return true;
};
