import {
  Button,
  Input,
  InputKeyboardEvent,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import {
  AdvertLocationType,
  IAdvertLocation
} from 'Colugo/interfaces/advertisement/IAdvertLocation';
import AdvertOperations from 'Colugo/operations/advertisement/AdvertOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const advertLocationOperations = container.resolve(AdvertOperations);

type Props = {
  advertLocationToUpdate: IAdvertLocation | undefined;
  setAdvertLocationToUpdate: (
    advertLocationToUpdate: IAdvertLocation | undefined
  ) => void;
  screenLocations: IAdvertLocation[] | undefined;
  mutateScreenLocations: (
    data?: IAdvertLocation[],
    shouldRevalidate?: boolean
  ) => Promise<IAdvertLocation[] | undefined>;
};

function AdvertLocationModal(props: Props) {
  const {
    advertLocationToUpdate,
    setAdvertLocationToUpdate,
    screenLocations,
    mutateScreenLocations
  } = props;

  function getEnumValue(index: string | number) {
    return (
      advertLocationToUpdate &&
      EnumHelper.getEnumValuesForFlyout(AdvertLocationType)[index].value
    );
  }

  async function onUpdateAdvertLocationAsync(
    advertLocationToUpdate: IAdvertLocation
  ) {
    const { error } = await advertLocationOperations.updateAdvertLocationAsync(
      advertLocationToUpdate
    );

    if (error) {
      toast.error('Failed to update advert location, please try again');
      return false;
    }

    const newAdvertLocation =
      screenLocations &&
      screenLocations.map((mscl) =>
        mscl.id === advertLocationToUpdate.id ? advertLocationToUpdate : mscl
      );

    toast.success('Advert Location detail successfully updated');
    mutateScreenLocations(newAdvertLocation, true);
  }

  async function handleKeyPressAsync(
    event: InputKeyboardEvent,
    advertLocationToUpdate: IAdvertLocation
  ) {
    if (event.key === 'Enter') {
      await onUpdateAdvertLocationAsync(advertLocationToUpdate);
      setAdvertLocationToUpdate(undefined);
    }
  }

  return (
    <Modal
      overlay
      isMenuOpen={!!advertLocationToUpdate}
      position="fixed"
      className="rounded-xl top-20"
      styles={{
        minHeight: '300px',
        width: '65vw',
        marginRight: '20%'
      }}
    >
      <div>
        <div className="h-16 -mt-1 rounded-md justify-center flex items-center font-bold text-xl font-inter bg-theme3">
          {`Update 
              ${getEnumValue(advertLocationToUpdate?.type!)} Information`}
        </div>
        <div className="flex flex-col mb-4 justify-start items-start px-5 mt-4">
          <div className="font-inter text-lg font-bold">Advertisements</div>
          <div className="font-inter text-sm mt-2 font-bold">
            Free Frequency
            <span className="font-inter text-sm font-normal ml-1">
              (every 'x' card)
            </span>
          </div>
          <div>
            <Input
              className="w-36"
              value={advertLocationToUpdate?.freeFrequency?.toString() || ''}
              onChange={(e) =>
                setAdvertLocationToUpdate({
                  ...advertLocationToUpdate!,
                  freeFrequency: parseInt(e.currentTarget.value, 10) || 0
                })
              }
              onKeyDown={(e) => handleKeyPressAsync(e, advertLocationToUpdate!)}
            />
          </div>
        </div>
        <div className="flex flex-row mt-6 justify-evenly">
          <Button
            className="px-6"
            onClick={async () => {
              await onUpdateAdvertLocationAsync(advertLocationToUpdate!);
              setAdvertLocationToUpdate(undefined);
            }}
          >
            Submit
          </Button>
          <Button
            onClick={() => setAdvertLocationToUpdate(undefined)}
            skin="secondary"
          >
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AdvertLocationModal;
