import AbstractOperations from 'Colugo/operations/AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';

class PostOperations extends AbstractOperations {
  protected basePath: string = 'social/post';

  public async deleteAsync(postId: string): requestResponse {
    return await this.executeDelete(postId);
  }
}

export default PostOperations;
