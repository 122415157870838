import HttpClient, { requestResponse } from './HttpClient';
import ky, { Options } from 'ky';
import {
  BUDGIE_API_URL,
  newTokenHeaderKeyValue,
  budgieUserErrorCode
} from '../utility/constants/constants';
import localStore from '../utility/general/localStore';

const emptyHttpClient = ky.create({});
const httpClient = new HttpClient();

class VerbHttpClient extends HttpClient {
  public async verbHttpClient(
    verb: 'post' | 'put' | 'get' | 'delete',
    url: string,
    body: any = undefined,
    options: Options = {},
    fullyTyped: boolean = false,
    client?: any
  ): requestResponse {
    if (fullyTyped) {
      options = {
        ...options,
        headers: {
          ...options?.headers,
          JsonSettings: 'FullyTyped',
          TransformableImage: 'False'
        }
      };
    }

    const httpClient = client || this.budgieHttpClientFactory();
    options.body = JSON.stringify(body);
    if (!client) {
      options.headers = {
        ...options.headers,
        'content-type': 'application/json'
      };
    }

    let response: Response | undefined = undefined;
    let data: any;
    try {
      switch (verb) {
        case 'post':
          response = await httpClient.post(url, options);
          break;
        case 'get':
          response = await httpClient.get(url, options);
          break;
        case 'delete':
          response = await httpClient.delete(url, options);
          break;
        case 'put':
          response = await httpClient.put(url, options);
          break;
      }
      this.checkHeaders(this.getHeaders(response!));
      data = await this.parseJson(response!);
    } catch (error) {
      if (response && response.ok) {
        console.error(error);
        return { error: undefined, data: {}, errorMessage: undefined };
      }
      this.checkHeaders(this.getHeaders((error as any)?.response));
      return this.handleErrors(error);
    }
    return { data, error: undefined, errorMessage: undefined };
  }

  public async postHttpClient(
    url: string,
    body: any = undefined,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await this.verbHttpClient('post', url, body, options, fullyTyped);
  }

  public async getHttpClient(
    url: string,
    options: Options = {},
    fullyTyped: boolean = false,
    noPrefixUrl: boolean = false
  ) {
    return await this.verbHttpClient(
      'get',
      url,
      undefined,
      options,
      fullyTyped,
      noPrefixUrl && emptyHttpClient
    );
  }

  public async putHttpClient(
    url: string,
    body: any = undefined,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await this.verbHttpClient('put', url, body, options, fullyTyped);
  }

  public async deleteHttpClient(
    url: string,
    body: any = undefined,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await this.verbHttpClient('delete', url, body, options, fullyTyped);
  }

  public async handleErrors(err) {
    let response: Response = err.response || {};

    let body;
    try {
      body = await response.json();
    } catch (error) {
      body = undefined;
      console.error(error);
    }

    if (body && body.message) {
      return { error: response, errorMessage: body.message, data: undefined };
    }

    return { error: response, data: undefined, errorMessage: undefined };
  }

  // Used solely for progress event during a request.
  public requestXHR = (
    method: 'POST' | 'GET' | 'PUT',
    url: string,
    file: File,
    onProgressUpdate?: (progress: number) => void
  ): Promise<{ error?: string; data: any }> => {
    const token = localStore.getItem('token');
    return new Promise(function (resolve, reject) {
      const formData = new FormData();
      formData.append('file', file);
      let xhr = new XMLHttpRequest();

      xhr.open(method, BUDGIE_API_URL + url);
      xhr.setRequestHeader('Authorization', `bearer ${token}`);
      xhr.withCredentials = false;

      if (onProgressUpdate) {
        xhr.upload.onprogress = (event) => {
          const percentage = (event.loaded / event.total) * 100;
          onProgressUpdate(percentage);
        };
      }
      xhr.onload = function () {
        if (this.readyState === this.HEADERS_RECEIVED) {
          xhr.getResponseHeader(newTokenHeaderKeyValue) &&
            httpClient.checkHeaders({
              NewTokenHeaderValue: xhr.getResponseHeader(
                newTokenHeaderKeyValue
              ),
              BudgieUserErrorCode: xhr.getResponseHeader(budgieUserErrorCode)
            });
        }
        if (this.status >= 200 && this.status < 300) {
          resolve({
            data: JSON.parse(xhr.response),
            error: undefined
          });
        } else {
          resolve({
            data: undefined,
            error: xhr.status.toString()
          });
        }
      };
      xhr.onerror = function () {
        resolve({
          data: undefined,
          error: xhr.status.toString()
        });
      };
      xhr.send(formData);
    });
  };
}

export default VerbHttpClient;
