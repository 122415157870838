import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import React, { useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { GiSecurityGate } from 'react-icons/gi';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<IUserReport>[];
  userReports: IUserReport[];
  isMutationLoading?: boolean;
  onClickModerateIcon: (userReportId: string) => void;
  onClickEditIcon: (userReportId: string) => void;
};

function UserReportTable(props: Props) {
  const { userReports, tableColumns, onClickModerateIcon, onClickEditIcon } =
    props;
  const [rowHovered, setRowHovered] = useState(-1);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: userReports
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useFlexLayout
    );

  const table = (
    <div className="overflow-y-auto p-2">
      <div {...getTableProps()} className="w-full">
        <div>{userReportTableHeaderConfig(headerGroups)}</div>
        <div>
          {userReportTableRowsConfig(
            rowHovered,
            setRowHovered,
            getTableBodyProps,
            rows,
            prepareRow,
            onClickModerateIcon,
            onClickEditIcon
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="mt-10">{table}</div>
    </div>
  );
}

export default UserReportTable;

const userReportTableHeaderConfig = (
  headerGroups: HeaderGroup<IUserReport>[]
) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: '300px' }}
            className="w-full ml-4"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>{column.render('Header')}</div>
              <div>
                {column.Header!.toString() === '' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const userReportTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IUserReport> | undefined
  ) => TableBodyProps,
  rows: Row<IUserReport>[],
  prepareRow: (row: Row<IUserReport>) => void,
  onClickDeleteIcon: (userReportId: string) => void,
  onClickEditIcon: (userReportId: string) => void
) => (
  <div
    style={{ height: `calc(${getMaxViewHeight()}` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      let bgColor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      const entityDeleted = !!row.cells.find(
        (c) => c.column.id.indexOf('deletedAt') > 0
      )?.value;
      bgColor = entityDeleted ? 'bg-alert2' : bgColor;
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap relative rounded-md ${bgColor} hover:shadow-lg`}
          onMouseEnter={() => setRowHovered(i)}
          key={i}
        >
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'my-1 text-left';
            if (index !== row.cells.length - 1) {
              classNames += ' text-center';
            } else if (rowHovered !== i) {
              return <div {...cell.getCellProps()}></div>;
            }
            if (cell.column.Header?.toString() === '') {
              if (cell.column.id === 'id') {
                return (
                  <div key={index} className="flex flex-row">
                    <BsCheckLg
                      className={`flex text-4xl cursor-pointer mx-4 mt-2 text-blue-800`}
                      onClick={() => {
                        onClickDeleteIcon(cell.value);
                      }}
                    />
                    <GiSecurityGate
                      className={`flex text-4xl cursor-pointer mx-4 mt-2 text-uniqueButtonPressed`}
                      onClick={() => {
                        onClickEditIcon(cell.value);
                      }}
                    />
                  </div>
                );
              }
              return (
                <div key={index} className="my-auto text-error1 font-bold">
                  {' '}
                  {entityDeleted ? 'DELETED' : ''}{' '}
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} my-auto ${
                  cell.column.Header!.toString() !== 'Image' && 'ml-6'
                }`}
                style={{
                  width: `${
                    cell.column.Header!.toString() === 'Image'
                      ? '120px'
                      : '200px'
                  }`
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
