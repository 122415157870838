import React, { useEffect, useState } from 'react';
import {
  Button,
  CardHeader,
  FlyoutMenu,
  Input,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import { ICategory } from 'Colugo/interfaces/common';
import { ITag } from 'Colugo/interfaces/tags/ITag';
import { isEqual, range } from 'lodash';
import CategoryTagTable, { categoryTagTableColumns } from './CategoryTagTable';

type Props = {
  category: ICategory;
  categoryCount: number;
  onClickUpdateSubmit: (category: ICategory) => void;
  onChangeCategoryOrder: (
    category: ICategory,
    position: number
  ) => Promise<ICategory | undefined>;
  onAddTagToCategory: (
    tag: ITag,
    categoryId: string,
    isAdded: boolean
  ) => Promise<boolean>;
  tags: ITag[];
};

const CategoryUpdateForm: React.FC<Props> = (props) => {
  const {
    category,
    categoryCount,
    onClickUpdateSubmit,
    onChangeCategoryOrder,
    onAddTagToCategory,
    tags
  } = props;
  const columns = React.useMemo(() => categoryTagTableColumns, []);

  const [localCategory, setLocalCategory] = useState(category);
  const [isOrderSelectOpen, setIsOrderSelectOpen] = useState(false);

  const [localTags, setLocalTags] = useState<ITag[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const orderOptions: FlyoutMenuItem[] = range(categoryCount).map((val) => {
    return { label: val.toString(), value: val };
  });

  const submitForUpdating = () => {
    onClickUpdateSubmit(localCategory);
  };

  const submitEnabled =
    localCategory.name && localCategory.key && localCategory.colour;

  const handleToggleTagInCategory = async (tagId: string, isAdded: boolean) => {
    const selectedTag = tags.find((tag) => tag?.id === tagId);
    await onAddTagToCategory(selectedTag!, category.id!, isAdded);
    setIsUpdate(!isUpdate);
  };

  useEffect(() => {
    setLocalTags(
      tags.map((tag) => {
        tag.isInCategory = category.tags!.some((t) => t.id === tag.id);
        return tag;
      })
    );
  }, [category.tags, tags, isUpdate]);

  return (
    <div className="flex flex-row w-full ml-10">
      <div>
        <CardHeader
          title={'Update Category Information'}
          fontWeight="font-semibold"
        />
        <div className="w-96 text-left mx-4 mt-4">
          <span className="font-semibold">{'Category Name:'}</span>
          <Input
            value={localCategory.name}
            onChange={(e) =>
              setLocalCategory({
                ...localCategory,
                name: e.currentTarget.value
              })
            }
          />
          <span className="font-semibold">{'Category Key:'}</span>
          <Input
            value={localCategory.key}
            onChange={(e) =>
              setLocalCategory({
                ...localCategory,
                key: e.currentTarget.value
              })
            }
          />
          <span className="font-semibold">{'Category Colour:'}</span>
          <Input
            value={localCategory.colour}
            onChange={(e) =>
              setLocalCategory({
                ...localCategory,
                colour: e.currentTarget.value
              })
            }
          />
          {/* TODO: Finish updating for categories to set size/amt and display this form on the page. */}
          {/* Will need to do backend update as well  */}
          {/* Flow for custom groups? will need update + games, use button toggle maybe*/}
          <div className="flex flex-row mt-4 mr-auto">
            <Button onClick={submitForUpdating} disabled={!submitEnabled}>
              {'Submit'}
            </Button>
          </div>
        </div>
        <div className="mt-10">
          <CardHeader
            title={'Update Category Order'}
            fontWeight="font-semibold"
            subtitle="This determines the category order at the top of the Discover page"
          />
          <div className="my-4 text-left">
            <span className="font-semibold">{'Category Order:'}</span>
            <div className="my-2">
              <FlyoutMenu
                activeLabel={localCategory.order?.toString()}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isOrderSelectOpen}
                setIsMenuOpen={setIsOrderSelectOpen}
                items={orderOptions}
                onChange={(e) => onChangeCategoryOrder(localCategory, e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ml-10 w-80">
        <CategoryTagTable
          onClickToggleIcon={handleToggleTagInCategory}
          tableColumns={columns}
          tags={localTags}
          categoryId={category.id!}
        />
      </div>
    </div>
  );
};

export default CategoryUpdateForm;
