import {
  Button,
  CardHeader,
  ErrorPage,
  FlyoutMenu,
  Input,
  Modal,
  Spinner,
  FlyoutMenuItem
} from '@bindystreet/bindystreet.kit.react';
import { ISpiritCustomization } from 'Colugo/interfaces/spirits';
import { SpiritCustomizationType } from 'Colugo/interfaces/spirits/enums';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';

type Props = {
  title: string;
  submitButtonText: string;
  onSubmitAsync: (
    localSpiritCustomization: ISpiritCustomization
  ) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  spiritCustomization?: ISpiritCustomization;
};

function SpiritCustomizationModal(props: Props) {
  const {
    onSubmitAsync,
    isModalOpen,
    title,
    setIsModalOpen,
    submitButtonText,
    spiritCustomization = {
      name: '',
      description: '',
      price: 0,
      type: SpiritCustomizationType.Head,
      colours: [],
      key: ''
    }
  } = props;

  const [isSelectTypeOpen, setIsSelectTypeOpen] = useState(false);
  const [isCategorySelectOpen, setIsCategorySelectOpen] = useState(false);
  const [localSpiritCustomization, setLocalSpiritCustomization] =
    useState<ISpiritCustomization>(spiritCustomization);
  const [selectedCustomizationTypeLabel, setSelectedCustomizationLabel] =
    useState<string | undefined>(undefined);

  const { data: categories, isLoading, isError } = useReqListCategories();

  if (isLoading || !categories) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load spiritCustomizations from server.'}</span>
      </ErrorPage>
    );
  }

  let categoryFlyoutMenuItems: FlyoutMenuItem[] = [
    { label: 'No item selected' }
  ];
  categoryFlyoutMenuItems = categoryFlyoutMenuItems.concat(
    categories.map((category) => {
      return { label: category.name, value: category.id };
    })
  );

  const isNameValid =
    localSpiritCustomization.name.length >= 5 &&
    localSpiritCustomization.name.length <= 25;

  const isDescriptionValid =
    localSpiritCustomization.description.length >= 5 &&
    localSpiritCustomization.description.length <= 90;

  const isSubmitEnabled = isNameValid && isDescriptionValid;

  const hexRegex = /^#?[0-9a-fA-F]{0,6}$/;

  const handleSetColour = (colourIndex: number, colour: string) => {
    if (hexRegex.test(colour)) {
      let spiritCustomizationColours = localSpiritCustomization.colours;
      spiritCustomizationColours![colourIndex] = colour;
      spiritCustomizationColours = spiritCustomizationColours.filter(
        (colour) => !!colour
      );
      setLocalSpiritCustomization({
        ...localSpiritCustomization,
        colours: spiritCustomizationColours
      });
    }
  };

  const numberRegex = /^[0-9]*$/;
  const handleSetPrice = (price: string) => {
    if (numberRegex.test(price)) {
      setLocalSpiritCustomization({
        ...localSpiritCustomization,
        price: Number(price)
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLocalSpiritCustomization({
      name: '',
      description: '',
      price: 0,
      type: SpiritCustomizationType.Head,
      colours: ['', ''],
      key: ''
    });
  };

  const keyInput = (
    <div>
      <div className="py-2 text-xs font-bold">KEY</div>
      <Input
        autoFocus={true}
        value={localSpiritCustomization.key}
        onChange={(e) =>
          setLocalSpiritCustomization({
            ...localSpiritCustomization,
            key: e.currentTarget.value
          })
        }
      />
    </div>
  );

  const nameInput = (
    <div>
      <div className="pt-2 text-xs font-bold">NAME</div>
      <Input
        autoFocus={true}
        value={localSpiritCustomization.name}
        onChange={(e) =>
          setLocalSpiritCustomization({
            ...localSpiritCustomization,
            name: e.currentTarget.value
          })
        }
        maxCharacterCount={25}
      />
      {!isNameValid && (
        <span className="block mx-auto text-xs italic">
          {`Name must be between 5 and 25 characters`}
        </span>
      )}
    </div>
  );

  const categorySelector = (
    <div className="cursor-pointer">
      <div className="py-2 text-xs font-bold uppercase">Category</div>
      <FlyoutMenu
        activeKey={categoryFlyoutMenuItems.findIndex(
          (c) => c.value === localSpiritCustomization.categoryId
        )}
        isEqual={isEqual}
        size="md"
        isMenuOpen={isCategorySelectOpen}
        setIsMenuOpen={setIsCategorySelectOpen}
        items={categoryFlyoutMenuItems}
        onChange={(e) =>
          setLocalSpiritCustomization({
            ...localSpiritCustomization,
            categoryId: e.value
          })
        }
      />
      <span className="block mx-auto text-xs italic mt-1">
        {`Not selecting a category adds it to the "Trendy" collection`}
      </span>
    </div>
  );

  const spiritCustomizationTypeFlyoutMenuItems =
    EnumHelper.getEnumValuesForFlyout(SpiritCustomizationType);

  const typeSelector = (
    <div className="cursor-pointer">
      <div className="py-2 text-xs font-bold">TYPE</div>
      <FlyoutMenu
        activeLabel={selectedCustomizationTypeLabel}
        isEqual={isEqual}
        size="sm"
        isMenuOpen={isSelectTypeOpen}
        setIsMenuOpen={setIsSelectTypeOpen}
        items={spiritCustomizationTypeFlyoutMenuItems}
        onChange={(e) => {
          setSelectedCustomizationLabel(e.label!);
          setLocalSpiritCustomization({
            ...localSpiritCustomization,
            type: e.value
          });
        }}
      />
    </div>
  );

  const descriptionInput = (
    <div>
      <Input
        autoFocus={true}
        value={localSpiritCustomization.description}
        onChange={(e) =>
          setLocalSpiritCustomization({
            ...localSpiritCustomization,
            description: e.currentTarget.value
          })
        }
        label="Description"
        maxCharacterCount={90}
        isMandatory={true}
        multiline
      />
      {!isDescriptionValid && (
        <span className="block mx-auto text-xs italic">
          {`Description must be between 5 and 90 characters`}
        </span>
      )}
    </div>
  );

  const priceInput = (
    <Input
      autoFocus={true}
      value={
        localSpiritCustomization.price
          ? localSpiritCustomization.price.toString()
          : '0'
      }
      onChange={(e) => handleSetPrice(e.currentTarget.value)}
      label="Price"
    />
  );

  const colourSelectors = (
    <div>
      <Input
        autoFocus={true}
        value={
          localSpiritCustomization.colours &&
          localSpiritCustomization.colours[0]
        }
        onChange={(e) => handleSetColour(0, e.currentTarget.value)}
        label="Colour 1"
      />
      <Input
        autoFocus={true}
        value={
          localSpiritCustomization.colours &&
          localSpiritCustomization.colours[1]
        }
        onChange={(e) => handleSetColour(1, e.currentTarget.value)}
        label="Colour 2"
      />
      <span className="block mx-auto text-xs italic">
        {`Hex codes must be valid (e.g. #129411)`}
      </span>
    </div>
  );

  const confirmationOptions = (
    <div className="flex flex-row my-4 justify-evenly">
      <Button
        onClick={async () => {
          await onSubmitAsync(localSpiritCustomization);
          setSelectedCustomizationLabel(undefined);
          handleCloseModal();
        }}
        disabled={!isSubmitEnabled}
      >
        {submitButtonText}
      </Button>
      <Button onClick={() => handleCloseModal()} skin="secondary">
        <MdClose className="mr-1 mt-1" />
        <div>Cancel</div>
      </Button>
    </div>
  );

  return (
    <Modal
      isMenuOpen={isModalOpen}
      position={'fixedCenter'}
      size="xl"
      overlay
      className="rounded-xl pt-8 px-6 -mt-32 -mb-8 overflow-y-scroll"
    >
      <CardHeader title={title} fontWeight="font-semibold" />
      <div className="text-left mx-4">
        {keyInput}
        {nameInput}
        {categorySelector}
        {typeSelector}
        {descriptionInput}
        {priceInput}
        {colourSelectors}
        {confirmationOptions}
      </div>
    </Modal>
  );
}

export default SpiritCustomizationModal;
