import {
  Button,
  CardHeader,
  Checkbox,
  FlyoutMenu,
  FlyoutMenuItem,
  Input,
  Spinner,
  Switch
} from '@bindystreet/bindystreet.kit.react';
import { ICategory } from 'Colugo/interfaces/common';
import RankedResultItemConverter from 'Colugo/interfaces/converters/RankedResultItemConverter';
import { IGame, ISpotVersion, IZoneVersion } from 'Colugo/interfaces/games';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import {
  CtaBannerMediaType,
  CtaBannerType,
  EditorialBlockMediaType
} from 'Colugo/interfaces/lobby/discover/blocks/IBlock';
import {
  BlockType,
  CardSize,
  ContentType,
  Layout
} from 'Colugo/interfaces/lobby/discover/enums';
import { ActionType } from 'Colugo/interfaces/lobby/discover/enums/ActionType';
import {
  ManualBlockNames,
  OmittedBlockTypes
} from 'Colugo/interfaces/lobby/discover/enums/BlockType';
import { CtaBannerCardSize } from 'Colugo/interfaces/lobby/discover/enums/CardSize';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import {
  Filter,
  ISearchRequest,
  Order
} from 'Colugo/interfaces/search/ISearchRequest';
import { ZoneOperations } from 'Colugo/operations';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';
import { useReqGetGuide } from 'Colugo/operations/guides';
import { useReqListBlocks } from 'Colugo/operations/lobby';
import SearchOperations from 'Colugo/operations/search/SearchOperations';
import { useReqListTags } from 'Colugo/operations/tags';
import { EnumHelper } from 'Colugo/utility/helpers';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { MutatorCallback } from 'swr/dist/types';
import { container } from 'tsyringe';
import BlockItemAdded from './BlockItemAdded';
import GameTable, { gameTableColumns } from './BlocksGameTable';
import CtaBannerManage from './CtaBannerManage';
import EditorialBlock, {
  MAX_HEADING_LENGTH,
  MAX_SUBHEADING_LENGTH
} from './EditorialBlock';
import TagSelector from './TagSelector';

//TODO: Add videos group management here also
const zoneOperations = container.resolve(ZoneOperations);
const searchOperations = container.resolve(SearchOperations);
const rankedResultItemConverter = container.resolve(RankedResultItemConverter);

export const ctaBannerTitleLength = 45;
export const ctaBannerDescriptionLength = 160;
export const ctaBannerLinkTextLength = 30;
export const ctaBannerColourHexCodeLength = 7;
export const blockNameMaxLength = 35;

export const listingBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.ListingsBlock, Dippy.Models';
export const guideBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.GamesBlock, Dippy.Models';
export const ctaBannerBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.CtaBannerBlock, Dippy.Models';
export const spotBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.SpotsBlock, Dippy.Models';
export const eventBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.EventsBlock, Dippy.Models';
export const videoBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.VideosBlock, Dippy.Models';
export const entityBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.EntitiesBlock, Dippy.Models';
export const baseBlockType =
  'Dippy.Models.Lobby.Discover.Blocks.BaseBlock, Dippy.Models';

type Props = {
  title?: string;
  block?: IBlock;
  isBlockPage: boolean;
  onCreateDiscoverBlock?: (discoverBlock: IBlock) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  onClickUpdateSubmit?: (block: IBlock) => void;
  onClickCloseUpdateModal?: () => void;
  shouldUpdateSuggestedBlockNearMe?: boolean;
  guideItems?: ISpotVersion[];
  updateItemsForGuideSpots?: (
    data?:
      | ISpotVersion[]
      | Promise<ISpotVersion[]>
      | MutatorCallback<ISpotVersion[]>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<ISpotVersion[] | undefined>;
  isScenario?: boolean;
};

function BlockManage(props: Props) {
  const {
    title,
    block,
    onCreateDiscoverBlock,
    setIsModalOpen,
    isBlockPage,
    onClickUpdateSubmit,
    guideItems,
    shouldUpdateSuggestedBlockNearMe = true,
    updateItemsForGuideSpots,
    onClickCloseUpdateModal,
    isScenario = false
  } = props;

  const isEditing = block != null;
  const getInitialState = () => {
    const state = isEditing
      ? block
      : {
          $type: '',
          name: '',
          cardSize: CardSize.Small,
          layout: Layout.Horizontal,
          contentType: ContentType.Local
        };
    return state;
  };

  const { data: categories } = useReqListCategories();
  const { data: tags } = useReqListTags();
  const [isTypeSelectorOpen, setIsTypeSelectorOpen] = useState<boolean>(false);
  const [isBlockSelectorOpen, setIsBlockSelectorOpen] =
    useState<boolean>(false);
  const [isLayoutSelectorOpen, setIsLayoutSelectorOpen] =
    useState<boolean>(false);
  const [isCardSizeSelectorOpen, setIsCardSizeSelectorOpen] =
    useState<boolean>(false);
  const [isCategorySelectorOpen, setIsCategorySelectorOpen] =
    useState<boolean>(false);
  const [localBlock, setLocalBlock] = useState<IBlock>(() => getInitialState());
  const [showGameTable, setShowGameTable] = useState<boolean>(
    !localBlock.gameId
  );
  const [showGameAdded, setShowGameAdded] = useState<boolean>(false);
  const [showZoneTable, setShowZoneTable] = useState<boolean>(false);
  const [currentGame, setCurrentGame] = useState<IGame>();
  const [currentZone, setCurrentZone] = useState<IZoneVersion>();
  const [zones, setZones] = useState<IZoneVersion[]>();
  const [allGames, setAllGames] = useState<IGame[]>();
  const [zoneVersion, setZoneVersion] = useState<IZoneVersion | undefined>(
    undefined
  );
  const [isEntityTypeSelectorOpen, setIsEntityTypeSelectorOpen] =
    useState<boolean>(false);
  const [isActionTypeSelectorOpen, setIsActionTypeSelectorOpen] =
    useState<boolean>(false);

  const columns = React.useMemo(() => gameTableColumns, []);

  const { data: game, isLoading: isGameLoading } = useReqGetGuide(
    localBlock.gameId
  );

  useEffect(() => {
    if (!!localBlock.zoneId) {
      (async () => {
        const { data: zoneVersion, error } =
          await zoneOperations.getLiveVersionAsync(localBlock.zoneId!);
        if (error) {
          toast.error('Failed to get linked Zone. It may have been deleted.');
          return;
        }
        setZoneVersion(zoneVersion);
      })();
    }
  }, [localBlock.zoneId]);

  const {
    data: guideSpotsBlocks,
    isLoading: isGuideSpotBlockLoading,
    isError: isGuideSpotsBlockError
  } = useReqListBlocks(
    localBlock.type === BlockType.GuideSpots ? localBlock.type : undefined
  );

  const {
    data: entitiesGroupBlocks,
    isLoading: isEntitiesGroupBlockLoading,
    isError: isEntitiesGroupBlockError
  } = useReqListBlocks(
    localBlock.type === BlockType.EntitiesGroup ? localBlock.type : undefined
  );

  const {
    data: videosGroupBlocks,
    isLoading: isVideosGroupBlockLoading,
    isError: isVideosGroupBlockError
  } = useReqListBlocks(
    localBlock.type === BlockType.VideosGroup ? localBlock.type : undefined
  );
  //NOTE: The useeffect hook here is used to check the current gameId for a guidesItem block,
  // if it exist, we make use of the Id to fetch the corresponding game from the database and
  //set the currentGame state to be the game retrieved. The useEffect runs on opening the edit Modal.
  useEffect(() => {
    if (game) {
      setCurrentGame(game);
    }
    if (zoneVersion) {
      setCurrentZone(zoneVersion);
      setShowGameTable(false);
    }
  }, [game, zoneVersion]);

  const onChangeSearchValue = async (query: string) => {
    if (!query || query.length < 2) {
      return;
    }

    const request: ISearchRequest = {
      query: query ?? '',
      order: Order.Relevance,
      entityFilter: Filter.Game,
      location: {
        latitude: 51,
        longitude: -0.2
      },
      take: 10
    };

    const { data: rankedResults, error } = await searchOperations.search(
      request
    );

    if (!rankedResults || error) {
      toast.error('No guides found');
      return;
    }

    const games = rankedResults.map((rr) =>
      rankedResultItemConverter.convertToGuide(rr)
    );
    setAllGames(games);
  };

  const isGamesBlock =
    localBlock.type === BlockType.NewGuides ||
    localBlock.type === BlockType.SuggestedGuides ||
    localBlock.type === BlockType.TopGuides ||
    localBlock.type === BlockType.TrendingGuides;

  const isCtaBanner = localBlock.type === BlockType.CtaBanner;
  const isEntitiesGroup = localBlock.type === BlockType.EntitiesGroup;
  const isVideoGroup = localBlock.type === BlockType.VideosGroup;

  const isSpotsBlock =
    localBlock.type === BlockType.GuideSpots ||
    localBlock.type === BlockType.ZoneSpots;

  const isListingsBlock = localBlock.type === BlockType.SuggestedListings;
  const isEventsBlock = localBlock.type === BlockType.SuggestedEvents;

  const isMostLikedBlock = localBlock.type === BlockType.MostLiked;

  const isTrendingPopularBlock =
    localBlock.type === BlockType.Trending ||
    localBlock.type === BlockType.Popular;

  const isCollectionRelatedBlock =
    localBlock.type === BlockType.Following ||
    localBlock.type === BlockType.CollectedNearMe;

  const isCollectionRelatedBlockSaveValid =
    !isCollectionRelatedBlock ||
    (localBlock.actionType === ActionType.Saved
      ? localBlock.entityType === BlockEntityType.Guide
      : localBlock.entityType !== BlockEntityType.Guide);

  const isCollectionRelatedBlockEntityTypeValid =
    !isCollectionRelatedBlock || localBlock.entityType !== BlockEntityType.Spot;

  const shouldShowDisplayOptions =
    isGamesBlock ||
    isSpotsBlock ||
    isListingsBlock ||
    isEventsBlock ||
    isEntitiesGroup ||
    localBlock.type === BlockType.MyDownloadedGuides ||
    isCtaBanner ||
    isCollectionRelatedBlock ||
    isMostLikedBlock ||
    isTrendingPopularBlock ||
    isVideoGroup;

  const isSuggestedBlock =
    localBlock.type === BlockType.SuggestedEvents ||
    localBlock.type === BlockType.SuggestedListings ||
    localBlock.type === BlockType.SuggestedGuides;

  const blockTypeFlyoutMenuItems = isBlockPage
    ? EnumHelper.getEnumValuesForFlyout(BlockType).filter((b) =>
        ManualBlockNames.includes(b.value)
      )
    : EnumHelper.getEnumValuesForFlyout(BlockType).filter(
        (b) => !OmittedBlockTypes.includes(b.value)
      );

  const layoutFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(Layout);
  const cardSizeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    isCtaBanner ? CtaBannerCardSize : CardSize
  );
  const actionTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    ActionType
  ).map((item) => ({
    ...item,
    label: item.label
  }));
  const entityTypeFlyoutMenuItems = EnumHelper.getEnumValuesForFlyout(
    BlockEntityType
  ).filter((item) => item.label !== 'None');

  const isGuideSpotsBlock = localBlock.type === BlockType.GuideSpots;
  const isTypeDefined = localBlock.type !== undefined;
  const isValidCountRange =
    !!localBlock.count && localBlock.count <= 20 && localBlock.count >= 0;

  const isCustomMadeBlock =
    localBlock.type === BlockType.FilterAndEventSearchWidgets ||
    localBlock.type === BlockType.ViewByCategory ||
    localBlock.type === BlockType.QuickAccess ||
    localBlock.type === BlockType.Ads ||
    localBlock.type === BlockType.MyRecentGuides;

  const isNonAdvertBlocks =
    localBlock.type === BlockType.FilterAndEventSearchWidgets ||
    localBlock.type === BlockType.ViewByCategory ||
    localBlock.type === BlockType.QuickAccess ||
    localBlock.type === BlockType.Ads;

  const isEditorialBlock =
    localBlock.heading !== undefined && localBlock.subHeading !== undefined;

  const blockCountError =
    localBlock.count && isEditorialBlock && localBlock.count < 0
      ? 'Count must be non-negative if editorial block'
      : (localBlock.count || 0) > 20
      ? 'Count must not be more than 20'
      : '';

  const isValidSpotsBlock =
    !isSpotsBlock ||
    (isBlockPage
      ? localBlock.gameId || localBlock.zoneId
      : isGuideSpotsBlock || localBlock.gameId || localBlock.zoneId);

  const hasValidName =
    localBlock.name &&
    localBlock.name.length !== 0 &&
    localBlock.name.length <= blockNameMaxLength;

  function validateCtaBannerBlock() {
    const {
      title,
      description,
      type,
      linkText,
      textColour,
      cardColour,
      routeUrl,
      tagIds,
      mediaType,
      image,
      animationType
    } = localBlock.linkMetadata || {};

    return (
      !isCtaBanner ||
      (!!title &&
        title.length <= ctaBannerTitleLength &&
        !!description &&
        description.length <= ctaBannerDescriptionLength &&
        !!linkText &&
        linkText.length <= ctaBannerLinkTextLength &&
        !!textColour &&
        textColour.length <= ctaBannerColourHexCodeLength &&
        !!cardColour &&
        cardColour.length <= ctaBannerColourHexCodeLength &&
        ((type === CtaBannerType.App && !!routeUrl) ||
          (type === CtaBannerType.Search && !!tagIds)) &&
        (mediaType === CtaBannerMediaType.None ||
          !mediaType ||
          (mediaType === CtaBannerMediaType.Image && !!image) ||
          (mediaType === CtaBannerMediaType.Animation && !!animationType)))
    );
  }

  function validateEditorialBlock() {
    const { heading, subHeading, video } = localBlock;
    const { mediaType, image } = localBlock.linkMetadata || {};
    return (
      !isEditorialBlock ||
      (!!heading &&
        heading !== '' &&
        heading.length <= MAX_HEADING_LENGTH &&
        (!subHeading || subHeading.length <= MAX_SUBHEADING_LENGTH) &&
        (mediaType === EditorialBlockMediaType.None ||
          !mediaType ||
          (mediaType === EditorialBlockMediaType.Image && !!image) ||
          (mediaType === EditorialBlockMediaType.Video && !!video)))
    );
  }

  const isValidCtaBannerBlock = validateCtaBannerBlock();
  const isValid = isEditorialBlock
    ? validateEditorialBlock()
    : isTypeDefined &&
      (isCustomMadeBlock || isCtaBanner || isValidCountRange || isScenario) &&
      isValidSpotsBlock &&
      hasValidName &&
      isValidCtaBannerBlock;

  const groupBlockFlyoutMenuItems = (
    groupBlocks: IBlock[] | undefined,
    isGroupBlockError: boolean | undefined,
    entityName: string
  ): FlyoutMenuItem[] => {
    if (!groupBlocks || isGroupBlockError) {
      toast.error(
        `Error retrieving ${entityName} group blocks from the server`
      );
      return [];
    }
    return groupBlocks.map((block) => {
      const flyoutMenuItem = {
        label: block.name,
        value: block.id
      } as FlyoutMenuItem;
      return flyoutMenuItem!;
    });
  };

  function displayBlockFlyoutMenuItems(type: BlockType) {
    switch (type) {
      case BlockType.GuideSpots:
        return groupBlockFlyoutMenuItems(
          guideSpotsBlocks,
          isGuideSpotsBlockError,
          'guide spots'
        );
      case BlockType.EntitiesGroup:
        return groupBlockFlyoutMenuItems(
          entitiesGroupBlocks,
          isEntitiesGroupBlockError,
          'entity'
        );
      case BlockType.VideosGroup:
        return groupBlockFlyoutMenuItems(
          videosGroupBlocks,
          isVideosGroupBlockError,
          'videos'
        );
      default:
        return [];
    }
  }

  const categoryFlyoutMenuItems: FlyoutMenuItem[] = categories
    ? categories.map((val) => {
        return { label: val.name, value: val };
      })
    : [];
  categoryFlyoutMenuItems.unshift({
    label: 'No item selected',
    value: undefined
  });

  const tagFlyoutMenuItems: FlyoutMenuItem[] = tags
    ? tags.map((val) => {
        return { label: val.name!, value: val.id };
      })
    : [];
  tagFlyoutMenuItems.unshift({
    label: 'No item selected',
    value: undefined
  });

  const setType = (type: number) => {
    const block = {
      ...localBlock,
      type: type,
      count: undefined
    };
    switch (type) {
      case BlockType.NewGuides:
      case BlockType.SuggestedGuides:
      case BlockType.TopGuides:
      case BlockType.TrendingGuides:
      case BlockType.MyDownloadedGuides:
        block.$type = guideBlockType;
        break;
      case BlockType.CtaBanner:
        block.$type = ctaBannerBlockType;
        break;
      case BlockType.GuideSpots:
      case BlockType.ZoneSpots:
        block.$type = spotBlockType;
        break;
      case BlockType.SuggestedListings:
        block.$type = listingBlockType;
        break;
      case BlockType.SuggestedEvents:
        block.$type = eventBlockType;
        break;
      case BlockType.VideosGroup:
        block.$type = videoBlockType;
        break;
      case BlockType.EntitiesGroup:
        block.$type = entityBlockType;
        break;
      case BlockType.CollectedNearMe:
      case BlockType.Popular:
      case BlockType.Trending:
      case BlockType.Following:
      case BlockType.MostLiked:
        block.$type = getBlockType(localBlock.entityType);
        break;
      default:
        block.$type = baseBlockType;
    }
    setLocalBlock(block);
  };

  function getBlockType(entityType?: BlockEntityType): string {
    switch (entityType) {
      case BlockEntityType.Listing:
        return listingBlockType;
      case BlockEntityType.Event:
        return eventBlockType;
      case BlockEntityType.Guide:
        return guideBlockType;
    }
    return listingBlockType;
  }

  const handleFlyoutOnChange = (e: FlyoutMenuItem) => {
    setType(BlockType[e.label]);
    isSpotsBlock && setShowGameTable(true);
    setShowZoneTable(false);
    setShowGameAdded(false);
    setCurrentGame(undefined);
    setCurrentZone(undefined);
  };

  const handleSelectGuideForGuideSpots = (gameId: string) => {
    const gameToAdd = allGames?.find((g) => g.id === gameId);

    setLocalBlock({
      ...localBlock,
      gameId: gameToAdd?.id
    });

    const gameAdded = allGames?.find((game) => {
      game.id = localBlock.gameId;
      return game;
    });

    setShowGameTable(false);
    setShowGameAdded(true);
    setCurrentGame(gameAdded);
  };

  const handleSelectGuideForZoneSpots = async (gameId: string) => {
    const gameToAdd = allGames?.find((g) => g.id === gameId);

    const { data, error } = await zoneOperations.listByGameAsync(gameId);
    if (!data || error) {
      toast.error('Failed to list zones for guide. Please try again');
      return;
    }

    setZones(data);
    setShowGameTable(false);
    setShowZoneTable(true);
    setCurrentGame(gameToAdd);
  };

  const handleSelectZoneForZoneSpots = async (zoneId: string) => {
    const zoneAdded = zones?.find((z) => z.versionable?.id === zoneId);

    setLocalBlock({
      ...localBlock,
      gameId: currentGame?.id,
      zoneId: zoneAdded?.versionable?.id
    });

    setCurrentZone(zoneAdded);
    setCurrentGame(undefined);
    setShowZoneTable(false);
    setShowGameAdded(true);
  };

  const handleRemoveItems = async () => {
    setLocalBlock({
      ...localBlock,
      gameId: undefined,
      zoneId: undefined
    });
    setShowGameAdded(false);
    setShowGameTable(true);
    setCurrentGame(undefined);
    setCurrentZone(undefined);

    updateItemsForGuideSpots && updateItemsForGuideSpots(guideItems, true);
  };

  const handleRemoveGame = () => {
    setCurrentGame(undefined);
    setShowZoneTable(false);
    setShowGameTable(true);
  };

  const submitForUpdating = () => {
    if (onClickUpdateSubmit) {
      onClickUpdateSubmit(localBlock);
    }
  };

  function changeIsTourist() {
    switch (localBlock.contentType) {
      case ContentType.Tourist:
        setLocalBlock({
          ...localBlock,
          contentType: ContentType.Local
        });
        break;
      default:
      case ContentType.Local:
        setLocalBlock({
          ...localBlock,
          contentType: ContentType.Tourist
        });
        break;
    }
  }

  function handleUpdateNearMe() {
    if (shouldUpdateSuggestedBlockNearMe) {
      setLocalBlock({
        ...localBlock,
        isLocationBased: !localBlock.isLocationBased
      });
      return;
    } else {
      toast.warn(
        `Suggested block is not location based and contains manually added items so the near me value can't be updated`
      );
    }
    return;
  }

  function getEntityTypeValue(value: string) {
    switch (value) {
      case 'Listing':
        return BlockEntityType.Listing;
      case 'Event':
        return BlockEntityType.Event;
      case 'Guide':
        return BlockEntityType.Guide;
      case 'Spot':
        return BlockEntityType.Spot;
    }
    return BlockEntityType.Listing;
  }

  function getActionTypeValue(value: string) {
    switch (value) {
      case 'Liked':
        return ActionType.Liked;
      case 'Visited':
        return ActionType.Visited;
      case 'Collected':
        return ActionType.Collected;
      case 'Saved':
        return ActionType.Saved;
    }
    return ActionType.Liked;
  }

  function getSelectedBlockTags(block: IBlock) {
    if (!tags) {
      return;
    }
    return block.tagIds?.map((tagId) => {
      const tag = tags.find((t) => t.id === tagId);
      return tag!;
    });
  }

  function handleAddTag(tagId: string) {
    setLocalBlock({
      ...localBlock,
      tagIds: [...(localBlock.tagIds || []), tagId]
    });
  }

  function handleRemoveTag(tagId: string) {
    const tagIds = localBlock.tagIds?.filter((t) => t !== tagId);
    setLocalBlock({
      ...localBlock,
      tagIds: tagIds
    });
  }

  const localBlockToSubmit = {
    ...localBlock
  };

  if (isValid && localBlockToSubmit.linkMetadata) {
    localBlockToSubmit.linkMetadata.mediaType = undefined;
  }

  return (
    <div>
      <CardHeader
        title={
          title ||
          (isEditing
            ? 'Update Block Information'
            : 'Create A Discover Sub Page Block')
        }
        fontWeight="font-semibold"
      />

      <div className={`text-left mx-4 mt-5`}>
        {!isEditing && (
          <div>
            <span className="font-semibold ml-1">{'Block Types'}</span>
            <div className="mt-1">
              <FlyoutMenu
                // NOTE: even though activeLabel is deprecated, kindly use it here as the keys are not in sync when filtered out
                activeLabel={
                  blockTypeFlyoutMenuItems.find(
                    (o) =>
                      o.label ===
                      BlockType[localBlock.type || BlockType.SuggestedGuides]
                  )?.label
                }
                isEqual={isEqual}
                size="md"
                isMenuOpen={isTypeSelectorOpen}
                setIsMenuOpen={setIsTypeSelectorOpen}
                items={blockTypeFlyoutMenuItems}
                onChange={handleFlyoutOnChange}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row">
          {isEditing && (
            <div className="flex flex-col">
              <span className="font-bold">{BlockType[localBlock.type!]}</span>
              <div>
                <span className="font-semibold">Block Id: </span>
                <span> {localBlock.id}</span>
              </div>
            </div>
          )}
          {localBlock.type !== undefined && !isNonAdvertBlocks && !isScenario && (
            <div className="ml-auto mt-2 flex-row flex justify-end gap-4 items-center">
              <div
                className={`font-semibold ${
                  isEditorialBlock ? 'text-primaryTeal' : ''
                }`}
              >
                {localBlock.type === BlockType.CtaBanner
                  ? 'Editorial Spotlight (Link)'
                  : 'Editorial Spotlight (Entity)'}
              </div>
              <div className="mt-1">
                <Switch
                  checked={isEditorialBlock}
                  onChange={() => {
                    setLocalBlock({
                      ...localBlock,
                      heading: isEditorialBlock ? undefined : '',
                      subHeading: isEditorialBlock ? undefined : '',
                      linkMetadata: isEditorialBlock ? undefined : {}
                    });
                  }}
                />
              </div>
            </div>
          )}
          {!isEditing && <div className="flex-grow" />}
        </div>
        {
          <div className="mt-2">
            {localBlock.type !== undefined && (
              <div>
                {!isEditing &&
                !isBlockPage &&
                (localBlock.type === BlockType.GuideSpots ||
                  localBlock.type === BlockType.EntitiesGroup ||
                  localBlock.type === BlockType.VideosGroup) ? (
                  <div>
                    <span className="font-semibold">{'Block:'}</span>
                    {(localBlock.type === BlockType.EntitiesGroup &&
                      isEntitiesGroupBlockLoading) ||
                    (localBlock.type === BlockType.GuideSpots &&
                      isGuideSpotBlockLoading) ||
                    (localBlock.type === BlockType.VideosGroup &&
                      isVideosGroupBlockLoading) ? (
                      <Spinner />
                    ) : (
                      <FlyoutMenu
                        activeLabel={localBlock.name}
                        isEqual={isEqual}
                        size="xl"
                        isMenuOpen={isBlockSelectorOpen}
                        setIsMenuOpen={setIsBlockSelectorOpen}
                        items={displayBlockFlyoutMenuItems(localBlock.type)}
                        onChange={(e) =>
                          setLocalBlock({
                            ...localBlock,
                            name: e.label,
                            id: e.value
                          })
                        }
                        withSearch
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <span className="font-semibold">{'Block Name:'}</span>
                    <Input
                      autoFocus={true}
                      value={localBlock.name}
                      onChange={(e) => {
                        setLocalBlock({
                          ...localBlock,
                          name: e.currentTarget.value
                        });
                      }}
                      maxCharacterCount={blockNameMaxLength}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        }

        {isScenario && localBlock.type !== undefined && (
          <div className="flex-col">
            <span className="flex text-md">
              For Headings and Subheadings, use the format
              &#123;&#123;user&#125;&#125; to display user names.
            </span>
            <span className="flex text-md">
              E.g. Good morning &#123;&#123;user&#125;&#125;
            </span>
            <span className="font-semibold">Block Heading:</span>
            <Input
              autoFocus={true}
              value={localBlock.heading}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  heading: e.currentTarget.value
                });
              }}
            />
            <span className="font-semibold flex">{'Block Subheading:'}</span>
            <Input
              autoFocus={true}
              value={localBlock.subHeading}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  subHeading: e.currentTarget.value
                });
              }}
            />
          </div>
        )}

        {shouldShowDisplayOptions &&
          !isScenario &&
          !isCtaBanner &&
          !isEditorialBlock && (
            <div className="">
              <span className="font-semibold">{'Layout:'}</span>
              <FlyoutMenu
                activeKey={localBlock.layout! - 1}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isLayoutSelectorOpen}
                setIsMenuOpen={setIsLayoutSelectorOpen}
                items={layoutFlyoutMenuItems}
                onChange={(e) =>
                  setLocalBlock({
                    ...localBlock,
                    layout: e.key! + 1
                  })
                }
              />
            </div>
          )}
        {shouldShowDisplayOptions && !isScenario && !isEditorialBlock && (
          <div className="mt-2">
            <span className="font-semibold">{'Card size:'}</span>
            <FlyoutMenu
              activeLabel={localBlock.cardSize}
              isEqual={isEqual}
              size="sm"
              isMenuOpen={isCardSizeSelectorOpen}
              setIsMenuOpen={setIsCardSizeSelectorOpen}
              items={cardSizeFlyoutMenuItems}
              onChange={(e) =>
                setLocalBlock({ ...localBlock, cardSize: e.value })
              }
            />
          </div>
        )}
        {(isGamesBlock || isSuggestedBlock) && (
          <div className="mt-2">
            <span className="font-semibold">{'Category:'}</span>
            <FlyoutMenu
              activeLabel={localBlock.category?.name}
              isEqual={isEqual}
              size="sm"
              isMenuOpen={isCategorySelectorOpen}
              setIsMenuOpen={setIsCategorySelectorOpen}
              items={categoryFlyoutMenuItems}
              onChange={(e) =>
                setLocalBlock({
                  ...localBlock!,
                  category: e.value as ICategory
                })
              }
            />
          </div>
        )}
        {isSuggestedBlock && (
          <div className="mt-2">
            <span className="font-semibold">{'Tags:'}</span>
            <TagSelector
              tags={tags || []}
              selectedTags={getSelectedBlockTags(localBlock) ?? []}
              onConfirmNewTag={handleAddTag}
              onClickTagDeleteIcon={handleRemoveTag}
            />
          </div>
        )}
        {(isGamesBlock ||
          isListingsBlock ||
          isEventsBlock ||
          isTrendingPopularBlock ||
          localBlock.type === BlockType.Following) && (
          <div className="">
            <span className="font-semibold">{'Near me:'}</span>
            <Checkbox
              size="lg"
              selectedColour="primaryButton"
              onChange={handleUpdateNearMe}
              checked={localBlock.isLocationBased || false}
            />
          </div>
        )}

        {localBlock.type !== undefined &&
          shouldShowDisplayOptions &&
          !isScenario && (
            <div className="mt-2 w-36">
              <span className="font-semibold">{'Tourist specific:'}</span>
              <Checkbox
                size="lg"
                selectedColour="primaryButton"
                onChange={changeIsTourist}
                checked={localBlock.contentType === ContentType.Tourist}
              />
            </div>
          )}

        {isCtaBanner && (
          <CtaBannerManage
            localBlock={localBlock}
            setLocalBlock={setLocalBlock}
            isEditorialBlock={isEditorialBlock}
          />
        )}

        {shouldShowDisplayOptions && !isCtaBanner && !isScenario && (
          <div>
            <div className="font-semibold">{'Count (required):'}</div>
            <Input
              value={localBlock.count?.toString() || ''}
              onChange={(e) => {
                setLocalBlock({
                  ...localBlock,
                  count: parseInt(e.currentTarget.value, 10) || 0
                });
              }}
              error={blockCountError}
            />
            {(localBlock.type === BlockType.ZoneSpots ||
              (localBlock.type === BlockType.GuideSpots && isBlockPage)) &&
              showGameTable && (
                <GameTable
                  onChangeSearchValue={onChangeSearchValue}
                  onClickAddIcon={
                    localBlock.type === BlockType.GuideSpots
                      ? handleSelectGuideForGuideSpots
                      : handleSelectGuideForZoneSpots
                  }
                  tableColumns={columns as any}
                  games={allGames || []}
                  isLarge={false}
                />
              )}
            <BlockItemAdded
              game={currentGame}
              block={localBlock}
              zoneVersion={currentZone}
              showBlockItemsAdded={showGameAdded}
              isEditing={isEditing}
              isGameLoading={isGameLoading}
              isZoneLoading={!zoneVersion}
              removeItem={handleRemoveItems}
            />
            <div>
              {showZoneTable && (
                <div>
                  <div className="mt-1 overflow-y-scroll max-h-56">
                    <div className="flex flex-row px-6 pt-2 items-center justify-between">
                      <img
                        src={currentGame?.versions[0].icon}
                        alt="game icon"
                        width={'60px'}
                        height={'60px'}
                        className="rounded-md"
                      />
                      <div className="font-semibold text-lg px-4 text-center">
                        {currentGame?.versions[0].name}
                      </div>
                      <RiDeleteBin2Line
                        onClick={handleRemoveGame}
                        className="text-3xl cursor-pointer ml-8 hover:text-error1"
                      />
                    </div>
                    <div className="font-semibold text-lg px-4 mt-2">
                      Zones in
                    </div>
                    {zones &&
                      zones.map((z, i) => (
                        <div
                          key={i}
                          className="flex flex-row m-4 bg-theme5 h-auto p-4 items-center"
                        >
                          <img
                            src={z.icon}
                            alt="zone icon"
                            width={'80px'}
                            height={'80px'}
                            className="rounded-md"
                          />
                          <div className="mx-10 text-lg font-semibold">
                            {z.name}
                          </div>
                          <BiPlus
                            className="ml-auto mr-16 cursor-pointer hover:text-success"
                            size={40}
                            onClick={() =>
                              handleSelectZoneForZoneSpots(z.versionable?.id!)
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {isCollectionRelatedBlock && (
          <div>
            <div className="flex flex-col mb-4">
              <div className="font-inter text-lg font-bold">Action Type:</div>
              <FlyoutMenu
                activeKey={(actionTypeFlyoutMenuItems || []).findIndex(
                  (t) => getActionTypeValue(t.value) === localBlock.actionType
                )}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isActionTypeSelectorOpen}
                setIsMenuOpen={setIsActionTypeSelectorOpen}
                items={actionTypeFlyoutMenuItems}
                onChange={(e) =>
                  setLocalBlock({
                    ...localBlock,
                    actionType: getActionTypeValue(e.value)
                  })
                }
              />
            </div>
          </div>
        )}

        {(isCollectionRelatedBlock ||
          isMostLikedBlock ||
          isTrendingPopularBlock) && (
          <div>
            <div className="flex flex-col mb-4">
              <div className="font-inter text-lg font-bold">Entity Type:</div>
              <FlyoutMenu
                activeKey={(entityTypeFlyoutMenuItems || []).findIndex(
                  (t) => getEntityTypeValue(t.value) === localBlock.entityType
                )}
                isEqual={isEqual}
                size="sm"
                isMenuOpen={isEntityTypeSelectorOpen}
                setIsMenuOpen={setIsEntityTypeSelectorOpen}
                items={entityTypeFlyoutMenuItems}
                onChange={(e) =>
                  setLocalBlock({
                    ...localBlock,
                    entityType: getEntityTypeValue(e.value)
                  })
                }
              />
            </div>
            {!isCollectionRelatedBlockSaveValid && (
              <span className="text-error1">
                You can't Save a Listing/Event and you can't Visit/Like/Collect
                a Guide
              </span>
            )}
            {!isCollectionRelatedBlockEntityTypeValid && (
              <span className="text-error1">
                You can't select "Spot" when the BlockType is CollectedNearMe
              </span>
            )}
          </div>
        )}
        {isEditorialBlock && (
          <EditorialBlock
            localBlock={localBlock}
            setLocalBlock={setLocalBlock}
            isScenario={isScenario}
            isCtaBannerBlock={isCtaBanner}
          />
        )}
        {localBlock.type !== undefined &&
          (isCtaBanner || isScenario || isEditorialBlock) && (
            <div className="mt-2">
              <span className="font-semibold">{'Link Text:'}</span>
              <Input
                value={localBlock.linkMetadata?.linkText}
                onChange={(e) => {
                  setLocalBlock({
                    ...localBlock,
                    linkMetadata: {
                      ...localBlock.linkMetadata!,
                      linkText: e.currentTarget.value
                    }
                  });
                }}
                maxCharacterCount={ctaBannerLinkTextLength}
              />
            </div>
          )}

        {/* NOTE: Hiding this component until we're ready to properly implement the feature */}
        {/* {localBlock.type !== undefined && !isNonAdvertBlocks && !isScenario && (
          <div className="flex flex-col mb-4">
            <div className="font-inter text-lg font-bold">Advertisements</div>
            <div className="font-inter text-sm mt-2 font-bold">
              Free Frequency
              <span className="font-inter text-sm font-normal ml-1">
                (every 'x' card)
              </span>
            </div>
            <div>
              <Input
                className="w-36"
                value={localBlock.freeFrequency?.toString() || ''}
                onChange={(e) => {
                  setLocalBlock({
                    ...localBlock,
                    freeFrequency: parseInt(e.currentTarget.value, 10) || 0
                  });
                }}
              />
            </div>
          </div>
        )} */}

        {isEditing ? (
          <div>
            <div className="flex flex-row mt-4 justify-evenly">
              <Button
                onClick={submitForUpdating}
                disabled={
                  !isValid ||
                  !isCollectionRelatedBlockSaveValid ||
                  !isCollectionRelatedBlockEntityTypeValid
                }
              >
                {'Submit'}
              </Button>
              <Button onClick={onClickCloseUpdateModal} skin="secondary">
                <MdClose className="mr-1 mt-1" />
                <div>Cancel</div>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row mt-6 justify-evenly">
            <Button
              onClick={() => {
                onCreateDiscoverBlock &&
                  onCreateDiscoverBlock(localBlockToSubmit);
                setIsModalOpen(false);
              }}
              disabled={!isValid}
            >
              Add
            </Button>

            <Button onClick={() => setIsModalOpen(false)} skin="secondary">
              <MdClose className="mr-1 mt-1" />
              <div>Cancel</div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlockManage;
