import React from 'react';
import CreateModal from '../CreateModal';
import { ICombo } from 'Colugo/interfaces/tags';

type Props = {
  onCreateCombo: (combo: ICombo) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

function CombosCreate(props: Props) {
  const { onCreateCombo, setIsModalOpen, isModalOpen } = props;

  async function handleSubmitFilterGroup(name: string) {
    const newCombo: ICombo = { name: name };
    await onCreateCombo(newCombo);
  }

  return (
    <CreateModal
      title={'Create Combo'}
      submitButtonText={'Create Combo'}
      minCharacterCount={4}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSubmit={handleSubmitFilterGroup}
    />
  );
}
export default CombosCreate;
