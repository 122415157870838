import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { requestResponse } from '../../provider/HttpClient';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

@scoped(Lifecycle.ContainerScoped)
class FilterGroupOperations extends AbstractOperations {
  protected basePath: string = 'filterGroup';

  public async create(
    filterGroup: IFilterGroup
  ): requestResponse<IFilterGroup> {
    return await this.executePost('', filterGroup);
  }

  public async update(
    filterGroup: IFilterGroup
  ): requestResponse<IFilterGroup> {
    return await this.executePut(`${filterGroup.id}`, filterGroup);
  }

  public async addTag(
    filterGroupId: string,
    tagId: string
  ): requestResponse<IFilterGroup> {
    return await this.executePost(`${filterGroupId}/tag/${tagId}`);
  }

  public async updateTagFeatureState(
    filterGroup: IFilterGroup,
    tagId: string,
    isFeatured: boolean
  ): requestResponse<IFilterGroup> {
    return await this.executePut(
      `${filterGroup.id}/tag/${tagId}?isFeatured=${isFeatured}`
    );
  }

  public async removeTag(
    filterGroupId: string,
    tagId: string
  ): requestResponse {
    return await this.executeDelete(`${filterGroupId}/tag/${tagId}`);
  }

  public async delete(filterGroupId: string): requestResponse {
    return await this.executeDelete(`${filterGroupId}`);
  }
}

const useReqListFilterGroups = (): swrRequestResponse<IFilterGroup[]> => {
  return useGetHttpClient(
    `filterGroup/list`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListFilterGroupsByTag = (
  tagId: string
): swrRequestResponse<IFilterGroup[]> => {
  return useGetHttpClient(
    tagId ? `filterGroup/listByTag/${tagId}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListFilterGroupsByCombo = (
  comboId: string
): swrRequestResponse<IFilterGroup[]> => {
  return useGetHttpClient(
    comboId ? `tagging/filterGroup/listByCombo/${comboId}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default FilterGroupOperations;
export {
  useReqListFilterGroups,
  useReqListFilterGroupsByTag,
  useReqListFilterGroupsByCombo
};
