import React, { useState } from 'react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import GlobalFilter from 'component/utility/GlobalFilter';
import { BiPencil } from 'react-icons/bi';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';
import { ImRadioUnchecked } from 'react-icons/im';

type Props = {
  tableColumns: Column<IFilterGroup>[];
  filterGroups: IFilterGroup[];
  onClickDeleteIcon?: (filterGroup: IFilterGroup) => void;
  onClickFeaturedIcon?: (filterGroup: IFilterGroup) => void;
  onClickEditIcon?: (filterGroup: IFilterGroup) => void;
  children: React.ReactNode;
  onClickRow: (filterGroupId: string) => void;
  rowSelectedId?: string;
  customHeight?: string;
};

function FilterGroupsTable(props: Props) {
  const {
    filterGroups,
    tableColumns,
    onClickDeleteIcon,
    onClickEditIcon,
    onClickFeaturedIcon,
    children,
    onClickRow,
    rowSelectedId,
    customHeight
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [isDeleteHover, setIsDeleteHover] = useState(false);
  const [isFeaturedHover, setIsFeaturedHover] = useState(false);
  const [isEditHover, setIsEditHover] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: filterGroups
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const table = (
    <div>
      <div
        {...getTableProps()}
        className="w-full overflow-y-auto"
        style={{ height: `${customHeight && customHeight}` }}
      >
        <div>{filterGroupTableHeaderConfig(headerGroups)}</div>
        {filterGroupTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          setIsDeleteHover,
          isDeleteHover,
          setIsFeaturedHover,
          isFeaturedHover,
          onClickDeleteIcon,
          onClickFeaturedIcon,
          setIsEditHover,
          isEditHover,
          onClickEditIcon,
          filterGroups,
          onClickRow,
          rowSelectedId
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row items-center h-20">
        {children}
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          searchPlaceholder={'groups'}
        />
      </div>
      <div className="mt-6">{table}</div>
    </div>
  );
}

export default FilterGroupsTable;

export const filterGroupTableColumns: Column<IFilterGroup>[] = [
  {
    Header: 'FilterGroup',
    accessor: 'name'
  },
  {
    Header: 'Edit',
    width: 50
  },
  {
    Header: '',
    accessor: 'id',
    width: 50
  },
  {
    Header: 'Featured',
    width: 50
  }
];

const filterGroupTableHeaderConfig = (
  headerGroups: HeaderGroup<IFilterGroup>[]
) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: '100px' }}
            className="w-full ml-6"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>
                {' '}
                {column.Header!.toString() === 'FilterGroup'
                  ? column.render('Header')
                  : ''}
              </div>
              <div>
                {column.Header!.toString() === '' ||
                column.Header!.toString() === 'Edit' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const filterGroupTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IFilterGroup> | undefined
  ) => TableBodyProps,
  rows: Row<IFilterGroup>[],
  prepareRow: (row: Row<IFilterGroup>) => void,
  setIsDeleteHover: (isHover: boolean) => void,
  isDeleteHover: boolean,
  setIsFeaturedHover: (isHover: boolean) => void,
  isFeaturedHover: boolean,
  onClickDeleteIcon: ((filterGroup: IFilterGroup) => void) | undefined,
  onClickFeaturedIcon: ((filterGroup: IFilterGroup) => void) | undefined,
  setIsEditHover: (isHover: boolean) => void,
  isEditHover: boolean,
  onClickEditIcon: ((filterGroup: IFilterGroup) => void) | undefined,
  filterGroups: IFilterGroup[],
  onClickRow: (filterGroupId: string) => void,
  rowSelectedId?: string
) => (
  <div
    style={{ maxHeight: `calc(${getMaxViewHeight()} - 4vh` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      const elementId = row.cells[row.cells.length - 1].value;
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap h-16 relative rounded-md ${bgcolor} hover:shadow-lg cursor-pointer`}
          onMouseEnter={() => setRowHovered(i)}
          onClick={() => onClickRow(row.cells[2].value)}
        >
          {rowSelectedId === elementId && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            let classNames = 'my-1 text-left';
            if (index !== row.cells.length - 1) {
              classNames += ' text-center';
            }
            if (cell.column.Header?.toString() === 'Edit') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: '40px' }}
                  className="absolute top-4 right-16"
                >
                  <div className="flex w-full flex-row">
                    {onClickEditIcon && (
                      <BiPencil
                        className={`text-3xl cursor-pointer  ${
                          isEditHover && rowHovered === i
                            ? 'text-success'
                            : 'text-theme6'
                        }`}
                        onMouseEnter={() => setIsEditHover(true)}
                        onMouseLeave={() => setIsEditHover(false)}
                        onClick={() => {
                          onClickEditIcon(
                            filterGroups.find(
                              (filterGroup) =>
                                filterGroup.id === cell.row.cells[2].value
                            )!
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            }

            if (cell.column.Header?.toString() === 'Featured') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: '40px' }}
                  className="absolute top-4 right-8"
                >
                  <div
                    className={`flex w-7 flex-row ${
                      cell.row.original.isFeatured ? 'bg-primaryButton' : ''
                    } rounded-full ${
                      cell.row.original.isFeatured
                        ? 'hover:opacity-75'
                        : 'hover:opacity-100'
                    }`}
                  >
                    {onClickFeaturedIcon && (
                      <ImRadioUnchecked
                        color={`${
                          cell.row.original.isFeatured ? '#27AF96' : ''
                        }`}
                        className={`text-3xl cursor-pointer  ${
                          isFeaturedHover && rowHovered === i
                            ? 'text-primaryButton'
                            : 'text-theme6'
                        }`}
                        onMouseEnter={() => setIsFeaturedHover(true)}
                        onMouseLeave={() => setIsFeaturedHover(false)}
                        onClick={() => {
                          onClickFeaturedIcon(
                            filterGroups.find(
                              (filterGroup) =>
                                filterGroup.id === cell.row.original.id
                            )!
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            }

            if (cell.column.Header?.toString() === '') {
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ width: '40px' }}
                  className="absolute top-4 right-0"
                >
                  <div className="flex w-full flex-row">
                    {onClickDeleteIcon && (
                      <RiDeleteBin2Line
                        className={`text-3xl cursor-pointer  ${
                          isDeleteHover && rowHovered === i
                            ? 'text-error1'
                            : 'text-theme6'
                        }`}
                        onMouseEnter={() => setIsDeleteHover(true)}
                        onMouseLeave={() => setIsDeleteHover(false)}
                        onClick={() => {
                          onClickDeleteIcon(
                            filterGroups.find(
                              (filterGroup) => filterGroup.id === cell.value
                            )!
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={`${classNames} my-auto`}
                style={{ width: '120px' }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
