import PlayersEditor from './PlayersEditor';
import UserCountContainer from './UserCountContainer';

function UserContainer() {
  //Should adjust container title based on if viewing existing or viewing to add
  const containerTitle = `User management`;

  return (
    <div className="h-full overflow-auto container">
      <div className="mx-auto flex flex-col">
        <div className="w-full">
          <span className="block text-3xl text-black mt-1 ml-4">
            {containerTitle}
          </span>
          <UserCountContainer />
          <PlayersEditor />
        </div>
      </div>
    </div>
  );
}

export default UserContainer;
