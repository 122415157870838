import {
  BindyIcon,
  Button,
  FlyoutMenu,
  Modal,
  Spinner
} from '@bindystreet/bindystreet.kit.react';
import RankedResultItemConverter from 'Colugo/interfaces/converters/RankedResultItemConverter';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IGame, ISpotVersion } from 'Colugo/interfaces/games';
import { IAppEntity } from 'Colugo/interfaces/IAppEntity';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IBlock, IBlockItem } from 'Colugo/interfaces/lobby/discover/blocks';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import { ManualBlockNames } from 'Colugo/interfaces/lobby/discover/enums/BlockType';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import {
  Filter,
  ISearchRequest,
  Order
} from 'Colugo/interfaces/search/ISearchRequest';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { useReqListItems } from 'Colugo/operations/guides/SpotOperations';
import BlockOperations, {
  useReqListItemsForEntitiesGroupBlock,
  useReqListItemsForEventsGroupBlock,
  useReqListItemsForGuidesGroupBlock,
  useReqListItemsForItemsGroupOrGuideSpotsBlock,
  useReqListItemsForListingsGroupBlock,
  useReqListItemsForVideosGroupBlock
} from 'Colugo/operations/lobby/BlockOperations';
import SearchOperations from 'Colugo/operations/search/SearchOperations';
import { useReqListVideosAsync } from 'Colugo/operations/video/VideoOperations';
import { EnumHelper } from 'Colugo/utility/helpers';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { FaCopy } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { formatDate } from 'utility/general/dateUtilities';
import { copyToClipboardAsync } from 'utility/general/generalUtils';
import { ImageKit } from 'utility/general/imageKit';
import BlockGamesEditor from '../discover/blocks/BlockGamesEditor';
import BlockItemsEditor from '../discover/blocks/BlockItemsEditor';
import BlockManage from '../discover/blocks/BlockManage';
import ManualBlockEntityEditor, {
  getBlockEntityType
} from '../discover/blocks/ManualBlockEntityEditor';
import BlockTable, { blockTableColumns } from './BlockTable';

const searchOperations = container.resolve(SearchOperations);
const blockOperations = container.resolve(BlockOperations);
const rankedResultItemConverter = container.resolve(RankedResultItemConverter);

const maxDescriptionLength = 350;

type Props = {
  blocks: IBlock[];
  deleteBlock: (block: IBlock) => Promise<boolean>;
  addBlock: (block: IBlock) => Promise<boolean>;
  editBlockAsync: (block: IBlock) => Promise<boolean>;
  setBlockType: (blockType: BlockType) => void;
  blockType: BlockType;
};

function BlockEditor(props: Props) {
  const {
    blocks,
    deleteBlock,
    addBlock,
    editBlockAsync,
    setBlockType,
    blockType
  } = props;
  const history = useHistory();
  const { path } = useRouteMatch();

  const [isSelectTypeOpen, setIsSelectTypeOpen] = useState<boolean>(false);
  const [isDescriptionUpdateLoading, setIsDescriptionUpdateLoading] =
    useState<boolean>(false);
  const [selectedBlockItem, setSelectedBlockItem] = useState<
    IBlockItem | undefined
  >();
  const [newDescription, setNewDescription] = useState<string | undefined>(
    selectedBlockItem?.description
  );

  const [blockToDelete, setBlockToDelete] = useState<undefined | IBlock>(
    undefined
  );
  const [selectedBlockId, setSelectedBlockId] = useState<undefined | string>(
    undefined
  );
  const [isAddBlockModalOpen, setIsAddBlockModalOpen] = useState(false);
  const [blockToUpdate, setBlockToUpdate] = useState<undefined | IBlock>(
    undefined
  );
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [
    shouldUpdateSuggestedBlockNearMe,
    setShouldUpdateSuggestedBlockNearMe
  ] = useState<boolean>(false);
  const [isShowGroupGames, setIsShowGroupGames] = useState(true);
  const [isAllItemsSelected, setIsAllItemsSelected] = useState<boolean>(false);
  const [isAllEventsSelected, setIsAllEventsSelected] =
    useState<boolean>(false);
  const [isAllListingsSelected, setIsAllListingsSelected] =
    useState<boolean>(false);
  const [isAllEntitiesSelected, setIsAllEntitiesSelected] =
    useState<boolean>(false);
  const [isAllVideosSelected, setIsAllVideosSelected] =
    useState<boolean>(false);
  const [allGamesNamesQueryValue, setAllGamesNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [allEventsNamesQueryValue, setAllEventsNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [allListingsNamesQueryValue, setAllListingsNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [allEntitiesNamesQueryValue, setAllEntitiesNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [allVideosNamesQueryValue, setAllVideosNameQueryValue] = useState<
    undefined | string
  >(undefined);

  const [allGuides, setAllGuides] = useState<IGame[] | undefined>();
  const [allEvents, setAllEvents] = useState<IEvent[] | undefined>();
  const [allListings, setAllListings] = useState<IListing[] | undefined>();
  const [allEntities, setAllEntities] = useState<IAppEntity[] | undefined>();
  const [allVideos, setAllVideos] = useState<IVideo[] | undefined>();
  const selectedBlock = blocks.find((block) => block?.id === selectedBlockId);

  useEffect(() => {
    setNewDescription(selectedBlockItem?.description);
  }, [selectedBlockItem?.description]);

  const isEventsBlock = (blockType: BlockType, selectedBlock: IBlock) => {
    return (
      blockType === BlockType.SuggestedEvents &&
      selectedBlock &&
      !selectedBlock.isLocationBased
    );
  };

  const isGuidesBlock = (blockType: BlockType, selectedBlock: IBlock) => {
    return (
      blockType === BlockType.SuggestedGuides &&
      selectedBlock &&
      !selectedBlock.isLocationBased
    );
  };

  const isListingsBlock = (blockType: BlockType, selectedBlock: IBlock) => {
    return (
      blockType === BlockType.SuggestedListings &&
      selectedBlock &&
      !selectedBlock.isLocationBased
    );
  };

  const isEntitiesBlock =
    selectedBlock && blockType === BlockType.EntitiesGroup;
  const isVideosBlock = selectedBlock && blockType === BlockType.VideosGroup;

  //If we want to show all games or a block isnt selected,
  // we dont want this to trigger
  const {
    data: gamesForGroup,
    mutate: updateGamesForGroup,
    isLoading
  } = useReqListItemsForGuidesGroupBlock(
    selectedBlock && isGuidesBlock(blockType, selectedBlock)
      ? selectedBlockId
      : undefined
  );

  const {
    data: itemsGroupOrGuideSpotsItems,
    mutate: updateItemsForItemsGroupOrGuideSpots
  } = useReqListItemsForItemsGroupOrGuideSpotsBlock(
    blockType === BlockType.GuideSpots ? selectedBlockId : undefined
  );

  const {
    data: eventsGroupItems,
    isLoading: isEventItemsLoading,
    isError: isEventItemsError,
    mutate: updateItemsForEventsGroup
  } = useReqListItemsForEventsGroupBlock(
    selectedBlock && isEventsBlock(blockType, selectedBlock)
      ? selectedBlockId
      : undefined
  );

  const {
    data: listingGroupItems,
    isLoading: isListingGroupItemsLoading,
    isError: isListingGroupItemsError,
    mutate: updateItemsForListingsGroup
  } = useReqListItemsForListingsGroupBlock(
    selectedBlock && isListingsBlock(blockType, selectedBlock)
      ? selectedBlockId
      : undefined
  );

  const {
    data: entityBlockItems,
    isLoading: isEntityBlockItemsLoading,
    isError: isEntityBlockItemsError,
    mutate: updateItemsForEntitiesGroup
  } = useReqListItemsForEntitiesGroupBlock(
    selectedBlock && isEntitiesBlock ? selectedBlockId : undefined
  );

  async function updateItemsForEntitiesGroupItemsAsync(items?: IAppEntity[]) {
    return await updateItemsForEntitiesGroup(
      items?.map((d) => {
        return { item: d } as IBlockItem;
      }),
      true
    );
  }

  const {
    data: videosGroupItems,
    isLoading: isVideosGroupItemsLoading,
    isError: isVideosGroupItemsError,
    mutate: updateItemsForVideosGroup
  } = useReqListItemsForVideosGroupBlock(
    selectedBlock && isVideosBlock ? selectedBlockId : undefined
  );

  const { data: videos, isLoading: isVideosLoading } = useReqListVideosAsync(
    selectedBlock && isVideosBlock ? true : undefined
  );

  const searchEntities = useCallback(
    async (
      query: string,
      entityFilter: Filter,
      setAllEntities: (entities: any) => void,
      entityName: string
    ) => {
      const request: ISearchRequest = {
        query: query ?? '',
        order: Order.Relevance,
        entityFilter: entityFilter,
        location: {
          latitude: 51,
          longitude: -0.2
        },
        take: 10,
        liveEventsOnly: true
      };

      const { data: rankedResultItems, error } = await searchOperations.search(
        request
      );

      if (!rankedResultItems || error) {
        toast.error(`No ${entityName} found`);
        return;
      }

      let entities: IAppEntity[] = rankedResultItems.map((e) => {
        let entity: IAppEntity = {};
        const blockEntityType = getBlockEntityType(e.$type);
        switch (blockEntityType) {
          case BlockEntityType.Guide:
            entity = rankedResultItemConverter.convertToGuide(e);
            break;
          case BlockEntityType.Spot:
            entity = rankedResultItemConverter.convertToItem(e);
            break;
          case BlockEntityType.Event:
            entity = rankedResultItemConverter.convertToEvent(e);
            break;
          case BlockEntityType.Listing:
            entity = rankedResultItemConverter.convertToListing(e);
            break;
        }
        return entity;
      });

      setAllEntities(entities);
    },
    []
  );

  useEffect(() => {
    if (
      !isShowGroupGames &&
      allGamesNamesQueryValue &&
      allGamesNamesQueryValue.length > 2
    ) {
      searchEntities(
        allGamesNamesQueryValue,
        Filter.Game,
        setAllGuides,
        'guides'
      );
    }
  }, [isShowGroupGames, allGamesNamesQueryValue, searchEntities]);
  useEffect(() => {
    if (
      isAllEventsSelected &&
      allEventsNamesQueryValue &&
      allEventsNamesQueryValue.length > 2
    ) {
      searchEntities(
        allEventsNamesQueryValue,
        Filter.EventActive,
        setAllEvents,
        'events'
      );
    }
  }, [isAllEventsSelected, allEventsNamesQueryValue, searchEntities]);

  useEffect(() => {
    if (
      isAllListingsSelected &&
      allListingsNamesQueryValue &&
      allListingsNamesQueryValue.length > 2
    ) {
      searchEntities(
        allListingsNamesQueryValue,
        Filter.ListingActive,
        setAllListings,
        'listing'
      );
    }
  }, [isAllListingsSelected, allListingsNamesQueryValue, searchEntities]);

  useEffect(() => {
    if (
      isAllEntitiesSelected &&
      allEntitiesNamesQueryValue &&
      allEntitiesNamesQueryValue.length > 2
    ) {
      searchEntities(
        allEntitiesNamesQueryValue,
        Filter.ActiveEntities,
        setAllEntities,
        'entity'
      );
    }
  }, [isAllEntitiesSelected, allEntitiesNamesQueryValue, searchEntities]);

  const searchVideos = useCallback(
    (queryText: string) => {
      const value = queryText.toLowerCase();
      if (value && videos) {
        const filteredVideos = videos.filter(
          (item) =>
            item.title?.toLowerCase().includes(value) ||
            item.caption.toLowerCase().includes(value) ||
            item.user.email.toLowerCase().includes(value) ||
            item.user.firstName?.toLowerCase().includes(value) ||
            item.user.lastName?.toLowerCase().includes(value) ||
            item.user.username?.toLowerCase().includes(value) ||
            item.user.displayName?.toLowerCase().includes(value)
        );
        setAllVideos(filteredVideos);
      } else {
        setAllVideos(videos);
      }
    },
    [videos]
  );

  useEffect(() => {
    if (
      isAllVideosSelected &&
      allVideosNamesQueryValue &&
      allVideosNamesQueryValue.length > 0
    ) {
      searchVideos(allVideosNamesQueryValue);
    }
  }, [isAllVideosSelected, allVideosNamesQueryValue, searchVideos]);

  // Filter out games that are part of the current group
  const games: IGame[] =
    isShowGroupGames && gamesForGroup
      ? gamesForGroup
      : allGuides?.filter(
          (game) => !gamesForGroup?.some((i) => i.id === game.id)
        ) || [];

  const events: IEvent[] =
    (!isAllEventsSelected && eventsGroupItems) ||
    allEvents?.filter(
      (event) => !eventsGroupItems?.some((e) => e.id === event.id)
    ) ||
    [];

  const listings: IListing[] =
    (!isAllListingsSelected && listingGroupItems) ||
    allListings?.filter(
      (listing) => !listingGroupItems?.some((l) => l.id === listing.id)
    ) ||
    [];

  const entities: IAppEntity[] =
    (!isAllEntitiesSelected && entityBlockItems?.map((ebi) => ebi.item)) ||
    allEntities?.filter(
      (entity) => !entityBlockItems?.some((egi) => egi.id === entity.id)
    ) ||
    [];

  const blockVideos: IVideo[] =
    (!isAllVideosSelected && videosGroupItems) ||
    allVideos?.filter(
      (video) => !videosGroupItems?.some((l) => l.id === video.id)
    ) ||
    [];

  const {
    data: guideItems,
    isLoading: isGuideItemsLoading,
    isError: isGuideItemsError
  } = useReqListItems(
    blockType === BlockType.GuideSpots ? selectedBlock?.gameId! : undefined
  );

  const filteredGuideSpotsBlockItems = !isAllItemsSelected
    ? itemsGroupOrGuideSpotsItems
    : guideItems?.filter(
        (guideItem) =>
          !itemsGroupOrGuideSpotsItems?.some((gsbi) => gsbi.id === guideItem.id)
      );

  const handleDeleteBlock = async () => {
    if (!blockToDelete) {
      toast.error('No block selected to delete');
      return;
    }
    const isDeleted = await deleteBlock(blockToDelete);
    isDeleted && setBlockToDelete(undefined);
  };

  const handleCreateBlock = async (newBlock: IBlock) => {
    if (newBlock.name === '') {
      toast.error('Block must have a name to be added');
      return;
    }

    const isAdded = await addBlock(newBlock);
    if (isAdded) {
      setIsAddBlockModalOpen(false);
      return;
    }
  };

  const handleUpdateBlock = async (block: IBlock) => {
    if (!blockToUpdate) {
      toast.error('No block selected to update');
      return;
    }
    await editBlockAsync(block);
    setBlockToUpdate(undefined);
  };

  const selectBlockForEditing = async (block: IBlock) => {
    const blockToUpdate = blocks.find((b) => b.id === block.id);
    setBlockToUpdate(blockToUpdate);
    setIsUpdateModalOpen(true);
  };

  function handleOnClickTab(currentPath: string) {
    const basePath = path.replace('*', `manage/${currentPath}`);
    history.push(`${basePath}`);
  }

  const columns = React.useMemo(() => blockTableColumns, []);

  const deleteBlockPopup = (
    <ConfirmDeletePopup
      isModalOpen={!!blockToDelete}
      onSubmit={handleDeleteBlock}
      skin="block"
      title={blockToDelete?.name || 'Unnamed Block'}
      closeModal={() => setBlockToDelete(undefined)}
    />
  );

  const containerTitle = 'Blocks';

  const openBlockModalButton = (
    <BindyIcon
      className="z-50 absolute ml-auto mt-2"
      size="lg"
      onClick={() => setIsAddBlockModalOpen(!isAddBlockModalOpen)}
    >
      <BiPencil
        size="1.5rem"
        className="absolute top-0 bottom-0 right-0 left-0 m-auto cursor-pointer text-orange-500"
      />
    </BindyIcon>
  );

  const blockTypeOptions = EnumHelper.getEnumValuesForFlyout(BlockType).filter(
    (b) => ManualBlockNames.includes(b.value)
  );

  useEffect(() => {
    switch (selectedBlock?.type) {
      case BlockType.SuggestedListings:
        return setShouldUpdateSuggestedBlockNearMe(
          !(listingGroupItems && listingGroupItems?.length > 0)
        );
      case BlockType.SuggestedEvents:
        return setShouldUpdateSuggestedBlockNearMe(
          !(eventsGroupItems && eventsGroupItems?.length > 0)
        );
      case BlockType.SuggestedGuides:
        return setShouldUpdateSuggestedBlockNearMe(
          !(gamesForGroup && gamesForGroup?.length > 0)
        );
    }
  }, [eventsGroupItems, gamesForGroup, listingGroupItems, selectedBlock]);

  const listingGroupColumns = useMemo(() => {
    const listingGroupItemsTableColumns: Column<IListing>[] = [
      {
        Header: 'Image',
        accessor: (row) => {
          return row?.images![0];
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="w-16 h-16 flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <img
                  className="rounded-md object-contain w-full h-5/6"
                  src={cellInfo.cell.value}
                  alt="listing icon"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return listingGroupItemsTableColumns;
  }, []);

  const eventGroupColumns = useMemo(() => {
    const eventGroupItemTableColumns: Column<IEvent>[] = [
      {
        Header: 'Image',
        accessor: (row) => {
          return row?.images![0];
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="w-16 h-16 flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <img
                  width={'80%'}
                  height={'80%'}
                  className="rounded-md"
                  src={cellInfo.cell.value}
                  alt="event icon"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Start Date',
        accessor: (row) => {
          return `${formatDate(row.startDate!)}`;
        }
      },
      {
        Header: 'End Date',
        accessor: (row) => {
          const isExpired = row.endDate && new Date(row.endDate) < new Date();
          return (
            <div className={isExpired ? 'text-error1' : ''}>
              {row?.endDate && formatDate(row.endDate)}
              {isExpired ? ' EXPIRED!' : ''}
              {!row.isActive ? ' INACTIVE!' : ''}
            </div>
          );
        }
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return eventGroupItemTableColumns;
  }, []);

  const videoGroupColumns = useMemo(() => {
    const videoGroupItemsTableColumns: Column<IVideo>[] = [
      {
        Header: 'Video',
        accessor: (row) => {
          return row?.url;
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <div className="rounded-md object-contain w-full border-primaryTeal border-2">
                  <ReactPlayer
                    url={ImageKit.withSizing(
                      ImageKit.convertVideoUrl(cellInfo.cell.value)
                    )}
                    width={200}
                    height={200}
                    controls
                  />
                </div>
              )}
            </div>
          );
        }
      },
      {
        Header: 'Caption',
        accessor: (row) => {
          return row?.caption.substring(0, 150) + '...';
        }
      },
      {
        Header: 'Video Id',
        accessor: (row) => {
          return (
            <div className="flex flex-col items-center justify-center text-center">
              <h2>{row.id}</h2>
              <Button
                className="flex justify-center items-center mt-4"
                onClick={async () => await copyToClipboardAsync(row.id!)}
              >
                Copy Video Id <FaCopy className="ml-2" />
              </Button>
            </div>
          );
        }
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return videoGroupItemsTableColumns;
  }, []);

  const entityGroupColumns = useMemo(() => {
    function imageAccessor(row: IAppEntity): string {
      return (
        (row as IGame).versions?.[0].icon ??
        (row as IListing | IEvent | ISpotVersion).images?.[0] ??
        ''
      );
    }
    function nameAccessor(row: IAppEntity): string {
      return (
        (row as IGame).versions?.[0].name ??
        (row as IListing | IEvent | ISpotVersion).name ??
        ''
      );
    }
    function typeAccessor(row: IAppEntity): string {
      const blockEntityType = getBlockEntityType(row.$type);
      switch (blockEntityType) {
        case BlockEntityType.Event:
          return 'Event';
        case BlockEntityType.Guide:
          return 'Guide';
        case BlockEntityType.Listing:
          return 'Listing';
        case BlockEntityType.Spot:
          return 'Item';
      }
      return 'Unknown Type';
    }
    const entityGroupItemsTableColumns: Column<IAppEntity>[] = [
      {
        Header: 'Image',
        accessor: imageAccessor,
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="w-16 h-16 flex justify-center items-center flex-col">
              {cellInfo.cell.value && (
                <img
                  className="rounded-md object-contain w-full h-5/6"
                  src={cellInfo.cell.value}
                  alt="icon"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Name',
        accessor: nameAccessor
      },
      {
        Header: 'Type',
        accessor: typeAccessor
      },
      {
        Header: 'End Date',
        accessor: (row) => {
          const event = row as IEvent;
          const isExpired =
            event?.endDate && new Date(event?.endDate || '') < new Date();
          return (
            <div className={isExpired ? 'text-error1' : ''}>
              {event?.endDate && formatDate(event?.endDate)}
              {isExpired ? ' EXPIRED!' : ''}
              {event && event.isActive === false ? ' INACTIVE!' : ''}
            </div>
          );
        }
      },
      {
        Header: 'Description',
        accessor: (row) => {
          return (
            <div className="truncate">
              {
                entityBlockItems?.find((ebi) => ebi.item.id === row.id)
                  ?.description
              }
            </div>
          );
        }
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return entityGroupItemsTableColumns;
  }, [entityBlockItems]);

  async function handleConfirmDescriptionChangeAsync() {
    if (!selectedBlock?.id || !selectedBlockItem?.item.id) {
      toast.error('No block or entity selected.');
      return;
    }
    setIsDescriptionUpdateLoading(true);

    const { error } = await blockOperations.updateItemDescriptionAsync(
      selectedBlock?.id,
      selectedBlockItem.item.id,
      newDescription
    );
    setIsDescriptionUpdateLoading(false);
    if (error) {
      toast.error('Failed to update description, please try again.');
      return;
    }
    toast.success('Successfully updated description.');
    setSelectedBlockItem(undefined);
    updateItemsForEntitiesGroup(
      entityBlockItems?.map((ebi) => {
        if (ebi.item.id === selectedBlockItem.item.id) {
          return {
            ...ebi,
            description: newDescription
          };
        }
        return ebi;
      }) || [],
      false
    );
  }

  const isValidDescription =
    !newDescription || newDescription.length <= maxDescriptionLength;

  const updateDescriptionModal = (
    <Modal
      isMenuOpen={!!selectedBlockItem}
      overlay
      position="fixedCenter"
      size="sm"
      className="rounded-xl "
      styles={{ height: '280px', width: '550px' }}
    >
      <div className="text-lg font-bold mr-96">Description</div>
      <div className="relative">
        <textarea
          value={newDescription}
          autoFocus={true}
          onChange={(e) => setNewDescription(e.currentTarget.value)}
          className="w-full p-1 border border-surfaceOutline rounded-lg"
          style={{ height: '175px' }}
        />
        <span
          className={`text-xs absolute bottom-5 right-2 ${
            !isValidDescription && 'text-error1'
          }`}
        >
          {newDescription?.length}/{maxDescriptionLength}
        </span>
      </div>
      {!isValidDescription && (
        <span className="text-error1">
          Exceeded text limit of {maxDescriptionLength}
        </span>
      )}
      <div className="flex flex-row w-full mt-1">
        <div
          className="cursor-pointer bg-error1 rounded-lg border-primaryTeal text-white w-48"
          onClick={() => {
            setNewDescription(undefined);
            setSelectedBlockItem(undefined);
          }}
        >
          Cancel
        </div>
        <div className="flex flex-grow" />
        {isDescriptionUpdateLoading ? (
          <Spinner />
        ) : (
          <div
            className={`${
              isValidDescription ? 'cursor-pointer' : 'cursor-not-allowed'
            } bg-primaryTeal rounded-lg border-error1 text-white w-48`}
            onClick={async () => {
              if (isValidDescription) {
                setNewDescription(undefined);
                await handleConfirmDescriptionChangeAsync();
              }
            }}
          >
            Confirm
          </div>
        )}
      </div>
    </Modal>
  );

  return (
    <div className="w-full">
      <Modal
        isMenuOpen={isAddBlockModalOpen}
        overlay
        position="fixed"
        size="3xl"
        className="rounded-xl right-1/4 top-10"
        styles={{ maxHeight: '90vh', minHeight: '550px' }}
      >
        <BlockManage
          title={'Create new block'}
          setIsModalOpen={setIsAddBlockModalOpen}
          isBlockPage={true}
          onClickUpdateSubmit={handleCreateBlock}
          onCreateDiscoverBlock={handleCreateBlock}
          onClickCloseUpdateModal={() => setIsAddBlockModalOpen(false)}
        />
      </Modal>
      {updateDescriptionModal}
      <span className="mx-auto block text-4xl text-black mt-1">
        {containerTitle}
      </span>
      <div className="flex flex-row">
        {deleteBlockPopup}
        <div className="pl-12 py-8 h-full w-2/5">
          <FlyoutMenu
            activeLabel={
              blockTypeOptions.find((o) => o.label === BlockType[blockType])
                ?.label
            }
            isEqual={isEqual}
            size="sm"
            isMenuOpen={isSelectTypeOpen}
            setIsMenuOpen={setIsSelectTypeOpen}
            items={blockTypeOptions}
            onChange={(e) => {
              setBlockType(BlockType[e.label]);
              handleOnClickTab(e?.label!);
            }}
          />
          <BlockTable
            onClickDeleteIcon={setBlockToDelete}
            onClickEditIcon={selectBlockForEditing}
            onClickRow={setSelectedBlockId}
            tableColumns={columns}
            blocks={blocks.sort((a, b) => {
              const date1 = new Date(a.updatedAt || '').getTime();
              const date2 = new Date(b.updatedAt || '').getTime();
              return date2 - date1;
            })}
            children={openBlockModalButton}
            rowSelectedId={selectedBlockId}
          />
        </div>
        <div className="mx-8 pt-8 w-full">
          {selectedBlock && isGuidesBlock(blockType, selectedBlock) ? (
            <BlockGamesEditor
              onChangeSearchValue={setAllGamesNameQueryValue}
              updateGamesForBlock={updateGamesForGroup}
              games={games}
              isLoading={isLoading}
              block={selectedBlock}
              isShowMyGames={isShowGroupGames}
              setIsShowMyGames={setIsShowGroupGames}
            />
          ) : (
            <>
              {selectedBlock?.type === BlockType.SuggestedGuides && (
                <div
                  className="w-auto px-10 text-center font-nunito text-2xl relative flex flex-col items-center justify-center rounded-md border-2 border-gray-400"
                  style={{ height: '80vh' }}
                >
                  The selected suggested guides block is location based and
                  therefore can't be updated with guides manually
                </div>
              )}
            </>
          )}

          {selectedBlock &&
            blockType === BlockType.GuideSpots &&
            filteredGuideSpotsBlockItems && (
              <BlockItemsEditor
                tableName={'Guide Spots Management'}
                isGuideItemsLoading={isGuideItemsLoading}
                selectedBlock={selectedBlock}
                isGuideItemsError={isGuideItemsError}
                itemVersions={filteredGuideSpotsBlockItems}
                isAllItemsSelected={isAllItemsSelected}
                setIsAllItemsSelected={setIsAllItemsSelected}
                updateItemsForBlock={updateItemsForItemsGroupOrGuideSpots}
              />
            )}
          {selectedBlock && isEventsBlock(blockType, selectedBlock) ? (
            <ManualBlockEntityEditor<IEvent>
              entityName="Event"
              columns={eventGroupColumns}
              entityType={BlockEntityType.Event}
              currentBlockItemsEntities={eventsGroupItems ?? []}
              tableName={'Events Group Management'}
              onChangeSearchValue={setAllEventsNameQueryValue}
              selectedBlock={selectedBlock}
              entities={events}
              isBlockItemsLoading={isEventItemsLoading}
              isBlockItemsError={isEventItemsError}
              isAllEntitiesSelected={isAllEventsSelected}
              setIsAllEntitiesSelected={setIsAllEventsSelected}
              updateItemsForBlock={updateItemsForEventsGroup}
              showGuideNameColumn={true}
            />
          ) : (
            <>
              {selectedBlock?.type === BlockType.SuggestedEvents && (
                <div
                  className="w-auto px-10 text-center font-nunito text-2xl relative flex flex-col items-center justify-center rounded-md border-2 border-gray-400"
                  style={{ height: '80vh' }}
                >
                  The selected suggested event block is location based and
                  therefore can't be updated with events manually
                </div>
              )}
            </>
          )}
          {selectedBlock && isListingsBlock(blockType, selectedBlock) ? (
            <ManualBlockEntityEditor<IListing>
              entityName="Listing"
              entityType={BlockEntityType.Listing}
              currentBlockItemsEntities={listingGroupItems ?? []}
              tableName="Listings Group Management"
              columns={listingGroupColumns}
              onChangeSearchValue={setAllListingsNameQueryValue}
              selectedBlock={selectedBlock}
              entities={listings}
              isBlockItemsLoading={isListingGroupItemsLoading}
              isBlockItemsError={isListingGroupItemsError}
              isAllEntitiesSelected={isAllListingsSelected}
              setIsAllEntitiesSelected={setIsAllListingsSelected}
              updateItemsForBlock={updateItemsForListingsGroup}
              showGuideNameColumn={true}
            />
          ) : (
            <>
              {selectedBlock?.type === BlockType.SuggestedListings && (
                <div
                  className="w-auto px-10 text-center font-nunito text-2xl relative flex flex-col items-center justify-center rounded-md border-2 border-gray-400"
                  style={{ height: '80vh' }}
                >
                  The selected suggested listings block is location based and
                  therefore can't be updated with listings manually
                </div>
              )}
            </>
          )}

          <div>
            {isVideosLoading && isVideosBlock ? (
              <Spinner />
            ) : (
              <div>
                {selectedBlock && isVideosBlock && (
                  <ManualBlockEntityEditor<IVideo>
                    entityName="Video"
                    entityType={BlockEntityType.Video}
                    tableName="Videos Group Management"
                    columns={videoGroupColumns}
                    currentBlockItemsEntities={videosGroupItems ?? []}
                    onChangeSearchValue={setAllVideosNameQueryValue}
                    selectedBlock={selectedBlock}
                    entities={blockVideos}
                    isBlockItemsLoading={isVideosGroupItemsLoading}
                    isBlockItemsError={isVideosGroupItemsError}
                    isAllEntitiesSelected={isAllVideosSelected}
                    setIsAllEntitiesSelected={setIsAllVideosSelected}
                    updateItemsForBlock={updateItemsForVideosGroup}
                    showGuideNameColumn={true}
                    tableRowHeight={'h-60'}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            {selectedBlock && isEntitiesBlock && (
              <ManualBlockEntityEditor<IAppEntity>
                entityName="Entity"
                entityType={BlockEntityType.None}
                tableName="Entity Group Management"
                columns={entityGroupColumns}
                currentBlockItemsEntities={entityBlockItems || []}
                onChangeSearchValue={setAllEntitiesNameQueryValue}
                selectedBlock={selectedBlock}
                entities={entities}
                isBlockItemsLoading={isEntityBlockItemsLoading}
                isBlockItemsError={isEntityBlockItemsError}
                isAllEntitiesSelected={isAllEntitiesSelected}
                setIsAllEntitiesSelected={setIsAllEntitiesSelected}
                updateItemsForBlock={updateItemsForEntitiesGroupItemsAsync}
                showGuideNameColumn={true}
                onClickManage={(entity) => {
                  setSelectedBlockItem({
                    item: { id: entity.id },
                    description:
                      entityBlockItems?.find((ebi) => ebi.item.id === entity.id)
                        ?.description || ''
                  } as IBlockItem);
                }}
              />
            )}
          </div>
        </div>
        <div className="w-auto">
          <Modal
            isMenuOpen={blockToUpdate !== undefined && isUpdateModalOpen}
            overlay
            position="fixed"
            size="3xl"
            className="rounded-xl right-1/4 top-10"
            styles={{ maxHeight: '90vh', minHeight: '550px' }}
          >
            {blockToUpdate ? (
              <BlockManage
                title={'Update block'}
                key={blockToUpdate.id}
                block={blockToUpdate}
                setIsModalOpen={setIsAddBlockModalOpen}
                onClickUpdateSubmit={handleUpdateBlock}
                shouldUpdateSuggestedBlockNearMe={
                  selectedBlock && shouldUpdateSuggestedBlockNearMe
                }
                isBlockPage={true}
                onClickCloseUpdateModal={() => setIsUpdateModalOpen(false)}
                guideItems={filteredGuideSpotsBlockItems}
                updateItemsForGuideSpots={updateItemsForItemsGroupOrGuideSpots}
              />
            ) : (
              'Error, no block selected'
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default BlockEditor;
