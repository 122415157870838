export enum InteractionEventType {
  GuideDownloaded = 1,

  SpotSpotted = 2,
  SpotQuestionCompleted = 3,
  GuideQuestionCompleted = 4,

  GapCreated = 5,
  SapCreated = 6,
  GapAwarded = 7,
  SapAwarded = 8,
  GapUpvoted = 9,
  SapUpvoted = 10,

  GuideAddedToCollection = 11,
  SpotAddedToCollection = 12,
  GapAddedToCollection = 13,
  SapAddedToCollection = 14,
  ListingAddedToCollection = 15,
  EventAddedToCollection = 16,

  ListingCardGameSwipeYes = 17,
  ListingCardGameSwipeNo = 18,

  GetGuideScroll = 19,
  GetItemScroll = 20,
  GetListingScroll = 21,
  GetEventScroll = 22,

  VisitCategorySubPage = 23,

  TagSearch = 24
}
