import { useState } from 'react';
import { ICombo } from 'Colugo/interfaces/tags';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';
import {
  useReqListFilterGroups,
  useReqListFilterGroupsByCombo
} from 'Colugo/operations/tags';
import ItemUpdateForm from 'component/utility/ItemUpdateForm';
import {
  Button,
  CardHeader,
  ErrorPage,
  Input,
  Spinner
} from '@bindystreet/bindystreet.kit.react';
import { MdClose } from 'react-icons/md';

type Props = {
  combo: ICombo;
  onClickUpdateSubmit: (combo: ICombo) => void;
  onClickCloseModal: () => void;
};

function ComboUpdateForm(props: Props) {
  const { combo, onClickUpdateSubmit, onClickCloseModal } = props;
  const [localCombo, setLocalCombo] = useState<ICombo>(combo);

  const {
    data: filterGroupsForCombo,
    isLoading: isFilterGroupsForComboLoading,
    mutate: mutateFilterGroupsForCombo,
    isError: isFilterGroupsForComboError
  } = useReqListFilterGroupsByCombo(combo?.id!);

  const {
    data: allFilterGroups,
    isLoading: isFilterGroupLoading,
    isError: isFilterGroupError
  } = useReqListFilterGroups();

  const submitEnabled = localCombo.name;

  const submitForUpdating = () => {
    onClickUpdateSubmit(localCombo);
  };

  if (isFilterGroupsForComboLoading || isFilterGroupLoading) {
    return <Spinner />;
  }

  if (isFilterGroupsForComboError || isFilterGroupError) {
    return (
      <ErrorPage>
        <span>{'Unable to load filterGroups from server.'}</span>
      </ErrorPage>
    );
  }
  return (
    <div className="flex flex-row w-full px-6">
      <div className="w-full">
        <CardHeader
          title={'Update Combo Information'}
          fontWeight="font-semibold"
        />
        <div className="w-full text-left mt-2">
          <span className="font-semibold text-lg">{'Combo Name:'}</span>
          <Input
            value={localCombo.name}
            onChange={(e) =>
              setLocalCombo({
                ...localCombo,
                name: e.currentTarget.value
              })
            }
          />
        </div>
        <ItemUpdateForm
          selectedItem={combo}
          itemChildren={filterGroupsForCombo!}
          allChildren={allFilterGroups || []}
          filterGroupTaggingType={FilterGroupTagging.AddFilterGroupToCombo}
          mutate={mutateFilterGroupsForCombo}
          filterGroup={combo}
        />
        <div className="flex flex-row mt-10 justify-evenly">
          <Button onClick={submitForUpdating} disabled={!submitEnabled}>
            {'Submit'}
          </Button>
          <Button onClick={onClickCloseModal} skin="secondary">
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ComboUpdateForm;
