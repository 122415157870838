import {
  BindyIcon,
  Input,
  InputKeyboardEvent,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { useClickAway } from 'ahooks';
import { ICategory } from 'Colugo/interfaces/common';
import { ITag, ITagRelevance } from 'Colugo/interfaces/tags';
import { FilterGroupTagging } from 'Colugo/interfaces/tags/FilterGroupTagging';
import ConfirmDeletePopup from 'component/utility/ConfirmDeletePopup';
import React, { useRef, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { toast } from 'react-toastify';
import RelevantTagTable, { relevantTagTableColumns } from './RelevantTagTable';
import ItemCreate from '../../utility/ItemCreate';
import TagCreateRelevance from './TagCreateRelevance';
import TagRelevanceUpdateForm from './TagRelevanceUpdateForm';
import TagTable, { tagTableColumns } from './TagTable';
import TagUpdateForm from './TagUpdateForm';

type Props = {
  tags: ITag[];
  categories: ICategory[];
  deleteTag: (tag: ITag) => Promise<boolean>;
  addTag: (tag: ITag) => Promise<boolean>;
  createOrUpdateTagRelevance: (
    newTagRelevance: ITagRelevance
  ) => Promise<boolean>;
  updateTag: (tag: ITag) => Promise<boolean>;
  updateTagRelevance: (tagRelevance: ITagRelevance) => Promise<boolean>;
  relevantTags: ITag[];
  setTagIdForRelevantTags: (tagId: string) => void;
};

function TagEditor(props: Props) {
  const {
    tags,
    deleteTag,
    addTag,
    createOrUpdateTagRelevance,
    updateTag,
    updateTagRelevance,
    relevantTags,
    setTagIdForRelevantTags,
    categories
  } = props;

  const [tagToDelete, setTagToDelete] = useState<undefined | ITag>(undefined);
  const [isAddTagInputFocused, setIsAddTagInputFocused] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTagRelevanceUpdateModalOpen, setIsTagRelevanceUpdateModalOpen] =
    useState(false);
  const [localTag, setLocalTag] = useState<ITag>();

  const [tagToUpdate, setTagToUpdate] = useState<undefined | ITag>(undefined);

  const [tagRelevanceToUpdate, setTagRelevanceToUpdate] = useState<
    undefined | ITagRelevance
  >(undefined);

  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isAddTagRelevanceModalOpen, setIsAddTagRelevanceModalOpen] =
    useState(false);
  const newTagAreaRef = useRef<any>();

  useClickAway(() => {
    setIsAddTagInputFocused(false);
  }, newTagAreaRef);

  const relevantTagsRounded = relevantTags.map((tag) => {
    if (!tag.relevance) {
      tag.relevance = 0;
    }

    tag.relevance = Math.round(tag.relevance * 1000) / 1000;
    return tag;
  });

  const handleAddTag = async () => {
    if (!localTag) {
      toast.error('No tag information provided');
      return;
    }
    localTag.id = undefined;
    if (!localTag.name) {
      toast.error('Error, Spot name cannot be empty.');
      return;
    }

    const tagsToAdd: ITag[] = localTag.name
      .split(/\r?\n/)
      .filter((tagName) => !!tagName && tagName.match(/^ *$/) === null)
      .map((name) => ({ id: undefined!, name }));

    setLocalTag({ ...localTag, name: '' });

    for (let tag of tagsToAdd) {
      if (tag?.name !== '') {
        addTag(tag);
      }
    }

    setLocalTag({ ...localTag, name: '' });
  };

  const dispatchAddTagEnter = async (e: InputKeyboardEvent) => {
    e.key === 'Enter' && (await handleAddTag());
  };

  const handleDeleteTag = async () => {
    if (!tagToDelete) {
      toast.error('No tag selected to delete');
      return;
    }
    const isDeleted = await deleteTag(tagToDelete);
    isDeleted && setTagToDelete(undefined);
  };

  const handleUpdateTag = async (tag: ITag) => {
    if (!tagToUpdate) {
      toast.error('No tag selected to update');
      return;
    }

    await updateTag(tag);
    setTagToUpdate(undefined);
  };

  const handleUpdateTagRelevance = async (tagRelevance: ITagRelevance) => {
    if (
      !tagRelevance ||
      !tagRelevance.relevance ||
      tagRelevance.relevance === undefined
    ) {
      toast.error('No tag relevance selected to update');
      return;
    }

    if (tagRelevance.relevance < 0 || tagRelevance.relevance > 1) {
      toast.error(
        'An update of this magniture would create a negative relevance, or a relevance greater than 1'
      );
      return;
    }

    await updateTagRelevance(tagRelevance);

    setTagRelevanceToUpdate(undefined);
  };

  const handleCreateTag = async (newTag: ITag) => {
    if (!newTag?.name) {
      toast.error('Tag must have a name to be added');
      return;
    }

    const isAdded = await addTag(newTag);
    if (isAdded) {
      setIsAddTagModalOpen(false);
      return;
    }
  };

  const handleClickFeaturedIcon = async (tag: ITag) => {
    if (!tag) {
      toast.error('No tag selected to update');
      return;
    }
    tag.isFeatured = !tag.isFeatured;
    await updateTag(tag);
    setTagToUpdate(undefined);
  };

  const handleCreateTagRelevance = async (newTagRelevance: ITagRelevance) => {
    if (!newTagRelevance.relevance || Math.abs(newTagRelevance.relevance) > 1) {
      toast.error('Tag relevance must be between 0 and 1');
      return;
    }

    const isAdded = await createOrUpdateTagRelevance(newTagRelevance);
    if (isAdded) {
      setIsAddTagRelevanceModalOpen(false);
      relevantTags.push({
        id: newTagRelevance.tag2Id,
        name: newTagRelevance.name
      });
      return;
    }
  };

  const tagColumns = React.useMemo(() => tagTableColumns, []);
  const relevantTagColumns = React.useMemo(() => relevantTagTableColumns, []);

  const deleteTagPopup = (
    <ConfirmDeletePopup
      isModalOpen={!!tagToDelete}
      onSubmit={handleDeleteTag}
      skin="tag"
      title={tagToDelete?.name || 'Unnamed Tag'}
      closeModal={() => setTagToDelete(undefined)}
    />
  );

  const containerTitle = 'Tags';

  const createTagButton = (
    <BindyIcon
      className="z-50 absolute ml-auto mt-2"
      size="lg"
      onClick={() => setIsAddTagModalOpen(!isAddTagModalOpen)}
    >
      <BiPencil
        size="1.5rem"
        className="absolute top-0 bottom-0 right-0 left-0 m-auto cursor-pointer text-orange-500"
      />
    </BindyIcon>
  );

  const createTagRelevanceButton = (
    <BindyIcon
      className="z-50 absolute ml-auto mt-2"
      size="lg"
      onClick={() => setIsAddTagRelevanceModalOpen(!isAddTagRelevanceModalOpen)}
    >
      <BiPencil
        size="1.5rem"
        className="absolute top-0 bottom-0 right-0 left-0 m-auto cursor-pointer text-orange-500"
      />
    </BindyIcon>
  );

  const selectTagForEditing = (tag: ITag) => {
    const tagToUpdate = tags.find((t) => t?.id === tag.id);
    setTagToUpdate(tagToUpdate);
    setIsModalOpen(true);
  };

  const selectTagRow = (tagId: string) => {
    setTagIdForRelevantTags(tagId!);
    setLocalTag({ ...localTag, id: tagId });
  };

  const selectTagRelevanceForEditing = (tagId: string) => {
    const currentRelevance = relevantTags.find((t) => t.id === tagId);
    const localTagRelevanceToUpdate: ITagRelevance = {
      relevance: currentRelevance && currentRelevance.relevance,
      tag2Id: tagId,
      tag1Id: localTag?.id
    };
    setTagRelevanceToUpdate(localTagRelevanceToUpdate);
    setIsTagRelevanceUpdateModalOpen(true);
  };

  const relevantTagsNoDuplicates = tags.filter(
    (t) =>
      relevantTags.find((t2) => t2.id === t.id) === undefined &&
      t.id !== localTag
  );

  return (
    <div className="w-full">
      <ItemCreate
        setIsModalOpen={setIsAddTagModalOpen}
        isModalOpen={isAddTagModalOpen}
        onCreateItem={handleCreateTag}
        itemTaggingType={FilterGroupTagging.AddTagToFilterGroup}
      />
      <TagCreateRelevance
        setIsModalOpen={setIsAddTagRelevanceModalOpen}
        isModalOpen={isAddTagRelevanceModalOpen}
        onCreateTagRelevance={handleCreateTagRelevance}
        tag1IdSelected={localTag?.id || ''}
        tags={relevantTagsNoDuplicates}
      />
      <span className="mx-auto block text-4xl text-black mt-4">
        {containerTitle}
      </span>
      <div className="flex flex-row pt-4">
        {deleteTagPopup}
        <div className="px-12 h-full w-full">
          <TagTable
            onClickDeleteIcon={setTagToDelete}
            onClickEditIcon={selectTagForEditing}
            onClickFeaturedIcon={handleClickFeaturedIcon}
            onClickRow={selectTagRow}
            tableColumns={tagColumns}
            tags={tags}
            children={createTagButton}
            rowSelectedId={localTag?.id || ''}
          />
          <div className="flex-row">
            <div
              className={`pt-auto ${
                isAddTagInputFocused ? 'bg-theme3' : 'bg-theme1'
              } mt-auto py-3 border-t border-theme3`}
            >
              <Input
                resizable={false}
                multiline={true}
                onFocus={() => setIsAddTagInputFocused(true)}
                onChange={(e) =>
                  setLocalTag({ ...localTag, name: e.currentTarget.value })
                }
                onKeyDown={dispatchAddTagEnter}
                size="md"
                className="mx-3"
                value={localTag?.name}
                placeholder="+ Type tag name or paste list and press 'enter'"
                roundedClass="rounded-sm"
              />
            </div>
          </div>
        </div>
        {relevantTags && localTag && (
          <RelevantTagTable
            key={localTag.id}
            tableColumns={relevantTagColumns}
            tags={relevantTagsRounded}
            children={createTagRelevanceButton}
            onClickRow={selectTagRelevanceForEditing}
          />
        )}

        <div className="w-full">
          <Modal
            isMenuOpen={tagToUpdate !== undefined && isModalOpen}
            position={'fixedCenter'}
            size="3xl"
            overlay
            className="rounded-xl pt-8 px-6 overflow-y-scroll -mt-32"
          >
            {tagToUpdate ? (
              <div>
                <TagUpdateForm
                  key={tagToUpdate.id}
                  tag={tagToUpdate}
                  categories={categories}
                  onClickUpdateSubmit={handleUpdateTag}
                  onClickCloseModal={() => setIsModalOpen(false)}
                />
              </div>
            ) : (
              'Error, no tag selected'
            )}
          </Modal>
        </div>
        <div className="w-full">
          <Modal
            isMenuOpen={
              tagRelevanceToUpdate !== undefined &&
              isTagRelevanceUpdateModalOpen
            }
            position={'fixedCenter'}
            size="lg"
            overlay
            className="h-64 rounded-xl pt-8 px-6"
          >
            {tagRelevanceToUpdate ? (
              <TagRelevanceUpdateForm
                key={tagRelevanceToUpdate.id}
                tagRelevance={tagRelevanceToUpdate}
                onClickUpdateSubmit={handleUpdateTagRelevance}
                onClickCloseModal={() =>
                  setIsTagRelevanceUpdateModalOpen(false)
                }
              />
            ) : (
              'Error, no tag selected'
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default TagEditor;
