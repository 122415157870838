import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Button, Checkbox, Modal } from '@bindystreet/bindystreet.kit.react';
import { BlockType, ContentType } from 'Colugo/interfaces/lobby/discover/enums';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import {
  reqAddBlockToSubPage,
  reqRemoveBlock,
  reqUpdateBlockOrder
} from 'provider/admin/methods';
import { BiCollapse, BiPlus } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { handleSetOrderableItems } from 'utility/general/handleSetOrderableItems';
import BlockManage from './BlockManage';
import { container } from 'tsyringe';
import { BlockOperations } from 'Colugo/operations/lobby';
import Table from 'component/utility/Table';
import { Column } from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';

const blockOperations = container.resolve(BlockOperations);

type Props = {
  subPage: IDiscoverSubPage;
  blocks?: IBlock[];
  setIsBlockContainerOpen: Dispatch<SetStateAction<boolean>>;
  handleOnChangeIsTourist: () => Promise<void>;
};

function BlocksContainer(props: Props) {
  const { subPage, setIsBlockContainerOpen, blocks, handleOnChangeIsTourist } =
    props;

  const [isAddBlockModalOpen, setIsAddBlockModalOpen] =
    useState<boolean>(false);

  const [selectedBlockId, setSelectedBlockId] = useState<undefined | string>(
    undefined
  );
  const [localBlocks, setLocalBlocks] = useState<IBlock[]>();
  const [blockToUpdate, setBlockToUpdate] = useState<IBlock | undefined>(
    undefined
  );
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const { data: categories } = useReqListCategories();

  useEffect(() => {
    const blocksWithType = (blocks || []).map((b) => {
      if (!b.type) {
        b.type = BlockType.NewGuides;
      }
      return b;
    });
    setLocalBlocks(blocksWithType);
  }, [blocks]);

  const columns = useMemo(() => {
    const discoverSubPageBlockTableColumns: Column<IBlock>[] = [
      {
        Header: 'Item Name',
        accessor: (row) => {
          return row.name;
        }
      },
      {
        Header: 'Type',
        accessor: (row) => {
          const blockType = BlockType[row.type!];
          return blockType;
        }
      },
      {
        Header: 'CardSize',
        accessor: 'cardSize'
      },
      {
        Header: 'Ad Freq',
        accessor: (row) => {
          const blockType = row.freeFrequency;
          return blockType;
        }
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return discoverSubPageBlockTableColumns;
  }, []);

  const openCreateBlockModal = (
    <div className="p-2 flex flex-row justify-between items-center w-auto">
      <div>
        <Button
          className="z-50 w-22"
          size="md"
          onClick={() => setIsAddBlockModalOpen(!isAddBlockModalOpen)}
        >
          <div className="flex flex-row">
            <BiPlus
              size="20"
              className=" m-auto cursor-pointer text-orange-500 ml-1"
            />
            <div className="ml-2 mr-3">{'Add'}</div>
          </div>
        </Button>
      </div>
      <div>
        <Button
          className="z-50 w-auto ml-2"
          size="md"
          onClick={() => setIsBlockContainerOpen(!subPage)}
          skin="secondary"
        >
          <div className="flex flex-row">
            <BiCollapse
              size="20"
              className="0 m-auto cursor-pointer text-orange-500 ml-1"
            />
            <div className="ml-2 mr-1">{'Close'}</div>
          </div>
        </Button>
      </div>
    </div>
  );

  const deleteBlock = async (blockToDelete: IBlock) => {
    if (!blockToDelete.id) {
      toast.error('Invalid block Id, please try again');
      return;
    }

    const { data, error } = await reqRemoveBlock(
      subPage?.id || '',
      blockToDelete.id
    );

    //Because we cant figure out how to return json on delete.
    if (!data && error?.status !== 404) {
      toast.error('Failed to remove block from sub page, please try again');
      return false;
    }

    const newBlocks =
      (localBlocks &&
        localBlocks.filter(
          (block: IBlock) => block.id !== blockToDelete?.id
        )) ||
      [];
    setLocalBlocks(newBlocks);
  };

  const handleChangeOrder = async (block: IBlock, position: number) => {
    const reqUpdateOrder = async () => {
      return await reqUpdateBlockOrder(subPage?.id || '', block.id!, position);
    };

    await handleSetOrderableItems(reqUpdateOrder, localBlocks!, setLocalBlocks);

    setLocalBlocks(localBlocks!.sort((a, b) => (a.order! > b.order! ? 1 : -1)));
    return true;
  };

  const handleAddBlock = async (block: IBlock) => {
    let newBlock: IBlock = block;
    if (
      block.type !== BlockType.GuideSpots &&
      block.type !== BlockType.EntitiesGroup
    ) {
      const { data, error } = await blockOperations.createAsync(block);
      if (!data || error) {
        toast.error('Block unable to be created');
        return;
      }
      newBlock = data!;
    }
    const { error } = await reqAddBlockToSubPage(
      subPage.id!,
      block.id || newBlock.id!
    );

    if (error) {
      toast.error(
        'Unable to add block to subpage, block may already be in subpage.'
      );
      return;
    }
    setLocalBlocks([
      ...(localBlocks || []),
      {
        ...block,
        order: localBlocks?.length || 0,
        id: block.id || newBlock.id
      }
    ]);

    toast.success('Block has been successfully added to the subpage');
    setIsAddBlockModalOpen(false);
  };

  const selectBlockForEditing = async (block: IBlock) => {
    block.category = (categories || []).find(
      (c) => c.id === block.category?.id
    );
    if (
      block.type === BlockType.GuideSpots ||
      block.type === BlockType.ZoneSpots
    ) {
      block.$type =
        'Dippy.Models.Lobby.Discover.Blocks.SpotsBlock, Dippy.Models';
    }

    setBlockToUpdate(block);
    setIsAddBlockModalOpen(false);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateBlock = async (blockToUpdate: IBlock) => {
    const { data: updatedBlock, error } = await blockOperations.updateAsync(
      blockToUpdate
    );

    if (!updatedBlock || error) {
      toast.error('Failed to update block, please try again.');
      return false;
    }

    const newBlocks = (localBlocks || []).map((localBlock) => {
      if (localBlock.id === blockToUpdate.id) {
        localBlock = updatedBlock;
      }
      if (!localBlock.type) {
        localBlock.type = BlockType.NewGuides;
      }

      return localBlock;
    });

    blockToUpdate.updatedAt = updatedBlock.updatedAt;

    setLocalBlocks(newBlocks);
    toast.success('Block details has been successfully updated ');
    setBlockToUpdate(undefined);
  };

  return (
    <div>
      <Modal
        overlay
        isMenuOpen={!!subPage}
        position="fixed"
        className="rounded-xl top-10"
        styles={{
          maxHeight: '90vh',
          minHeight: '550px',
          width: '65vw',
          marginRight: '10%'
        }}
      >
        {isAddBlockModalOpen ? (
          <BlockManage
            isBlockPage={false}
            onCreateDiscoverBlock={handleAddBlock}
            setIsModalOpen={setIsAddBlockModalOpen}
            onClickUpdateSubmit={handleUpdateBlock}
          />
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-col items-center">
              <div className="text-xl font-semibold">{subPage.name}</div>
              {!subPage.category && (
                <div className="flex flex-row mt-2">
                  <div className="text-xl font-medium mr-4">
                    Tourist Specific
                  </div>
                  <Checkbox
                    size="lg"
                    selectedColour="primaryButton"
                    onChange={handleOnChangeIsTourist}
                    checked={subPage.contentType === ContentType.Tourist}
                  />
                </div>
              )}
            </div>
            <div>
              <DndProvider backend={HTML5Backend}>
                <Table
                  onClickDeleteIcon={deleteBlock}
                  onClickManageIcon={selectBlockForEditing}
                  onChangeOrder={handleChangeOrder}
                  children={openCreateBlockModal}
                  onClickRow={setSelectedBlockId}
                  searchPlaceholderText={'blocks'}
                  tableColumns={columns}
                  isBlocksTable={true}
                  entities={localBlocks || []}
                  isModalView={true}
                  isOrderable={true}
                  shouldShowOrderArrows={false}
                  isEditable={true}
                  rowSelectedId={selectedBlockId}
                />
              </DndProvider>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isMenuOpen={blockToUpdate !== undefined && isUpdateModalOpen}
        position="fixed"
        size="3xl"
        overlay
        className="rounded-xl right-1/4 top-10 overflow-y-auto"
        styles={{ maxHeight: '90vh', minHeight: '550px' }}
      >
        {blockToUpdate ? (
          <BlockManage
            key={blockToUpdate.id}
            block={blockToUpdate}
            isBlockPage={false}
            onCreateDiscoverBlock={handleAddBlock}
            setIsModalOpen={setIsAddBlockModalOpen}
            onClickUpdateSubmit={handleUpdateBlock}
            onClickCloseUpdateModal={() => setIsUpdateModalOpen(false)}
          />
        ) : (
          'Error, no block selected'
        )}
      </Modal>
    </div>
  );
}

export default BlocksContainer;
