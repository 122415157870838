import { Input, InputKeyboardEvent } from '@bindystreet/bindystreet.kit.react';
import { IInteractionEvent } from 'Colugo/interfaces/interest/IInteractionEvent';
import { InteractionEventType } from 'Colugo/interfaces/lobby/discover/enums/InteractionEventType';
import InterestScoreOperations from 'Colugo/operations/interest/InteractionEventOperations';
import produce from 'immer';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const interestScoreOperations = container.resolve(InterestScoreOperations);

type Props = {
  interactionEvent: IInteractionEvent;
  updateInteractionEvents: (
    ineractionEvent: IInteractionEvent
  ) => Promise<void>;
};

function InterestScoreCard(props: Props) {
  const { interactionEvent, updateInteractionEvents } = props;
  const [localValue, setLocalValue] = useState<string | undefined>(
    interactionEvent.value?.toString()
  );
  const [isEditValueOpen, setIsEditValueOpen] = useState<boolean>(false);

  const isValidValue = (value?: string) => {
    if (!value) {
      return false;
    }
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue >= -1 && parsedValue <= 1;
  };

  async function handleChangeValueAsync(newValue: string) {
    if (isValidValue(newValue)) {
      const numericValue = newValue.trim() !== '' ? parseFloat(newValue) : 0;
      const newInteractionEvent = produce(interactionEvent, (itemDraft) => {
        itemDraft.value = numericValue;
      });
      setIsEditValueOpen(false);
      setLocalValue(newValue);
      const { error } =
        await interestScoreOperations.updateInteractionEventValueAsync(
          newInteractionEvent
        );
      if (error) {
        toast.error('Failed to save value');
        setLocalValue(interactionEvent.value?.toString() || '');
        return;
      }
      await updateInteractionEvents(newInteractionEvent);
    }
  }

  async function handleEnterValueAsync(e: InputKeyboardEvent) {
    if (e.key === 'Enter' && isValidValue(localValue)) {
      e.preventDefault();
      setIsEditValueOpen(false);
      await handleChangeValueAsync(localValue || '');
    }
  }

  return (
    <div
      className="border-b-2 mb-1 flex flex-col hover:bg-gray-100"
      style={{ minHeight: 48 }}
    >
      <div className="w-1/2 flex flex-row items-center">
        <div className="flex items-start w-64 font-inter font-normal mt-3">
          {InteractionEventType[interactionEvent.type!]}
        </div>
        <div className="w-56 ">
          <Input
            type="number"
            size="sm"
            autoFocus={true}
            className={`${!isEditValueOpen && 'hidden'} w-28`}
            value={localValue?.toString()}
            placeholder={localValue}
            error={
              !isValidValue(localValue) && 'Number should be between -1 and 1'
            }
            onKeyDown={handleEnterValueAsync}
            onChange={(e) => {
              setLocalValue(e.currentTarget.value);
            }}
          />

          <div
            className={`flex items-start  font-inter font-normal ${
              isEditValueOpen && 'hidden'
            }`}
          >
            {localValue}
          </div>
        </div>
        {isEditValueOpen ? (
          <div className="flex flex-row">
            <button
              className="font-inter text-s text-black font-normal mr-5"
              onClick={() => {
                handleChangeValueAsync(localValue!);
              }}
            >
              Save
            </button>
            <button
              className="font-inter text-s text-gray-600 font-normal"
              onClick={() => {
                setIsEditValueOpen(false);
                setLocalValue(interactionEvent.value?.toString());
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="font-inter text-s text-green-500 font-normal"
            onClick={() => {
              setIsEditValueOpen(true);
            }}
          >
            edit
          </button>
        )}
      </div>
    </div>
  );
}

export default InterestScoreCard;
