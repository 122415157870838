import {
  GuideTemplateTypeEnum,
  IGame,
  IGameVersion
} from '../../interfaces/games';
import {
  IGuideCreation,
  IGuideCreationWithGameUpdatedAt
} from 'Colugo/interfaces/games/IGuideCreation';
import { requestResponse } from '../../provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';
import { Lifecycle, scoped } from 'tsyringe';

@scoped(Lifecycle.ContainerScoped)
class GuideOperations extends AbstractOperations {
  protected basePath: string = 'guide';

  public async createGuide(
    guide: IGame,
    template: GuideTemplateTypeEnum
  ): requestResponse<IGameVersion> {
    return await this.executePost(`${template}`, guide);
  }

  public async updateVersion(guide: IGame): requestResponse<IGameVersion> {
    return await this.executePut(`/version/${guide.id}`, guide, {}, true);
  }

  public async addCategoryToGuideVersion(
    gameVersionId: string,
    categoryId: string
  ): requestResponse {
    return this.executePost(
      `/category/${categoryId}/gameVersion/${gameVersionId}`
    );
  }

  public async removeCategoryFromGuideVersion(
    gameVersionId: string,
    categoryId: string
  ): requestResponse {
    return await this.executeDelete(
      `/category/${categoryId}/gameVersion/${gameVersionId}`
    );
  }

  public async republishAll(secretCode: string) {
    return await this.executePost(`republishAll?code=${secretCode}`);
  }

  public async unpublishGuide(guideId: string): requestResponse {
    return await this.executeDelete(`${guideId}/unpublish`);
  }

  public async publishVersion(versionId: string): requestResponse {
    return await this.executePost(`version/${versionId}/queuePublish`);
  }

  public async updateGuideType(
    guideId: string,
    guideType: GuideTemplateTypeEnum
  ): requestResponse<IGame> {
    return await this.executePut(
      `${guideId}/updateType/${guideType}`,
      {},
      {},
      true
    );
  }

  public async updateGuideCreation(
    guideId: string,
    guideCreation: IGuideCreation
  ): requestResponse<IGuideCreationWithGameUpdatedAt> {
    return await this.executePut(
      `${guideId}/creator/guideCreation`,
      guideCreation
    );
  }
}

//TODO: still used as a function until we find a fix for the swr library(https://jirabindystreet.atlassian.net/browse/KW-2152)
const useReqGetGuide = (guideId?: string): swrRequestResponse<IGame> => {
  return useGetHttpClient(
    guideId ? `game/${guideId}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export default GuideOperations;
export { useReqGetGuide };
