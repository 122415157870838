import IRankedResultItem from 'Colugo/interfaces/search/IRankedResultItem';
import { ISearchRequest } from 'Colugo/interfaces/search/ISearchRequest';
import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class SearchOperations extends AbstractOperations {
  protected basePath: string = 'search';

  public search(
    searchRequest: ISearchRequest
  ): requestResponse<IRankedResultItem[]> {
    return this.executePost('', searchRequest, {}, true);
  }
}

export default SearchOperations;
