import {
  FavouriteFeaturesAnswer,
  HowUsingAnswer,
  QuestionType,
  RadioAnswer,
  WhyUsingAnswer
} from 'Colugo/interfaces/feedback/ISurveyResponse';

const favouriteFeatures = 'What are your favourite features on Bindy Street?';
const haveVisited =
  'Have you visited places or events after finding them on Bindy Street?';
const howFast = 'How do you find loading times while using the app?';
const howUseful = 'How useful was Bindy Street in finding something to do?';
const howUsing = 'How do you use Bindy Street?';
const whyUsing = 'Why are you using Bindy Street?';

export function parseQuestionType(questionType: QuestionType) {
  let questionText = '';

  switch (questionType) {
    case QuestionType.FavouriteFeatures:
      questionText = favouriteFeatures;
      break;
    case QuestionType.HaveVisited:
      questionText = haveVisited;
      break;
    case QuestionType.HowFast:
      questionText = howFast;
      break;
    case QuestionType.HowUseful:
      questionText = howUseful;
      break;
    case QuestionType.HowUsing:
      questionText = howUsing;
      break;
    default:
      questionText = whyUsing;
      break;
  }

  return questionText;
}

export function parseEnum<T>(enumOptions: T[], enumType: any): string {
  let enumValuesText = '';

  if (Array.isArray(enumOptions) && enumOptions.length > 0) {
    const featureTexts = enumOptions.map((feature) => {
      const featureString = enumType[feature];
      return featureString !== undefined ? featureString : 'Unknown';
    });
    enumValuesText = featureTexts.join(', ');
  } else {
    enumValuesText = 'None';
  }

  return enumValuesText;
}

export function parseRadioAnswer(radioAnswer: RadioAnswer) {
  let radioAnswerText = '';
  switch (radioAnswer) {
    case RadioAnswer.Bad:
      radioAnswerText = 'Bad';
      break;
    case RadioAnswer.Average:
      radioAnswerText = 'Average';
      break;
    case RadioAnswer.Good:
      radioAnswerText = 'Good';
      break;
    case RadioAnswer.VeryGood:
      radioAnswerText = 'Very Good';
      break;
    default:
      radioAnswerText = 'None';
      break;
  }
  return radioAnswerText;
}

export function formatLabel(key: string) {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
}

export function parseQuestionAnswer(questionAnswer: string) {
  switch (questionAnswer) {
    case 'favouriteFeaturesAnswers':
      return favouriteFeatures;
    case 'haveVisitedAnswer':
      return haveVisited;
    case 'howFastAnswer':
      return howFast;
    case 'howUsefulAnswer':
      return howUseful;
    case 'howUsingAnswers':
      return howUsing;
    default:
      return whyUsing;
  }
}

export function getResponseName(key: string, type: QuestionType) {
  switch (type) {
    case QuestionType.FavouriteFeatures:
      return getResponseForFavouriteFeatures(key);
    case QuestionType.HaveVisited:
      return getResponseForHaveVisited(key);
    case QuestionType.HowFast:
      return getResponseForHowFast(key);
    case QuestionType.HowUseful:
      return getResponseForHowUseful(key);
    case QuestionType.HowUsing:
      return getResponseForHowUsing(key);
    default:
      return getResponseForWhyUsing(key);
  }
}
export function getResponseNameForQuestion(key: string, question: string) {
  switch (question) {
    case haveVisited:
      return getResponseForHaveVisited(key);
    case howFast:
      return getResponseForHowFast(key);
    case howUseful:
      return getResponseForHowUseful(key);
    default:
      return key;
  }
}

function getResponseForHowFast(key: string) {
  switch (key) {
    case RadioAnswer[RadioAnswer.Bad]:
      return 'Extremely slow';
    case RadioAnswer[RadioAnswer.Average]:
      return 'Slower than expected';
    case RadioAnswer[RadioAnswer.Good]:
      return 'As Expected';
    case RadioAnswer[RadioAnswer.VeryGood]:
      return 'Faster than expected';
  }
}

function getResponseForHowUseful(key: string) {
  switch (key) {
    case RadioAnswer[RadioAnswer.Bad]:
      return 'Not useful at all';
    case RadioAnswer[RadioAnswer.Average]:
      return 'Somewhat useful';
    case RadioAnswer[RadioAnswer.Good]:
      return 'Helpful';
    case RadioAnswer[RadioAnswer.VeryGood]:
      return 'Very useful';
  }
}

function getResponseForHowUsing(key: string) {
  switch (key) {
    case HowUsingAnswer[HowUsingAnswer.Spontaneous]:
      return 'Spontaneous things to do on the day';
    case HowUsingAnswer[HowUsingAnswer.Planning]:
      return 'Planning in advance';
    case HowUsingAnswer[HowUsingAnswer.OutAndAbout]:
      return 'While out and about';
  }
}

function getResponseForHaveVisited(key: string) {
  switch (key) {
    case RadioAnswer[RadioAnswer.Bad]:
      return 'No - nothing is appealing to me';
    case RadioAnswer[RadioAnswer.Average]:
      return "No - but I'd like to";
    case RadioAnswer[RadioAnswer.Good]:
      return 'Yes - once or twice';
    case RadioAnswer[RadioAnswer.VeryGood]:
      return 'Yes - lots!';
  }
}

function getResponseForWhyUsing(key: string) {
  switch (key) {
    case WhyUsingAnswer[WhyUsingAnswer.Inspiration]:
      return 'Looking for inspiration';
    case WhyUsingAnswer[WhyUsingAnswer.BestThingsToDo]:
      return 'To find the best things to do';
    case WhyUsingAnswer[WhyUsingAnswer.HottestEvents]:
      return 'Keep up with the hottest events';
    case WhyUsingAnswer[WhyUsingAnswer.ExclusiveDeals]:
      return 'For exclusive events and promotions';
  }
}

function getResponseForFavouriteFeatures(key: string) {
  switch (key) {
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.Map]:
      return 'The Map';
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.FilterSearch]:
      return 'Searching using Filters';
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.Browsing]:
      return 'Browsing our curated pages';
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.Collections]:
      return 'Creating Collections';
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.WizardSearch]:
      return 'The Search Wizard';
    case FavouriteFeaturesAnswer[FavouriteFeaturesAnswer.Guides]:
      return 'Guides';
  }
}
