import ReportSource from 'Colugo/interfaces/moderation/enums/ReportSource';
import ResolutionStatus from 'Colugo/interfaces/moderation/enums/ResolutionStatus';
import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class UserReportOperations extends AbstractOperations {
  protected basePath: string = 'moderation/userReport';

  public async updateResolutionStatusAsync(
    id: string,
    resolutionStatus: ResolutionStatus
  ): requestResponse {
    return await this.executePut(`${id}/resolutionStatus/${resolutionStatus}`);
  }
}

const useReqListUserReportsAsync = (
  reportSource: ReportSource
): swrRequestResponse<IUserReport[]> => {
  return useGetHttpClient(
    `moderation/userReport/list/${reportSource}`,
    {
      revalidateOnFocus: true
    },
    false
  );
};

export { useReqListUserReportsAsync };
export default UserReportOperations;
