import React from 'react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import CreateModal from '../CreateModal';

type Props = {
  onCreateFilterGroup: (filterGroup: IFilterGroup) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

const FilterGroupCreate = (props: Props) => {
  const { onCreateFilterGroup, setIsModalOpen, isModalOpen } = props;
  const handleSubmitFilterGroup = async (name: string) => {
    const newFilterGroup: IFilterGroup = { name };
    await onCreateFilterGroup(newFilterGroup);
  };

  return (
    <CreateModal
      title={'Create FilterGroup'}
      submitButtonText={'Create Filter Group'}
      minCharacterCount={4}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSubmit={handleSubmitFilterGroup}
    />
  );
};
export default FilterGroupCreate;
