import React, { useEffect, useState } from 'react';
import { Input } from '@bindystreet/bindystreet.kit.react';
import { IDiscoverSubPage } from 'Colugo/interfaces/lobby/discover/subPages';
import { BiSearchAlt } from 'react-icons/bi';
import { FaArrowDown, FaArrowUp, FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiDeleteBin2Line, RiTableAltLine } from 'react-icons/ri';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import { getMaxViewHeight } from 'utility/general/getViewHeight';

type Props = {
  tableColumns: Column<IDiscoverSubPage>[];
  discoverSubPages: IDiscoverSubPage[];
  onClickDeleteIcon: (subPageId: string) => void;
  onClickManageIcon: (subPage: IDiscoverSubPage) => void;
  onChangeOrder: (subPageId: string, position: number) => void;
  onClickRow: (subPageId: string) => void;
  rowSelectedId?: string;
  isOrderable?: boolean;
};

const DiscoverSubPageTable: React.FC<Props> = (props) => {
  const {
    discoverSubPages,
    tableColumns,
    onClickDeleteIcon,
    onClickManageIcon,
    children,
    onClickRow,
    onChangeOrder,
    rowSelectedId,
    isOrderable = false
  } = props;
  const [rowHovered, setRowHovered] = useState(-1);
  const [rowHover, setRowHover] = useState<number | undefined>(undefined);
  const [isManageHover, setIsManageHover] = useState(false);
  const [isMoveUpHover, setIsMoveUpHover] = useState(false);
  const [isMoveDownHover, setIsMoveDownHover] = useState(false);
  const [isDeleteHover, setIsDeleteHover] = useState(false);

  const setClickableRowIconElements = (cell: Cell) => {
    return (
      <div
        onMouseEnter={() => setRowHover(cell.row.index)}
        onMouseLeave={() => setRowHover(undefined)}
        className="flex flex-row"
      >
        <RiTableAltLine
          className={`text-4xl cursor-pointer mx-3 mt-2 ${
            rowHover === cell.row.index && isManageHover
              ? 'text-success'
              : 'text-theme6'
          }`}
          onClick={() => {
            onClickManageIcon(
              discoverSubPages.find((subPage) => subPage.id === cell.value)!
            );
          }}
          onMouseEnter={() => setIsManageHover(true)}
          onMouseLeave={() => setIsManageHover(false)}
        />
        {isOrderable && (
          <>
            {cell.row.index !== 0 && (
              <FaArrowUp
                className={`text-4xl cursor-pointer mx-3 mt-2 ${
                  rowHover === cell.row.index && isMoveUpHover
                    ? 'text-hot'
                    : 'text-theme6'
                }`}
                onClick={() => {
                  const subPageId = discoverSubPages.find(
                    (subPage) => subPage.id === cell.value
                  )!.id!;
                  onChangeOrder(subPageId, cell.row.index - 1);
                }}
                onMouseEnter={() => setIsMoveUpHover(true)}
                onMouseLeave={() => setIsMoveUpHover(false)}
              />
            )}
            {cell.row.index !== discoverSubPages.length - 1 && (
              <FaArrowDown
                className={`text-4xl cursor-pointer mx-3 mt-2 ${
                  rowHover === cell.row.index && isMoveDownHover
                    ? 'text-hot'
                    : 'text-theme6'
                }`}
                onClick={() => {
                  const subPageId = discoverSubPages.find(
                    (subPage) => subPage.id === cell.value
                  )!.id!;
                  onChangeOrder(subPageId, cell.row.index + 1);
                }}
                onMouseEnter={() => setIsMoveDownHover(true)}
                onMouseLeave={() => setIsMoveDownHover(false)}
              />
            )}
          </>
        )}

        {discoverSubPages[cell.row.index].label !== 'Category Sub Page' && (
          <RiDeleteBin2Line
            className={`text-4xl cursor-pointer mx-3 mt-2 ${
              rowHover === cell.row.index && isDeleteHover
                ? 'text-error1'
                : 'text-theme6'
            }`}
            onClick={() => {
              onClickDeleteIcon(
                discoverSubPages.find((subPage) => subPage.id === cell.value)!
                  .id!
              );
            }}
            onMouseEnter={() => setIsDeleteHover(true)}
            onMouseLeave={() => setIsDeleteHover(false)}
          />
        )}
      </div>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: discoverSubPages
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const table = (
    <div>
      <div {...getTableProps()} className="pb-20">
        <div>{discoverSubPageTableHeaderConfig(headerGroups)}</div>
        {discoverSubPageTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          setClickableRowIconElements,
          onClickRow,
          rowSelectedId
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {children}
      </div>
      {table}
    </div>
  );
};

export default DiscoverSubPageTable;

//Component specifically used for this table
const GlobalFilter: React.FC<any> = ({ globalFilter, setGlobalFilter }) => {
  const [filterValue, setFilterValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 500);

  //GlobalFilter resets along with everything else on a mutation that
  // changes items in the table. This is messy but it works for an admin tool
  useEffect(() => {
    if (filterValue !== globalFilter) {
      setGlobalFilter(filterValue);
    }
  }, [globalFilter, setGlobalFilter, filterValue]);

  return (
    <div className="w-4/5 flex">
      <div className="relative flex flex-row w-full">
        <Input
          className="flex-shrink-0 mb-6 md:mb-3 flex-grow"
          placeholder="Search Blocks"
          color="text-gray-400"
          size="lg"
          value={filterValue || ''}
          onChange={(e) => {
            setFilterValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
        />
        <BiSearchAlt className="text-3xl text-hot absolute mt-5 mr-3 right-0" />
      </div>
    </div>
  );
};

export const discoverSubPageTableColumns: Column<IDiscoverSubPage>[] = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Type',
    accessor: 'label'
  },
  {
    Header: '',
    accessor: 'id', //note: id must be the last row
    width: 0
  }
];

const discoverSubPageTableHeaderConfig = (
  headerGroups: HeaderGroup<IDiscoverSubPage>[]
) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            style={{ width: '600px' }}
            className="w-full ml-2"
          >
            <div
              className={
                'flex flex-row align-center text-xs text-left pl-2 xl:text-sm my-auto'
              }
            >
              <div>{column.render('Header')}</div>
              <div>
                {column.Header!.toString() === '' ? (
                  <> </>
                ) : column.isSorted ? (
                  column.isSortedDesc ? (
                    <FaSortDown className="ml-1 text-black" />
                  ) : (
                    <FaSortUp className="ml-1 text-black mt-auto" />
                  )
                ) : (
                  <FaSortDown className="ml-1 text-gray-300" />
                )}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

const discoverSubPageTableRowsConfig = (
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<IDiscoverSubPage> | undefined
  ) => TableBodyProps,
  rows: Row<IDiscoverSubPage>[],
  prepareRow: (row: Row<IDiscoverSubPage>) => void,
  setEndRowElements: (cell: Cell) => JSX.Element,
  onClickRow: (blockId: string) => void,
  rowSelectedId?: string
) => (
  <div
    className="overflow-y-auto"
    style={{ maxHeight: `calc(${getMaxViewHeight()}` }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    {rows.map((row, i) => {
      prepareRow(row);
      const bgcolor: string = i % 2 ? 'bg-transparent' : 'bg-theme2';
      const elementId = row.cells[row.cells.length - 1].value;
      return (
        <div
          {...row.getRowProps({})}
          className={`whitespace-no-wrap h-16 relative rounded-md ${bgcolor} hover:shadow-lg cursor-pointer`}
          onMouseEnter={() => setRowHovered(i)}
          onClick={() => onClickRow(elementId)} //Block Id
        >
          {rowHovered === i && (
            <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
              <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
            </div>
          )}
          {row.cells.map((cell, index) => {
            if (cell.column.Header?.toString() === '') {
              return (
                <div {...cell.getCellProps()} className="flex flex-row w-full">
                  <div className="flex-grow" />
                  <div className="flex flex-row mr-4">
                    {setEndRowElements(cell as any)}
                  </div>
                </div>
              );
            }
            return (
              <div
                {...cell.getCellProps()}
                className={'ml-4 text-left my-auto'}
                style={{
                  width:
                    cell.column.Header?.toString() === 'Name'
                      ? '445px'
                      : '720px'
                }}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);
