import { IOrderedItem } from 'Colugo/interfaces/common';
import {
  IOperationWithUpdatedModulesRequest,
  IOperationWithUpdatedModulesResponse,
  ISpotVersion
} from 'Colugo/interfaces/games';
import AbstractOperations from 'Colugo/operations/AbstractOperations';
import { requestResponse } from 'Colugo/provider/HttpClient';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

class SpotOperations extends AbstractOperations {
  protected basePath: string = 'spot';

  public async createAsync(
    zoneVersionId: string,
    operationWithUpdatedModulesRequest: IOperationWithUpdatedModulesRequest
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executePost(
      `/zone/version/${zoneVersionId}/spot/version`,
      operationWithUpdatedModulesRequest,
      undefined,
      true
    );
  }

  public async getUserViewAsync(spotId: string): requestResponse<ISpotVersion> {
    return await this.executeGet(`${spotId}/userView`);
  }

  public async listByNameAsync(query: string): requestResponse<ISpotVersion[]> {
    return await this.executeGet(`listByName?query=${query}`);
  }

  public async updateAsync(spot: ISpotVersion): requestResponse<ISpotVersion> {
    return await this.executePut(`version/${spot.id}`, spot, {}, true);
  }

  public async addListingAsync(
    spot: ISpotVersion,
    listingId: string
  ): requestResponse<ISpotVersion> {
    return await this.executePost(
      `version/listing/${listingId}`,
      spot,
      {},
      true
    );
  }

  public async createAndAddListingAsync(
    zoneVersionId: string,
    spot: ISpotVersion,
    listingId: string
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executePost(
      `/zone/version/${zoneVersionId}/spot/version/addListing/${listingId}`,
      spot,
      {},
      true
    );
  }

  public async updateSpotOrderAsync(
    zoneVersionId: string,
    spotVersionId: string,
    position: number
  ): requestResponse<IOrderedItem[]> {
    return await this.executePut(
      `/zone/version/${zoneVersionId}/spot/version/${spotVersionId}/position/${position}`
    );
  }

  public async updateZoneVersionAsync(
    gameVersionId: string,
    zoneVersionId: string,
    operationWithUpdatedModulesRequest: IOperationWithUpdatedModulesRequest
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executePut(
      `version/moveToZone?zoneVersionId=${zoneVersionId}&gameVersionId=${gameVersionId}`,
      operationWithUpdatedModulesRequest,
      undefined,
      true
    );
  }

  public async deleteAsync(
    gameVersionId: string,
    operationWithUpdatedModulesRequest: IOperationWithUpdatedModulesRequest
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executeDelete(
      `version?gameVersionId=${gameVersionId}`,
      operationWithUpdatedModulesRequest,
      undefined,
      true
    );
  }

  public async removeListingAsync(
    spot: ISpotVersion
  ): requestResponse<IOperationWithUpdatedModulesResponse> {
    return await this.executeDelete(
      `version/listing/${spot.id}`,
      spot,
      undefined,
      true
    );
  }
}

const useReqListItems = (
  guideId?: string
): swrRequestResponse<ISpotVersion[]> => {
  return useGetHttpClient(
    guideId ? `spot/version/${guideId}/listLightByGuide` : null,
    {
      revalidateOnFocus: true
    },
    false,
    true
  );
};

export { useReqListItems };
export default SpotOperations;
