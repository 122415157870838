import { IUser } from 'Colugo/interfaces/identity';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { useGetHttpClient } from 'Colugo/provider/useGetHttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class UserOperations extends AbstractOperations {
  protected basePath: string = 'user';

  public async getUserCountAsync(): requestResponse<number> {
    return await this.executeGet('count');
  }

  public async getUserAsync(): requestResponse<IUser> {
    return await this.executeGet('/creator');
  }

  public async suspendAsync(userId: string): requestResponse {
    return await this.executePut(`suspend/${userId}`);
  }

  public async updateAsync(user: IUser): requestResponse {
    return await this.executePut(`/player`, user);
  }

  public async banAsync(userId: string): requestResponse {
    return await this.executePut(`ban/${userId}`);
  }

  public async reindexAllAsync(secretValue: string): requestResponse {
    return await this.executePost(`reindexAll?code=${secretValue}`);
  }
}

const useReqGetUser = (isTokenAvailable: boolean) => {
  return useGetHttpClient<IUser>(isTokenAvailable ? `creator` : null, {
    //Since this resets the dashboard, we want full control over when it happens.
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};

export default UserOperations;
export { useReqGetUser };
