import { ICategoryUserView } from 'Colugo/interfaces/common';
import { requestResponse } from '../../provider/HttpClient';
import AbstractOperations from '../AbstractOperations';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

class CategoryOperations extends AbstractOperations {
  protected basePath: string = 'category';

  public async addItemVersionAsync(
    itemVersionId: string,
    categoryId: string
  ): requestResponse {
    return await this.executePost(`${categoryId}/itemVersion/${itemVersionId}`);
  }

  public async removeItemVersionAsync(
    itemVersionId: string,
    categoryId: string
  ): requestResponse {
    return await this.executeDelete(
      `${categoryId}/itemVersion/${itemVersionId}`
    );
  }
}

//TODO: still used as a function until we find a fix for the swr library(https://jirabindystreet.atlassian.net/browse/KW-2152)
const useReqListCategories = (): swrRequestResponse<ICategoryUserView[]> => {
  return useGetHttpClient(
    `category/list`,
    {
      revalidateOnFocus: true
    },
    false
  );
};
export default CategoryOperations;
export { useReqListCategories };
