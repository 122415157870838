import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ITag, ITagRelevance } from 'Colugo/interfaces/tags';
import { TagOperations, useReqListTags } from 'Colugo/operations/tags';
import {
  reqCreateOrUpdateTagRelevance,
  reqDeleteTag,
  reqListRelevantTags,
  reqUpdateDefaultCategoryForTag,
  reqUpdateTag
} from 'provider/admin/methods';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import TagEditor from './TagEditor';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';

const tagOperations = container.resolve(TagOperations);

function TagContainer() {
  const {
    data: tags,
    isLoading: isTagsLoading,
    isError: isTagsError,
    mutate: mutateTags
  } = useReqListTags();
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError
  } = useReqListCategories();

  const [localRelevantTags, setLocalRelevantTags] = useState<ITag[]>([]);

  const handleChangeSelectedTag = async (tagId: string) => {
    const { data: relevantTags } = await reqListRelevantTags(tagId);

    setLocalRelevantTags(relevantTags || []);
  };

  const handleDeleteTag = async (tagToDelete: ITag) => {
    if (!tagToDelete?.id) {
      toast.error('Invalid tag Id, please try again');
      return false;
    }

    const { data, error } = await reqDeleteTag(tagToDelete.id);

    //Because we cant figure out how to return json on delete.
    if (!data && error?.status !== 404) {
      toast.error('Failed to delete tag, please try again');
      return false;
    }

    const newtags = tags!.filter((tag) => tag.id !== tagToDelete.id);
    mutateTags(newtags, true);
    return true;
  };

  const handleCreateTag = async (tagToAdd: ITag) => {
    const { data, error } = await tagOperations.create(tagToAdd);

    if (!data || error) {
      if (error?.status === 409) {
        toast.error(
          'Failed to create tag, most likely tag name already exists.'
        );
      } else {
        toast.error('Failed to create tag, please try again');
      }
      return false;
    }

    const newtags = [...tags!, data];
    mutateTags(newtags, true);
    return true;
  };

  const handleCreateOrUpdateTagRelevance = async (
    newTagRelevance: ITagRelevance
  ) => {
    if (
      !newTagRelevance.tag2Id ||
      !newTagRelevance.relevance ||
      !newTagRelevance.tag1Id
    ) {
      toast.error('Must provide 2 tag Ids and Relevance');
      return false;
    }

    const { data, error } = await reqCreateOrUpdateTagRelevance(
      newTagRelevance.tag1Id,
      newTagRelevance.tag2Id,
      newTagRelevance.relevance
    );

    if (!data || error) {
      toast.error('Failed to create tag relevance, please try again');
      return false;
    }

    const newTag: ITag = {
      id: newTagRelevance.tag2Id,
      relevance: newTagRelevance.relevance,
      name: newTagRelevance.name
    };
    setLocalRelevantTags(
      [...localRelevantTags, newTag].sort((a, b) => b.relevance! - a.relevance!)
    );
    return true;
  };

  const handleUpdateTag = async (tagToUpdate: ITag) => {
    const updateResult = await reqUpdateTag(tagToUpdate);
    const updateDefaultTagResult = await reqUpdateDefaultCategoryForTag(
      tagToUpdate.id!,
      tagToUpdate.defaultCategoryId
    );

    if (!updateResult.data || updateResult.error) {
      toast.error(
        'Failed to update tag, please try again. Tag name must be unique.'
      );
      return false;
    }

    if (updateDefaultTagResult.error) {
      toast.error('Failed to update tag default category, please try again.');
      return false;
    }

    const newtags = tags!.map((tag) =>
      tag === tagToUpdate ? updateResult.data : tag
    );

    toast.success('Tag successfully updated');

    mutateTags(newtags, true);
    return true;
  };

  const handleUpdateTagRelevance = async (
    tagRelevanceToUpdate: ITagRelevance
  ) => {
    if (
      !tagRelevanceToUpdate.tag2Id ||
      !tagRelevanceToUpdate.relevance ||
      !tagRelevanceToUpdate.tag1Id
    ) {
      toast.error('Must provide 2 tag Ids and Relevance');
      return false;
    }

    const result = await reqCreateOrUpdateTagRelevance(
      tagRelevanceToUpdate.tag1Id,
      tagRelevanceToUpdate.tag2Id,
      tagRelevanceToUpdate.relevance
    );

    if (!result.data || result.error) {
      toast.error('Failed to update tag relevance, please try again.');
      return false;
    }

    const newLocalRelevantTags: ITag[] = localRelevantTags.map((tag) => {
      if (tag.id === tagRelevanceToUpdate.tag2Id) {
        if (!tag.relevance) {
          tag.relevance = 0;
        }

        tag.relevance = tagRelevanceToUpdate.relevance!;
      }
      return tag;
    });

    newLocalRelevantTags.sort((a, b) => b.relevance! - a.relevance!);

    setLocalRelevantTags(newLocalRelevantTags);
    return true;
  };

  if (isTagsLoading || isCategoriesLoading) {
    return <Spinner />;
  }

  if (isTagsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load tags from server.'}</span>
      </ErrorPage>
    );
  }

  if (isCategoriesError) {
    return (
      <ErrorPage>
        <span>{'Unable to load categories from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
      <TagEditor
        tags={tags!}
        categories={categories || []}
        deleteTag={handleDeleteTag}
        addTag={handleCreateTag}
        createOrUpdateTagRelevance={handleCreateOrUpdateTagRelevance}
        updateTag={handleUpdateTag}
        relevantTags={localRelevantTags}
        setTagIdForRelevantTags={handleChangeSelectedTag}
        updateTagRelevance={handleUpdateTagRelevance}
      />
    </div>
  );
}

export default TagContainer;
