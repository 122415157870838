import IBase from '../IBase';

export interface IAdvertLocation extends IBase {
  freeFrequency: number;
  screen: ScreenType;
  type: AdvertLocationType;
}

export enum ScreenType {
  None = 0,
  MapSearchAndContentScroll = 1,
  Community = 2
}

export enum AdvertLocationType {
  None = 'None',
  MyRecentSearches = 'My Recent Searches',
  AllSearchResults = 'All Search Results',
  AllSearchResultsGuideHorizontalScroll = 'All Search Results (Guide Horizontal Scroll) ',
  SearchGuidesResults = 'Search Guides Results',
  SearchEventsResults = 'Search Events Results',
  SearchPlacesResults = 'Search Places Results',
  StoryFeed = 'Story Feed'
}
