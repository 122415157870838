import localStore from 'utility/general/localStore';
import {
  localStorage_Me,
  localStorage_Token
} from 'utility/constants/constants';
import { authHttpClient } from 'provider/httpClient';

const signin = async (
  email: string,
  password: string,
  setErrors: (errors: any) => void,
  setToken: (token: any) => void
) => {
  let token: string;
  try {
    token = await authHttpClient
      .post('auth/handle?loginOrigin=Kraken', {
        json: { email, password }
      })
      .json();
    localStore.setItem(localStorage_Token, token);
    setToken(window.localStorage.token);
  } catch (err) {
    handleAuthError(err, setErrors);
  }
};

// no need for email and password
const resetPassword = async (
  setErrors: (errors: any) => void,
  email: string
) => {
  try {
    await authHttpClient.post('auth/resetPassword', {
      json: { email }
    });
  } catch (err) {
    handleAuthError(err, setErrors);
  }
};
// no need for email and password
const signout = (
  setErrors: (errors: any) => void,
  setToken: (token: any) => void,
  messageFromSystem?: string
) => {
  //TODO: Signout call on api
  try {
    localStore.removeItem(localStorage_Token);
    localStore.removeItem(localStorage_Me);
    setToken(null);
    if (messageFromSystem) {
      setErrors(() => [messageFromSystem]);
    }
  } catch (err) {
    handleAuthError(err, setErrors);
  }
};

const signup = async (
  email: string,
  password: string,
  fullname: string,
  setErrors: (errors: any) => void
) => {
  try {
    await authHttpClient.post('auth/create', {
      json: { Email: email, Password: password, FullName: fullname }
    });
  } catch (err) {
    handleAuthError(err, setErrors);
  }
};

const getToken = () => localStore.getItem('token');

const handleAuthError = async (err: any, setErrors: (errors: any) => void) => {
  const response: Response = err.response;
  if (response && response.status === 403) {
    setErrors((prev: any) => ['Failed to login. Unauthorized.']);
  } else if (response && response.status < 500) {
    const body = await response.json();
    const msg = body.message || 'bad request';
    setErrors((prev: any) => [msg]);
  } else {
    setErrors((prev: any) => [
      "Bindy Street's Admin Panel is unavailable. Please try again later."
    ]);
  }
};

const authMethods = {
  signin,
  signout,
  signup,
  getToken,
  resetPassword
};

export default authMethods;
