import { IBase } from '..';

export interface IUserCountThreshold extends IBase {
  threshold: number;
  type: ThresholdType;
}

export enum ThresholdType {
  None,
  Notification,
  AbsoluteLimit
}
