import { IUser } from 'Colugo/interfaces/identity';
import { localStorage_Me } from '../constants/constants';

/* ISC License (ISC). Copyright 2017 Michal Zalecki */
function storageFactory(storage: Storage): Storage {
  let inMemoryStorage: { [key: string]: string } = {};
  const length = 0;

  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear(): void {
    if (isSupported()) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name: string): string | null {
    if (isSupported()) {
      return storage.getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function getItemAsObject(name: string): any | null {
    if (isSupported()) {
      try {
        let item = storage.getItem(name);
        if (item) {
          return JSON.parse(item);
        }
      } catch (ex) {
        console.error(ex);
        return null;
      }
      return null;
    }
  }

  function getCurrentVenueId(): string | undefined {
    const userSerialised = localStore.getItem(localStorage_Me);
    if (userSerialised == null) {
      return undefined;
    }
    const user: IUser = JSON.parse(userSerialised);
    return user.creator.venues[0].id || undefined;
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      storage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name: string, value: string): void {
    if (isSupported()) {
      storage.setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  }

  return {
    getItemAsObject,
    getItem,
    setItem,
    getCurrentVenueId,
    removeItem,
    clear,
    key,
    length
  };
}

const localStore = storageFactory(localStorage);
export default localStore;
