import React, { useState } from 'react';
import {
  Button,
  CardHeader,
  Input,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import { ITag, ITagRelevance } from 'Colugo/interfaces/tags';
import { MdClose } from 'react-icons/md';
import TagTable, { tagTableColumns } from './TagTable';

type Props = {
  onCreateTagRelevance: (newTagRelevance: ITagRelevance) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  tag1IdSelected: string;
  tags: ITag[];
};

const TagCreateRelevance: React.FC<Props> = (props) => {
  const {
    onCreateTagRelevance,
    setIsModalOpen,
    isModalOpen,
    tag1IdSelected,
    tags
  } = props;

  const [newTagRelevance, setNewTagRelevance] = useState<ITagRelevance>();

  const setTagRelevance = (e: InputFormEvent) => {
    const relevance = parseFloat(e.currentTarget.value);
    setNewTagRelevance({ ...newTagRelevance, relevance: relevance });
  };

  const tagColumns = React.useMemo(() => tagTableColumns, []);

  const selectTagRow = async (tag2IdFromTable: string) => {
    const tagName = (tags.find((t) => t.id === tag2IdFromTable) as ITag).name;
    const tagRelevanceToCreate: ITagRelevance = {
      tag1Id: tag1IdSelected,
      tag2Id: tag2IdFromTable,
      relevance: newTagRelevance?.relevance,
      name: tagName
    };
    setNewTagRelevance(tagRelevanceToCreate);
  };

  const isEnabled =
    newTagRelevance?.relevance &&
    newTagRelevance.relevance <= 1 &&
    newTagRelevance.relevance >= 0;

  return (
    <Modal
      isMenuOpen={isModalOpen}
      className="bottom-16 top-16 right-1/3"
      position="fixed"
      size="lg"
      overlay
    >
      <CardHeader title={'Create Tag Relevance'} fontWeight="font-semibold" />
      <div className="text-left mx-4 mt-4">
        <span className="font-semibold">{'Relevance:'}</span>
        <Input
          autoFocus={true}
          defaultValue={
            newTagRelevance?.relevance
              ? newTagRelevance.relevance!.toString()
              : ''
          }
          onChange={setTagRelevance}
        />
        {!isEnabled && (
          <span className="block mx-auto text-xs italic">
            {'Relevance must be between 0 and 1'}
          </span>
        )}
        <span className="font-semibold">{'Relevant Tag:'}</span>
        <TagTable
          onClickRow={selectTagRow}
          tableColumns={tagColumns}
          tags={tags}
          customHeight={'460px'}
          rowSelectedId={newTagRelevance?.tag2Id}
        />
        <div className="flex flex-row mt-4 justify-evenly">
          <Button
            onClick={() => onCreateTagRelevance(newTagRelevance!)}
            disabled={!isEnabled}
          >
            {'Create New Tag Relevance'}
          </Button>
          <Button onClick={() => setIsModalOpen(false)} skin="secondary">
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TagCreateRelevance;
