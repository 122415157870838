import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  IoLogoGameControllerB,
  IoIosStats,
  IoMdCube,
  IoIosAlbums,
  IoIosBody
} from 'react-icons/io';
import { GiWorld } from 'react-icons/gi';
import { FaHome } from 'react-icons/fa';
import { BiLock, BiMapPin } from 'react-icons/bi';
import { TiWeatherSunny } from 'react-icons/ti';
import { GiSecurityGate } from 'react-icons/gi';
import { AiFillTag, AiFillFilter, AiFillFileText } from 'react-icons/ai';
import { GiClothes } from 'react-icons/gi';
import { Link, useLocation } from 'react-router-dom';
import bindyStreetLogo from 'images/bindyStreetLogo.svg';
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs';
import useWindowDimensions from 'utility/hooks/useWindowDimensions';
import { IUser } from 'Colugo/interfaces/identity';
import { HoverIcon } from '@bindystreet/bindystreet.kit.react';
import { IconType } from 'react-icons/lib';
import { useReqGetVersionInfo } from 'provider/admin/methods';
import { VscCombine } from 'react-icons/vsc';
import CustomSideBarMenu from './CustomSideBarMenu';
import { RiCommunityLine } from 'react-icons/ri';
import { FcSurvey } from 'react-icons/fc';

type Props = {
  user: IUser;
};

type SideBarLink = {
  link: string;
  icon: IconType;
  text: string;
};

type SidebarMenu = {
  icon: IconType;
  link: string;
  text: string;
};

export type NestedSidebarMenu = {
  headerText: string;
  sideBarMenu: SidebarMenu[];
};

function DashboardSidebar(props: Props) {
  const { user } = props;
  const collapseWidth = 1400;
  const dimensions = useWindowDimensions();

  const [activeLink, setActiveLink] = useState('');
  const [isExpanded, setIsExpanded] = useState(
    dimensions.width > collapseWidth
  );
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { data: versionInfo } = useReqGetVersionInfo();

  const sidebarLinks = [
    { icon: FcSurvey, link: '/survey/summary', text: 'Survey' },
    { icon: IoLogoGameControllerB, link: '/categories', text: 'Categories' },
    { icon: AiFillTag, link: '/tags', text: 'Tags' },
    { icon: AiFillFilter, link: '/filterGroups', text: 'Filter Groups' },
    { icon: VscCombine, link: '/combos', text: 'Combos' },
    { icon: IoMdCube, link: '/blocks/manage', text: 'Blocks' },
    { icon: IoIosStats, link: '/discovery', text: 'Discovery Page' },
    { icon: IoIosAlbums, link: '/manifest', text: 'Manifests' },
    {
      icon: GiClothes,
      link: '/spiritCustomization',
      text: 'SpiritCustomizations'
    },
    { icon: IoIosBody, link: '/users', text: 'Users' },
    { icon: AiFillFileText, link: '/interestScore', text: 'Interest Score' },
    { icon: GiSecurityGate, link: '/moderation', text: 'Moderation' },
    { icon: BiLock, link: '/secret', text: 'Secret' }
  ] as SideBarLink[];

  const pagesMenu = {
    headerText: 'Pages',
    sideBarMenu: [
      { icon: FaHome, link: '/discovery', text: 'Home Screen' },
      { icon: GiWorld, link: '/explore', text: 'Explore Screen' },
      { icon: TiWeatherSunny, link: '/scenario', text: 'Scenarios' },
      { icon: BiMapPin, link: '/mapSearchCs', text: 'Map, Search & CS' },
      { icon: RiCommunityLine, link: '/community', text: 'Community' }
    ]
  };

  useEffect(() => {
    setIsExpanded(dimensions.width > collapseWidth);
  }, [dimensions.width]);

  const location = useLocation();
  useMemo(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const isActiveLink = (newActiveLinkName: string) =>
    activeLink === newActiveLinkName;

  const getClassNames = (newActiveLinkName: string) =>
    isActiveLink(newActiveLinkName)
      ? 'bg-sidebarButton text-gray-100'
      : 'text-gray-500';

  const sidebarSizing = isExpanded ? 'w-80' : 'w-28';

  const handleSetIsExpanded = (expanded: boolean) => {
    if (!expanded) {
      setIsExpanded(expanded);
    }
    const sidebar = sidebarRef.current;
    sidebar?.classList.toggle('w-80');
    sidebar?.classList.toggle('w-28');
    if (expanded) {
      setTimeout(() => setIsExpanded(expanded), 150);
    }
  };

  return (
    <div
      key="sidebar"
      ref={sidebarRef}
      className={`${sidebarSizing} bg-sidebar z-40 inset-y-0 left-0 transition-all duration-300 transform overflow-visible lg:translate-x-0 lg:static lg:inset-0`}
    >
      <div className="relative mb-8">
        <div className="absolute right-0 top-0 -mr-5 ">
          <div
            onClick={() => handleSetIsExpanded(!isExpanded)}
            className="rounded-full text-xl w-11 h-11 bg-sidebar cursor-pointer text-white flex flex-col"
          >
            <div className="flex-grow"></div>
            {isExpanded && <BsArrowBarLeft className="mx-auto" />}
            {!isExpanded && <BsArrowBarRight className="mx-auto" />}
            <div className="flex-grow"></div>
          </div>
        </div>
        <div className="flex flex-row pt-6 mt-12 pb-1 px-auto">
          <div className="flex-grow"></div>
          <img src={bindyStreetLogo} alt="bindy street" />
          {isExpanded && (
            <div className="flex flex-col ">
              <div className="flex flex-col mt-2">
                <span className="text-2xl text-left px-5 inline  pb-1 text-white font-bold">
                  Kraken
                </span>
                <span className="px-5 text-left text-white opacity-80">
                  Admin Panel
                </span>
              </div>
            </div>
          )}
          <div className="flex-grow"></div>
        </div>

        {isExpanded ? (
          <nav className="mt-10 mx-4">
            <div className="text-gray-400 font-bold text-left ml-2">
              <span className="text-left uppercase">
                {user.username ?? user.firstName + ' ' + user.lastName}
              </span>
            </div>
            {sidebarLinks.map((sidebarLink: SideBarLink, i) => (
              <div key={i}>
                {sidebarLink.text === 'Discovery Page' ? (
                  <CustomSideBarMenu
                    nestedSidebarMenu={pagesMenu}
                    isActiveLink={isActiveLink}
                  />
                ) : (
                  <>
                    <Link
                      key={sidebarLink.text}
                      to={sidebarLink.link}
                      className={`flex items-center mt-4 py-2 px-2 rounded-full ${getClassNames(
                        sidebarLink.link
                      )}`}
                    >
                      <sidebarLink.icon fontSize="1.5em" className="ml-1" />
                      <span className="mx-4">{sidebarLink.text}</span>
                    </Link>
                  </>
                )}
              </div>
            ))}
          </nav>
        ) : (
          <nav className="mt-12">
            <div className="text-gray-400 font-bold">
              <div>BINDY</div>
              <div>STREET</div>
            </div>
            <div className="flex flex-row">
              <div className="flex-grow"></div>
              <div className="flex flex-col mx-auto">
                {sidebarLinks.map((sidebarLink: SideBarLink) => {
                  return (
                    <Link
                      key={sidebarLink.text}
                      to={sidebarLink.link}
                      className="my-1"
                    >
                      <HoverIcon
                        icon={<sidebarLink.icon />}
                        text={sidebarLink.text}
                        isActive={isActiveLink(sidebarLink.link)}
                      />
                    </Link>
                  );
                })}
              </div>
              <div className="flex-grow"></div>
            </div>
          </nav>
        )}
      </div>
      <div className="text-white absolute bottom-0 inset-x-0 mt-6">
        <span className="block">{'v' + versionInfo?.publicVersion}</span>
        <span>{versionInfo?.buildNumber}</span>
      </div>
    </div>
  );
}

export default DashboardSidebar;
