import {
  Button,
  getTailwindColour,
  Input,
  Modal
} from '@bindystreet/bindystreet.kit.react';
import { IPlayer } from 'Colugo/interfaces/identity';
import {
  PlayerOperations,
  useListPlayersByEmail
} from 'Colugo/operations/identity';
import { reqResetPlayerProfile } from 'provider/admin/methods';
import React, { useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { numbersRegex } from 'utility/constants/regex';
import PlayersTable, { playerTableColumns } from './PlayersTable';

const playerOperations = container.resolve(PlayerOperations);

function PlayersEditor() {
  const [essenceAmount, setEssenceAmount] = useState('0');
  const [allGamesNamesQueryValue, setAllGamesNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [player, setPlayer] = useState<undefined | IPlayer>(undefined);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [secretCode, setSecretCode] = useState<string>('');

  const { data: players, mutate } = useListPlayersByEmail(
    allGamesNamesQueryValue && allGamesNamesQueryValue.length > 2
      ? allGamesNamesQueryValue
      : undefined
  );

  players?.forEach((player) => (player.userEmail = player.user?.email));

  const handleSetEssenceAmount = (newEssenceValue: string) => {
    if (newEssenceValue === '' || numbersRegex.test(newEssenceValue)) {
      setEssenceAmount(newEssenceValue);
    }
  };

  const handleUpdateEssence = async (
    updateEssenceString: string,
    isIncrease: boolean = true
  ) => {
    let updateEssenceNumber = Number(updateEssenceString);

    updateEssenceNumber = isIncrease
      ? updateEssenceNumber
      : -updateEssenceNumber;

    if (!players || !player || !player.id) {
      toast.error('Error occurred: No player selected');
      return;
    }

    if (player.essences + updateEssenceNumber < 0) {
      toast.error('Error occurred: cannot have negative essence');
      return;
    }

    const result = await playerOperations.updateEssenceAsync(
      updateEssenceNumber,
      player.id!
    );

    if (result.error) {
      toast.error('Error occurred: Unable to update player essence');
      return;
    }

    players.find((p) => p.id === player.id)!.essences += updateEssenceNumber;

    mutate(players, true);
    toast.success(`Player essence successfully updated`);
  };

  const handleResetPlayerProfile = async () => {
    if (!players || !selectedPlayerId) {
      toast.error('Error occurred: No player selected');
      return;
    }
    if (secretCode === undefined || secretCode.length === 0) {
      toast.error('Request missing secret');
      return;
    }

    const { data, error } = await reqResetPlayerProfile(
      selectedPlayerId,
      secretCode
    );

    if (error) {
      toast.error('Error occurred: Unable to reset player profile');
      return;
    }

    const newPlayers = players.map((oldPlayer) =>
      oldPlayer.id === selectedPlayerId ? data : oldPlayer
    );

    mutate(newPlayers, true);
    toast.success(`Player profile has been successfully reset`);
    setIsWarningModalOpen(false);
  };

  const handleSelectPlayer = async (player: Record<string, any>) => {
    const selectedPlayer = player as IPlayer;
    selectedPlayer.essences = selectedPlayer.essences || 0;
    setPlayer(selectedPlayer);
  };

  const columns = React.useMemo(() => playerTableColumns, []);

  //TODO: consider moving the 2 modals below into their own file and component if further features are added.

  return (
    <div className="mt-4">
      <span className="block text-3xl text-black mt-1 ml-4">
        Update player essence
      </span>
      <Modal
        isMenuOpen={isModalOpen}
        position={'center'}
        size="lg"
        className={'rounded-lg mt-20'}
        overlay
      >
        <div className="mx-5 my-2">
          <div className="text-2xl text-hot">Essence Updater</div>
          <div className="mt-3">
            {`The player "${player?.userEmail}" currently has ${player?.essences} essence`}
          </div>
          <div className="flex flex-row mt-1">
            <div className="mt-4 ml-10">Increase/decrease essence by:</div>
            <div className="w-40 ml-10">
              <Input
                placeholder="Essence amount"
                color="text-gray-400"
                size="md"
                value={essenceAmount.toString() || ''}
                onChange={(e) => handleSetEssenceAmount(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="flex w-full mt-3">
            <Button
              className="w-22"
              size="md"
              onClick={() => {
                handleUpdateEssence(essenceAmount);
                setIsModalOpen(false);
              }}
            >
              Increase
            </Button>
            <Button
              className="w-22 ml-2"
              size="md"
              skin="error"
              onClick={() => {
                handleUpdateEssence(essenceAmount, false);
                setIsModalOpen(false);
              }}
            >
              Decrease
            </Button>
            <div className="ml-auto">
              <Button onClick={() => setIsModalOpen(false)} skin="secondary">
                <MdClose className="mr-1 mt-1" />
                <div>Cancel</div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isMenuOpen={isWarningModalOpen}
        position={'center'}
        size="lg"
        className={'rounded-lg mt-20 flex flex-col items-center p-2'}
        overlay
      >
        <div className="flex flex-row items-center justify-center">
          <AiFillWarning size="64" color={getTailwindColour('error1')} />
          <div className="text-2xl font-bold mt-4">
            Warning: You are about to reset a player profile
          </div>
        </div>
        <div className="mt-4 w-3/4">
          <Input
            label="type in the secret code"
            onChange={(e) => setSecretCode(e.currentTarget.value)}
          />

          <div className="flex flex-row justify-between">
            <Button
              skin="error"
              onClick={handleResetPlayerProfile}
              className="mt-4"
            >
              Reset Player Profile
            </Button>

            <Button
              skin="secondary"
              onClick={() => setIsWarningModalOpen(false)}
              className="mt-4"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row mt-10">
        <div className="h-full ml-10 mr-auto">
          <PlayersTable
            onChangeSearchValue={setAllGamesNameQueryValue}
            onClickSelectPlayer={(e) => {
              setIsModalOpen(true);
              handleSelectPlayer(e);
            }}
            onClickRow={setSelectedPlayerId}
            onClickResetPlayer={() => {
              setIsWarningModalOpen(true);
            }}
            rowSelectedId={selectedPlayerId}
            tableColumns={columns as any}
            players={players || []}
          />
        </div>
      </div>
    </div>
  );
}

export default PlayersEditor;
