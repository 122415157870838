import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

@scoped(Lifecycle.ContainerScoped)
class VideoOperations extends AbstractOperations {
  protected basePath: string = 'video';

  public async listAsync(): requestResponse<IVideo[]> {
    return await this.executeGet('list', {}, true);
  }
}

const useReqListVideosAsync = (
  shouldFetchVideos?: boolean
): swrRequestResponse<IVideo[]> => {
  return useGetHttpClient(
    shouldFetchVideos ? `video/list` : null,
    {
      revalidateOnFocus: false
    },
    false
  );
};

export { useReqListVideosAsync };
export default VideoOperations;
