import React, { useState } from 'react';
import { Button, Input } from '@bindystreet/bindystreet.kit.react';
import { ITagRelevance } from 'Colugo/interfaces/tags';
import { MdClose } from 'react-icons/md';

type Props = {
  tagRelevance: ITagRelevance;
  onClickUpdateSubmit: (tag: ITagRelevance) => void;
  onClickCloseModal: () => void;
};

const TagUpdateForm: React.FC<Props> = (props) => {
  const { tagRelevance, onClickUpdateSubmit, onClickCloseModal } = props;

  const [localTagRelevance, setLocalTagRelevance] =
    useState<ITagRelevance>(tagRelevance);

  const [localRelevanceString, setLocalRelevanceString] = useState('');

  const isSubmitEnabled = localRelevanceString;

  const setRelevance = (relevanceString: string) => {
    setLocalRelevanceString(relevanceString);
    setLocalTagRelevance({
      ...localTagRelevance,
      relevance: parseFloat(relevanceString)
    });
  };

  return (
    <div className="mx-5 my-2">
      <div className="text-2xl text-hot">Relevance Updater</div>
      <div className="flex flex-row mt-1">
        <div className="mt-4 ml-10">New relevance:</div>
        <div className="w-40 ml-10">
          <Input
            placeholder="Essence amount"
            color="text-gray-400"
            size="md"
            value={localRelevanceString || undefined}
            onChange={(e) => setRelevance(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className="flex w-full mt-3">
        <div className="ml-auto">
          <Button onClick={onClickCloseModal} skin="secondary">
            <MdClose className="mr-1 mt-1" />
            <div>Cancel</div>
          </Button>
        </div>
        <Button
          className="w-22 ml-10"
          size="md"
          onClick={() => onClickUpdateSubmit(localTagRelevance)}
          disabled={!isSubmitEnabled}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default TagUpdateForm;
