import {
  ImageInput,
  InputHeader,
  Modal,
  readImageFile
} from '@bindystreet/bindystreet.kit.react';
import ImageCropper from './ImageCropper';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from 'utility/hooks/usePortal';
import { modalRoot } from 'utility/constants/constants';

type Props = {
  resolution?: { width: number; height: number };
  onCropComplete: (image: File) => void;
  label?: string;
  isFullWidth?: boolean;
  customPlaceholderSize?: number;
  customHeightPx?: string;
  customWidthPx?: string;
  fit?: 'fill' | 'cover' | 'contain';
  isMandatory?: boolean;
  autoMargins?: boolean;
  imageUrl?: string;
  deleteImage: () => void;
  isLoading?: boolean;
  onClick?: () => void;
  autoOpen?: boolean;
  placeholderOrientation?: 'horizontal' | 'vertical';
  onCancel?: () => void;
  error?: string | false;
  accept?: string | string[];
  isEnabled?: boolean;
  progress: number;
  isUploadEnabled?: boolean;
  isCroppingImage?: boolean;
  setIsCroppingImage: (isCroppingImage: boolean) => void;
  isIcon?: boolean;
};

function ImageCropperInput(props: Props) {
  const {
    resolution,
    onCropComplete,
    label,
    isFullWidth,
    customHeightPx,
    customWidthPx,
    fit,
    isMandatory = true,
    autoMargins,
    imageUrl,
    deleteImage,
    isLoading = false,
    onClick,
    autoOpen = false,
    placeholderOrientation = 'vertical',
    onCancel,
    error,
    isCroppingImage,
    setIsCroppingImage,
    customPlaceholderSize,
    accept,
    isEnabled = true,
    isUploadEnabled = true,
    isIcon = false,
    progress
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(autoOpen);
  const [imageToBeCropped, setImageToBeCropped] = useState<string | undefined>(
    imageUrl
  );

  const target = usePortal(modalRoot);

  const onDrop = async (acceptedFiles: File[]) => {
    const imageFile = acceptedFiles[0];
    await handleSetImage(imageFile);
    setIsModalOpen(true);
  };

  const handleSetImage = async (imageFile: File) => {
    const imageUrl = await readImageFile(imageFile);
    setImageToBeCropped(imageUrl);
  };

  const onDeleteImage = () => {
    setIsModalOpen(false);
    if (onCancel) {
      onCancel();
    }
    if (deleteImage) {
      deleteImage();
    }
  };

  const onCrop = (image: File) => {
    onCropComplete(image);
  };

  const modal = (
    <Modal
      centerTop="15%"
      padding="none"
      position="fixedCenter"
      size="3xl"
      isMenuOpen={isModalOpen}
      overlay
      bgColour="bg-transparent"
    >
      <ImageCropper
        resolution={resolution}
        label={label}
        onClose={() => {
          setIsModalOpen(false);
          if (onCancel) {
            onCancel();
          }
        }}
        setIsCroppingImage={setIsCroppingImage}
        onDelete={onDeleteImage}
        onCrop={onCrop}
        imageSrc={imageToBeCropped!}
      />
    </Modal>
  );
  return (
    <div className="flex flex-col">
      <InputHeader label={label} isMandatory={isMandatory} />
      <div>
        {!!imageToBeCropped && createPortal(modal, target)}
        <div className={`${isEnabled ? '' : 'opacity-40'}`}>
          <ImageInput
            autoMargins={autoMargins}
            fit={fit}
            isFullWidth={isFullWidth}
            customHeightPx={customHeightPx}
            customWidthPx={customWidthPx}
            imageUrl={imageUrl}
            onDrop={onDrop}
            isLoading={isLoading}
            isOldVersion={false}
            deleteImage={onDeleteImage}
            isCropping={isCroppingImage}
            progress={progress}
            onClick={onClick}
            isResizing={false}
            resolution={resolution}
            customPlaceholderSize={customPlaceholderSize}
            placeholderOrientation={placeholderOrientation}
            error={error}
            accept={accept}
            isUploadEnabled={isUploadEnabled}
            isIcon={isIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageCropperInput;
