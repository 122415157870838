import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { requestResponse } from '../../provider/HttpClient';
import {
  IItemOfInterestBlockItem,
  IItemOfInterestData
} from 'Colugo/interfaces/games/blocks';

@scoped(Lifecycle.ContainerScoped)
class ItemOfInterestOperations extends AbstractOperations {
  protected basePath: string = 'itemOfInterest';

  public async getDataForVenueInformationBlockItem(
    venueInformationBlockItem: IItemOfInterestBlockItem,
    isOnlyAddressField: boolean = false
  ): requestResponse<IItemOfInterestData> {
    return await this.executePost(
      `fromBlockItem?isOnlyAddressField=${isOnlyAddressField}`,
      venueInformationBlockItem
    );
  }
}

export default ItemOfInterestOperations;
