import React, { useState } from 'react';
import authMethods from './authMethods';

type Props = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<any>(undefined);

const AuthProvider = (props: Props) => {
  const initState = { email: '', password: '', password2: '', fullname: '' };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState<string[]>([]);
  const [token, setToken] = useState(authMethods.getToken());
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUp = async () => {
    setErrors([]);
    setIsLoading(true);
    await authMethods.signup(
      inputs.email,
      inputs.password,
      inputs.fullname,
      setErrors
    );
    setIsLoading(false);
  };

  const handleSignIn = async () => {
    setErrors([]);
    setIsLoading(true);
    await authMethods.signin(
      inputs.email,
      inputs.password,
      setErrors,
      setToken
    );
    setIsLoading(false);
    return true;
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    await authMethods.resetPassword(setErrors, inputs.email);
    setIsLoading(false);
    setErrors([]);
  };

  const resetState = () => {
    setInputs({ password: '', password2: '', email: '', fullname: '' });
    setIsLoading(false);
    setErrors([]);
  };

  const handleSignout = (message?: string) => {
    authMethods.signout(setErrors, setToken, message);
  };

  return (
    <AuthContext.Provider
      value={{
        handleSignUp,
        handleSignIn,
        token,
        inputs,
        setInputs,
        errors,
        setErrors,
        handleSignout,
        resetState,
        handleResetPassword,
        isLoading
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
