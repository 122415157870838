import { Options } from 'ky';
import VerbHttpClient from '../provider/VerbHttpClient';

const verbHttpClient = new VerbHttpClient();

abstract class AbstractOperations {
  protected abstract basePath: string;

  protected getUrl(path: string) {
    if (path.startsWith('/')) {
      return path.substring(1);
    } else {
      return `${this.basePath}/${path}`;
    }
  }
  protected async executePost(
    path: string,
    body?: any,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await verbHttpClient.postHttpClient(
      this.getUrl(path),
      body,
      options,
      fullyTyped
    );
  }

  protected async executeGet(
    path: string,
    options: Options = {},
    fullyTyped: boolean = false,
    noPrefixUrl: boolean = false
  ) {
    return await verbHttpClient.getHttpClient(
      this.getUrl(path),
      options,
      fullyTyped,
      noPrefixUrl
    );
  }

  protected async executePut(
    path: string,
    body?: any,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await verbHttpClient.putHttpClient(
      this.getUrl(path),
      body,
      options,
      fullyTyped
    );
  }

  protected async executeDelete(
    path: string,
    body?: any,
    options: Options = {},
    fullyTyped: boolean = false
  ) {
    return await verbHttpClient.deleteHttpClient(
      this.getUrl(path),
      body,
      options,
      fullyTyped
    );
  }

  protected async executeXHRAsync(
    method: 'POST' | 'GET' | 'PUT',
    path: string,
    file: File,
    onProgressUpdate?: (progress: number) => void
  ) {
    return await verbHttpClient.requestXHR(
      method,
      this.getUrl(path),
      file,
      onProgressUpdate
    );
  }
}

export default AbstractOperations;
