import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { ICategory } from 'Colugo/interfaces/common';
import { ITag } from 'Colugo/interfaces/tags/ITag';
import { useReqListTags } from 'Colugo/operations/tags';
import {
  reqAddCategoryToTag,
  reqRemoveCategoryFromTag,
  reqUpdateCategory,
  reqUpdateCategoryOrder
} from 'provider/admin/methods';
import React from 'react';
import { toast } from 'react-toastify';
import CategoryEditor from './CategoryEditor';
import { useReqListCategories } from 'Colugo/operations/categories/CategoryOperations';

type Props = {};

const CategoryContainer: React.FC<Props> = () => {
  const {
    data: categories,
    isLoading,
    isError,
    mutate
  } = useReqListCategories();

  const { data: tags } = useReqListTags();

  if (isLoading || !categories) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load categories from server.'}</span>
      </ErrorPage>
    );
  }

  const handleUpdateCategory = async (categoryToUpdate: ICategory) => {
    const result = await reqUpdateCategory(categoryToUpdate);

    if (!result.data || result.error) {
      toast.error(
        'Failed to update category, please try again. Category name and key must be unique.'
      );
      return false;
    }

    const newCategories = categories.map((category) =>
      category === categoryToUpdate ? result.data : category
    );

    categoryToUpdate.updatedAt = result.data.updatedAt;

    mutate(newCategories, true);
    return true;
  };

  const handleToggleCategoryTag = async (
    tag: ITag,
    categoryId: string,
    isAdded: boolean
  ) => {
    if (!tag) {
      toast.error('tag undefined');
    }

    if (isAdded) {
      const result = await reqAddCategoryToTag(tag.id!, categoryId);

      if (!result.data || result.error) {
        toast.error('Failed to add Tag to category, please try again.');
        return false;
      }

      const newCategories = categories.map((category) => {
        if (category.id === categoryId) {
          category.tags?.push(tag);
        }
        return category;
      });

      mutate(newCategories);
    } else {
      const result = await reqRemoveCategoryFromTag(tag.id!, categoryId);

      if (!result.data || result.error) {
        toast.error('Failed to remove Tag from category, please try again.');
        return false;
      }

      const newCategories = categories.map((category) => {
        if (category.id === categoryId) {
          category.tags = category.tags?.filter((t) => t.id !== tag.id);
        }
        return category;
      });

      mutate(newCategories);
    }

    return true;
  };

  const handleUpdateCategoryOrder = async (
    categoryToUpdate: ICategory,
    position: number
  ) => {
    const result = await reqUpdateCategoryOrder(
      categoryToUpdate.id!,
      position || 0
    );

    if (!result.data || result.error) {
      toast.error('Failed to update category order, please try again');
      return undefined;
    }

    const newCategories = categories.map((category) =>
      category.id === categoryToUpdate.id
        ? { ...category, order: position }
        : category
    );

    mutate(newCategories, true);

    const orderedCategory = result.data.find(
      (c) => c.id === categoryToUpdate.id
    );
    categoryToUpdate.updatedAt = orderedCategory && orderedCategory.updatedAt;
    categoryToUpdate.order = orderedCategory && orderedCategory.order;
    return categoryToUpdate;
  };

  return (
    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
      <CategoryEditor
        categories={categories}
        updateCategory={handleUpdateCategory}
        onChangeCategoryOrder={handleUpdateCategoryOrder}
        onAddTagToCategory={handleToggleCategoryTag}
        tags={tags!}
      />
    </div>
  );
};

export default CategoryContainer;
