import React from 'react';
import FilterGroupsEditor from './FilterGroupsEditor';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import { container } from 'tsyringe';
import {
  FilterGroupOperations,
  useReqListFilterGroups
} from 'Colugo/operations/tags';
import { toast } from 'react-toastify';

const filterGroupOperations = container.resolve(FilterGroupOperations);

function FilterGroupsContainer() {
  const {
    data: filterGroups,
    isLoading: isFilterGroupLoading,
    isError: isFilterGroupError,
    mutate: mutateFilterGroups
  } = useReqListFilterGroups();

  const handleCreateFilterGroup = async (filterGroupToAdd: IFilterGroup) => {
    const { data, error } = await filterGroupOperations.create(
      filterGroupToAdd
    );

    if (!data || error) {
      if (error?.status === 409) {
        toast.error(
          'Failed to create filter group, most likely filter group name already exists.'
        );
      } else {
        toast.error('Failed to create filter group, please try again');
      }
      return false;
    }

    const newfilterGroups = [...(filterGroups || []), data];
    mutateFilterGroups(newfilterGroups, true);
    return true;
  };

  const handleUpdateFilterGroup = async (filterGroupToUpdate: IFilterGroup) => {
    const { error } = await filterGroupOperations.update(filterGroupToUpdate);

    if (error) {
      toast.error(
        'Failed to update filter group, please try again. Filter Group name must be unique.'
      );
      return false;
    }

    const newfilterGroups =
      filterGroups &&
      filterGroups.map((filterGroup) =>
        filterGroup.id === filterGroupToUpdate.id
          ? filterGroupToUpdate
          : filterGroup
      );

    toast.success('FilterGroup successfully updated');

    mutateFilterGroups(newfilterGroups, false);
    return true;
  };

  const handleDeleteFilterGroup = async (filterGroupToDelete: IFilterGroup) => {
    if (!filterGroupToDelete?.id) {
      toast.error('Invalid filter group Id, please try again');
      return false;
    }

    const { error } = await filterGroupOperations.delete(
      filterGroupToDelete.id
    );

    if (error) {
      toast.error('Failed to delete filter group, please try again');
      return false;
    }

    const newfilterGroups = filterGroups?.filter(
      (filterGroup) => filterGroup.id !== filterGroupToDelete.id
    );
    mutateFilterGroups(newfilterGroups, true);
    return true;
  };

  if (isFilterGroupLoading) {
    return <Spinner />;
  }

  if (isFilterGroupError) {
    return (
      <ErrorPage>
        <span>{'Unable to load filter groups from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
      <FilterGroupsEditor
        filterGroups={filterGroups || []}
        addFilterGroup={handleCreateFilterGroup}
        updateFilterGroup={handleUpdateFilterGroup}
        deleteFilterGroup={handleDeleteFilterGroup}
      />
    </div>
  );
}

export default FilterGroupsContainer;
