import { IUserReport } from 'Colugo/interfaces/moderation/IUserReport';
import IPostComment from 'Colugo/interfaces/social/IPostComment';

type Props = {
  comments?: IPostComment[];
  selectedUserReport?: IUserReport;
};

export default function ReportPostComments(props: Props) {
  const { comments = [], selectedUserReport } = props;
  return (
    <div className="mb-2">
      <span className="font-semibold">Comments:</span>
      <div className="overflow-y-auto" style={{ maxHeight: `280px` }}>
        {comments.map((comment, index) => {
          return (
            <div key={index}>
              <div
                className={`flex flex-row ${
                  comment.id === selectedUserReport?.entity?.id
                    ? 'bg-error1'
                    : ''
                }`}
              >
                <b>{comment.user?.tag}</b> :
                <div className={`ml-1`}>{comment.text}</div>
              </div>
              {comment.replies?.map((commentReply, j) => {
                return (
                  <div
                    className={`ml-4 flex flex-row  ${
                      commentReply.id === selectedUserReport?.entity?.id
                        ? 'bg-error1'
                        : ''
                    }`}
                    key={j}
                  >
                    <b>{commentReply.user?.tag}</b> :
                    <div className={`ml-1`}>{commentReply.text}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
